import { useContext, useEffect, useState } from "react";
import PnlChart from "../../components/BacktestResult/PnlChart";
import IsFetching from "../../components/IsFetching";
import Properties from "../../components/Properties";
import { formatDuration, parseSearchParams } from "../../utils/functions";
import { getProperty } from "../Settings";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { useLocation } from "react-router-dom";
import useUrlFilters from "../../hooks/useUrlFilters";
import StatusDisplay from "../../components/Backtests/StatusDisplay";
import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";
import { getRightOrderData, statusEnum } from "../../components/MainLayouts/BacktestLayout";
import FreeBalanceChart from "../../components/BacktestResult/FreeBalanceChart";

//export const backtestId = "3bf, no slip(dyn), 1h, com_cash False V9"

export function BacktestGeneralPage() {

    const [currentSubBacktest, setCurrentSubBecktest] = useState({})
    const [backtestRightOrder, setBacktestRightOrder] = useState({})
    const [additionalData, setAdditionalData] = useState({})
    const [filters, setFilters] = useState([]);


    const {
        backtestByIdData,
        isFetchingBacktestById,
        backtestByIdStatsData,
        isFetchingBacktestByIdStats,
        mainAsset,
    } = useContext(BacktestResultsContext)

    const location = useLocation();

    const isSomeSubBackTest = backtestByIdStatsData?.backtest.length > 1

    const filterOptions = {
        pair: {
            type: 2,
            values: backtestByIdStatsData?.pairs,
        },
    }

    useUrlFilters(filters, setFilters, filterOptions);

    const searchParams = new URLSearchParams(location.search);
    const initialFiltersFull = parseSearchParams(searchParams);

    const initialFilters = [];

    initialFiltersFull.forEach(value => {

        if (Object.keys(filterOptions).includes(value.id)) {
            initialFilters.push({ id: value.id, value: value.value });
        }

    });

    useEffect(() => {
        const hasPairFilter = initialFilters.some(filter => filter.id === 'pair');

        if (!hasPairFilter && backtestByIdStatsData && isSomeSubBackTest) {
            const pairValue = backtestByIdStatsData?.pairs[0];
            if (pairValue) {

                const newFilters = [...initialFilters, { id: 'pair', value: pairValue }];

                setFilters(newFilters);

            }
        }

    }, [backtestByIdStatsData]);

    useEffect(() => {

        const additionalDataKeys = [
            'sharpe_ratio',
            'sortino_ratio',
            'omega_ratio',
            'calmar_ratio',
            'profit_factor',
            'max_drawdown_duration',
            'max_drawdown',
            'expectancy',
        ];

        if (!backtestByIdStatsData) return;

        if (isSomeSubBackTest) {

            const subBacktestByPair = backtestByIdStatsData.backtest.find(el => filters.length > 0 ? el.pairs[0] === filters[0]?.value : el.pairs[0] === backtestByIdStatsData.pairs[0]);


            const currentSubBacktestRightOrder = getRightOrderData(subBacktestByPair, backtestByIdData);


            setCurrentSubBecktest(subBacktestByPair)
            setBacktestRightOrder(currentSubBacktestRightOrder)
        } else {

            const currentSubBacktestRightOrder = getRightOrderData(backtestByIdStatsData.backtest[0], backtestByIdData);
            const currentAddData = Object.entries(backtestByIdStatsData.backtest[0]).filter(([key, value]) => additionalDataKeys.includes(key)).reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});;

            setAdditionalData(currentAddData)

            setCurrentSubBecktest(backtestByIdStatsData.backtest[0])
            setBacktestRightOrder(currentSubBacktestRightOrder)
        }
    }, [backtestByIdStatsData, filters])

    if (statusEnum[backtestByIdData?.status] !== 'Finished') {
        return (
            <StatusDisplay status={statusEnum[backtestByIdData?.status]} />

        )
    }

    if (isFetchingBacktestByIdStats || isFetchingBacktestById) {
        return <IsFetching />
    }

    let formatedData = {}

    for (const [key, value] of Object.entries(backtestRightOrder)) {

        if (key.includes("commission")) {
            formatedData[key] = `${getProperty(key, value, mainAsset)} ${mainAsset}`;
        } else if (key.includes("time")) {
            formatedData[key] = getProperty(key, value).split(' ')[0];
        } else if (key.includes("period")) {
            formatedData[key] = formatDuration(value);
        }

        else {
            formatedData[key] = getProperty(key, value, mainAsset);
        }
    }

    if (statusEnum[backtestByIdData.status] === 'Finished') {
        return (
            <>
                {isSomeSubBackTest && (
                    <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} isApply={true} />
                )}
                <div className="itemPage">
                    {Object.keys(currentSubBacktest).length > 0 && (
                        <>
                            <Properties
                                data={currentSubBacktest}
                                formatedData={formatedData}
                                additionalData={additionalData}
                            />

                            <PnlChart
                                data={currentSubBacktest.pnl_chart}
                                startBalance={currentSubBacktest.start_balance}
                                mainAsset={mainAsset}
                                dataKey="pnl_cumulative"
                            />
                            {
                                currentSubBacktest.pnl_chart?.pnl_closed &&
                                <PnlChart
                                    data={currentSubBacktest.pnl_chart}
                                    startBalance={currentSubBacktest.start_balance}
                                    mainAsset={mainAsset}
                                    dataKey="pnl_closed"
                                />
                            }
                            {
                                currentSubBacktest.pnl_chart?.free_balance &&
                                <FreeBalanceChart
                                    data={currentSubBacktest.pnl_chart}
                                    startBalance={currentSubBacktest.start_balance}
                                    mainAsset={mainAsset}
                                />
                            }
                        </>

                    )}

                </div>
            </>
        )
    }




}