import Icon from "../Icon";

const ActionButton = ({ action, onClick, className, disabled=false }) => {
    return (
        <button className={`button ${className}`} onClick={onClick} disabled={disabled}>
            <Icon type={action} />
            <span>{action}</span>
        </button>
    )
}

export default ActionButton;