import { calculatePrecisionForUniqueTicks, formatEnamKey, formatFloat, formatTimestemp } from "../../utils/functions";
import LineChart from "../Charts/LineChart";

import * as styles from '../../styles/utils/_variables.scss';
import { format } from "date-fns";
import { useState } from "react";
import ChartTabs from "../Charts/ChartTabs";

const AccountsTotalBalanceChart = ({ data = [] }) => {

  const modesArray = ['Cumulative PnL', 'Total Balance'];

  const [mode, setMode] = useState(0)

  const tooltipsAccountChart = {
    'Cumulative PnL': 'Зміна загального балансу у відсотках (по закритим та відкритим угодам)',
    'Total Balance': 'Зміна загального балансу в BTC (по закритим та відкритим угодам)'
  }

  const getY = (value, dataArray, i) => {

    switch (modesArray[mode]) {
      case 'Total Balance':
        return value.balance;

      default:
        return dataArray[i]

    }
  }

  const getTableTitle = () => {
    switch (modesArray[mode]) {
      case 'Total Balance':
        return 'Account Total Balance'

      default:
        return 'Account Cumulative PnL'

    }
  }

  const getData = (dataArray) => {
    let cumulativePnL = 0;
    let cumulativePnLData = [0];

    for (let i = 1; i < dataArray.length; i++) {
      let prevBalance = dataArray[i - 1]?.balance;

      //let currentBalance = dataArray[i]?.balance - dataArray[i]?.force_difference ;
      let currentBalance = dataArray[i]?.balance;
      let currentPnL = currentBalance - prevBalance;
      let currentPnLPercent = (prevBalance === 0) ? currentPnL : currentPnL / (prevBalance);

      cumulativePnL += currentPnLPercent;
      cumulativePnLData.push(cumulativePnL);
    }

    return dataArray.map((el, i) => {
      return {
        x: el.update_time,
        y: getY(el, cumulativePnLData.map(el => el * 100), i),
      }
    })
  }

  const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : styles.default.colorCandleDown;

  const chartData = {
    datasets: [
      {
        data: getData(data),

        fill: false,
        type: 'line',
        label: data[0]?.account_id,
        radius: 1,
        //borderColor: styles.default.colorGray,
        backgroundColor: 'transparent',
        hoverRadius: 5,
        borderWidth: 2,
        segment: {
          borderColor: ctx => down(ctx, styles.default.colorCandleUp)
        }
      }
    ]
  }

  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        title: {
          text: 'Datetime',
        },
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          callback: function (value) {
            const date = new Date(value);
            const timeString = format(date, 'HH:mm');
            const dateString = format(date, 'dd.MM.yyyy');
            return [timeString, dateString];
          },
        }
      },
      y: {
        title: {
          text: modesArray[mode],
        },
        ticks: {

          callback: function (value, index, ticks) {
            const precision = calculatePrecisionForUniqueTicks(ticks);
            return (
              (modesArray[mode] === 'Cumulative PnL')
                ? value.toFixed(2) + '%'
                : parseFloat(value).toFixed(precision) + ' BTC'
            )

          }
        },
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context) {
            const x = context[0].raw.x

            return `Time: ${formatTimestemp(x)}`
          },

          label: function (context) {
            const { y } = context.raw;

            return modesArray[mode] === 'Cumulative PnL' ? `PnL: ${y.toFixed(2)} %` : `Balance: ${y.toFixed(9)} BTC`
          }
        }
      },
      title: {
        text: getTableTitle(),
      },
      legend: {
        display: true,

        align: 'end',
        labels: {
          usePointStyle: true,
        }
      }
    }
  };
  return (
    <div className="flexColumnGap5" style={{ gap: 0 }}>
      <ChartTabs
        mode={mode}
        setMode={setMode}
        modesArray={modesArray}
        tooltips={tooltipsAccountChart}
      />

      <div>
        <LineChart
          chartData={data.length === 0 ? null : chartData}
          options={chartOptions}
        />
      </div>
    </div>
  )
}

export default AccountsTotalBalanceChart;