import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFetchMlModelVersionQuery, useFetchMlModelVersionBacktestsQuery, useMlModelVersionBacktestListRefreshMutation } from '../../redux/statisticsServices/mlModelsApi';
import { classnames, formatDurationSec, formatEnamKey, formatFloat } from '../../utils/functions';
import Table from '../../components/Table/Table';
import LineChart from '../../components/Charts/LineChart';
import IsFetching from '../../components/IsFetching';
import Properties from '../../components/Properties';
import * as styles from '../../styles/utils/_variables.scss';
import React, { useEffect } from "react";
import { getProperty } from '../Settings';
import Icon from '../../components/Icon';
import useMobileView from '../../hooks/useMobileView';
import BackButton from '../../components/Buttons/BackButton';
import Button from '../../components/Buttons/Button';
import { toast } from 'react-toastify';

export function MlModelVersionPage() {
    const { mlModelName, versionMlModel } = useParams();
    const navigate = useNavigate();

    const isMobile = useMobileView();

    const { data: mlModelVersionData, isFetching: isFetchingVersion } = useFetchMlModelVersionQuery({
        modelName: mlModelName,
        version: versionMlModel
    });


    const { data: mlModelVersionBacktestsData, isFetching: isFetchingBacktests, refetch } = useFetchMlModelVersionBacktestsQuery({
        modelName: mlModelName,
        version: versionMlModel
    });

    const [triggerMlModelVersionBacktestListRefresh, { 
        isLoading: isLoadingMlModelVersionBacktestListRefresh, 
        isSuccess: isSuccessMlModelVersionBacktestListRefresh 
    }] = useMlModelVersionBacktestListRefreshMutation();

    useEffect(() => {
        refetch();
    }, [isSuccessMlModelVersionBacktestListRefresh])

    if (isFetchingVersion || isFetchingBacktests) {
        return (
            <div className="page">
                <IsFetching />
            </div>
        )
    }
    const { name, version, payload, intellect_rate, production_ready, training_start_month, training_end_month, category_breakdown, buy_hold_pnl_percent, ...restData } = mlModelVersionData;

    const data = mlModelVersionData.intellect_rate.map(el => {
        return {
            x: el[0],
            y: el[1]
        }
    });

    const chartData = {
        datasets: [
            {
                data,
                borderColor: styles.default.colorBlue,
                label: formatEnamKey('rate'),
                fill: false,
                type: 'line',
                radius: isMobile ? 3 : 7,
                hoverRadius: 15,
            }
        ]
    };


    const chartOptions = {
        scales: {
            x: {
                type: 'linear',
                beginAtZero: true,
                
                title: {
                    text: 'Iterations', // Y-axis title
                },
            },
            y: {
                min: 0,
                max: 1,
                ticks: {
                    stepSize: 0.1, // Optional: Adjust the interval between ticks
                },
                title: {
                    text: 'Intellect', // Y-axis title
                },
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: function (context) {
                        const x = context[0].raw.x

                        return `Iteration: ${x}`
                    }
                }
            },
            title: {
                text: formatEnamKey('intellect_rate'),
            },
            legend: {
                display: false
            }
        }
    };

    const tableData = mlModelVersionBacktestsData.map(el => {
        const { ml_model_name, ml_model_version, ...rest } = el;
        return { ...rest }
    })
    const hiddenColumns = ['details', 'result_summary', 'best_pair', 'best_pair_trades'];

    const tableColumns = tableData[0] && Object.keys(tableData[0])
        .filter(key => !hiddenColumns.includes(key)) // Фільтруємо ключі, які не мають бути показані
        .map(key => ({
            accessorKey: key,
            header: formatEnamKey(key),
            filterFn:  'myCustomFilter',
            filterable: true,
            sortable: true,
            size: '25%',
            cell: info => {
                const value = info.getValue();

                if (key === 'backtest_link_id') {
                    return (
                        <Link to={`/backtests/backtestId/${value}/general`} >
                            <button className="button tableButton" >
                                Show results
                            </button>
                        </Link>
                    );
                } else {
                    return key === 'pnl' ? (
                        <div
                            style={{ color: value > 0 ? styles.default.colorGreen : styles.default.colorRed }}
                            className='flexGap5'
                        >
                            <Icon type={value > 0 ? 'up' : 'down'} size={10} />
                            {`${formatFloat(value)} %`}
                        </div>
                    ) : getProperty(key, value);
                }
            },
        }))

    const trade_settings = {
        "type": "Тип моделі",
        "source_types": "Тип даних які використовувала модель",
        "num_iterations": "Кількість ітерацій",
        "training_duration": "Час навчання моделі",
        "training_start_date": "Дата початку навчання моделі",
        "training_end_date": "Дата закінчення навчання моделі",
        "iteration_duration": "Час за який моделька проходить одну ітерацію",
        "training_intervals": "Часові інтервали про які знає модель",
        "quality_assessment": "Метрика, яка описує здатність моделі помилятись",
        "testing_start_date": "Період на якому тестувалась модель (дата початку)",
        "testing_end_date": "Період на якому тестувалась модель (дата закінчення)",
        "avg_monthly_trades": "Середня кількість трейдів за місяць",
        "initial_pnl_percent": "PnL моделі до початку навчання",
        "final_pnl_percent": "PnL моделі після того як вона пройшла всі ітерації",
        "status": "Статус моделі"
    }

    let formatedData = {}

    for (const [key, value] of Object.entries(restData)) {
        if (key.includes("training_duration") || key.includes("iteration_duration")) formatedData[key] = formatDurationSec(value);
        else
            formatedData[key] = getProperty(key, value);
    }

    const handleNavigateBacktests = () => {
        navigate(`/backtests/new?strategy=${mlModelName}@${versionMlModel}`)
    }

    const handleRefresh = async () => {
        try {
            await  triggerMlModelVersionBacktestListRefresh({
                modelName: mlModelName, 
                versionName: versionMlModel,
            });
            toast.success("The backtest list has been updated")
        } catch (error) {
            console.error("BacktestList update error", error);
            toast.error("BacktestList update error", error.message)
        }
      };


    return (
        <div className="itemPage">
            <div className="flexSpaceBetween titleMargin">
                <div className="flexGap10">
                    <BackButton path={`/ml_stat/ml_model/${mlModelName}`} />
                    <h1 style={{ marginLeft: 0 }}>{mlModelName}</h1>
                   
                </div>
                 <Button 
                    className="button--widthMaxContent"
                    onClick={handleRefresh}
                    disabled={isLoadingMlModelVersionBacktestListRefresh && !isSuccessMlModelVersionBacktestListRefresh}
                 >
                    <Icon 
                        type="refreshTwoArrow" 
                        size={20} 
                        className={classnames('', {'spin': isLoadingMlModelVersionBacktestListRefresh})}
                    />
                </Button>
            </div>
           

            <h2>Version: {versionMlModel}</h2>

                {mlModelVersionData.payload.Custom
                    && (
                        <div className='itemPage__customField'>{mlModelVersionData.payload.Custom}</div>
                    )}
                <Properties data={restData} formatedData={formatedData} trade_settings={trade_settings} />

                <LineChart
                    chartData={chartData}
                    options={chartOptions}
                    zoomEnabled={false}
                />

                {tableData.length > 0 && (
                    <Table
                        title={'Backtests'}
                        tableData={tableData}
                        tableColumns={tableColumns}
                        isPinned={false}
                    />
                )}

                <Button className="button--alignEnd" onClick={handleNavigateBacktests}>
                    Backtest
                </Button>
            
    </div>
    )
}
