import DoughnutChart from "../Charts/DoughnutChart";
import { pick_colors_doughnut } from "../../utils/variables";
import { hexToRgb } from "../../utils/functions";

const brightenedColors = pick_colors_doughnut.map(hex => {
  let color = hexToRgb(hex);
  return `rgba(${color.r + 30}, ${color.g + 30}, ${color.b + 30}, 1)`; 
});


const PositionsDoughnutChart = ({ data }) => {

    const chartData = {
        labels: data.map(d => d.label),
        datasets: [
          {
            label: 'Count',
            data: data.map(d => d.amount), 
            backgroundColor: pick_colors_doughnut,
            hoverBackgroundColor: brightenedColors,
          }
        ]
      };
    const options = {
        plugins: {
            title: {
                text: 'Positions', 
              },
        }
    }
    return (
        <DoughnutChart options={options} data={chartData}/>
    )
}

export default PositionsDoughnutChart;