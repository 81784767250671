import { memo, useEffect, useMemo } from "react"
import FormModel from "../../components/FormModels/FormModel"
import { getFilledModel } from "../../utils/functions";
import { ballanceControl__model } from "../FormModels/formConfig";

const BallanceControl = memo(({ model, setModel, mainAsset }) => {

    const slippageToggle = useMemo(() => model.find(el => el.key === 'slippage_toggle')?.value, [model]);

    useEffect(() => {
        if(slippageToggle) {
            setModel(getFilledModel(model,  ballanceControl__model.filter(el => el.key !== 'slippage'))) 
        } else {
            setModel(getFilledModel(model,  ballanceControl__model))
        }
        
    }, [slippageToggle]);

    useEffect(() => {
        let newModel = [...model];
        newModel = getFilledModel(newModel, ballanceControl__model).map(el => {
            switch(el.key) {
                case  'initial_equity': 
                    return {
                        ...el,
                        title: `Opening ballance (${mainAsset})`,
                        value: mainAsset === 'BTC' ? 1 : 100000,

                    };

                case  'position_size': 
                    return {
                        ...el,
                        value: mainAsset === 'BTC' ? 0.01 : 1000,
                    };
                
                default:
                    return el;
            }
        })

        setModel(newModel)

    }, [mainAsset])

     useEffect(() => {
        setModel(ballanceControl__model.filter(el => el.key !== 'slippage'))
    }, [])

    return (
        <div className="flexColumnGap5 backgroundContainer" style={{ gap: 10 }}>
            <FormModel inputModels={model} setInputModels={setModel} />
        </div>
    )
    
   
}) 

export default BallanceControl;