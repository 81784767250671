import { format } from "date-fns";

export const formatEnamKey = (input) => {

  const replaced = input.replace(/_/g, ' ');

  const formatted = replaced.charAt(0).toUpperCase() + replaced.slice(1).toLowerCase();
  return formatted;
}

export const getFilteredArray = (array, filters) => {
  return array.filter(el =>
    filters.every(filter => {
      const valueToCompare = typeof el[filter.id] === 'number' ? Number(filter.value) : filter.value;
      return el[filter.id] === valueToCompare;
    })
  );
};


export const formatNumber = (value) => {
  if (typeof value === 'number') {
    const strValue = value.toString();
    const decimalPointIndex = strValue.indexOf('.');
    if (decimalPointIndex !== -1 && (strValue.length - decimalPointIndex - 1) > 9) {
      return value.toFixed(9);
    }
  }
  return value;
};

export const formatDuration = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000) % 60;
  const minutes = Math.floor(milliseconds / 60000) % 60;
  const hours = Math.floor(milliseconds / 3600000) % 24;
  const days = Math.floor(milliseconds / 86400000);

  const parts = [
    days > 0 && `${days}d`,
    (days > 0 || hours > 0) && `${hours}h`,
    (days > 0 || hours > 0 || minutes > 0) && `${minutes}m`,
    `${seconds}s`
  ];

  return parts.filter(Boolean).join(' : ');
};

export const formatDurationSec = (seconds) => {
  const sec = seconds % 60;
  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(seconds / 3600) % 24;
  const days = Math.floor(seconds / 86400);

  const parts = [
    days > 0 && `${days}d`,
    (days > 0 || hours > 0) && `${hours}h`,
    (days > 0 || hours > 0 || minutes > 0) && `${minutes}m`,
    `${sec}s`
  ];

  return parts.filter(Boolean).join(' : ');
};

export const convertEnam = (enam) => {
  return Object.fromEntries(
    Object.entries(enam).map(([key, value]) => [value, key]))
}

export const buildQueryString = (filters) => {
  if (!filters || filters.length === 0) return '';

  const params = new URLSearchParams();
  const filterMap = {};


  filters.forEach(({ id, value }) => {
    if (!filterMap[id]) {
      filterMap[id] = [];
    }
    filterMap[id].push(value);
  });


  Object.keys(filterMap).forEach(key => {
    const valueArray = filterMap[key].flat();
    params.append(key, valueArray.join('|'));
  });

  return `?${params.toString()}`;
};

export const parseSearchParams = (searchParams) => {
  const filters = [];

  for (const [key, value] of searchParams.entries()) {
    filters.push({ id: key, value: (value.includes('|') || key.includes('_in')) ? value.split('|') : value });
  }


  return filters;
};

export const formatTimestemp = (timestamp) => {
  return format(new Date(timestamp), 'HH:mm dd.MM.yyyy')
}

export const formatTimestempUTC = (timestamp) => {
  const date = new Date(timestamp);
  const minutesOffset = date.getTimezoneOffset();
  date.setTime(date.getTime() + minutesOffset * 60 * 1000)
  return format(date, 'HH:mm dd.MM.yyyy')
}

export const formatTimestempUTCTable = (timestamp) => {
  const date = new Date(timestamp);
  const minutesOffset = date.getTimezoneOffset();
  date.setTime(date.getTime() + minutesOffset * 60 * 1000)
  return format(date, 'yyyy-MM-dd HH:mm:ss')
}

export const formatFloat = (num) => {
  if (typeof num === "number") {
    if (Number.isInteger(num)) {
      return num.toString();
    } else if (num === 0) {
      return '0.00';
    } else {
      if (typeof num === "number") num = num.toFixed(20);
      let numStr = num.toString();
      let decimalIndex = numStr.indexOf('.');

      // If there's no decimal point or if the number has no fractional part, return 0
      if (decimalIndex === -1 || decimalIndex === numStr.length - 1) {
        return 0;
      }

      // Count zeros after the decimal point
      let zeroCount = 0;
      for (let i = decimalIndex + 1; i < numStr.length; i++) {
        if (numStr[i] === '0') {
          zeroCount++;
        } else {
          break;
        }
      }

      const formattedNumber = parseFloat(num).toFixed(zeroCount + 2);
      return formattedNumber;
    }
  }
  return num;
}

export const getFilledModel = (model, modelPrototype) => {
  const newModel = modelPrototype.map(el => {
    const filledElement = model.find(e => e.key === el.key);
    return filledElement
      ? {
        ...el,
        value: filledElement.value,
        ...(filledElement.values && { values: filledElement.values })
      }
      : el
  })

  return newModel;
}


export const convertModelToObject = (model) => {
  return model.filter(el => el.type !== 'header').reduce((res, el) => {
    res[el.key] = el.value
    return res;
  }, {})
}

export const reorderKeys = (data, keyOrder) => {
  return data && data.map(item => {
    const newItem = {};
    keyOrder.forEach(key => {
      newItem[key] = item[key];
    });
    return newItem;
  });
}

export const getColumnWidth = (tabletWidth, fullScreenWidth) => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 1200) {
    return tabletWidth;
  } else {
    return fullScreenWidth;
  }
}

export function calculatePrecisionForUniqueTicks(ticks) {
  let precision = 2;
  let unique = false;

  while (!unique) {
    let formattedValues = ticks.map(t => parseFloat(t.value).toFixed(precision));
    let uniqueValues = new Set(formattedValues);
    if (uniqueValues.size === ticks.length) {
      unique = true;
    } else {
      precision++;
      if (precision > 20) {
        console.error("Unable to achieve unique tick labels with max precision of 100.");
        precision = 20;
        break;
      }
    }
  }

  return precision;
}

export function hexToRgb(hex) {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
}

export const formValidation = (model, setModel) => {
  const validatedModel = model.map(el => {
    return  el.validation && (!el.value || el.value.length === 0)
    ? {...el, error: true}
    : el
  });

  setModel(validatedModel);
  return validatedModel.some(el => el.error)
}

export const classnames = (mainClass, conditions={}, additionClasses = []) => {
  return `${mainClass} ${Object.entries(conditions).filter(el => el[1]).map(el => el[0]).join(' ')} ${additionClasses.join(' ')}`
}

export const filledInputs = (config, inputs) => {
  return config.map(el => {
    return {
      ...el, value: inputs[el.key] !== undefined  ? inputs[el.key] : el.value
    }
    
})
}