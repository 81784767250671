import { memo, useEffect } from "react"
import FormModel from "../../components/FormModels/FormModel"
import { blacklistSettings__model } from "../FormModels/formConfig";

const BlacklistSettings= memo(({ model, setModel }) => {

    useEffect(() => {
        setModel(blacklistSettings__model) 
    }, []);

    return (
        <div className="backgroundContainer">
            <FormModel inputModels={model} setInputModels={setModel} />
        </div>
    ) 
}) 

export default BlacklistSettings;