import { Link, useLocation } from "react-router-dom";
import { getProperty } from "../../pages/Settings";
import { formatEnamKey } from "../../utils/functions";
import Table from '../Table/Table';
import Icon from "../Icon";
import useMobileView from "../../hooks/useMobileView";
import useTabletView from "../../hooks/useTabletView";
import useValueDependingOnView from "../../hooks/useValueDependingOnView";


const PositionsTable = ({ data = [], title, isOpen, positionsTableClick }) => {
    const getValueDependingView = useValueDependingOnView()
    const location = useLocation();
    const isMobile = useMobileView();
    const isTablet = useTabletView();

    const tableData = data.map(el => {                      
        const { position_id, is_closed, strategy, pair, position_start_timestamp, position_update_timestamp, position_end_timestamp, percent_pnl } = el;
     
        return {
            strategy,
            pair,
            position_start_timestamp,
            ...(isOpen ? {position_update_timestamp} : {}),
            ...(isOpen ? {} : { position_end_timestamp }),
            percent_pnl,
            is_closed,
           
            binance: '',
            details: '',
            position_id,
        };
    });

    const columnsWidth = getValueDependingView(
        [170, 125, 180, 180, 110, 110, 30, 130], [120, 100, 125, 125, 100, 70, 25, 100], [120, 100, 130, 130, 100, 80, 25, 120]
    );
    //const columnsWidthClose = isMobile ? [120, 90, 130, 130, 70, 60, 25, 110] : [150, 100, 160, 160, 70, 50, 30, 110];

    const getHeader = (key) => {
        switch(key) {
            case 'strategy':
                return 'Model';

            case 'binance':
                return <Icon type="binance" size={isMobile ? 20 : 25}/>;

            default: 
                return formatEnamKey(key.replace('timestamp', ''));
        }
    }

    const handleClickBinance = (pair) => {
        window.open(`https://www.binance.com/en/trade/${pair.replace('/', '_')}?_from=markets&type=spot`, '_blank', 'noopener,noreferrer');
      };


    const tableColumns = tableData[0] && Object.keys(tableData[0]).slice(0,-1).map((key, i) => ({
        accessorKey: key,
        header:  getHeader(key),
        filterFn:  'myCustomFilter',
        filterable: !(key === 'details') && !(key === 'is_closed') && !(key === 'binance'),
        sortable:  !(key === 'details') && !(key === 'is_closed') && !(key === 'binance'),
        size: columnsWidth[i],

        cell: info => {

            const value = info.getValue();           

            switch (key) {
                case 'strategy':
                    return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                            <button className="link" onClick={() => positionsTableClick('strategy_in', [value])} >
                                {getProperty(key, value)}
                            </button>                 
                    );

                case 'pair':
                    return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        <button className="link" onClick={() => positionsTableClick('pair_in', [value])} >
                           <div>{getProperty(key, value)}</div> 
                        </button>                 
                    );

                case 'position_update_timestamp':
                    return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        <div className="textOverflowEllipsis " style={{ whiteSpace: 'nowrap'}} >
                          {info.row.original.position_update_timestamp === info.row.original.position_start_timestamp ? '-' :  getProperty(key, value)}
                        </div>                 
                    );

                case 'binance':
                    const pairValue = info.row.original.pair;
                    return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        <button className="link"  onClick={() => handleClickBinance(pairValue)}  >
                           <Icon type="binance" size={isMobile ? 20 : 25}/> 
                        </button>                 
                    );
                

                case 'details':
                    return (
                        <Link to={`position/${info.row.original.position_id}${location.search}`} >                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                            <button className="button tableButton" >
                                Show details
                            </button>
                        </Link>                                 
                    );

                default:
                    return getProperty(key, value);
            }
        },
    }))

    const trade_settings = isOpen 
    ? {
        "strategy": "Модель",
        "pair": "Торгова пара по якій відкрита угода",
        "position_start_timestamp":"“Дата та час відкриття угоди",
        "position_update_timestamp": "Дата та час останньої докупки по цій угоді",
        "percent_pnl": "PnL угоди на даний момент",
        "is_closed": "Чи закрита угода"
    }
    :  {
        "strategy": "Модель",
        "pair": "Торгова пара",
        "position_start_timestamp": "Дата та час відкриття угоди",
        "position_end_timestamp": "Дата та час закриття угоди",
        "percent_pnl": "PnL угоди на даний момент",
        "is_closed": "Чи закрита угода"
    }

    return (
        <Table
            tableData={tableData.length === 0 ? null : tableData}
            tableColumns={tableColumns}
            title={title}
            csvData={data}
            trade_settings={trade_settings}
        />


    )
}

export default PositionsTable;