import React, { useState, useEffect } from 'react';

const TimeProgressBar = ({ startDate, endDate }) => {
  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    const now = new Date().getTime();
    if (now >= endDate) {
      setProgress(100);
    } else {
      const totalDuration = endDate - startDate;
      const timePassed = now - startDate;
      const progressPercent = (timePassed / totalDuration) * 100;
      setProgress(progressPercent);
    }
  };

  useEffect(() => {
    updateProgress(); 
    const interval = setInterval(updateProgress, 10000);

    return () => clearInterval(interval);
  }, [startDate, endDate]);

  return (
    <div className="timeProgressBar" style={{ width: '100%', backgroundColor: '#ddd' }}>
      <div 
        className={`timeProgressBar__inner ${progress >= 100 ? 'timeProgressBar__inner--inactive' : 'timeProgressBar__inner--active'}`}
        style={{ width: `${progress}%`}} 
    />
    </div>
  );
};

export default TimeProgressBar;
