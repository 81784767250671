import { addHours, format } from "date-fns";
import { pick_colors } from "../../utils/variables";
import LineChart from "../Charts/LineChart";
import { formatDuration, formatEnamKey, formatFloat, formatTimestemp, formatTimestempUTC } from "../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import useTabletView from "../../hooks/useTabletView";
import useValueDependingOnView from "../../hooks/useValueDependingOnView";

const PnLByPositionChart = ({ positionsData, isLegend = true, allowedClick = false, startDate, endDate, limit = 100, mainAsset = 'BTC', useUTCTZ = false }) => {
  const isTablet = useTabletView();
  const navigate = useNavigate();
  const location = useLocation();

  function groupByStrategy(objects) {
    const groups = {};

    objects.forEach(obj => {

      const strategy = obj.strategy;

      if (groups[strategy]) {
        groups[strategy].push(obj);
      } else {

        groups[strategy] = [obj];
      }
    });

    return Object.values(groups);
  }


  const getData = (data) => {
    return data.map(el => {
      return {
        x: el.position_end_timestamp,
        y: el.percent_pnl,
        startTime: el.position_start_timestamp,
        duration: el.position_duration,
        quantityBuy: el.buy_quote_qty,
        quantitySell: el.sell_quote_qty,
        buyPrice: el.buy_average_price,
        sellPrice: el.sell_average_price,
        strategy: el.strategy,
        commissionBTC: el.commission_real || formatFloat(el.commission),
        positionId: el.position_id,
        isClosed: el.is_closed

      }

    })
  }

  const chartData = {
    datasets:
      groupByStrategy(positionsData).map((strategyPositionData, index) => {

        const dataForChart = getData(strategyPositionData)

        return {
          data: dataForChart,
          borderColor: dataForChart.map(el => !el.isClosed ? pick_colors[index + 1] : pick_colors[index]),
          backgroundColor: dataForChart.map(el => !el.isClosed ? `${pick_colors[index + 1]}90` : `${pick_colors[index]}90`),
          label: strategyPositionData[0].strategy,
          fill: false,
          type: 'scatter',
          radius: strategyPositionData.map(el => {
            const itemRadius = 5 + Math.abs(el.percent_pnl) * 2

            return itemRadius > 20 ? 20 : itemRadius
          }),

          hoverRadius: 20,
          //pointBorderWidth: 2,
        }
      })
  };

  const chartOptions = {
    onClick: allowedClick ? (event, elements) => {
      if (elements.length > 0) {
        const id = elements[0].element.$context.raw.positionId;


        navigate(`/statistics/positions/position/${id}${location.search}`)
      }
    } : null,
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          callback: function (value) {
            if (useUTCTZ) return formatTimestempUTC(value).split(" ");
            else {
              const date = new Date(value);
              const timeString = format(date, 'HH:mm');
              const dateString = format(date, 'dd.MM.yyyy');
              return [timeString, dateString];
            }
          },
        },
        min: startDate,
        max: addHours(new Date(+endDate), ((+endDate - +startDate) / 86400000)),
        //max:  endDate,
      },
      y: {
        ticks: {
          callback: function (value) {
            return value.toFixed(2) + '%'
          }
        },

        title: {
          text: 'PnL %',
          align: 'center'
        }
      }
    },
    plugins: {
      tooltip: {
        intersect: false,
        mode: 'nearest',
        backgroundColor: function (context) {
          const tooltipItem = context.tooltip.dataPoints[0];
          const datasetIndex = tooltipItem.datasetIndex;
          const index = tooltipItem.dataIndex;
          const isClosed = context.chart.data.datasets[datasetIndex].data[index].isClosed;

          return !isClosed ? pick_colors[datasetIndex + 1] : pick_colors[datasetIndex];
        },
        displayColors: false,
        callbacks: {
          label: function (context) {
            const {
              x,
              y,
              startTime,
              duration,
              quantityBuy,
              quantitySell,
              buyPrice,
              sellPrice,
              strategy,
              commissionBTC } = context.raw;

            return [
              `Start Date: ${useUTCTZ ? formatTimestempUTC(startTime) : formatTimestemp(startTime)}`,
              `End Date: ${useUTCTZ ? formatTimestempUTC(x) : formatTimestemp(x)}`,
              `Duration: ${formatDuration(duration)}`,
              `Quantity buy: ${quantityBuy}`,
              `Quantity sell: ${quantitySell}`,
              `Buy Price: ${buyPrice}`,
              `Sell Price: ${sellPrice}`,
              strategy && `Strategy: ${strategy}`,
              `Commission ${mainAsset}: ${commissionBTC}`,
              `Trade PnL: ${y.toFixed(2)}%`
            ].filter(el => el);
          },
          title: undefined
        }
      },

      title: {
        display: true,
        align: 'start',
        text: formatEnamKey(`Pnl by positon (limit ${limit})`), // TODO
        color: '#333333',
      },
      legend: {
        display: isLegend,

        align: 'end',
        labels: {
          usePointStyle: true,
        }
      },

    }
  };

  const getValueDependingView = useValueDependingOnView()

  return (
    
      <LineChart
        chartData={chartData}
        options={chartOptions}
        showZeroLine={true}
        allowedClick={allowedClick}
        tooltip={'PnL всіх закритих угод по обраній парі'}
        tooltipLeft={getValueDependingView(270, 210, 200)}
      />
  )
}

export default PnLByPositionChart;