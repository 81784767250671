import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../Header';
import { Links } from '../../links';
import SubHeaderTwo from '../SubHeaderTwo';
import { BacktestResultsProvider } from '../../contexts/BacktestResultsContext';
import { useDeleteBacktestMutation, useFetchBacktestByIdQuery, useFetchBacktestByIdStatsQuery, useFetchBacktestsQuery } from '../../redux/statisticsServices/backtestsApi';
import Configuration from '../BacktestResult/Configuration';
import BackButton from '../Buttons/BackButton';
import Icon from '../Icon';
import { convertEnam } from '../../utils/functions';
import { BACKTESTSTATUSES } from '../../utils/enams';
import { BacktestsProvider } from '../../contexts/BacktestsContext';
import { useLazyFetchDataFromGoogleStorageQuery } from '../../redux/portfolioBacktestServices/defaultApi';
import useTabletView from '../../hooks/useTabletView';
import useMobileView from '../../hooks/useMobileView';
import { formatAssetOrPercent } from '../../pages/Settings';
import { toast } from 'react-toastify';
import DeleteButton from '../Buttons/DeleteButton';
import useScrollToTop from '../../hooks/useScrollToTop';
import Button from '../Buttons/Button';

export const getRightOrderData = (inputData, backtestByIdData = { status: 4 }) => {
  if (statusEnum[backtestByIdData?.status] !== 'Finished') return

  const {
    start_time,
    end_time,
    period,
    start_balance,
    end_balance,
    benchmark_percent,
    total_pnl_percent,
    total_commission,
    total_positions,
    total_closed_positions,
    total_open_positions,
    win_rate_percent,
    best_position_pnl_percent,
    worst_position_pnl_percent,
    avg_winning_position_pnl_percent,
    avg_losing_position_pnl_percent,
    avg_winning_position_duration,
    avg_losing_position_duration


  } = inputData;

  const result = {
    start_time,
    end_time,
    period,
    start_balance,
    end_balance,
    benchmark_percent,
    total_pnl_percent,
    total_commission,
    total_positions,
    total_closed_positions,
    total_open_positions,
    win_rate_percent,
    best_position_pnl_percent,
    worst_position_pnl_percent,
    avg_winning_position_pnl_percent,
    avg_losing_position_pnl_percent,
    avg_winning_position_duration,
    avg_losing_position_duration
  }

  return result

}

export const statusEnum = convertEnam(BACKTESTSTATUSES)

const BacktestLayout = () => {

  useScrollToTop()

  useEffect(() => {
    document.body.classList.add('body--withSubheader');

    return () => {
      document.body.classList.remove('body--withSubheader');
    };
  }, []); 

  const location = useLocation();

  const navigate = useNavigate();
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);

  const isTablet = useTabletView();
  const isMobile = useMobileView();

  const configRef = useRef(null);

  const isResultPage = useMemo(() => (
    location.pathname.includes('backtestId')
  ), [location])

  const isAddNewPage = useMemo(() => (
    location.pathname.includes('new')
  ), [location]);


  const { data: backtestsData, isFetching: isFetchingBacktests, refetch } = useFetchBacktestsQuery();

  const { backtestId } = useParams();
  const { data: backtestByIdData, isFetching: isFetchingBacktestById } = useFetchBacktestByIdQuery(backtestId, {
    skip: !backtestId ,
  });
  const { data: backtestByIdStatsData, isFetching: isFetchingBacktestByIdStats } = useFetchBacktestByIdStatsQuery(backtestId, {
    skip: !backtestId,
  });

  const [triggerRefreshDataFromGoogleStorage, { 
    isLoading: isLoadingRefreshDataFromGoogleStorage, 
    isSuccess: isSuccessRefreshDataFromGoogleStorage }] = useLazyFetchDataFromGoogleStorageQuery();
    
  const [deleteBacktest] = useDeleteBacktestMutation();

  useEffect(() => {
    const subHeader = document.querySelector('.subHeaderTwo');

    if(isResultPage) {
      subHeader?.classList.add('subHeaderTwo--backtestResults');
      document.body.classList.add(isConfigurationOpen ? 'body--backtest--configOpen' : 'body--backtest');
    } else {
      subHeader?.classList.remove('subHeaderTwo--backtestResults');
      document.body.classList.remove(isConfigurationOpen ? 'body--backtest--configOpen' : 'body--backtest');
    }

    if(!isAddNewPage && !isResultPage) {
      document.body.classList.add('body--withSubheader');
    } else {
      document.body.classList.remove('body--withSubheader');
    }

    return () => {
      document.body.classList.remove('body--withSubheader');
      document.body.classList.remove('body--backtest--configOpen');
      document.body.classList.remove('body--backtest');
      subHeader?.classList.remove('subHeaderTwo--backtestResults')
    };
  }, [isAddNewPage, isResultPage, isConfigurationOpen]); 

  const handleDeleteBacktest = async (id) => {
    const body = {
      status: -2
    };

    try {
      await deleteBacktest({backtestId: id, body}).unwrap();
      toast.success("Backtest was deleted")
    } catch (error) {
      console.error("Backtest delete error", error);
      toast.error("Backtest delete error", error.message)
    }
  }

  const mainAsset = useMemo(() => {
    if (backtestByIdData) {
      return backtestByIdData.main_asset || 'BTC'
    }

  }, [backtestByIdData]);


  const backtestsContextData = {
    backtestsData,
    isFetchingBacktests,
    refetch, 
    handleDeleteBacktest,
  }

  const backtestData = {
    backtestId,
    backtestByIdData,
    isFetchingBacktestById,
    backtestByIdStatsData,
    isFetchingBacktestByIdStats,
    mainAsset,
    isConfigurationOpen
  }


  const handleRefresh = () => {
    triggerRefreshDataFromGoogleStorage();
  };

  const getExtraPart = () => {
    switch (true) {
      case isResultPage:
        return null;

      default:
        return () => (
          <div className="flexGap20">

            {!location.pathname.includes('compare') && (
              <div className="flexGap20" style={{ justifyContent: 'space-around' }}>
                <Button
                  className="button--widthMaxContent"
                  onClick={handleRefresh}
                  disabled={isLoadingRefreshDataFromGoogleStorage && !isSuccessRefreshDataFromGoogleStorage}
                >
                  <Icon type="refresh" size={isTablet ? 15 : 20} className={isLoadingRefreshDataFromGoogleStorage ? 'scale' : ''} />
                </Button>
                <Link to={"/backtests/compare"} >
                  <Button className="button--widthMaxContent">
                    <Icon type="scale" size={isTablet ? 15 : 20} />
                  </Button>
                </Link>
              </div>

            )}


            <Link to={"/backtests/new"} >
                <Button className="button">
                  + Add new
                </Button>
            </Link>
          </div>
        );
    }
  }

  const handleDeleteBacktestFull = () => {
    handleDeleteBacktest(backtestByIdData.backtest_id);
    navigate('/backtests/calculated')

  }

  const handleCloneBacktest = () => {
    navigate(`/backtests/new?backtest=${backtestByIdData.backtest_id}`)
  }


  return (
    <BacktestResultsProvider value={backtestData} >
      <BacktestsProvider value={backtestsContextData} >
        <Header />

        {isMobile ? (
          <div className="section">
            <div className="page--layout txt30x45 textAlCenter flexColumnGap30">
              <p>You need to use device with bigger screen resolution to view this page</p>
              <div className='textAlCenter'>
                <Icon type="exclamation" size={40} />
              </div>
            </div>
          </div>
        ) : (

          <div className="backtestLayout">
            {location.pathname !== '/backtests/new' && (
              <div className="backtestLayout__fixedContainer">
                {isResultPage ? (
                  <div className="section" >
                    <div className="flexSpaceBetween"  style={{ marginBottom: isTablet ? 20 : 30 }}>
                      <div className="flexGap10">
                         <BackButton path={'/backtests/calculated'} />
                        <h1>{backtestByIdData && backtestByIdData.backtest_id} {(backtestByIdStatsData && backtestByIdStatsData.backtest.length === 1) ? formatAssetOrPercent('percent', backtestByIdStatsData.backtest[0].total_pnl_percent) : ''}</h1>
                      </div>
                      <div className="flexGap20">
                        <Button 
                          className="button--widthMaxContent"
                          onClick={handleCloneBacktest}
                        >
                          <Icon type="copy" size={20} />
                        </Button>
                     
                        <DeleteButton deletingFunc={handleDeleteBacktestFull}/>
                      </div>
                      
                      
                    </div>

                    {backtestByIdData && (
                      <Configuration
                        data={backtestByIdData.configuration}
                        isConfigurationOpen={isConfigurationOpen}
                        setIsConfigurationOpen={setIsConfigurationOpen}
                        ref={configRef}
                      />
                    )}
                    <SubHeaderTwo
                      menuItem={Links.backtests}
                      linkList={isResultPage ? Links.backtests.children.slice(6, Links.backtests.children.length) : Links.backtests.children.slice(2, 6)}
                      withSlug={isResultPage}
                      currentPathIndex={isResultPage ? 4 : 2}
                      extraPart={getExtraPart()}
                    />
                  </div>
                ) : (
                  <SubHeaderTwo
                      menuItem={Links.backtests}
                      linkList={isResultPage ? Links.backtests.children.slice(6, Links.backtests.children.length) : Links.backtests.children.slice(2, 6)}
                      withSlug={isResultPage}
                      currentPathIndex={isResultPage ? 4 : 2}
                      extraPart={getExtraPart()}
                    />
                )}

          
              </div>
            )}

              <div className="section">
                <div className="page">
                  <Outlet />
                </div>
              </div>
        
          </div>

        )}

      </BacktestsProvider>
    </BacktestResultsProvider>
  );
};

export default BacktestLayout;
