import React from 'react';

const IsSpinnerBlur = () => {
    return (
        <div className="spinnerBlur">
            <div className="spinnerBlur__item spin" ></div>
        </div>
    );
}

export default IsSpinnerBlur;