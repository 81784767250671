// components/StatistickLayout.js
import { Outlet } from 'react-router-dom';

import Header from '../Header';
import { Links } from '../../links';
import SubHeaderTwo from '../SubHeaderTwo';
import { FilterProvider } from '../../contexts/FilterContext';
import { useEffect } from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';

const StatisticsLayout = () => {
  useScrollToTop()

  useEffect(() => {
    document.body.classList.add('body--withSubheader');

    return () => {
      document.body.classList.remove('body--withSubheader');
    };
  }, []); 

  return (
    <FilterProvider>
      <Header />
      <SubHeaderTwo menuItem={Links.statistics} linkList={Links.statistics.children} withSearchParams={true}/>
      {/* <SubHeader menuItem={Links.statistics} /> */}
     
        <div className="section">
          <div className="page">
        
            <Outlet />
          </div>
        </div>
  </FilterProvider>
    
  );
};

export default StatisticsLayout;
