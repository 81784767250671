import React from 'react';
import { Navigate } from 'react-router-dom';
import { Links } from '../links.js';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
    const { token } = useSelector(state => state.token);
    const isTokenValid = token ? true : false;
    console.log("isTokenValid", isTokenValid);

    return (
        isTokenValid ? (
            <>
                {children}
            </>
        ) : (
            <Navigate to={Links.login.path} />
        )
    );
};

export default ProtectedRoute;
