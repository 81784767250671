import { useEffect, useState } from "react";
import useUrlFilters from "../hooks/useUrlFilters";
import { useLocation } from "react-router-dom";
import { formatEnamKey, parseSearchParams } from "../utils/functions";
import { format, startOfHour } from "date-fns";
import FilterSectionWithAplly from "../components/PageLoyouts/FilterSectionWithAplly";
import { useLazyFetchPairsBlacklistQuery } from "../redux/statisticsServices/pairsApi";
import IsFetching from "../components/IsFetching";
import { getProperty } from "./Settings";
import Table from "../components/Table/Table";
import { blacklist_criterions } from "../utils/variables";
import * as styles from '../styles/utils/_variables.scss';
import useMobileView from "../hooks/useMobileView";

export function BlackList() {
    const [filters, setFilters] = useState([]);
    const location = useLocation();

    const isMobile = useMobileView()

    const [triggerFetchPairsBlacklist, { data: pairsBlacklistData, isFetching: isFetchingPairsBlacklist }] = useLazyFetchPairsBlacklistQuery();

    const filterOptions = {

        timestamp_event: {
            type: 4
        },

    }

    useUrlFilters(filters, setFilters, filterOptions);

    const searchParams = new URLSearchParams(location.search);
    const initialFiltersFull = parseSearchParams(searchParams);

    const initialFilters = [];

    initialFiltersFull.forEach(value => {

        if (Object.keys(filterOptions).includes(value.id)) {
            initialFilters.push({ id: value.id, value: value.value });
        }
    });

    useEffect(() => {

        const filterTimestamp = initialFilters.find(filter => filter.id === 'timestamp_event')?.value;

        if (!filterTimestamp) {
            const newFilters = [...initialFilters, { id: 'timestamp_event', value: (startOfHour(new Date()).getTime()) }];

            setFilters(newFilters);
            triggerFetchPairsBlacklist(newFilters)

        } else {
            triggerFetchPairsBlacklist(initialFilters)
        }


    }, []);

    const handleApplyFilters = (filters) => {
        triggerFetchPairsBlacklist(filters)
    }

    if (isFetchingPairsBlacklist) {
        return (
            <>
                <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
                <IsFetching />
            </>
        )
    }

    // if (pairsBlacklistData?.length === 0) {
    //     return (
    //         <>
    //             <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
    //             <div>NO DATA</div>
    //         </>

    //     )
    // }

  

    const tableData = pairsBlacklistData?.map(item => {
        const { pair, is_active, ...rest } = item;

        const modifiedRest = Object.keys(rest).sort().reduce((acc, key) => {
            if (blacklist_criterions[key]) {
                const newName = blacklist_criterions[key].name;


                acc[newName] = rest[key].toFixed(2);

            }
            return acc;
        }, {});

        return {
            pair,
            is_active,
            ...modifiedRest,
        }
    });

    const hiddenColumns = ['timestamp', 'timestamp_event'];

    const tableColumns = tableData?.[0] ? Object.keys(tableData[0])?.filter(key => !hiddenColumns.includes(key)).map(key => ({
        accessorKey: key,
        header: formatEnamKey(key),
        filterFn:  'myCustomFilter',
        filterable: !(key === 'is_active') ,
        sortable: true,

        size: isMobile ? 160 : 170,


        cell: info => {

            const currentCriterion = Object.values(blacklist_criterions).find(criterion => criterion.name === key)

            const symbol = currentCriterion?.symbol;
            const threshold = currentCriterion?.threshold;


            let condition = false;

            switch (symbol) {
                case ">=":
                    condition = +info.getValue() >= threshold;
                    break;
                case "<":
                    condition = +info.getValue() < threshold;
                    break;
                case "<=":
                    condition = +info.getValue() <= threshold;
                    break;
                case ">":
                    condition = +info.getValue() > threshold;
                    break;

                default:
                    condition = null;
                    break
            }

            return key !== 'pair' && key !== 'is_active'
                ? <div style={{ color: condition ? styles.default.colorRed : styles.default.colorGreen }}>{info.getValue()}</div>
                : getProperty(key, info.getValue(), undefined, isMobile)
        }

    })) : [];

    const timestempEvent = pairsBlacklistData && format(new Date(pairsBlacklistData[0].timestamp_event), 'yyyy-MM-dd HH:mm');

    const trade_settings =  {
        "pair": "Пара",
        "is_active": "Статус пари",
        "price_btc_to_usdt": "Відношення ціни пари в USDT до цієї ж пари в BTC в деномінації до BTC",
        "cnt_transaction_24h": "Середня кількість транзакцій за 24 години",
        "zero_candles_volume_ratio": "Кількість свічок з 0 volume поділена на загальну",
        "change_btc_vol_hour": "Зміна об‘ємів BTC пар",
        "volume_24h": "Об‘єм за 24 години по парі",
        "diff_usdt_btc_vol": "Відношення зміни об‘ємів BTC до зміни об’ємів USDT"
    }

    return (
        <>
            <FilterSectionWithAplly 
                filters={filters} 
                setFilters={setFilters} 
                filterOptions={filterOptions} 
                handleApplyFilters={handleApplyFilters} 
                isApply={true}
            />
            <div className="itemPage">
                {tableData && (
                    <Table
                        tableData={tableData}
                        tableColumns={tableColumns}
                        headerItemWidth={100}
                        csvData={tableData}
                        mainHeight={window.innerHeight - 80 - 55 - 80 - 27 - 20}
                        trade_settings={trade_settings}
                    />
                )}
            </div>
        </>
    )
}