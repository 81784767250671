import statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls';
import { buildQueryString } from '../../utils/functions';

export const positionsApi = statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPositions: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.positions}${buildQueryString(filters)}`,
      providesTags: (result = []) => [
        // ...result.map(({ position_id }) => ({ type: 'Positions', id: position_id.position_id })),
        { type: 'Positions', id: 'Positions_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'Positions')
      },
    }),

    fetchPositionsClosed: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.positions_closed}${buildQueryString(filters)}`,
      providesTags: (result) => [
        // ...result.map(({ position_id }) => ({ type: 'PositionsClosed', id: position_id.position_id })),
        { type: 'PositionsClosed', id: 'PositionsClosed_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PositionsClosed')
      },
    }),

    fetchPositionsOpen: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.positions_open}${buildQueryString(filters)}`,
      providesTags: (result) => [
        // ...result.map(({ position_id }) => ({ type: 'PositionsOpen', id: position_id.position_id })),
        { type: 'PositionsOpen', id: 'PositionsOpen_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PositionsOpen')
      },
    }),

    fetchPosition: builder.query({
      query: (positionId) => STATISTICS_API_URLS.positions_position.replace("{position_id}", positionId),
      providesTags: (result) => [
        [{ type: 'Position', id: result.position_id }],
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'Position')
      },
    }),

    fetchPositionTrades: builder.query({
      query: (positionId) => STATISTICS_API_URLS.positions_position_trades.replace("{position_id}", positionId),
      providesTags: (result) => [
        [{ type: 'PositionTrades', id: result.position_id }],
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PositionTrades')
      },
    }),

    fetchPositionCandles: builder.query({
      query: (positionId) => STATISTICS_API_URLS.positions_position_candles.replace("{position_id}", positionId),
      providesTags: (result) => [
        [{ type: 'PositionCandles', id: result.position_id }],
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PositionCandles')
      },
    }),

    closePosition: builder.mutation({
      query: ({ positionId }) => ({
        url: STATISTICS_API_URLS.close_position.replace("{position_id}", positionId),
        method: 'POST',
        body: []
      }),
      async onQueryStarted({ positionId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await validateResponse(dispatch, queryFulfilled, 'PositionsOpen');
          dispatch(
            statisticsApi.util.updateQueryData('fetchPosition', positionId, (draft) => {
              Object.assign(draft, {})
            })
          );
          dispatch(statisticsApi.util.invalidateTags([{ type: 'PositionsOpen', id: 'PositionsOpen_LIST' }]));
          dispatch(statisticsApi.util.invalidateTags([{ type: 'Position', id: positionId }]));
          dispatch(statisticsApi.util.invalidateTags([{ type: 'PositionTrades', id: positionId }]));
          dispatch(statisticsApi.util.invalidateTags([{ type: 'PositionCandles', id: positionId }]));

        } catch {
          console.error(`Error while closing Position: ${positionId}`);
        }
      },
    }),

    closeAllStrategyPositions: builder.mutation({
      query: (strategyName) => ({
        url: STATISTICS_API_URLS.close_all_strategy_positions + `?strategy_in=${strategyName}`,
        method: 'POST',
        body: []
      }),
      async onQueryStarted({ strategyName }, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await validateResponse(dispatch, queryFulfilled, 'PositionsOpen');

          // dispatch(api.util.invalidateTags([{ type: 'PositionsOpen', id: 'PositionsOpen_LIST' }]));
          // dispatch(api.util.invalidateTags([{ type: 'Position', id: positionId }]));
          // dispatch(api.util.invalidateTags([{ type: 'PositionTrades', id: positionId }]));
          // dispatch(api.util.invalidateTags([{ type: 'PositionCandles', id: positionId }]));

        } catch {
          console.error(`Error while closing all Positions: Strategy${strategyName}`);
        }
      },
    }),

    savePositionBuyMore: builder.mutation({
      query: ({ positionId, qtyMultiplicator }) => ({
        url: STATISTICS_API_URLS.positions_position_buy_more.replace("{position_id}", positionId) + `?qty_multiplicator=${qtyMultiplicator}`,
        method: 'POST',
      }),
      async onQueryStarted({ positionId }, { dispatch, queryFulfilled }) {
        try {
          validateResponse(dispatch, queryFulfilled, 'Position')
          // const { data } = await validateResponse(dispatch, queryFulfilled, 'PositionsOpen');

          // dispatch(api.util.invalidateTags([{ type: 'PositionsOpen', id: 'PositionsOpen_LIST' }]));
          // dispatch(api.util.invalidateTags([{ type: 'Position', id: positionId }]));
          // dispatch(api.util.invalidateTags([{ type: 'PositionTrades', id: positionId }]));
          // dispatch(api.util.invalidateTags([{ type: 'PositionCandles', id: positionId }]));

        } catch {
          console.error(`Error while buy more in position: ${positionId}`);
        }
      },
    }),

    createNewPosition: builder.mutation({
      query: ({ strategy, pair, qtyMultiplicator }) => ({
        url: STATISTICS_API_URLS.positions + `?strategy=${strategy}&pair=${pair}&qty_multiplicator=${qtyMultiplicator}`,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          validateResponse(dispatch, queryFulfilled, 'PositionsOpen')


        } catch {
          console.error(`Error while try add new position`);
        }
      },
    }),



  }),


  overrideExisting: false,
});

export const {
  useLazyFetchPositionsQuery,
  useLazyFetchPositionsClosedQuery,
  useLazyFetchPositionsOpenQuery,
  useFetchPositionQuery,
  useLazyFetchPositionQuery,
  useFetchPositionTradesQuery,
  useLazyFetchPositionTradesQuery,
  useFetchPositionCandlesQuery,
  useLazyFetchPositionCandlesQuery,
  useClosePositionMutation,
  useCloseAllStrategyPositionsMutation,
  useSavePositionBuyMoreMutation,
  useCreateNewPositionMutation,

} = positionsApi;