import { startOfDay, endOfDay  } from "date-fns";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

const DatePickerFilter = ({ setFilters, filtersStartDate, filtersEndDate, handleIsDatePicker}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
      const [start, end] = dates;
    
      if (start) {
          setStartDate(startOfDay(start));
      }

      if (end) {
          setEndDate(endOfDay(end)); 
          setFilters(prevState => {
            return prevState.map(filter => {
              switch(filter.id) {
                case 'position_end_timestamp_lte': 
                  return {...filter, value: endOfDay(end).getTime()};

                case  'position_end_timestamp_gte': 
                  return {...filter, value: startOfDay(start).getTime()};

                default: 
                  return filter;
              }
            }); 
          });
          handleIsDatePicker()
      } else {
          setEndDate(null);
      }
    };

  useEffect(() => {
    if(filtersStartDate) {
      setStartDate(new Date(+filtersStartDate) )
    } else {
      setStartDate(startOfDay(new Date()))
    }

    if(filtersEndDate) {
      setEndDate(new Date(+filtersEndDate))
    } else {
      setEndDate(endOfDay(new Date()))
    }

  }, [])

  return (
    <>
      <ReactDatePicker
        selected={startDate} 
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
    </>

    
  );
}

export default DatePickerFilter;