import { useLocation, useNavigate } from "react-router-dom";
import BubbleChart from "../Charts/BubbleChart";

const PairPnlChart = ({ pairsStatsData, allowedModes = true }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const options = {
        onClick: (element) => {
            
                const pair = `${element.label}/BTC`;

                searchParams.set('pair', pair);

                const newSearchString = searchParams.toString();
      
                navigate(`/statistics/pairs?${newSearchString}`)

          },
        plugins: {
            title: {
                text: 'Pairs PnL',
            }
        }
    }
    return (
        <BubbleChart data={pairsStatsData} options={options} allowedModes={allowedModes} />
    )
}

export default PairPnlChart;
