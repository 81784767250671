import { useEffect, useState } from 'react';

const useMobileView = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 430 || window.innerHeight <= 430);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 430 || window.innerHeight <= 430);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
};

export default useMobileView