import BarChartHorizontal, { getModifiedData } from "../Charts/BarChartHorizontal";
import { pick_colors } from "../../utils/variables";
import { formatFloat } from "../../utils/functions";
import useMobileView from "../../hooks/useMobileView";
import Tooltip from "../Tooltip";
import useTabletView from "../../hooks/useTabletView";

const PairPnLByStrategiesChart = ({ strategiesData = [] }) => {

  const dataArray = getModifiedData(strategiesData, 'total_pnl');
  const totalCount = strategiesData.reduce((sum, strategy) => sum + strategy.total_position_count, 0);

  const isMobile = useMobileView();
  const isTablet = useTabletView();


  const data = {
    labels: dataArray.map(item => item.strategy),
    datasets: [
      {
        label: 'BTC',
        data: dataArray.map(item => item.total_value),
        backgroundColor: pick_colors.map((color, index) => `${color}90`),
        borderColor: pick_colors,
        borderWidth: 1,
        maxBarThickness: isMobile ? 30 : 40
      }
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          callback: function (value, index, values) {
            return formatFloat(value);
          }
        },
        title: {
          text: 'PnL in BTC',
        }
      },

    },
    plugins: {
      title: {
        text: 'PnL by Models',
      },
      tooltip: {
        backgroundColor: function (context) {
          const tooltipIndex = context.tooltip.dataPoints[0].dataIndex;
          return pick_colors[tooltipIndex];
        },

        callbacks: {
          label: function (tooltipItem) {
            let label = tooltipItem.dataset.label || '';

            if (label) {
              label += ': ';
            }

            label += parseFloat(dataArray[tooltipItem.dataIndex].original_value).toFixed(9);
            return label;
          }
        }
      },
      datalabels: {
        formatter: (value, context) => {
          return dataArray[context.dataIndex].original_value ? formatFloat(dataArray[context.dataIndex].original_value) + ' BTC' : null;
        }
      }
    },
  };

  return (
    <>
      <BarChartHorizontal data={isNaN(totalCount) ? null : data} options={options} enableDataLabels={true}
        tooltip={<Tooltip text='PnL всіх закритих угод по конкретній моделі' maxWidth={300} style={{ top: isTablet ? 28 : 31, left: isTablet ? 110 : 140 }} />}
      />
    </>
  )
}

export default PairPnLByStrategiesChart;