import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';

const Layout = () => {

  return (
    <>
      <Header />
        <div className="section">
          <div className="page">
      
            <Outlet /> 
          </div>
        </div>
    </>
   
  );
};

export default Layout;