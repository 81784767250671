const validateToken = () => {
    const token = localStorage.getItem('token');
    console.log("token", token)
    if (token === null) return false;
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    const isTokenValid = token && tokenExpiration && tokenExpiration > Math.floor(Date.now() / 1000);
    console.log("isTokenValid", isTokenValid);
    return isTokenValid
}

const storeToken = (token, expirationDate, username) => {
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', expirationDate); // Store as ISO string
    localStorage.setItem('username', username);
};

const deleteToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration'); // Store as ISO string
    localStorage.removeItem('username');
};


export { validateToken, storeToken, deleteToken }