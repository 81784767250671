import { useLocation, useNavigate, useParams } from "react-router-dom";
import EntityButton from "../../components/Buttons/EntityButton";
import IsFetching from "../../components/IsFetching";
import TwoColumnLayout from "../../components/PageLoyouts/TwoColumnLayout";
import { useFetchUserAccountQuery } from "../../redux/statisticsServices/authApi";
import { useContext } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";

export function Accounts() {
    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();
    const location = useLocation();

    const { accountId } = useParams();
    const navigate = useNavigate();

    const { setIsOpen } = useContext(SidebarContext);

    const handleAddNewAccount = () => {
        navigate(`addAccount`);
        setIsOpen(false);
    };

    const handleSelectCurrentAccount = (account) => {
        navigate(`account/${account}`);
        setIsOpen(false);
    };

    if (isFetchingUserAccounts) {

        return (
            <TwoColumnLayout rightContent={<IsFetching />} />
        )
    }

    return (
        <TwoColumnLayout leftContent={
            <div>
                <EntityButton
                    title="+ Add"
                    style={{ height: 32, flexDirection: 'row' }}
                    onClick={handleAddNewAccount}
                    isActive={location.pathname.includes('addAccount')}
                />

                <div className="twoColumnLayout__leftContainer scrolling">
                    {userAccountsData?.map(account => {

                        return (
                            <div className="entityButtonContainer" key={account}>
                                <EntityButton
                                    isActive={accountId && accountId === account}
                                    onClick={() => handleSelectCurrentAccount(account)}
                                    title={account}
                                />
                            </div>
                        )

                    })}
                </div>

            </div>
        }

        />

    )
}
