import { useEffect, useState } from "react";
import IsFetching from "./IsFetching";
import { useFetchStrategyPairsQuery, useUpdateStrategyPairPositionQuantityMutation, useUpdateStrategyPairCanTradeMutation } from "../redux/statisticsServices/strategiesApi";

import { getProperty } from "../pages/Settings";
import Table from "./Table/Table";
import { formatEnamKey } from "../utils/functions";
import ToggleSwitch from "./Inputs/ToggleSwitch";
import InputSave from "./Inputs/InputSave";
import useMobileView from "../hooks/useMobileView";
import useTabletView from "../hooks/useTabletView";

export function SettingsStrategyPairs({ strategy, handleOpenModal, handleCloseModal, trade_settings }) {
    const { data: strategyPairsData, isFetching: isStrategyPairsFetching, isError: isStrategyPairsError } = useFetchStrategyPairsQuery(strategy);
    const isMobile = useMobileView();


    if (isStrategyPairsFetching) {
        return (
            <IsFetching />
        )
    }
    if (isStrategyPairsError) {
        return (
            <div className="label">No pairs data</div>
        )
    }

    const tableData = strategyPairsData;

    const excludedKeys = ['strategy', 'can_trade', 'position_quantity'];

    const tableColumns = Object.keys(tableData[0]).filter(key => !excludedKeys.includes(key)).map((key, index) => ({
        accessorKey: key,
        header: formatEnamKey(key),
        filterFn:  'myCustomFilter',
        filterable: !(key === 'backtest_link_id'),
        sortable: !(key === 'backtest_link_id'),
        size: isMobile  ?  index === 0 ? 135 : 135 : 165,

        cell: info => getProperty(key, info.getValue())
    }))

    return (
        <Table
            title={'Pairs'}
            tableData={tableData}
            tableColumns={tableColumns}
            headerItemWidth={150}
            extraRow={(row) => <ExtraRow data={row.original} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal} />}
            trade_settings={trade_settings}
        />
    )
}

const ExtraRow = ({ data, handleOpenModal, handleCloseModal }) => {
    const isTablet = useTabletView();
    const [positionQuantity, setPositionQuantity] = useState('');
    const [canTrade, setCanTrade] = useState(false);
    const [updateStrategyPairPositionQuantity, { isLoading: isUpdatingStrategyPairPositionQuantity, isError: isErrorStrategyPairPositionQuantity }] = useUpdateStrategyPairPositionQuantityMutation();
    const [updateStrategyPairCanTrade, { isLoading: isUpdatingStrategyPairCanTrade, isError: isErrorStrategyPairCanTrade }] = useUpdateStrategyPairCanTradeMutation();

    if (isErrorStrategyPairPositionQuantity) {
        console.log("isErrorStrategyPairPositionQuantity", isErrorStrategyPairPositionQuantity);
        alert("Error, when updating strategy position quantity");
    }

    if (isErrorStrategyPairCanTrade) {
        console.log("isErrorStrategyPairCanTrade", isErrorStrategyPairCanTrade);
        alert("Error, when updating strategy balance");
    }

    const hanldeCanTradeSave = () => {
        const strategyName = data.strategy
        const pair = data.pair;
        updateStrategyPairCanTrade({ strategyName, pair, canTrade });
        handleCloseModal();
    }

    const hanldeQuantitySave = () => {
        const strategyName = data.strategy
        const pair = data.pair;
        updateStrategyPairPositionQuantity({ strategyName, pair, positionQuantity });
        handleCloseModal();
    }

    useEffect(() => {
        setPositionQuantity(data.position_quantity);
        setCanTrade(data.can_trade)
    }, [data])

    return (
        <div className="flexGap20" style={{ padding: '0 14px'}}>
            <div style={{ marginRight: isTablet ? 0 : 80 }}>
                <InputSave
                    value={positionQuantity}
                    setValue={setPositionQuantity}
                    title={'Position quantity'}
                    titleClass={"txt14x21"}
                    commonClass={"flexGap5"}
                    handleSave={() => handleOpenModal(hanldeQuantitySave)}
                />

            </div>

            <label className="flexGap5">
                <span className="txt14x21">Can Trade</span>
                <ToggleSwitch value={canTrade} setValue={setCanTrade} />
                <button
                    className="button buttonSave"
                    onClick={() => handleOpenModal(hanldeCanTradeSave)}
                >
                    Save
                </button>
            </label>
        </div>
    )
}


