import React from 'react';
import {useParams} from "react-router-dom";


export function BacktestResult() {
    const { backtestId } = useParams();
    // const backtestSrc =`http://0.0.0.0:8085/?type=regular&filename=${backtestId}`;
    const backtestResultApiUrl = "https://backtest-result.obscurial.org/?type=regular&filename=" + backtestId;

    return (
        <div className="iframe-container">
          <iframe
            className="iframe-fullscreen"
            src={backtestResultApiUrl}
            title="Introduction To WiseGPT"
            allowFullScreen
          ></iframe>
        </div>
    )
}
