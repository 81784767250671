import { Link, useLocation } from "react-router-dom";

const SubHeaderTwoMenuItem = ({ link, path, currentPathIndex }) => {
    const { pathname } = useLocation();

    const currentPath = `${pathname.split('/')[currentPathIndex]}`;
  
    return (
        <div className={`subHeaderTwoMenuItem txt16x24 ${link.path.includes(currentPath)  ? 'subHeaderTwoMenuItem--active': ''}`} >
            <Link to={path}>{link.title}</Link>
        </div> 
    )
}

export default SubHeaderTwoMenuItem;