import { useContext, useEffect, useMemo, useRef, useState } from "react";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import PairPnlChart from "../../components/Statistics/ PairPnlChart";
import { useFetchStrategiesGeneralInfoQuery, useLazyFetchStrategiesBalancesQuery } from "../../redux/statisticsServices/strategiesApi";
import { useFetchPairsQuery, useLazyFetchPairsStatsQuery } from "../../redux/statisticsServices/pairsApi";
import useUrlFilters from "../../hooks/useUrlFilters";
import { parseSearchParams } from "../../utils/functions";
import { useLocation } from "react-router-dom";
import IsFetching from "../../components/IsFetching";
import { endTimestemp, startTimestemp } from "./StatisticsGeneralPage";
import ModelsBalancesChart from "../../components/Statistics/ModelsBalancesChart";
import { useFetchUserAccountQuery } from "../../redux/statisticsServices/authApi";
import { FilterContext } from "../../contexts/FilterContext";

export function StatisticsStrategiesPage() {
    const { filters, setFilters } = useContext(FilterContext);

    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();

    const { data: strategiesDataFetch, isFetching: isFetchingStartegy } = useFetchStrategiesGeneralInfoQuery();

    const { data: pairsData, isFetching: isFetchinPairs } = useFetchPairsQuery();

    const [triggerFetchPairsStats, { data: pairsStatsData, isFetching: isFetchingPairsStats, isError: isErrorPairsStats }] = useLazyFetchPairsStatsQuery();

    const [triggerFetchStrategiesBalances, { data: strategiesBalancesData, isFetching: isFetchingStrategiesBalances }] = useLazyFetchStrategiesBalancesQuery();


    const accountId = useMemo(() => {
        const result = filters.find(filter => filter.id === 'account_id_in')?.value
        return Array.isArray(result) ? result[0] : result
    }, [filters.find(filter => filter.id === 'account_id_in')?.value]);

    const strategiesData = useMemo(() => {
        return strategiesDataFetch?.filter(el => el.account_id === accountId)
    }, [strategiesDataFetch, accountId]);

    const filterOptions = useMemo(() => {

        return {
            account_id_in: {
                type: 2,
                values: userAccountsData,
            },

            strategy: {
                type: 2,
                values: strategiesData?.map(el => el.strategy),
            },

            pair_in: {
                type: 1,
                values: pairsData?.map(el => el.pair),
            },

            position_end_timestamp_gte: {
                type: 3
            },

            position_end_timestamp_lte: {
                type: 3
            }
        }
    }, [strategiesData, userAccountsData, pairsData, accountId])


    const filtersInitialized = useUrlFilters(filters, setFilters, filterOptions);

    const updateFilters = (updatedFilters) => {
        return updatedFilters.map(filter => {
            if (filter.id === 'strategy') {
                return { ...filter, id: 'strategy_in' };
            }
            return filter;
        })
    }

    function createFilterForStrategiesBalances(filters) {

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const endFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_lte')?.value;
        const strategy = filters.find(filter => filter.id === 'strategy')?.value;

       
        const accountId = filters.find(filter => filter.id === 'account_id_in')?.value;


        return [
            { id: "timeframe", value: '15m' },
            { id: "account_id_in", value: accountId },
            strategy && { id: "strategy", value: strategy },
            startFilterTimestamp && { id: "update_time_gte", value: startFilterTimestamp },
            endFilterTimestamp && { id: "update_time_lte", value: endFilterTimestamp },

        ].filter(filter => filter);
    }

    const applyFiltersAndFetchData = (filters, ifSetFilters = false) => {
        if(ifSetFilters) {
              setFilters(filters);
        }
        
        const filterForStrategiesBalances = createFilterForStrategiesBalances(filters);
        triggerFetchPairsStats(updateFilters(filters));
        triggerFetchStrategiesBalances(updateFilters(filterForStrategiesBalances))

    };
    

    useEffect(() => {
        if (!userAccountsData || !strategiesDataFetch || !filtersInitialized ) return
        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const hasStrategyFilter = filters.some(filter => filter.id === 'strategy');

        if (!startFilterTimestamp && !hasStrategyFilter) {
            const newFilters = [
                ...filters,
                { id: 'position_end_timestamp_gte', value: startTimestemp },
                { id: 'position_end_timestamp_lte', value: endTimestemp },
                { id: 'account_id_in', value: userAccountsData[0] },
                { id: 'strategy', value: strategiesDataFetch.filter(el => el.account_id === userAccountsData[0])[0].strategy}
            ];

           

            applyFiltersAndFetchData(newFilters, true)

        } else if (startFilterTimestamp && !hasStrategyFilter) {

            const newFilters = [
                ...filters,
                { id: 'strategy', value: strategiesData[0].strategy}
            ];

            applyFiltersAndFetchData(newFilters, true)

           
        } else if (startFilterTimestamp && hasStrategyFilter) {
            applyFiltersAndFetchData(filters)
        }

    }, [userAccountsData, strategiesDataFetch, filtersInitialized])



    const handleApplyFilters = (filters) => {
        applyFiltersAndFetchData(filters)
    }

     useEffect(() => {
        if(filtersInitialized) {
            applyFiltersAndFetchData(filters)
        }
    }, [JSON.stringify(filters)]);

    useEffect(() => {

        if(!strategiesDataFetch || !filtersInitialized) return;

        const filterStrategyValue  = filters.find(filter => filter.id === 'strategy')?.value

        const strategy = strategiesDataFetch?.find(el => el.strategy === filterStrategyValue);
    
        
        if(strategy && strategy.account_id !== accountId) {
             const newFilters = filters.map(filter => filter.id === 'strategy' 
            ? { ...filter, value: strategiesData?.sort((a, b) => a.status - b.status)[0]?.strategy } 
            : filter
        ) 
      
        setFilters(newFilters)
        }
    
    }, [accountId, strategiesDataFetch, filtersInitialized])

   

    console.log(filtersInitialized)

    return (
        <>
            <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
            <div className="itemPage">

                {isFetchingStrategiesBalances ? (
                    <IsFetching height={200} />
                ) : (
                    <ModelsBalancesChart data={strategiesBalancesData} />
                )}

                {isFetchingPairsStats ? (
                    <IsFetching height={200} />
                ) : (
                    <PairPnlChart pairsStatsData={pairsStatsData} />
                )} 
            </div>

        </>

    )
}
