import PositionsTableBacktest from "../../components/BacktestResult/PositionsTableBacktest";
import IsFetching from "../../components/IsFetching";
import { useFetchBacktestByIdPositionsQuery, useFetchBacktesTradesDistributionQuery } from "../../redux/statisticsServices/backtestsApi";
import StatusDisplay from "../../components/Backtests/StatusDisplay";

import { useContext, useMemo, useState } from "react";

import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";
import { statusEnum } from "../../components/MainLayouts/BacktestLayout";
import PositionsStatsTab from "../../components/BacktestResult/PositionStatsTab";
import TabsContainer from "../../components/TabsContainer";
import BacktestTradesDistributionChart from "../../components/BacktestResult/BacktestTradesDistributionChart";
import useTabletView from "../../hooks/useTabletView";
import { useIsAppleDevice } from "../../hooks/useIsAppleDevice";

export function BacktestPositionsPage () {

    const { 
        backtestId,
        backtestByIdData, 
        isFetchingBacktestById,
        isConfigurationOpen
    } = useContext(BacktestResultsContext)
    const isTablet = useTabletView();

    const [backtestTradesDistributionMode, setBacktestTradesDistributionMode] = useState(0);

    const backtestTradesDistributionModesArray = ['Day', 'Week', 'Month'];

    const isApple = useIsAppleDevice();

    const { data: backtestByIdPositionsData, isFetching:  isFetchingBacktestByIdPositions} = useFetchBacktestByIdPositionsQuery({backtestId, limit: 1000});

    const { data: backtesTradesDistributionData, isFetching:  isFetchingbacktesTradesDistribution} = 
    useFetchBacktesTradesDistributionQuery({backtestId, timeframe: `1${backtestTradesDistributionModesArray[backtestTradesDistributionMode][0].toLowerCase()}`});

    const sortedBacktestByIdPositionsData = useMemo(() => {
        if(backtestByIdPositionsData) {
            return [...backtestByIdPositionsData].sort((a, b) => a.pair.localeCompare(b.pair))
        }
        
    }, [backtestByIdPositionsData]) 

    

   
    if (statusEnum[backtestByIdData?.status] !== 'Finished') {
        return (
           <StatusDisplay status={statusEnum[backtestByIdData?.status]} />
        )
    }

    if (isFetchingBacktestByIdPositions ||  isFetchingBacktestById ) {
        return (
            <IsFetching />
        )
    }

    const tabsContent = [
    
        <PositionsStatsTab
            key={0} 
            backtestId={backtestId}
            isFilters={false}
        />,

        <PositionsStatsTab 
            key={1} 
            backtestId={backtestId}
            isFilters={true}
        />,
    ];

    const tabsLabels = ['Unrealised', 'Realised'];

    return (
        <div className="itemPage">

            <PositionsTableBacktest data={sortedBacktestByIdPositionsData} generalLink={`/backtests/backtestId/${backtestId}`} />
            <TabsContainer tabsLabels={tabsLabels}>
                {tabsContent}
            </TabsContainer>

            <BacktestTradesDistributionChart 
                data={backtesTradesDistributionData} 
                mode={backtestTradesDistributionMode}
                setMode={setBacktestTradesDistributionMode}
                modesArray={backtestTradesDistributionModesArray}
                isFetching={isFetchingbacktesTradesDistribution}
            /> 
           
        </div>
    )
}