import { useEffect } from "react";
import FormModel from "./FormModels/FormModel";
import { strategy__model } from "./FormModels/formConfig";

const AddStrategyForm = ({ model, setModel, userAccountsData }) => {

    useEffect(() => {

        const newModel = strategy__model.map(el => {
            return el.key === 'account_id'
                ? {
                    ...el,
                    values: userAccountsData,
                    value: userAccountsData[0],
                }
                : el;
        })
        setModel(newModel)
    }, [userAccountsData]);

    return (
            <FormModel
                inputModels={model}
                setInputModels={setModel}
            />
    
    )
}

export default AddStrategyForm