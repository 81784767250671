import "react-datepicker/dist/react-datepicker.css";
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles/style.scss';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './pages/Login.js';
import { Links } from './links.js';
import Layout from './components/MainLayouts/Layout.js';
import ProtectedRoute from './hoc/ProtectedRoute.js';
import StatisticsLayout from './components/MainLayouts/StatisticsLayout.js';
import { Settings } from './pages/Settings.js';
import BacktestLayout from './components/MainLayouts/BacktestLayout.js';
import { ToastContainer} from 'react-toastify';

import { TradeSettingsProvider } from './contexts/TradeSettingsContext.js';
import { Accounts}  from './pages/Accounts/Accounts.js';
import { SidebarProvider } from './contexts/SidebarContext.js';
import { useAppMode } from './contexts/AppModeContext.js';
import { useEffect } from "react";
import { startLink, startLinkIndex } from "./utils/variables.js";


export const isBacktestOnlyMode = process.env.REACT_APP_BACKTEST_ONLY === 'true';

export const routes =  isBacktestOnlyMode ? Object.values(Links).filter(link => link.title === 'Backtests') : Object.values(Links).slice(1);

function App() {

const { mode } = useAppMode();

const renderRoutes = (currentRoutes) => {
  return currentRoutes.map(route => {
    const routeElement = mode === 'simple' && route.element_simple ? route.element_simple  : route.element;

    if (route.children && route.children.length > 0) {
      return (
        <Route key={route.path} path={route.path} >
          <Route index element={<ProtectedRoute>{routeElement}</ProtectedRoute>} />
          {renderRoutes(route.children)}
        </Route>
      );
    } else {
      return (
        <Route key={route.path} path={route.path} element={<ProtectedRoute>{routeElement}</ProtectedRoute>} />
      );
    }
  });
}

  return (
    <>
      <Routes>
        <Route path={Links.login.path} element={<Login />} />

        <Route path="/" element={<Navigate to={routes[startLinkIndex].path} replace />} />

        <Route element={<Layout />}>
          {renderRoutes(routes.filter(route => !route.path.startsWith('/statistics') && !route.path.startsWith('/settings') && !route.path.startsWith('/backtests') && !route.path.startsWith('/accounts')))}
        </Route>

        <Route element={<StatisticsLayout />}>
          {renderRoutes(routes.filter(route => route.path.startsWith('/statistics')))}
        </Route>

        <Route element={<BacktestLayout />}>
          {renderRoutes(routes.filter(route => route.path.startsWith('/backtests')))}
        </Route>

        <Route path='/settings' element={
          <ProtectedRoute>
            <SidebarProvider>
               <TradeSettingsProvider>
                 <Settings />
              </TradeSettingsProvider>
            </SidebarProvider>
             
          </ProtectedRoute>}>
          {renderRoutes(Links.strategies.children)}
        </Route>

        <Route path='/accounts' element={
          <ProtectedRoute>
            <SidebarProvider>
              <Accounts />
            </SidebarProvider>
              
          </ProtectedRoute>}>
          {renderRoutes(Links.accounts.children)}
        </Route>

      </Routes>
  
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        //theme="light"
        //transition='Bounce'
      />
    </>
  );
}

export default App;
