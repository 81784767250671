import { useContext, useEffect, useMemo, useState } from "react";
import useUrlFilters from "../../hooks/useUrlFilters";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { useLazyFetchBacktestByIdPositionsQuery, useLazyFetchBacktestByIdTradesQuery } from "../../redux/statisticsServices/backtestsApi";
import IsFetching from "../../components/IsFetching";
import PnLByPositionChart from "../../components/Statistics/PnLByPositionChart";
import CandlestickChartWithVolume from "../../components/Charts/CandlestickChartWithVolume";
import StatusDisplay from "../../components/Backtests/StatusDisplay";
import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";
import { useLazyFetchParquetPairCandlesQuery } from "../../redux/portfolioBacktestServices/defaultApi";
import { statusEnum } from "../../components/MainLayouts/BacktestLayout";
import PositionsTableBacktest from "../../components/BacktestResult/PositionsTableBacktest";
import { formatEnamKey, formatFloat, formatTimestempUTC } from "../../utils/functions";
import InfoItem from "../../components/InfoItem";


export function BacktestPairsPage() {
    const [filters, setFilters] = useState([]);
    const [quantityData, setQuantityData] = useState({});

    const {
        backtestId,
        backtestByIdData,
        isFetchingBacktestById,
        backtestByIdStatsData,
        isFetchingBacktestByIdStats,
        mainAsset,
    } = useContext(BacktestResultsContext)

    const filterOptions = {
        pair: {
            type: 2,
            values: backtestByIdStatsData && [...backtestByIdStatsData.pairs],
        },
        open_time_gte: {
            show: false,
        },
        open_time_lte: {
            show: false,
        },
    }

    const [triggerFetchBacktestPositions, { data: backtestPositionsData, isFetching: isFetchingBacktestPositions, isError: isErrorBacktestPositions }] = useLazyFetchBacktestByIdPositionsQuery();
    const [triggerFetchPairCandles, { data: pairCandlesData, isFetching: isFetchingPairCandles }] = useLazyFetchParquetPairCandlesQuery();
    const [triggerFetchBacktestPairTrades, { data: pairBacktestTradesData, isFetching: isFetchingBacktestPairTrades, isSuccess: isSuccessBacktestPairTrades }] = useLazyFetchBacktestByIdTradesQuery();

    useEffect(() => {
        if (!pairBacktestTradesData) return;

        function calculateMaxQuantity(trades) {
            if (!trades || trades.length === 0) return { maxQuantity: 0, maxQuantityCount: 0 };
        
            return trades.reduce((acc, trade) => {
                if (trade.quantity * trade.price > acc.maxQuantity) {
                    return { maxQuantity: trade.quantity * trade.price, maxQuantityCount: 1 };
                } else if (trade.quantity * trade.price === acc.maxQuantity) {
                    acc.maxQuantityCount += 1;
                }
                return acc;
            }, { maxQuantity: 0, maxQuantityCount: 0 });
        }
        const buyTrades = pairBacktestTradesData.filter(el => el.side === 1);
        const sellTrades = pairBacktestTradesData.filter(el => el.side === -1);

        const max_buy_quantity = calculateMaxQuantity(buyTrades);
        const max_sell_quantity = calculateMaxQuantity(sellTrades);

        const max_used_quantity = [...pairBacktestTradesData].sort((a, b) => a.timestamp - b.timestamp).reduce((acc, el) => {

            acc.totalQuantity += el.quantity * el.side * el.price;

            if(acc.maxQuantityObject.quantity < acc.totalQuantity) {
                acc.maxQuantityObject = {
                    quantity: acc.totalQuantity,
                    timestamp: el.timestamp,
                }
            }

            return acc
           
        }, {totalQuantity: 0, maxQuantityObject: {quantity: 0, timestamp: null}})

       
        setQuantityData({
            max_buy_quantity, 
            max_sell_quantity,
            max_used_quantity
        })

    }, [pairBacktestTradesData])

    const filtersInitialized = useUrlFilters(filters, setFilters, filterOptions);

    function createFilterForCandles() {
        if (backtestByIdStatsData) {
            return [
                { id: "open_time_gte", value: backtestByIdStatsData.backtest[0].start_time },
                { id: "open_time_lte", value: backtestByIdStatsData.backtest[0].end_time }
            ].filter(filter => filter);
        }
    }

    useEffect(() => {
        const hasPairFilter = filters.some(filter => filter.id === 'pair');
        const filterForCandles = createFilterForCandles();
        if(!backtestByIdStatsData || !filtersInitialized) return

        if (!hasPairFilter) {
            const pairValue = backtestByIdStatsData?.pairs[0];
            if (pairValue) {

                const newFilters = [...filters, { id: 'pair', value: pairValue }];

                setFilters(newFilters);
                triggerFetchBacktestPositions({ filters: newFilters, backtestId, limit: 1000 });
                triggerFetchPairCandles({ pair: pairValue, filters: filterForCandles });
                triggerFetchBacktestPairTrades({ filters: newFilters, backtestId })

            }

        } else {

            const pairValue = filters.find(filter => filter.id === 'pair')?.value;

            triggerFetchBacktestPositions({ filters: filters, backtestId, limit: 1000 });
            triggerFetchPairCandles({ pair: pairValue, filters: filterForCandles });
            triggerFetchBacktestPairTrades({ filters: filters, backtestId })
        }
        

    }, [backtestByIdStatsData, filtersInitialized]);

    const handleApplyFilters = (filters) => {
        const pairValue = filters.find(filter => filter.id === 'pair')?.value;
        const filterForCandles = createFilterForCandles();
        triggerFetchBacktestPositions({ filters, backtestId, limit: 1000 });
        triggerFetchPairCandles({ pair: pairValue, filters: filterForCandles });
        triggerFetchBacktestPairTrades({ filters, backtestId })
    }

    useEffect(() => {
        if(filtersInitialized) {
            handleApplyFilters(filters)
        }
    }, [JSON.stringify(filters)])

    const sortedBacktestPositionsData = useMemo(() => {
        if (backtestPositionsData) {
            return [...backtestPositionsData].sort((a, b) => b.position_start_timestamp - a.position_start_timestamp)
        }
    }, [backtestPositionsData])

    if (statusEnum[backtestByIdData?.status] !== 'Finished') {
        return (
            <StatusDisplay status={statusEnum[backtestByIdData?.status]} />
        )

    }

    if (isFetchingBacktestByIdStats || isFetchingBacktestPositions || isFetchingPairCandles || isFetchingBacktestById) {
        return (
            <>
                <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
                <IsFetching />
            </>
        )
    }
    
    const getQuantityValue = (key, value) => {
        switch(key) {
            case 'max_buy_quantity':
            case 'max_sell_quantity':
                return <InfoItem inputKey={formatEnamKey(key)} value={`${formatFloat(value.maxQuantity)} BTC`} />


            default: 
                return  <InfoItem inputKey={formatEnamKey(key)} value={[`${formatFloat(value.maxQuantityObject.quantity)} BTC`, `(${formatTimestempUTC(value.maxQuantityObject.timestamp)})`]} />
                   
               
        }
    }

    return (
        <>
            <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
            <div className="itemPage">

            {isFetchingBacktestPairTrades ? (
                        <IsFetching height={200}/>
                    ) : (
                        <div className="backgroundContainer">
                            {!isSuccessBacktestPairTrades ? (
                                <div className="noData">No Data</div>
                            ) : (
                                <div className="statisticsGeneralPage__mainInfo">
                        
                                <>
                                {Object.entries(quantityData).map(([key, value]) => {
                                        return (
                                            <div className="infoItem" key={key}>
                                                {getQuantityValue(key, value)}
                                            </div>

                                        )
                                    })}
                                </>
                                
                            </div>
                            )}
                    </div>
                )}
            
                <CandlestickChartWithVolume
                    data={{
                        candles: pairCandlesData,
                        trades: (pairBacktestTradesData && isSuccessBacktestPairTrades) ? pairBacktestTradesData : [],
                    }}
                    isBacktests={true}
                    mainAsset={mainAsset}
                    positions={backtestPositionsData}
                    useUTC={true}
                />
                <PnLByPositionChart
                    limit={1000}
                    positionsData={(!isErrorBacktestPositions && backtestPositionsData)
                        ? backtestPositionsData
                        : []} isLegend={false}
                    startDate={pairCandlesData && [...pairCandlesData].sort((a, b) => a.open_time - b.open_time)[0].open_time}
                    endDate={pairCandlesData && [...pairCandlesData].sort((a, b) => a.open_time - b.open_time)[pairCandlesData.length - 1].open_time}
                    mainAsset={mainAsset}
                    useUTCTZ={true}
                />

                {isSuccessBacktestPairTrades && <PositionsTableBacktest data={sortedBacktestPositionsData} />}

            </div>
        </>

    )
}