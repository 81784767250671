//backtestForms

import { startOfYear, subDays } from "date-fns";
import { timeFrames } from "../../utils/variables";
import { STATUSES } from "../../utils/enams";
import { formatEnamKey } from "../../utils/functions";

const defaultMainSettingsChange__model = [
    {
        key: 'main_asset',
        type: 'radio',
        title: 'Main asset',
        values: ['BTC', 'USDT'],
        value: 'BTC',
    },

    {
        key: 'parquet_filename',
        type: 'select',
        title: 'Data selection',
        value: null,
        values: [],
    },

    {
        key: 'selection_of_pairs',
        type: 'radio',
        title: 'Selection of pairs',
        value: 'List',
        values: ['List', 'One Pair', 'Whitelist']
    },
];

export const mainSettingsChange__model = {
    'List': [
        ...defaultMainSettingsChange__model,
        {
            key: 'pairs_list',
            type: 'select',
            title: 'Pairs List',
            value: null,
            values: [],
        },
        {
            key: 'blacklist_of_pairs',
            type: 'selectbox',
            title: 'Blacklist of Pairs',
            value: null,
            values: [],
        },
        {
            key: 'common_cash',
            type: 'toggle',
            title: 'One balance for all pairs',
            value: true,
        },
    ],
    'One Pair': [
        ...defaultMainSettingsChange__model,
        {
            key: 'select_symbol',
            type: 'select',
            title: 'Select symbol',
            value: null,
            values: [],
        },
    ],
    'Whitelist': [
        ...defaultMainSettingsChange__model,
        {
            key: 'list_of_backtests',
            type: 'selectbox',
            title: 'List of pairs (all by default)',
            value: null,
            values: [],
        },
        {
            key: 'common_cash',
            type: 'toggle',
            title: 'One balance for all pairs',
            value: true,
        },
    ],
};

export const mainSettings__model = [
    
    {
        key: 'backtest_id',
        type: 'string',
        title: 'Backtest id',
        placeholder: '',
        value: '',
    },
    {
        key: 'high_priority',
        type: 'toggle',
        title: 'High priority',
        groupWithNext: true,
        value: false,
    },
    {
        key: 'calculate_estimate',
        type: 'toggle',
        title: 'Calculate estimate',
        value: false,
    },
    {
        key: 'time_frame',
        type: 'select',
        title: 'Time interval',
        value: '1m',
        values: timeFrames,
        withoutSearch: true,
        shouldSort: false
    },
    {
        key: 'testing_start_date',
        type: 'date',
        title: 'Testing start date',
        value: subDays(new Date(), 30).getTime(),
    },
    {
        key: 'testing_end_date',
        type: 'date',
        title: 'Testing end date',
        value: new Date().getTime(),
    },
    
];

export const ballanceControl__model = [
    {

        key: 'balance_control',
        type: 'header',
        title: 'balance control',
    },
    {
        key: 'initial_equity',
        type: 'number',
        title: 'Opening ballance (BTC)',
        value: 1,

    },
    {
        key: 'position_size',
        type: 'number',
        title: 'Balance for the deal',
        value: 0.01,
    },
    {
        key: 'size_type',
        type: 'select',
        title: 'Type of purchase',
        value: 'фіксоване значення',
        values: ['фіксоване значення', 'відсоток від балансу'],
        withoutSearch: true
    },
    {
        key: 'fees',
        type: 'number',
        title: 'Commission (%)',
        value: 0.075,
    },
    {
        key: 'direction',
        type: 'select',
        title: 'Trade type',
        value: 'longonly',
        values: ['longonly', 'shortonly', 'both'],
        withoutSearch: true
    },
    {
        key: 'stop_loss',
        type: 'number',
        title: 'Stop loss (as %) [<0 - disable]',
        value: -10,
    },
    {
        key: 'take_profit',
        type: 'number',
        title: 'Take profit (as %) [<0 - disable]',
        value: -10.0,
    },
    {
        key: 'slippage',
        type: 'number',
        title: 'Fixed slippage',
        value: 0.1,
    },
    {
        key: 'slippage_toggle',
        type: 'toggle',
        title: 'Use dynamic slippage',
        value: true,
    },
];

export const modelsControls__model = [
    {
        key: 'strategy',
        type: 'select',
        title: 'Model',
        value: null,
        values: [],
        validation: true,
    },
];

export const blacklistSettings__model = [
    {

        key: 'balance_control',
        type: 'header',
        title: 'BlackLists Settings',
    },
    {
        key: 'criterion_1',
        type: 'number',
        title: 'Price BTC to USDT (>=0.17)',
        value: '',
    },
    {
        key: 'criterion_2',
        type: 'number',
        title: 'Cnt transaction 24h (<1000)',
        value: '',
    },
    {
        key: 'criterion_4',
        type: 'number',
        title: 'Zero candles volume ratio (>=78)',
        value: '',
    },
    {
        key: 'criterion_5',
        type: 'number',
        title: 'Change BTC vol hour (>=8)',
        value: '',
    },
    {
        key: 'criterion_6',
        type: 'number',
        title: 'Volume 24h (<4)',
        value: '',
    },
    {
        key: 'criterion_7',
        type: 'number',
        title: 'Diff USDT BTC vol (>=17)',
        value: '',
    },
];


//other models

export const strategy__model = [
    {
        key: 'strategy',
        type: 'string',
        title: 'Strategy name',
        placeholder: '',
        value: '',
    },
    {
        key: 'status',
        type: 'radio',
        title: 'Status',
        values: ['Active', 'Pause'],
        value: 'Active',
    },
    {
        key: 'position_quantity',
        type: 'number',
        title: 'Position quantity',
        value: null,
    },
    {
        key: 'balance',
        type: 'number',
        title: 'Balance',
        value: 0,
        disabled: true,
    },
    {
        key: 'account_id',
        type: 'select',
        title: 'Account id',
        value: null,
        values: []
    },
    {
        key: 'exchange',
        type: 'string',
        title: 'Exchange',
        disabled: true,
        value: 'Binance',
    },
];

export const account__model = [
    {
        key: 'exchange',
        type: 'string',
        title: 'Exchange',
        disabled: true,
        value: 'Binance',
    },
    {
        key: 'account_id',
        type: 'string',
        title: 'Account id',
        value: '',
    },

    {
        key: 'main_asset',
        type: 'string',
        disabled: true,
        title: 'Main asset',
        value: 'BTC',
    },
    {
        key: 'free_balance',
        type: 'number',
        title: 'Free balance',
        value: 0,
    },
    {
        key: 'is_sandbox',
        type: 'toggle',
        title: 'Is sandbox',
        value: false,
    },
    {
        key: 'trading_bot_url',
        type: 'string',
        title: 'Trading bot url (dev only)',
        disabled: true,
        value: '',
    },
    {
        key: 'api_key',
        type: 'string',
        title: 'api key',
        value: '',
    },
    {
        key: 'api_secret',
        type: 'string',
        title: 'api secret',
        value: '',
    },

    {
        key: 'public_key',
        type: 'textarea',
        title: 'Public key',
        value: '',
    },

];

export const buyBNB__model = [
    {
        key: 'change_commission_balance_qty',
        type: 'number',
        title: 'Buy or Sell comission BNB',
        value: 0,
        withSave: true,
        actionTitle: 'Buy'
    },
];

export const multiBuy__model = [
    {
        key: 'qty_multiplicator',
        type: 'number',
        title: 'Quantity multiplicator',
        value: 1,
        withSave: true,
        actionTitle: 'Buy more'
    },
];

export const newPosition__model = [
    {
        key: 'strategy',
        type: 'select',
        title: 'Strategy',
        values: [],
        value: null,
    },

    {
        key: 'pair',
        type: 'select',
        title: 'Pair',
        values: [],
        value: null,
    },
    {
        key: 'qty_multiplicator',
        type: 'number',
        title: 'Quantity multiplicator',
        value: 1,
    },
];

export const mlModel__model = [
    {
        key: 'name',
        type: 'string',
        title: 'Name',
        placeholder: '',
        value: '',
        validation: true,
    },
    {
        key: 'status',
        type: 'select',
        title: 'Status',
        value: Object.keys(STATUSES).map(el => formatEnamKey(el))[0],
        values: Object.keys(STATUSES).map(el => formatEnamKey(el)),
        withoutSearch: true
    },
    {
        key: 'details',
        type: 'textarea',
        title: 'Details',
        placeholder: '',
        value: '',
    },
];

export const mlModelVersion__model = [
    {
        key: 'name',
        type: 'string',
        title: 'Name',
        placeholder: '',
        value: '',
        disabled: true,
        groupWithNext: true,
       
    },
    {
        key: 'version',
        type: 'string',
        title: 'Version',
        placeholder: '',
        value: '',
        validation: true,
    },
    {
        key: 'type',
        type: 'string',
        title: 'Type',
        placeholder: '',
        value: '',
    },
    {
        key: 'source_types',
        type: 'string',
        title: 'Source types',
        placeholder: '',
        value: '',
    },
    {
        key: 'training_start_date',
        type: 'date',
        title: 'Training start date',
        value: subDays(new Date(), 14).getTime(),
        groupWithNext: true
    },
    {
        key: 'training_end_date',
        type: 'date',
        title: 'Training end date',
        value: new Date().getTime(),
    },
    {
        key: 'num_iterations',
        type: 'number',
        title: 'Num iterations',
        value: 0,
        groupWithNext: true,
    },
    {
        key: 'training_duration',
        type: 'number',
        title: 'Training duration',
        value: 0,
    },
    {
        key: 'iteration_duration',
        type: 'number',
        title: 'Iteration duration',
        value: 0,
        groupWithNext: true
    },
   
    {
        key: 'training_intervals',
        type: 'selectbox',
        title: 'Training intervals',
        value: [],
        values: timeFrames,
        withoutSearch: true,
    },
    {
        key: 'quality_assessment',
        type: 'string',
        title: 'Quality assessment',
        placeholder: '',
        value: '',
    },
    {
        key: 'testing_start_date',
        type: 'date',
        title: 'Testing start date',
        value: startOfYear(new Date()).getTime(),
        groupWithNext: true
    },
    {
        key: 'testing_end_date',
        type: 'date',
        title: 'Testing end date',
        value: new Date().getTime(),
    },
    {
        key: 'status',
        type: 'string',
        title: 'Status',
        placeholder: '',
        value: '',
    },
    {
        key: 'payload',
        type: 'textarea',
        title: 'Details',
        placeholder: '',
        value: '',
    },
    {
        key: 'category_breakdown',
        value: '',
        show: false,
    },
    {
        key: 'buy_hold_pnl_percent',
        value: 0,
        show: false
    },
    {
        key: 'training_start_month',
        value: null,
        show: false
    },
    {
        key: 'training_end_month',
        value: null,
        show: false
    },
    {
        key: 'avg_monthly_trades',
        value: 0,
        show: false
    },
    {
        key: 'initial_pnl_percent',
        value: 0,
        show: false
    },
    {
        key: 'final_pnl_percent',
        value: 0,
        show: false
    },

    {
        key: 'intellect_rate',
        value: [[0, 0]],
        show: false,
    },
    {
        key: 'production_ready',
        value: false,
        show: false,
    },
   
]