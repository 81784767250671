import statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'
import { buildQueryString } from '../../utils/functions';


export const tradesApi = statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchTrades: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.trades}${buildQueryString(filters)}`,
      providesTags: (result) => [
        //...result.map(({ pair }) => ({ type: 'Pairs', id: pair })),
        { type: 'Trades', id: `Trades_Pair${result?.[0]?.pair}_LIST`},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'Trades')
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useLazyFetchTradesQuery,
  useFetchTradesQuery,
} = tradesApi;