import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import IsFetching from '../../components/IsFetching';

import BackButton from '../../components/Buttons/BackButton';
import { useFetchMlModelsQuery, useFetchMlModelVersionsStatsQuery } from '../../redux/statisticsServices/mlModelsApi';
import { accountsWhiteList } from '../../utils/variables';
import Button from '../../components/Buttons/Button';
import ModelVersionsChart from '../../components/MLStat/ModelVersionsChart';

export function MlModelPage() {

  //const { data: mlModelsData } = useFetchMlModelsQuery();

  const { mlModelName } = useParams();

  const { data: mlModelsData, isFetching: isFetchingMlModels } = useFetchMlModelsQuery();

  const { data: mlModelVersionsData, isFetching, isError } = useFetchMlModelVersionsStatsQuery(mlModelName);

  const navigate = useNavigate();

  if (isFetching || isFetchingMlModels) {
    return (
      <div className="page">
        <IsFetching />
      </div>
    )
  }

  const  userName = localStorage.getItem('username');

  const handleAddNewMLModelVersion = () => {
    navigate('addNew')
}

  return (
      <div className='itemPage'>

        <div className="flexSpaceBetween titleMargin">
          <div className="flexGap10">
            <BackButton path={'/ml_stat'} />
            <h1 style={{ marginLeft: 0}}>{mlModelName}</h1>
          </div>
          
          {accountsWhiteList.includes(userName) && (
            <Button
              onClick={handleAddNewMLModelVersion}
            >
              + Add new
            </Button>
          )}
                    
        </div>
        {isError ? (
          <div className="noData">No Data</div>
        ) : (
          <>
            {mlModelsData.find(el => el.name === mlModelName).details
              && (
                <div className='itemPage__customField'>{mlModelsData.find(el => el.name === mlModelName).details}</div>
              )}
              
             <ModelVersionsChart mlModelVersionsData={mlModelVersionsData} />
          </>
        
  )}
        
     
    </div>
  )
}