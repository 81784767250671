import { format } from "date-fns";
import { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";


const DateInput = ({ input, update }) => {
    const [startDate, setStartDate] = useState(new Date(input.value));

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button 
            className="input" 
            onClick={onClick} 
            ref={ref}
            type="button"
        >
          {format(new Date(value), 'dd/MM/yyyy')  }
        </button>
      ));

    const handleChange = (date) => {
        setStartDate(date)
        update(input, date.getTime())
    }
    
    return (
        <div className="formModel__item">
            {input.title}
            <div>
                <ReactDatePicker 
                selected={startDate} 
                onChange={(date) => handleChange(date)} 
                customInput={<CustomInput />}
                //inline
            />
            </div>
            
        </div>
    )
}

export default DateInput;