import { Link } from "react-router-dom";
import { convertEnam, formatEnamKey } from "../../utils/functions";
import Table from "../Table/Table"
import { getProperty } from "../../pages/Settings";
import { BACKTESTSTATUSES } from "../../utils/enams";
import TimeProgressBar from "../TimeProgressBar";
import Icon from "../Icon";
import { useBacktestCancelMutation, useBacktestSetHighPriorityMutation } from "../../redux/statisticsServices/backtestsApi";
import useTabletView from "../../hooks/useTabletView";

import * as styles from '../../styles/utils/_variables.scss';
import { useContext } from "react";
import { BacktestsContext } from "../../contexts/BacktestsContext";
import DeleteButton from "../Buttons/DeleteButton";


const BacktestListTable = ({ data = [], title, calculatedBacktests = false, errorBacktest = false, canceledBacktests = false, highlightRow }) => {

    const [backtestCancel] = useBacktestCancelMutation();

    const {
        handleDeleteBacktest

    } = useContext(BacktestsContext);


    const [backtestSetHighPriority] = useBacktestSetHighPriorityMutation();

    const isTablet = useTabletView();

    const handleCancelBacktest = async (id) => {
        try {
            await backtestCancel(id).unwrap();
        } catch (error) {
            console.error("Backtest cancel error", error);
            alert("Backtest cancel error", error);
        }
    }

    const handleSetHighPriority = async (id) => {
        try {
            await backtestSetHighPriority(id).unwrap();
        } catch (error) {
            console.error("Backtest set high priority error", error);
            alert("Backtest set high priority error", error);
        }
    }

    const tableData = data.map(el => {
        const { backtest_id, status, model, launch_timestamp, configuration, estimation_calculation, finish_timestamp, high_priority, error, pnl_percent, positions_count } = el;

        return {

            backtest_id,
            ...(errorBacktest ? {
                error_message: error.message
            } : {}),
            ...(calculatedBacktests || errorBacktest || canceledBacktests ? {} : {
                actions: {
                    status,
                    high_priority,
                    backtest_id
                }
            }),
            ...(calculatedBacktests || errorBacktest || canceledBacktests ? {} : {
                status: convertEnam(BACKTESTSTATUSES)[status],
            }),

            ...(calculatedBacktests ? { pnl_percent: pnl_percent, positions_count: positions_count } : {}),

            launch_at_timestamp: launch_timestamp,
            ...(calculatedBacktests || errorBacktest ? {} : {
                estimeted_calculated_time: {
                    start_time: launch_timestamp,
                    value: launch_timestamp + estimation_calculation,
                    estimation_calculation,
                    status,
                }
            }),
            model,
            period: configuration.api_params.period.map(e => {
                return `${e.slice(-2)}/${e.slice(-4, -2)}/${e.slice(0, 4)}`
            }).join(' - '),
            assets: {
                count: configuration.api_params.assets.length,
                description: configuration.api_params.assets.length > 6 ? `${configuration.api_params.assets.slice(0, 6).join(',  ')}...` : configuration.api_params.assets.join(',  ')
            },
            time_frame: configuration.api_params.time_frame,
            ...(calculatedBacktests ? { duration: finish_timestamp - launch_timestamp } : {}),
            ...(calculatedBacktests || errorBacktest || canceledBacktests ? { delete: backtest_id } : {}),
        };
    });

    const getActionButtons = (status, id, high_priority) => {

        switch (status) {
            case BACKTESTSTATUSES['Added']:

                return !high_priority && (
                    <div className="flexGap10">
                        <button
                            onClick={() => handleSetHighPriority(id)}
                            className="button"
                            style={{ minWidth: 20 }}
                        >
                            <Icon type="high_priority" size={20} className="coloWhite" />
                        </button>
                        <button
                            onClick={() => handleCancelBacktest(id)}
                            className="button"
                            style={{ minWidth: 20 }}
                        >
                            <Icon type="close" size={20} color={'#fff'} className="coloWhite" />
                        </button>
                    </div>
                );

            case BACKTESTSTATUSES['CalculatingEstimates']:
            case BACKTESTSTATUSES['CalculatingBacktest']:

                return (
                    <button
                        onClick={() => handleCancelBacktest(id)}
                        className="button"
                        style={{ minWidth: 20 }}
                    >
                        <Icon type="close" size={20} color={'#fff'} className="coloWhite" />
                    </button>
                );

            default:

                return null;
        }
    }

    const getEstimetedCalculated = (value, key) => {

        switch (value.estimation_calculation) {
            case null:
                return 'no estimate'
            case -1:
                return 'error estimate calculation'
            default:
                return (
                    <>
                        {value.status !== 5
                            ? (
                                <>
                                    {getProperty(key, value.value)}
                                    <TimeProgressBar startDate={value.start_time} endDate={value.value} />
                                </>
                            ) : (
                                'no estimate'
                            )
                        }
                    </>

                )
        }
    }

    const columnsSizeCalculated = isTablet ? [250, 105, 100, 100, 150, 200, 180, 100, 100, 120, 50] : [300, 105, 100, 100, 150, 200, 180, 100, 100, 120, 50];

    const columnsSizeError = isTablet ? [250, 300, 150, 200, 180, 100, 100, 50] : [300, 300, 150, 200, 180, 100, 100, 50];

    const columnsSizeCanceled = isTablet ? [250, 150, 150, 200, 180, 100, 100, 50] : [300, 150, 150, 200, 180, 100, 100, 50];

    const columnsSizeInProgress = isTablet ? [250, 110, 150, 170, 200, 200, 180, 100, 100] : [300, 110, 150, 170, 200, 200, 180, 100, 100];

    const getColumnSize = () => {
        switch (true) {
            case calculatedBacktests:
                return columnsSizeCalculated;

            case errorBacktest:
                return columnsSizeError;

            case canceledBacktests:
                return columnsSizeCanceled;

            default:
                return columnsSizeInProgress;
        }
    }

    const tableColumns = tableData[0] && Object.keys(tableData[0]).map((key, i) => ({
        accessorKey: key,
        header: formatEnamKey(key.replace('timestamp', '')),
        filterFn: 'myCustomFilter',
        filterable: !(key === 'details') && !(key === 'assets') && !(key === 'estimeted_calculated_time') && !(key === 'actions') && !(key === 'delete'),
        sortable: !(key === 'details') && !(key === 'assets') && !(key === 'estimeted_calculated_time') && !(key === 'actions') && !(key === 'delete'),
        size: getColumnSize()[i],

        cell: info => {

            const value = info.getValue();

            switch (key) {

                case 'backtest_id':
                    return (
                        <div className="link txt16x24">
                            <Link to={`/backtests/backtestId/${value}/general`}>
                                {value}
                            </Link>

                        </div>
        
                    );

                case 'assets':
                    return (
                        <div className="textTableCenter" style={{ color: styles.default.colorBlue }} >
                            {getProperty(key, value.count)}
                        </div>

                    );

                case 'actions':
                    return getActionButtons(value.status, value.backtest_id, value.high_priority);


                case 'estimeted_calculated_time':
                    return (
                        <div className="flexColumnGap5">
                            {getEstimetedCalculated(value, key)}
                        </div>
                    )


                case 'delete':
                    return (
                        <DeleteButton deletingFunc={() => handleDeleteBacktest(value)} />

                    );



                default:
                    return getProperty(key, value);
            }
        },
    }))

    return (
        <div className="page--layout">
            <Table
                tableData={tableData.length === 0 ? null : tableData}
                tableColumns={tableColumns}
                tooltipKey="assets"
                title={title}
                keyBold={["backtest_id", "error_message"]}
                highlightRow={highlightRow}
                textAlignCenterKeys={['status', 'time_frame', 'duration']}
                mainHeight={window.innerHeight - 80 - 63 - 80 - 27 - 60}
            />
        </div>


    )
}

export default BacktestListTable