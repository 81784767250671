import { merge } from 'lodash';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import useTabletView from '../../hooks/useTabletView';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';


const DoughnutChart = ({ data, options}) => {

  const isTablet = useTabletView();

  const getValueDependingView = useValueDependingOnView()


  const defaultOptions = {
    
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            align: 'end',
            labels: {
              usePointStyle: true,
              boxWidth: getValueDependingView(15, 10, 5),
              boxHeight: getValueDependingView(15, 10, 5),
    
              font: {
                size: getValueDependingView(12, 10, 8),
              }
            }
          },
    

      title: {
        display: true,
        align: 'start',
        color: '#333333',
        font: {
          size: isTablet ? 16 : 20,
          lineHeigth: '27px',
          weigth: 500,
        }
      },

      tooltip: {
        mode: 'nearest',
        intersect: true,
        
          displayColors: false,
      },
      datalabels: false,
    }
  };

  const mergedOptions = merge({}, defaultOptions, options);

  return (
    <div style={{ height: getValueDependingView('300px', '250px', '200px')}}> 
        <Doughnut data={data} options={mergedOptions} />
    </div>
  )
};

export default DoughnutChart;
