import { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { dropdown } from "../utils/animations";

const TabsContainer = ({ children, tabsLabels, activeIndex = null }) => {

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if(activeIndex !== null) {
            setActiveTab(activeIndex)
        }
    }, [])

    const handleClick = (index) => {
        setActiveTab(index);
    }

    return  (
        <div className="tabsContainer">
           <div className="tabsContainer__tabsList">
                {children.map((child, index) => (
                    <button
                        key={index}
                        className={`txt14x21 tabButton  ${activeTab === index ? 'tabButton--active' : ''}`} 
                        onClick={() => handleClick(index)}
                    >
                        {tabsLabels[index]}
                    </button>
                ))}
           </div>
        
                <motion.div
                    key={activeTab}
                    initial="hidden" 
                    animate="visible"
                    exit="hidden"
                    variants={dropdown}
                    style={{ width: '100%'}}
                >
                    {children[activeTab]}
                </motion.div>
           
        </div>
    )
}

export default TabsContainer;