import { formatEnamKey, formatTimestempUTC } from "../../utils/functions"
import * as styles from '../../styles/utils/_variables.scss';
import { format } from "date-fns";
import LineChart from "../Charts/LineChart";

const PnlChart = ({ data, startBalance, mainAsset, dataKey }) => {
  const dataLabel = dataKey === "pnl_cumulative" ? "Unrealised PNL" : "Realised PNL";
  const chartData = {
    datasets: [
      {
        data: data.benchmark_cumulative.map(el => {

          return {
            x: el.timestamp,
            y: el.pnl,
          }

        }),

        label: formatEnamKey('benchmark'),
        fill: false,
        type: 'line',
        radius: 0,
        borderColor: styles.default.colorDarkGray,
        backgroundColor: 'transparent',
        hoverRadius: 5,
        borderWidth: 2,
        hidden: dataKey === "pnl_cumulative" ? false : true
      },

      {
        data: data[dataKey].map(el => {

          return {
            x: el.timestamp,
            y: el.pnl,
          }

        }),

        label: dataLabel,
        type: 'line',
        radius: 0,
        borderColor: styles.default.colorRed,
        backgroundColor: 'transparent',
        hoverRadius: 5,
        borderWidth: 2,
        fill: {
          target: {
            value: startBalance,
          },
          below: `${styles.default.colorRed}90`,
          above: `${styles.default.colorGreen}90`,
        }

      }
    ]
  }

  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        title: {
          text: 'Datetime',
        },
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          maxTicksLimit: 10,
          callback: function (value) {
            return formatTimestempUTC(value).split(" ")
          },
        }
      },
      y: {
        //max: startBalance,
        title: {
          text: 'PnL',
        },
        ticks: {
          callback: function (value) {
            return `${value.toFixed(5)} ${mainAsset}`
          }
        },
      }
    },
    plugins: {
      tooltip: {
        intersect: false,
        displayColors: false,
        callbacks: {
          title: function (context) {
            const x = context[0].raw.x

            return `Time: ${formatTimestempUTC(x)}`
          },

          label: function (context) {
            const { y } = context.raw;

            return `${context.dataset.label} ${y.toFixed(9)} ${mainAsset}`
          }
        }
      },
      title: {
        text: `${dataLabel} Chart`,
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: 'line'
        }
      }
    }
  };


  return (
    <LineChart
      chartData={data.length === 0 ? null : chartData}
      options={chartOptions}
      showZeroLine={true}
      zeroLineValue={startBalance}
      zeroLineColor={styles.default.colorDarkGray}
    />
  )
}

export default PnlChart;