import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { dropdown } from "../../../utils/animations";
import { motion } from "framer-motion"
import DropdownToggle from "./DropdownToggle";
import { classnames } from "../../../utils/functions";

const Dropdown = ({ input, update, values, renderSpan, notClickClose = false, height = 'max-content', customToggle = null, shouldSort = true, onFocus = () => {}}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [isUpwards, setIsUpwards] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownListRef = useRef(null);

    useEffect(() => {
        const dropdownList = dropdownListRef.current;
        if (dropdownList) {
            const dropdownRect = dropdownList.getBoundingClientRect();
            const shouldOpenUpwards = window.innerHeight < dropdownRect.bottom + dropdownRect.height;

            setIsUpwards(shouldOpenUpwards);
        }
    }, [isOpen]);

   
    const handleToggle = () => {
        if(input && input.error) {
            onFocus(input);
        }
        setIsOpen(!isOpen);

        setIsUpwards(false)
    };

    const handleClick = (value) => {
        update(value);
        !notClickClose && setIsOpen(false)
    }

    useOnClickOutside(dropdownRef, () => setIsOpen(false));

     const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }
    const filteredValues = values && values
    .filter(value => 
        !(input && input.value?.includes(value)) && value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (shouldSort) {
        filteredValues?.sort((a, b) => a.localeCompare(b));
    }

    return (
        <motion.div 
            className="dropdown"
            ref={dropdownRef} 
            initial="hidden" 
            animate={isOpen ? 'visible' : 'hidden'}
            
        >
            {customToggle ? (
                customToggle(handleToggle, isOpen)
            ) : (
                <DropdownToggle 
                    isOpen={isOpen}
                    handleToggle={handleToggle}
                    height={height}
                    renderSpan={renderSpan}
                    input={input}
                />
            )}
 
            {isOpen && (
                <motion.div 
                    className={classnames('dropdown__list', {'dropdown__list--upwards' : isUpwards})} 
                    ref={dropdownListRef}
                    variants={dropdown}
                    
                >
                    {!input?.withoutSearch && (
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Type to search..."
                            className="input dropdown__list__input"
                            autoFocus
                        />
                    )}
                    
                    <div className="dropdown__list__main scrolling">
                        {filteredValues && filteredValues.map((value, i) => (
                            <div 
                                key={value + i} 
                                onClick={() => handleClick(value)} 
                                className="dropdown__item txt14x21"
                            >
                                <p>{value}</p>
                            </div>
                        ))}
                    </div>
                </motion.div>
            )}
        </motion.div>
    )
}

export default Dropdown;




               