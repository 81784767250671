import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLazyFetchStrategiesBalancesQuery, useLazyFetchStrategiesQuery } from "../../redux/statisticsServices/strategiesApi";
import useUrlFilters from "../../hooks/useUrlFilters";
import { formatEnamKey, formatFloat, parseSearchParams, reorderKeys } from "../../utils/functions";
import IsFetching from "../../components/IsFetching";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { getProperty } from "../Settings";
import { useLazyFetchPairsStatsQuery } from "../../redux/statisticsServices/pairsApi";
import PairPnlChart from "../../components/Statistics/ PairPnlChart";
import PairPnLByStrategiesChart from "../../components/Statistics/PairPnLByStrategiesChart";
import AccountsTotalBalanceChart from "../../components/Statistics/AccountsTotalBalanceChart";
import { useLazyFetchAccountCommissionBalanceQuery, useLazyFetchAccountsStatisticGeneralQuery, useLazyFetchAccountsTotalBalanceQuery } from "../../redux/statisticsServices/accountsApi";
import ModelsBalancesChart from "../../components/Statistics/ModelsBalancesChart";
import { useFetchUserAccountQuery } from "../../redux/statisticsServices/authApi";
import { FilterContext } from "../../contexts/FilterContext";
import AccountCommissionBalanceChart from "../../components/Statistics/AccountCommissionBalanceChart";
import useMobileView from "../../hooks/useMobileView";
import { endTimestemp, getCustomLink, startTimestemp } from "./StatisticsGeneralPage";
import StrategiesTable from "../../components/Statistics/StrategiesTable";
import * as styles from '../../styles/utils/_variables.scss';
import Icon from "../../components/Icon";
import Tooltip from "../../components/Tooltip";
import useTabletView from "../../hooks/useTabletView";
import useValueDependingOnView from "../../hooks/useValueDependingOnView";
import PositionsDoughnutChart from "../../components/Statistics/PositionsDoughnutChart";
import UnusedBalanceDoughnutChart from "../../components/Statistics/UnusedBalanceDoughnutChart";
import ActualBalancePolarAreaChart from "../../components/Statistics/ActualBalancePolarAreaChart";
import FreeBalancePolarAreaChart from "../../components/Statistics/TotalPositionsPolarAreaChart";
import TotalPositionsPolarAreaChart from "../../components/Statistics/TotalPositionsPolarAreaChart";
import InfoItem from "../../components/InfoItem";



export function StatisticsGeneralPageSimple() {
    const { filters, setFilters } = useContext(FilterContext);

    const location = useLocation();
    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();

    const [triggerFetchGeneralData, { data: generalData, isFetching: isFetchingGeneralData, isError: isErrorGeneralData }] = useLazyFetchAccountsStatisticGeneralQuery();
    const [triggerFetchStrategies, { data: strategiesFilteredData, isFetching: isFetchingStartegiesFiltered }] = useLazyFetchStrategiesQuery();
    const [triggerFetchPairsStats, { data: pairsStatsData, isFetching: isFetchingPairsStats, isError: isErrorPairsStats }] = useLazyFetchPairsStatsQuery();
    const [triggerFetchAccountaTotalBalance, { data: accountsTotalBalanceData, isFetching: isFetchingAccountsTotalBalance, isError: isErrorAccountsTotalBalance }] = useLazyFetchAccountsTotalBalanceQuery();
    const [triggerFetchStrategiesBalances, { data: strategiesBalancesData, isFetching: isFetchingStrategiesBalances }] = useLazyFetchStrategiesBalancesQuery();
    const [triggerFetchAccountCommissionBalance, { data: AccountCommissionBalanceData, isFetching: isFetchingAccountCommissionBalance }] = useLazyFetchAccountCommissionBalanceQuery();

    const getValueDependingView = useValueDependingOnView()


    const isTablet = useTabletView();

    const isMobile = useMobileView();

    const filterOptions = {
        account_id_in: {
            type: 2,
            values: userAccountsData,
        },

        position_end_timestamp_gte: {
            type: 3
        },
        position_end_timestamp_lte: {
            type: 3
        }

    }

    const filtersInitialized = useUrlFilters(filters, setFilters, filterOptions);

    function createFilterForAccountStatisticGeneral(filters) {
        return filters.filter(filter => filter.id !== 'account_id_in');
    }

    function createFilterForAccountsTotalBalance(filters) {

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const endFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_lte')?.value;


        return [
            startFilterTimestamp && { id: "update_time_gte", value: startFilterTimestamp },
            endFilterTimestamp && { id: "update_time_lte", value: endFilterTimestamp },
        ];
    }

    function createFilterForStrategiesBalances(filterForAccountsTotalBalance, accountId) {

        return [
            { id: "timeframe", value: '15m' },
            { id: "account_id_in", value: accountId },
            ...filterForAccountsTotalBalance

        ].filter(filter => filter);
    }

    function createFilterForAccountCommissionBalance(filterForAccountsTotalBalance) {

        return [
            { id: "timeframe", value: '15m' },
            ...filterForAccountsTotalBalance

        ].filter(filter => filter);
    }

    const searchParams = new URLSearchParams(location.search);

    const applyFiltersAndFetchData = (filters, ifSetFilters = false) => {
        const accountId = filters.find(filter => filter.id === 'account_id_in')?.value;
        const filterForAccountsTotalBalance = createFilterForAccountsTotalBalance(filters);
        const filterForStrategiesBalances = createFilterForStrategiesBalances(filterForAccountsTotalBalance, accountId);
        const filterForAccountCommissionBalance = createFilterForAccountCommissionBalance(filterForAccountsTotalBalance);
        if (ifSetFilters) {
            setFilters(filters);
        }

        if (accountId) {
            triggerFetchGeneralData({ filters: createFilterForAccountStatisticGeneral(filters), accountId });
            triggerFetchAccountaTotalBalance({ filters: filterForAccountsTotalBalance, accountId });
            triggerFetchAccountCommissionBalance({ filters: filterForAccountCommissionBalance, accountId });
        }

        triggerFetchStrategies(filters);
        triggerFetchPairsStats(filters);
        triggerFetchStrategiesBalances(filterForStrategiesBalances);
    };

    useEffect(() => {
        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        if (!userAccountsData || !filtersInitialized) return;


        if (!startFilterTimestamp) {
            const newFilters = [
                ...filters,
                { id: 'position_end_timestamp_gte', value: startTimestemp },
                { id: 'position_end_timestamp_lte', value: endTimestemp },
                { id: 'account_id_in', value: userAccountsData[0] },

            ];
            applyFiltersAndFetchData(newFilters, true);


        } else {
            applyFiltersAndFetchData(filters);
        }
    

    }, [userAccountsData, filtersInitialized]);

    const [visibleGeneralData, setVisibleGeneralData] = useState({});

    
    useEffect(() => {
        if (generalData) {
            // end balance from Binance
            // const freeBalancePercent = generalData.end_balance !== 0 ? `(${(generalData.account_free_balance / generalData.end_balance * 100).toFixed(2)}%)` : ``;
            // end balance from DB
            const freeBalancePercent = generalData.strategies_total_balance !== 0 ? `(${(generalData.account_free_balance_percent).toFixed(2)}%)` : ``;
            const strategiesUnusedBalancePercent = generalData.strategies_total_balance !== 0 ? `(${(generalData.strategies_unused_balance_percent).toFixed(2)}%)` : ``;
            const accountUnusedBalancePercent = generalData.account_total_balance !== 0 ? `${(generalData.account_unused_balance_percent).toFixed(2)}%` : ``;

            const startBalance = generalData.start_balance !== 0 ? `${generalData.start_balance.toFixed(3)} BTC` : 'No data';
            const endBalance = generalData.end_balance !== 0 ? `${generalData.end_balance.toFixed(3)} BTC` : 'No data';
            const balanceChange = generalData.start_balance === 0 || generalData.end_balance === 0 ? 'No data' : (
                <>
                    {/* <div>
                    </div> */}
                    <div
                        style={{ color: generalData.balance_diff > 0 ? styles.default.colorGreen : styles.default.colorRed, display: 'block' }}
                        className='flexGap5'
                    >
                        {/*  */}
                        <Icon type={generalData.balance_diff > 0 ? 'up' : 'down'} size={15} />
                        {(generalData.balance_diff_percent).toFixed(2)}%
                        ({generalData.balance_diff.toFixed(3)} BTC)
                    </div>
                </>
            );



            const mergedData = !isErrorGeneralData ? {
                'Total positions count': generalData.total_position_count,
                'Open positions count': generalData.open_position_count,
                'Closed positions count': generalData.close_position_count,
                'Account Unused balance': `${accountUnusedBalancePercent} (${generalData.account_unused_balance.toFixed(3)} BTC)`,
                'Start | end balance (BTC)': `${startBalance} | ${endBalance}`,
                'Balance change': balanceChange,
                'Best pair': `${generalData.best_pair} (${formatFloat(generalData.best_pair_pnl)} BTC)`,
                'Best model': `${generalData.best_strategy} (${formatFloat(generalData.best_strategy_pnl)} BTC)`,
                'Account Commission Balance': `${generalData.account_commission_balance.toFixed(3)} BNB`,
            } : {};

            setVisibleGeneralData(mergedData);
        }

    }, [generalData, isErrorGeneralData]);


    const sortedAccounts = useMemo(() => {
        if (accountsTotalBalanceData) {
            return !isErrorAccountsTotalBalance ? [...accountsTotalBalanceData].sort((a, b) => a.update_time - b.update_time) : []
        }
    }, [accountsTotalBalanceData, isErrorAccountsTotalBalance])

    const handleApplyFilters = (filters) => {
        applyFiltersAndFetchData(filters);
    }

    useEffect(() => {
        if(filtersInitialized) {
            applyFiltersAndFetchData(filters);
        }
    }, [JSON.stringify(filters)])

    //console.log(JSON.stringify(filters))


    if (strategiesFilteredData?.length === 0) {
        return (
            <>
                <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
                <div>NO DATA</div>
            </>

        )
    }

    const desiredKeyOrder = [
        'strategy',
        'start_balance',
        'actual_balance',
        'free_balance_percent',
        'realised_pnl_percent',
        'unrealised_pnl_percent',
        'total_position_count',
        'total_trades_count',
        'avg_pnl_percent',
        'total_avg_duration',
        'win_position_count',
        'loss_position_count',
        'buy_trades_count',
        'sell_trades_count',

        'exchange',
    ];


    const tableData = reorderKeys(strategiesFilteredData, desiredKeyOrder)

    const getHeaders = (key) => {
        switch (key) {
            case 'strategy':
                return 'Model';

            case  'total_avg_duration':
                return 'Avg position duration';

            default:
                return formatEnamKey(key)
        }
    }


    const tableColumns = tableData?.[0] ? Object.keys(tableData[0])?.map((key, index) => ({
        accessorKey: key,
        header: getHeaders(key),
        filterFn:  'myCustomFilter',
        filterable: true,
        sortable: true,
        size: isMobile ? index === 0 ? 140 : 140 : 170,

        cell: info => {
            const value = info.getValue();
            if (key === 'strategy') {
                return getCustomLink(searchParams, key, value, 'strategies')
            }

            return getProperty(key, value)
        }

    })) : []



    // const sortedAccounts = accountsTotalBalanceData && [...accountsTotalBalanceData].sort((a, b) => a.update_time - b.update_time);

    const getGeneralInfo = (key, value) => {
        switch (key) {
            case 'Best model':
                const modelName = value.split(' ')[0];
                const otherData = value.split(' ')[1] + ' ' + value.split(' ')[2]

                return  <InfoItem inputKey={key} value={[
                    getCustomLink(searchParams, 'strategy', modelName, 'strategies'),
                    otherData
                ]} />

            case 'Best pair':
                const pairName = value.split(' ')[0];
                const otherDataPair = value.split(' ')[1] + ' ' + value.split(' ')[2]

                return <InfoItem inputKey={key} value={[
                   getCustomLink(searchParams, 'pair', pairName, 'pairs'),
                    otherDataPair
                ]} />

            default:
                return <InfoItem inputKey={key} value={value} />

        }
    }

    const trade_settings_generalBlock = {
        "Total positions count": "Загальна кількість угод",
        "Open positions count": "Кількість відкритих угод",
        "Closed positions count": "Кількість закритих угод",
        "Account Unused balance": "Вільний баланс акаунту",
        "Start | end balance (BTC)": "Початковий баланс та актуальний баланс",
        "Balance change": "Зміна балансу",
        "Best pair": "Найкраща пара",
        "Best model": "Найкраща модель",
        "Account Commission Balance": "Актуальний баланс виділений на комісії"
    }

    const trade_settings_modelsTable = {
        "strategy": "Модель",
        "start_balance": "Початковий баланс моделі за обраний період",
        "actual_balance": "Актуальний баланс моделі",
        "free_balance_percent": "Вільний баланс моделі",
        "realised_pnl_percent": "PnL по закритим угодам",
        "unrealised_pnl_percent": "PnL по відкритим угодам",
        "total_position_count": "Загальна кількість угод",
        "total_trades_count": "Загальна кількість трейдів",
        "avg_pnl_percent": "Середній PnL по закритим угодам",
        "total_avg_duration": "Середня тривалість угоди",
        "win_position_count": "Кількість угод з додатнім PnL",
        "loss_position_count": "Кількість угод з відʼємним PnL",
        "buy_trades_count": "Кількість BUY трейдів",
        "sell_trades_count": "Кількість SELL трейдів",
        "exchange": "Біржа на якій торгує модель"
    }
  
    return (
        <>
            <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
            <div className="itemPage statisticsGeneralPage">
                {isFetchingGeneralData ? (
                    <IsFetching height={200} />
                ) : (
                    <>
                        <div className="backgroundContainer">
                            {isErrorGeneralData ? (
                                <div className="noData">No Data</div>
                            ) : (
                                <div className="statisticsGeneralPage__mainInfo">

                                    <>
                                        {Object.entries(visibleGeneralData).map(([key, value]) => {
                                            return (
                                                <div className="infoItem" style={{position: 'relative'}}  key={key}>
                                                    <Tooltip text={trade_settings_generalBlock[key]} maxWidth={getValueDependingView(150, 100, 80) } style={{position: 'absolute', top: 3, right: 3}}/>
                                                    {getGeneralInfo(key, value)}
                                                </div>
                                            )
                                        })}
                                    </>

                                </div>
                            )}
                        </div> 
                        <div className="backgroundContainer" style={{ display: 'flex', justifyContent: 'center'}}>
                        {isErrorGeneralData ? (
                                <div className="noData">No Data</div>
                            ) : (
                              
                                    <div className={`${isMobile ? "flexColumnGap20" :  "flexSpaceBetween"}`}  style={{ width: getValueDependingView('65%', '80%', '100%') }}>
                                        <PositionsDoughnutChart 
                                            data={[
                                            {label: 'Closed Positions', amount: generalData?.close_position_count},   
                                            {label: 'Open Positions', amount: generalData?.open_position_count},              
                                                   
                                            ]}
                                        />
                                        <UnusedBalanceDoughnutChart 
                                            data={[
                                            {label: 'Used Balance', amount: (100 -  generalData?.account_unused_balance_percent).toFixed(2)},      
                                            {label: 'Unused Balance', amount: generalData?.account_unused_balance_percent.toFixed(2)},              
                                                   
                                            ]}
                                        />
                                        
                                    </div>
                              
                            )}
                        </div>
                    </>
                    
                    
                )}

                {isFetchingAccountsTotalBalance ? (
                    <IsFetching height={200} />
                ) : (
                    <AccountsTotalBalanceChart data={sortedAccounts} />
                )}


                {isFetchingStartegiesFiltered ? (
                    <IsFetching height={200} />
                ) : (

                    <>
                        {!tableData || tableData.length === 0 ? (
                            <div className="backgroundContainer">
                                <div className="noData">No Data</div>
                            </div>
                        ) : (
                            <>
                                <StrategiesTable tableData={tableData} tableColumns={tableColumns} trade_settings={trade_settings_modelsTable} />
                                <div className="backgroundContainer" style={{ display: 'flex', justifyContent: 'center'}}>
                                     <div className={`${isMobile ? "flexColumnGap20" :  "flexSpaceBetween"}`}   style={{ width: getValueDependingView('65%', '80%', '100%') }}>
                                    <ActualBalancePolarAreaChart
                                        data={strategiesFilteredData.map(strategy => ({
                                            label: strategy.strategy,
                                            value: strategy.actual_balance
                                        }))}
                                    />
                                     <TotalPositionsPolarAreaChart
                                        data={strategiesFilteredData.map(strategy => ({
                                            label: strategy.strategy,
                                            value: strategy.total_position_count
                                        }))}
                                    />
                                
                                </div>
                                </div>
                               
                            </>
                            
                  

                        )}
                    </>
                )}
               

                {isFetchingStrategiesBalances ? (
                    <IsFetching height={200} />
                ) : (
                    <ModelsBalancesChart data={strategiesBalancesData} />
                )}

                {isFetchingAccountCommissionBalance ? (
                    <IsFetching />
                ) : (
                    <AccountCommissionBalanceChart data={AccountCommissionBalanceData} accountId={filters.find(filter => filter.id === 'account_id_in')?.value} />
                )}

                {isFetchingPairsStats ? (
                    <IsFetching height={200} />
                ) : (
                    <>
                        <PairPnlChart pairsStatsData={pairsStatsData} />
                    </>

                )}


                {isFetchingStartegiesFiltered ? (
                    <IsFetching height={200} />
                ) : (
                    <PairPnLByStrategiesChart strategiesData={strategiesFilteredData} />
                )}
            </div>
        </>

    )
}
