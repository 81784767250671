import { motion } from 'framer-motion';
import React, { createRef, useCallback, useRef, useState } from 'react';
import TooltipWithoutButton from '../TooltipWithoutButton';
import Icon from '../Icon';
import { flexRender } from '@tanstack/react-table';
import * as styles from '../../styles/utils/_variables.scss';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';
import useMobileView from '../../hooks/useMobileView';

const TableBody = ({ 
    rows, 
    highlightRow, 
    textAlignCenterKeys, 
    tooltipKey, 
    extraRow, 
    keyBold, 
    tableColumnsLength,  
    rowVirtualizer,
    isPinned
}) => {

    const [tooltipCellId, setTooltipCellId] = useState(null);
    const [activeRow, setActiveRow] = useState(null);

    const getValueDependingView = useValueDependingOnView()

    const isMobile = useMobileView()

    const tooltipCellsRefs = useRef([]);

    const getTooltipCellsRef = (index) => {
        if (!tooltipCellsRefs.current[index]) {
            tooltipCellsRefs.current[index] = createRef();
        }
        return tooltipCellsRefs.current[index];
      };

    const handleMouseAssetsSellEnter = useCallback((id) => {
        if (tooltipKey && tooltipKey === id.split('_')[1]) {
            setTooltipCellId(id);
        }
    }, [tooltipKey]) 
    
    const handleMouseAssetsSellLeave = useCallback(() => {
        if(tooltipKey)  {
            setTooltipCellId(null)
        } 
    }, [tooltipKey]) 

    const toggleActiveRow = (rowId) => {
        if (extraRow && !isMobile) {
            setActiveRow(activeRow === rowId ? null : rowId);
        }
    };

    return (

        <tbody 
            className="table__body txt14x21"
            style={{
                display: 'grid',
                height: `${rowVirtualizer.getTotalSize()}px`, 
                position: 'relative',
              }}
        >
            {rowVirtualizer.getVirtualItems().map((virtualRow, i) => {
                const row = rows[virtualRow.index];

                return (
                    <React.Fragment key={row.id}>
                    <tr
                        key={row.id}
                        className={`table__row ${(highlightRow && (row.original[highlightRow?.key] === highlightRow?.value)) ? 'table__row--highlighted' : ''}`}
                        data-index={virtualRow.index}
                        ref={node => rowVirtualizer.measureElement(node)} 
                
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`, 
                          width: '100%',
                          height:  activeRow === row.id ? getValueDependingView(80, 80, 60) :  'auto',
                          alignItems: activeRow === row.id ? 'flex-start' :  'initial',
    
                        }}
                    >
                        {row.getVisibleCells().map((cell, index) => {
                            const key = cell.id.split('_').slice(1).join('_')
                            return (
                                <td 
                                    key={cell.id} 
                                    className={
                                        `table__row__item 
                                        ${textAlignCenterKeys.includes(key) ? 'textTableCenter' : ''}
                                        ${keyBold.includes(key) ? 'bold' : ''}
                                        ${isPinned && index === 0 ? 'table--stickyCell' : ''}
                                        `} 
                                    onMouseEnter={() => handleMouseAssetsSellEnter(cell.id)}
                                    onMouseLeave={handleMouseAssetsSellLeave}
                                    ref={getTooltipCellsRef(cell.id)}
                                    style={{
                                        width: cell.column.getSize(), 
                                        borderLeft: isPinned && index === 0 && styles.default.inputBorder, 
                                        borderRadius: isPinned && i === rowVirtualizer.getVirtualItems().length - 1 ? '0 0  0 8px' : 0,
                                        borderTop: i === 0 ? 'none' : styles.default.inputBorder
                                    }}
                                >
                                    {tooltipKey && (
                                        <TooltipWithoutButton
                                            text={cell.getValue()?.description} 
                                            isVisible={cell.id === tooltipCellId}
                                            maxWidth={isMobile ? 80 : 300}
                                            parentRef={tooltipCellsRefs.current[cell.id] }
                                            setIsVisible={setTooltipCellId}
                                        />
                                    )}
                                    
                                    {index === 0
                                        ? (
                                            <div 
                                                onClick={() => toggleActiveRow(row.id)} 
                                                className={`twoRows textOverflowEllipsis ${extraRow ? 'dropdown__toggle' : ''} ${activeRow === row.id ? 'dropdown__toggle--open' : ''} `}
                                                style={{ margin: '3px 0', minHeight: extraRow && 0, justifyContent: 'start'}}
                                            >
                                                {extraRow && !isMobile &&  (
                                                    <div className="dropdown__toggle__icon"> 
                                                        <Icon type={'down'} size={10} />
                                                    </div>
                                                )}
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        ) 
                                        : (
                                            <div 
                                                className="textOverflowEllipsis scrollingHidden"  
                                                style={{ margin: activeRow === row.id ? '9px 0' : '', maxHeight: '100%', overflowY: 'auto'}}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        ) 
                                    }

                                    {activeRow === row.id && index === 0 && (
                                        <motion.tr
                                            key={"extra-" + row.id}
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: getValueDependingView(40, 40, 30) }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.1 }}
                                            className="table__row table__row--expanding"
                                            style={{ 
                                                borderLeft: isPinned && styles.default.inputBorder,
                                                borderRadius: 0,
                                            }}
                                        >
                                            <td colSpan={tableColumnsLength}>
                                                {extraRow(row)}
                                            </td>
                                        </motion.tr>
                                    )}
                                    
                                </td>
                            )
                        })}

                    </tr>

                </React.Fragment>
                )
            })}
        </tbody>
    )

}

export default TableBody;