import { useSortable } from "@dnd-kit/sortable"
import { flexRender } from "@tanstack/react-table"
import * as styles from '../../styles/utils/_variables.scss';

import { CSS } from '@dnd-kit/utilities'

const DragAlongCell = ({ cell, isPinned, isLast }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  })

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    display: 'table-cell'
  }

  if (isPinned) {
    return (
      <td
        ref={setNodeRef}
        style={{
          display: 'table-cell',
        }}
        className="table__row__item table--stickyCell"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }

  return (
    <td style={style} ref={setNodeRef} className="table__row__item">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

export default DragAlongCell