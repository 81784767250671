import { useEffect, useRef, useState } from "react";
import { formatEnamKey } from "../utils/functions";
import Tooltip from "./Tooltip";
import Icon from "./Icon";
import { motion } from 'framer-motion'
import { dropdown } from "../utils/animations";
import { getProperty } from "../pages/Settings";

const Properties = ({ data, renderFunction = (key, value) => value, formatedData = null, trade_settings={}, additionalData = null}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const [firstColWidth, setFirstColWidth] = useState(0);
    const firstColRef = useRef(null);

    useEffect(() => {
        if (firstColRef.current) {
            setFirstColWidth(firstColRef.current.offsetWidth);
        }
    }, []);

    const handleExpande = () => {
        setIsExpanded(!isExpanded)
    }
    const propertiesData = formatedData === null ? data : formatedData;
    return (

        <div className="backgroundContainer">
            <table className='properties'>
                <tbody>
                    {Object.entries(propertiesData).map(([key, value], i) => {

                    return (
                        <tr key={i} className='property-row'>
                            <td className='property-key txt16x24' ref={i === 0 ? firstColRef : null}>
                                <div>
                                    {formatEnamKey(key)}
                                    {trade_settings[key] && (
                                        <Tooltip text={trade_settings[key]} maxWidth={300}/>
                                    )}

                                </div>
                                
                            </td>
                            <td className='property-value txt16x24'><div className="textOverflowEllipsis"> {renderFunction(key, value)}</div></td>
                        </tr>
                    );
                })}
                {additionalData && (
                    <tr className='property-row'>
                        
                        <td colSpan={2} className='property-key txt16x24' style={{ borderBottom: isExpanded && 'none' }}>
                            <button 
                                className={`properties__expandButton ${isExpanded ? 'dropdown__toggle--open' : ''}`}
                                onClick={handleExpande}
                                style={{ height: 18, overflow: 'hidden'}}
                            >
                                <span className="txt14x21 bold">For developers</span>
                                <div className="dropdown__toggle__icon" >
                                    <Icon
                                        type={'dropdownDown'} 
                                        size={20}
                                    />
                                </div>
                            </button>
                        </td>
                        
                    
                    </tr>

                )}
                </tbody> 
            </table>

            {(isExpanded && additionalData) && (
                <motion.table className='properties'
                    initial="hidden"
                    animate="visible"
                    variants={dropdown}
                    exit="hidden"
                    //style={{ width: 1279}}
                >
                    <tbody>
                        {Object.entries(additionalData).map(([key, value], i) => {

                        return (
                                 <tr key={i} className='property-row'>
                                <td className='property-key txt16x24' style={{ width: firstColWidth, boxSizing: 'border-box'}} >
                                    <div>
                                        {formatEnamKey(key)}
                                        {trade_settings[key] && (
                                            <Tooltip text={trade_settings[key]} maxWidth={300}/>
                                        )}
            
                                    </div>
                                        
                                 </td>
                                <td className='property-value  txt16x24'> <div className="textOverflowEllipsis">{getProperty(key, value)}</div></td>
                            </tr>
                           
                            );
                        })
                    }
                    </tbody>
                    
                </motion.table>
            
            )}
        </div>
        
    );
};

export default Properties;