import  statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'


export const mlModelsApi =  statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMlModels: builder.query({
      query: () => STATISTICS_API_URLS.ml_models,
      providesTags: (result = []) => [
        ...result.map(({ name }) => ({ type: 'MLModels', id: name })),
        { type: 'MLModels', id: 'MLModels_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'MLModels')
      },
    }),

    fetchMlModelVersionsStats: builder.query({
      query: (modelName) => STATISTICS_API_URLS.ml_model_stats.replace("{model_name}", modelName),
      providesTags:
      (result = []) => [
        ...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'ModelVersions', id: result[0] ? `Model${result[0].ml_model_name}_Versions_LIST` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "ModelVersions")
      },
      extraOptions: { tags: "ModelVersions" },
    }),

    fetchMlModelVersion: builder.query({
      query: ({modelName, version}) =>  STATISTICS_API_URLS.ml_model_version.replace("{model_name}", modelName).replace("{model_version}", version),
      providesTags: (result) => [{ type: 'ModelVersion', id: result && `Model${result.name}_Version${result.version}`}],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'ModelVersion')
      },
    }),

    fetchMlModelVersionBacktests: builder.query({
      query: ({modelName, version}) =>  STATISTICS_API_URLS.ml_model_version_backtests.replace("{model_name}", modelName).replace("{model_version}", version),
      providesTags: (result = []) => [
        ...result.map(({ ml_model_name, ml_model_version}, i) => ({ type: 'ModelVersionBacktests', id: `${ml_model_name}_${ml_model_version}_backtest${i}` }) ),
        { type: 'ModelVersionBacktests', id: `Model${result[0]?.ml_model_name}_Version${result[0]?.ml_model_version}_Backtests_LIST` },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'ModelVersionBacktests')
      },
    }),

    mlModelAdd: builder.mutation({
      query: (body) => ({
        url: STATISTICS_API_URLS.ml_models,
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'MLModels');
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchMlModels', undefined, (draft) => {
            draft.push(arg); 
          })
        );
        try {
          await queryFulfilled;
        } catch {
          console.error(`Error while mlModel ${arg.name} add`);
          patchResult.undo()
        }
      },
    }),

    mlModelVersionAdd: builder.mutation({
      query: ({body, modelName}) => ({
        url: STATISTICS_API_URLS.ml_models_ml_model_versions.replace("{ml_name}", modelName),
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'ModelVersions');
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchMlModelVersionsStats', arg.name, (draft) => {
            draft.push({
              name: arg.name,
              version: arg.version,
              training_end_date: arg.training_end_date,
              final_pnl_percent: arg.final_pnl_percent
            }); 
          })
        );
        try {
          await queryFulfilled;
        } catch {
          console.error(`Error while mlModelVersion ${arg.Version} add`);
          patchResult.undo()
        }
      },
    }),

    mlModelVersionBacktestListRefresh: builder.mutation({
      query: ({ modelName, versionName }) => ({
        url: STATISTICS_API_URLS.ml_models_ml_model_version_backtest_list.replace("{ml_name}", modelName).replace("{ml_version}", versionName),
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'ModelVersions');
      },
    }),

  }),
  overrideExisting: false,
});

export const {
  useFetchMlModelsQuery,
  useFetchMlModelVersionsStatsQuery,
  useFetchMlModelVersionQuery,
  useFetchMlModelVersionBacktestsQuery,
  useMlModelAddMutation,
  useMlModelVersionAddMutation,
  useMlModelVersionBacktestListRefreshMutation,
} = mlModelsApi;
