import useMobileView from "../../hooks/useMobileView"
import useTabletView from "../../hooks/useTabletView"
import Tooltip from "../Tooltip"

const ChartTabs = ({ modesArray, mode, setMode, tooltips = {} }) => {

  const handleModeChange = (i) => {
    setMode(i)
  }

  const isTablet = useTabletView();
  const isMobile = useMobileView();

  return (
    <div className="flexGap10 scrollingHidden chartTabs" >
      {modesArray.map((el, i) => {
        return (
          <button onClick={() => handleModeChange(i)} className={`tabButton txt14x21 ${i === mode ? 'tabButton--active' : ''}`} key={el}>
            {tooltips[el] && !isMobile && (
              <Tooltip text={tooltips[el]} maxWidth={isTablet ? 150 : 300} />
            )}
            {el}
          </button>
        )
      })}

    </div>
  )
}

export default ChartTabs;