export const scale = {
    hidden: {
        scale: 0,
        opacity: 0
    }, 
    visible: {
        scale: 1,
        opacity: 1
    }
}

export const dropdown = {
    hidden: {
        y: -20,
        opacity: 0
    }, 
    visible: {
        y: 0,
        opacity: 1
    }
}

export const rightPanel = {
    hidden: {
        x: 0,
        width: 90,
        borderRadius: '0 0 0 8px' , 
       
    }, 
    visible: {
        x: 0,
        width: 300,
        borderRadius: 0
    }
}

export const opacity = {
    hidden: {
        scale: 0.5,
        opacity: 0
    }, 
    visible: {
        scale: 1,
        opacity: 1
    }
}

export const tooltipVariants = {
    hidden: { opacity: 0, y: -5 },
    visible: { opacity: 1, y: -25 }
};

export const sidebarVariants = {
    open: { x: 0 },
    closed: { x: "-100%" },
};


