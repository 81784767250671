const Button = ({children, className, ...props}) => {
    return (
        <button 
            className={`${className} button`}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button