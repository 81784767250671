import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS,  BarElement, } from 'chart.js';
import merge from 'lodash.merge';
import useMobileView from '../../hooks/useMobileView';
import useTabletView from '../../hooks/useTabletView';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';
import { backgroundItem } from '../../utils/variables';

ChartJS.register(
  BarElement,
);

const BarChartVertical = ({ data, options }) => {

  
  const getValueDependingView = useValueDependingOnView()
  const isTablet = useTabletView();
  const isMobile = useMobileView();
  const chartData = {
    labels: data.labels,
    datasets: data.datasets,
  };

  const defaultOptions = {
    
    responsive: true,
    scales: {
        x: {
          stacked: true,
          title: {
            display: isMobile ? false : true,
            align: 'center',
            font: {
              size: 16,
              weight: '600',
            }
          },
  
          ticks: {
            maxTicksLimit: isMobile ? 5 : 10,
            font: {
              size: getValueDependingView(12, 10, 8),
            }
           
          }
        },
        y: {
        stacked: true,
          title: {
            display: true,
            font: {
              size: 16,
              weight: '600',
            }
          },
  
          ticks: {
            mirror: isTablet, 
            font: {
              size: isTablet ? 8 : 12,
            }
           
          }
        }
      },
    plugins: {
      legend: {
        display: true,
        align: 'end',
        labels: {
          usePointStyle: true,
          boxWidth: getValueDependingView(15, 10, 5),
          boxHeight: getValueDependingView(15, 10, 5),

          font: {
            size: getValueDependingView(12, 10, 8),
          }
        }
      },
      tooltip: {
        displayColors: false,
        
      },
      title: {
        display: true,
        align: 'start',
        color: '#333',
        font: {
          size: isTablet ? 16 : 20,
          lineHeigth: '27px',
          weigth: 500,
        }
      },
      datalabels: false,
    },
    
  };

  const mergedOptions = merge({}, defaultOptions, options);

  return  (
    <div className="backgroundContainer" style={{ height: getValueDependingView('600px', '400px', '300px')}}>
       <Bar data={chartData} options={mergedOptions} />
    </div>
  ) 
 
};

export default BarChartVertical;
