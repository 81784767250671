import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Links } from '../links.js';
import StatisticsAPI from '../api/statistics_api/statistics_api_connector.js';
import { deleteToken } from '../utils/auth.js';
import Icon from './Icon.js' 
import { useDispatch } from 'react-redux';
import { resetToken } from '../redux/token.js';

export function Logout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        deleteToken();
        dispatch(resetToken())
        await StatisticsAPI.logout();
        navigate(Links.login.path);
    };

    return (
        <div>
            <button onClick={handleLogout}>
                <Icon type="logout" size={24}/>
            </button>
        </div>
    )
};
