import { useCallback, useEffect, useState } from "react";

const Slider = ({ input, update }) => {
    const [minValue, setMinValue] = useState(input.value ? input.value.min : input.min);
    const [maxValue, setMaxValue] = useState(input.value ? input.value.max : input.max);
    const [isDragging, setIsDragging] = useState(false);
    const startDrag = () => {
        setIsDragging(true);
    };
  
    const endDrag = () => {
        setIsDragging(false);
    };

    const updateRange = useCallback(() => {
        if (!isDragging) {
            update(input, { min: minValue, max: maxValue });
        }
    }, [minValue, maxValue, isDragging]);

    useEffect(() => {
        if (!isDragging) {
            updateRange();
        }
    }, [isDragging, updateRange]);

    const onMinChange = (e) => {
        e.preventDefault();
        const newMinVal = Math.min(+e.target.value, maxValue - input.step);
        setMinValue(newMinVal);
    };
  
    const onMaxChange = (e) => {
        e.preventDefault();
        const newMaxVal = Math.max(+e.target.value, minValue + input.step);
        setMaxValue(newMaxVal);
    };

    const minPos = ((minValue - input.min) / (input.max - input.min)) * 100;
    const maxPos = ((maxValue - input.min) / (input.max - input.min)) * 100;
  

    return (
        <div className="formModel__item">
            {input.title}
            <div className="multiRangeSlider">
            <div className="multiRangeSlider__inputWrapper">
                <input
                    type="range"
                    value={minValue}
                    min={input.min}
                    max={input.max}
                    step={input.step}
                    onMouseDown={startDrag}
                    onMouseUp={endDrag}
                    onTouchStart={startDrag}
                    onTouchEnd={endDrag}
                    onChange={onMinChange}
                />
                <input
                    type="range"
                    value={maxValue}
                    min={input.min}
                    max={input.max}
                    step={input.step}
                    onMouseDown={startDrag}
                    onMouseUp={endDrag}
                    onTouchStart={startDrag}
                    onTouchEnd={endDrag}
                    onChange={onMaxChange}
                />
            </div>
            <div className="multiRangeSlider__controlWrapper">
                <div className="multiRangeSlider__controlWrapper__control" style={{ left: `${minPos}%` }}>
                    <div className="txt12x18">{minValue.toFixed(2)}</div>
                </div>
                <div className="multiRangeSlider__controlWrapper__rail">
                    <div
                        className="multiRangeSlider__controlWrapper__innerRail" 
                        style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
                    />
                </div>
                <div className="multiRangeSlider__controlWrapper__control multiRangeSlider__controlWrapper__control--top" style={{ left: `${maxPos}%` }}>
                    <div className="txt12x18">{maxValue.toFixed(2)}</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Slider;