import { useSortable } from "@dnd-kit/sortable";
import { flexRender } from "@tanstack/react-table";
import { CSS } from '@dnd-kit/utilities'
import { Link } from "react-router-dom";
import * as styles from '../../styles/utils/_variables.scss';


const DraggableTableHeader = ({
  header,
  isPinned = false
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    })

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    display: 'table-cell',
    background: styles.default.colorItemBackground,
    borderBottom: '1px solid #ddd'
  }

  if (isPinned) {
    return (
      <th
        className="table--stickyCell"
        style={style}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </th>
    );
  }

  return (
    <th colSpan={header.colSpan} ref={setNodeRef} style={style}>
      {header.isPlaceholder
        ? null
        : <div className="link txt16x24">
          <Link to={`/backtests/backtestId/${header.column.columnDef.header}/general`}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </Link>

        </div>
      }
      {!isPinned && (
        <button {...attributes} {...listeners}>
          🟰
        </button>

      )}

    </th>
  )
}

export default DraggableTableHeader;
