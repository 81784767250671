const CommingSoon = ({ children, maxWidth=false, top, right }) => {
        return (
            <div className={`commingSoon  ${maxWidth ? 'widthMax' : ''}`} >
                {children}
                 <div className="commingSoon__label txt8x12" style={{ top: top, right }}>
                    soon
                </div>
            </div>
           
        )
}
export default CommingSoon;