import {  useEffect, useMemo } from "react";
import IsFetching from "../../components/IsFetching";
import { useLocation } from "react-router-dom";
import BacktestListTable from "../../components/Backtests/BacktestListTable";
import { statusEnum } from "../../components/MainLayouts/BacktestLayout";


import { useFetchBacktestsQuery } from "../../redux/statisticsServices/backtestsApi";


const BacktestsInProgress = () => {

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);


  // const { 
  //   backtestsData,
  //   isFetchingBacktests,
  //   refetch
  // } = useContext(BacktestsContext)

  const { data: backtestsData, isFetching: isFetchingBacktests, refetch } = useFetchBacktestsQuery();


  useEffect(() => {
    if(searchParams.get('runningBT')) {
      refetch();
    }

  }, [])

    const inProgressBacktestData = useMemo(() => {
     
        if (!backtestsData) return [];
   
        return backtestsData.filter(backtest => statusEnum[backtest.status] !== 'Finished' && statusEnum[backtest.status] !== 'Error' && statusEnum[backtest.status] !== 'Canceled').sort((a, b) => {
           const priorityA = a.estimation_calculation === null ? 1 : (a.estimation_calculation === -1 ? 2 : 0);
           const priorityB = b.estimation_calculation === null ? 1 : (b.estimation_calculation === -1 ? 2 : 0);
   
           if (priorityA !== priorityB) {
             return priorityA - priorityB;
           }
   
           if (a.estimation_calculation !== null && a.estimation_calculation !== -1 && b.estimation_calculation !== null && b.estimation_calculation !== -1) {
             const currentTime = Math.floor(new Date().getTime());
             const timeToEventA = a.launch_timestamp + a.estimation_calculation;
             const timeToEventB = b.launch_timestamp + b.estimation_calculation;
             return Math.abs(currentTime - timeToEventA) - Math.abs(currentTime - timeToEventB);
           }
   
           return b.launch_timestamp - a.launch_timestamp;
        });
     }, [backtestsData]);

     if (isFetchingBacktests ) {
        return <IsFetching />
    }
  
    return (
        <BacktestListTable  
          data={inProgressBacktestData} 
          title={'Backtests inProgress'}
          calculatedBacktests={false}
          highlightRow={searchParams.get('runningBT') ? {
            key: 'backtest_id',
            value: searchParams.get('runningBT')
          } : null}
        />
    )
}

export default BacktestsInProgress;