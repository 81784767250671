import  statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'
import { buildQueryString } from '../../utils/functions';


export const backtestsApi =  statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchBacktests: builder.query({
      query: () => STATISTICS_API_URLS.backtests,
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'Backtests', id: result[0] ? `Backtest_Trades_List` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "Backtests")
      },
    }),

    fetchBacktestById: builder.query({
      query: (backtestId) => STATISTICS_API_URLS.backtest.replace("{backtest_id}", backtestId),
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'Backtest', id: result[0] ? `Backtest_${result[0].backtest_id}` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "Backtest")
      },
    }),

    
    fetchBacktestByIdStats: builder.query({
      query: (backtestId) => STATISTICS_API_URLS.backtest_stats.replace("{backtest_id}", backtestId),
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'BacktestStat', id: result[0] ? `BacktestStat_${result[0].backtest_id}` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "BacktestStat")
      },
    }),

    fetchBacktestByIdPairsStats: builder.query({
      query: ({filters, backtestId}) => `${STATISTICS_API_URLS.backtest_pairs_stats.replace("{backtest_id}", backtestId)}${buildQueryString(filters)}`,
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'BacktestStats', id: result[0] ? `Backtest_${result[0].backtest_id}Stats` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "BacktestStats")
      },
    }),

    fetchBacktestByIdPositions: builder.query({
      query: ({filters, backtestId, limit = null}) => `${STATISTICS_API_URLS.backtest_positions.replace("{backtest_id}", backtestId)}${buildQueryString(filters)}${limit ? filters ? `&limit=${limit}` : `?limit=${limit}` : ''}`,
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'BacktestPositions', id: result[0] ? `BacktestPositions_List` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "BacktestPositions")
      },
    }),

    fetchBacktestByIdTrades: builder.query({
      query: ({filters, backtestId}) => `${STATISTICS_API_URLS.backtest_trades.replace("{backtest_id}", backtestId)}${buildQueryString(filters)}`,
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'BacktestTrades', id: result[0] ? `Backtest_${result[0].backtest_id}_Trades_List` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "BacktestTrades")
      },
    }),

    backtestRun: builder.mutation({
      query: (body) => ({
        url: STATISTICS_API_URLS.backtest_run,
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
        
        } catch {
          console.error(`Error while backtest ${arg.backtest_id} run`);
        }
      },
    }),

    backtestCancel: builder.mutation({
      query: (backtestId) => ({
        url:  STATISTICS_API_URLS.backtest_cancel_task.replace("{backtest_id}", backtestId),
        method: 'POST',
      }),
      async onQueryStarted(backtestId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchBacktests', undefined, (draft) => {
            const backtest = draft.find(b => b.backtest_id  === backtestId);

            console.log(draft)
            if (backtest) {
              backtest.status = 5;
            }
          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Backtests')
        } catch {
          patchResult.undo()
          console.error(`Error while backtest ${backtestId} cancel`);
        }
      },
    }),

    backtestSetHighPriority: builder.mutation({
      query: (backtestId) => ({
        url:  STATISTICS_API_URLS.backtest_set_high_priority.replace("{backtest_id}", backtestId),
        method: 'POST',
      }),
      async onQueryStarted(backtestId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchBacktests', undefined, (draft) => {
            const backtest = draft.find(b => b.backtest_id  === backtestId);
            if (backtest) {
              backtest.high_priority = true;
            }
          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Backtests')
        } catch {
          patchResult.undo()
          console.error(`Error while backtest ${backtestId} cancel`);
        }
      },
    }),

    fetchBacktestsStats: builder.query({
      query: (filters) => {
        console.log(`${STATISTICS_API_URLS.backtests_stats}${buildQueryString(filters)}`);
        return `${STATISTICS_API_URLS.backtests_stats}${buildQueryString(filters)}`},
      
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'BacktestsStats', id: result[0] ? `BacktestsStats_List` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "BacktestsStats")
      },
    }),
    
    fetchBacktesTradesDistribution: builder.query({
      query: ({backtestId, timeframe}) => 
        STATISTICS_API_URLS.backtests_backtest_trades_distribution.replace("{backtest_id}", backtestId)+ `?timeframe=${timeframe}`,
      
      providesTags: (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'BacktesTradesDistribution', id: result[0] ? `BacktesTradesDistribution${result.backtestId}_List` : ''},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "BacktesTradesDistribution")
      },
    }),

    deleteBacktest: builder.mutation({
      query: ({backtestId, body}) => ({
        url:  STATISTICS_API_URLS.backtests_backtest_status.replace("{backtest_id}", backtestId),
        method: 'POST',
        body
      }),
      async onQueryStarted({backtestId}, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchBacktests', undefined, (draft) => {
            const backtest = draft.find(b => b.backtest_id  === backtestId);
            console.log(backtest)
            if (backtest) {
              backtest.status = -2;
            }
          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Backtests')
        } catch {
          patchResult.undo()
          console.error(`Error while try delete ${backtestId}`);
        }
      },
    }),


  }),
  overrideExisting: false,
});

export const {
    useFetchBacktestsQuery,
    useFetchBacktestByIdQuery,
    useFetchBacktestByIdStatsQuery,
    useFetchBacktestByIdPairsStatsQuery,
    useFetchBacktestByIdPositionsQuery,
    useLazyFetchBacktestByIdPositionsQuery,
    useLazyFetchBacktestByIdTradesQuery,
    useBacktestRunMutation,
    useBacktestCancelMutation,
    useBacktestSetHighPriorityMutation,
    useLazyFetchBacktestsStatsQuery,
    useFetchBacktesTradesDistributionQuery,
    useDeleteBacktestMutation,
} = backtestsApi;