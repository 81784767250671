import useMobileView from "../../hooks/useMobileView";
import BarChartVertical from "../Charts/BarChartVertical";

import * as styles from '../../styles/utils/_variables.scss';
import ChartTabs from "../Charts/ChartTabs";
import IsFetching from "../IsFetching";
import useValueDependingOnView from "../../hooks/useValueDependingOnView";
import { format } from "date-fns";


const BacktestTradesDistributionChart = ({ data = {}, mode, setMode, modesArray, isFetching }) => {

    const isMobile = useMobileView();
    const getValueDependingView = useValueDependingOnView();

 
    const datasets = [
        {
          label: 'Buy trades count',
          data: Object.values(data).map(el => el.buy_trades_count),
          backgroundColor: `${styles.default.colorGreen}90`,
          borderColor: styles.default.colorGreen,
          borderWidth: 1,
          datalabels: {
            display: function(context) {
              return context.dataset.data[context.dataIndex] !== 0;
            }
          },
          maxBarThickness: isMobile ? 20 : 80
        },
        {
          label: 'Sell trades count',
          data: Object.values(data).map(el => el.sell_trades_count),
          backgroundColor: `${styles.default.colorRed}90`,
          borderColor: styles.default.colorRed,
          borderWidth: 1,
          datalabels: {
            display: function(context) {
              return context.dataset.data[context.dataIndex] !== 0;
            }
          },
          maxBarThickness: isMobile ? 20 : 80
        },
      ]

    const options = {
        scales: {
            y: {
              title: {
                text: 'Count',
              },
            },

            x: {
                ticks: {
                    callback: function (value, index) {
                      const dateString = this.getLabelForValue(index);

                      switch(modesArray[mode]) {
                        case 'Month': 
                            return dateString.split('.').slice(1).join('.');

                        default: 
                            return dateString;
                      }
                    },
                  }
          },

          },
        plugins: {
            title: {
              text: 'Trades distribution',
            },

            tooltip: {
                backgroundColor: function(context) {
                    const label = context.tooltip.dataPoints[0].dataset.label;
                    return label === 'Sell trades count' ? styles.default.colorRed : styles.default.colorGreen;
                  },
              },
        }
    }

    const chartData = {
        labels: Object.keys(data).map(el => format(new Date(+el), 'dd.MM.yy')),
        datasets,
    }
    
    return (
        <>
            {isFetching ? (
                <IsFetching height={getValueDependingView('600px', '400px', '300px')}/>
            ) : (
                <div className="flexColumnGap5" style={{ gap: 0 }}>
                    <ChartTabs
                        mode={mode} 
                        setMode={setMode}
                        modesArray={modesArray}
                    />
                    <BarChartVertical data={chartData} options={options}/>
                </div>
            )}
        </>
       
    )
}

export default BacktestTradesDistributionChart;



