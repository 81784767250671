import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { dropdown } from '../../utils/animations';
import * as styles from '../../styles/utils/_variables.scss';
import useTabletView from '../../hooks/useTabletView';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';
import { useLocation, useNavigate } from 'react-router-dom';
import useMobileView from '../../hooks/useMobileView';

function hexToRGB(hex) {
    if (hex.length === 4) {
        hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }

  
    const r = parseInt(hex.substring(1, 3), 16); 
    const g = parseInt(hex.substring(3, 5), 16); 
    const b = parseInt(hex.substring(5, 7), 16); 

    return `rgb(${r}, ${g}, ${b})`;

}
function hexToRGBWithOpacity(hex, opacity) {
    if (hex.length === 4) {
        hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }

  
    const r = parseInt(hex.substring(1, 3), 16); 
    const g = parseInt(hex.substring(3, 5), 16); 
    const b = parseInt(hex.substring(5, 7), 16); 

    return `rgba(${r}, ${g}, ${b}, ${opacity}) `; 
}


const BubbleChartD3 = ({ data, modesArray, mode}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isMobile = useMobileView();

    const getValueDependingView = useValueDependingOnView()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth); 

    const handleResize = () => {
        setWindowWidth(window.innerWidth); 
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const  handleClick = (element) => {
            
        const pair = `${element}/BTC`;

        searchParams.set('pair', pair);

        const newSearchString = searchParams.toString();

        navigate(`/statistics/pairs?${newSearchString}`)

  };

    const isTablet = useTabletView()
    const colorRed = styles.default.colorRed;
    const colorGreen = styles.default.colorGreen;
    const colorBlue = styles.default.colorBlue;
   
    const ref = useRef();
    const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: "", color: "white" });
  
    useEffect(() => {
      const svg = d3.select(ref.current);
      const tooltipDiv = d3.select("#bubbleChartD3__tooltip");
      svg.selectAll("*").remove();
      const svgWidth = svg.node().getBoundingClientRect().width;

      const scale = d3.scaleSqrt()
        .domain([0, d3.max(data, d => Math.abs(d.value))])
        .range(getValueDependingView([10, 70], [8, 50], [8, 30]));

        const radii = data.map(d => scale(Math.abs(d.value)));

      
        const diameters =  radii.map(radius => (radius * 2) + getValueDependingView(30, 20, 10));
      
        
        const averageDiameter = diameters.reduce((acc, dia) => acc + dia, 0) / data.length;

      let height = Math.max(data.length * averageDiameter / (svgWidth / averageDiameter) + 50 , isMobile ? 400 : 600);

      if (isNaN(height) || height <= 0) {
        height = isMobile ? 400 : 600; 
      }


      data.forEach(d => {
        d.x = Math.random() * svgWidth;
        d.y = Math.random() * height;
      });

      const opacityScale = d3.scaleLinear()
        .domain([0, d3.max(data, d => Math.abs(d.value))])
        .range([0.2, 0.6]);
  
        const simulation = d3.forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(getValueDependingView(-150, -100, -50))) 
        .force("center", d3.forceCenter(svgWidth / 2, height / 2).strength(0.05)) 
        .force("x", d3.forceX().x(svgWidth / 2).strength(0.05))
        .force("y", d3.forceY().y(height / 2).strength(0.05))
        .force("collide",   d3.forceCollide(d => scale(Math.abs(d.value)) + (getValueDependingView(10, 8, 5))))
        .on("tick", ticked);

        let previousClosestBubble = null;

      function handleInteraction(event) {
        const [mouseX, mouseY] = d3.pointer(event);

        let closestBubble = null;
        let minDistance = Infinity;

        data.forEach((d, i) => {
            const distance = Math.sqrt(Math.pow(mouseX - d.x, 2) + Math.pow(mouseY - d.y, 2)); // Евклидово расстояние
            if (distance < minDistance) {
                minDistance = distance;
                closestBubble = { d, index: i };
            }
        });

        if (closestBubble && closestBubble !== previousClosestBubble) {
            previousClosestBubble = closestBubble;
            const bubbleElement = svg.selectAll('.bubble').nodes()[closestBubble.index];

            if (bubbleElement) {
                const bubbleRect = bubbleElement.getBoundingClientRect();

                const cx = bubbleRect.x + bubbleRect.width / 2;
                const cy = bubbleRect.y + bubbleRect.height / 2;

                const isRightHalf = closestBubble.d.x > window.innerWidth / 2;

                const tooltipX = isRightHalf ? cx - 95 : cx + 35;
                const tooltipY = cy - 20;

                setTooltip({
                    visible: true,
                    x: tooltipX,
                    y: tooltipY,
                    text:  modesArray[mode] === 'PnL' ? `${closestBubble.d.label}: ${closestBubble.d.value.toFixed(2)}%` : `${closestBubble.d.label }: ${closestBubble.d.value}`,
                    color: modesArray[mode] === 'PnL' ?  closestBubble.d.value >= 0 ? colorGreen : colorRed : colorBlue,
                    positionClass: isRightHalf ? 'right' : 'left',
                    key: closestBubble.index 
                });
            }
        } else if (!closestBubble) {
            setTooltip({ visible: false });
        }
      }

      svg.on('mousemove', function (event) {
        handleInteraction(event);
      });

      svg.on('touchmove', function (event) {
        handleInteraction(event.touches[0]);
      });

      svg.on('mouseleave', function () {
        setTooltip({ visible: false });
      });

      
      function ticked() {
        const bubbles = svg.selectAll('.bubble')
          .data(data)
          .join('circle')
          .classed('bubble', true)
          .attr('r', d => scale(Math.abs(d.value)))
          .style('fill', d => {
            const opacity = opacityScale(Math.abs(d.value));
            return modesArray[mode] === 'PnL' ? d.value >= 0 ? hexToRGBWithOpacity(colorGreen, opacity) : hexToRGBWithOpacity(colorRed, opacity) : hexToRGBWithOpacity(colorBlue, opacity);
          })
          .style('stroke', d => modesArray[mode] === 'PnL' ?  d.value >= 0 ? hexToRGB(colorGreen) : hexToRGB(colorRed) : hexToRGB(colorBlue)) 
          .style('stroke-width', 1)
          .attr('cx', d => Math.max(scale(Math.abs(d.value)), Math.min(svgWidth - scale(Math.abs(d.value)), d.x)))
          .attr('cy', d => {
            return Math.max(scale(Math.abs(d.value)), Math.min(height - scale(Math.abs(d.value)), d.y))
            })
            .on('mouseover', function (event, d) {
              
              const bubble = d3.select(this);
              
              
              
              bubble.transition()
                  .duration(100)
                  .attr('r', scale(Math.abs(d.value)) * 1.05)
                  .style('stroke-width', 2)
                  .style('cursor', 'pointer');
          
           
          })
          
          .on('mousemove', function (event) {
            //tooltipDiv.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY + 10}px`);
          })
          .on('mouseout', function (event, d) {
            d3.select(this).transition()
              .duration(100)
              .attr('r', scale(Math.abs(d.value)))
              .style('stroke-width', 1);
              setTooltip({ visible: false })
          })
          .on('click', function (event, d) {
            handleClick(d.label)
          });


         
          bubbles.each(function(d) {
            const r = scale(Math.abs(d.value)) + 4;
            d.x = Math.max(r, Math.min(svgWidth - r , d.x)); 
            d.y = Math.max(r , Math.min(height - r, d.y));   
          });
  
          const labels = svg.selectAll('.label')
          .data(data)
          .join('text')
          .classed('label', true)
          .attr('x', d => d.x)
          .attr('y', d => d.y)
          .style('fill', 'black')
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'middle')
          .style('font-size',getValueDependingView( '11px', '10px', '8px'))
          .style('font-weight', 'normal')  
          .style('pointer-events', 'none') 
          .text(d => d.label);
  
        if (simulation.alpha() < 0.01) { 
          simulation.stop();
        }
      }
  
      svg.attr('height', height);
  
      return () => {
        simulation.stop();
      };
    }, [data, windowWidth, isTablet]);
  
    return (
        <div className="bubbleChartD3">
            <svg ref={ref} style={{ width: "100%"}}></svg>
            {tooltip.visible && (
                <motion.div
                    key={tooltip.key}  
                    id="bubbleChartD3__tooltip"
                    className={`bubbleChartD3__tooltip ${tooltip.positionClass}`}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdown}
                  
                    transition={{ duration: 0.2 }}
                    style={{
                        left: tooltip.x,
                        top: tooltip.y,
                        background: tooltip.color,
                        '--tooltip-color': tooltip.color,
                    }}
                >
                    {tooltip.text}
                </motion.div>
        )}
        </div>
    ) 
  };

export default BubbleChartD3;
