import { classnames } from "../../../utils/functions";

const String = ({ input, update, save, onFocus = () => {}}) => {
    const handleChange = (e) => {
        const value = e.target.value;
        update(input, value )
    }

    const handleOnFocus = () => {
        onFocus(input)
    }

    return (
        <label className={classnames('formModel__item', {'formModel__item--error': input.error})} >
            {input.error ? `${input.title} *`: input.title}
            <div className="flexGap10" >

                <input
                    className={classnames('input', {'input--error': input.error})} 
                    value={input.value}
                    placeholder={input.placeholder || input.title.replace(/\s*\(.*?\)\s*/g, '')}
                    onChange={handleChange}
                    disabled={input.disabled}
                    onFocus={handleOnFocus}
                />
            {input.withSave && (
                <button 
                    className="button buttonSave" 
                    onClick={save}
                    type="button"
                    style={{ minWidth: 70}}
                >
                    {input.actionTitle ?  input.actionTitle : 'Save'} 
                </button>
            )}
            </div>
            
        </label>
    )
}

export default String;