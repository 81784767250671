import React from 'react';

const IsSpinner = () => {
    return (
        <div className="spinner">
            <div className="spinner__item spin" ></div>
        </div>
    );
}

export default IsSpinner;
