import { useContext, useMemo } from "react";
import IsFetching from "../../components/IsFetching";
import BacktestListTable from "../../components/Backtests/BacktestListTable";
import { statusEnum } from "../../components/MainLayouts/BacktestLayout";
import { BacktestsContext } from "../../contexts/BacktestsContext";

const BacktestsCanceled = () => {

    const { 
        backtestsData,
        isFetchingBacktests,
    
      } = useContext(BacktestsContext)
    
    const canceledBacktestData = useMemo(() => {
     
        if (!backtestsData) return [];
    
        return backtestsData.filter(backtest => statusEnum[backtest.status] === 'Canceled').sort((a, b) => b.launch_timestamp - a.launch_timestamp);
     }, [backtestsData]);
   
     if (isFetchingBacktests ) {
         return <IsFetching />
     }
    return (
        <BacktestListTable
            data={canceledBacktestData} 
            title={'Canceled Backtests'} 
            canceledBacktests={true}
            
        />
    )
}

export default BacktestsCanceled;