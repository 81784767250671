import { classnames } from "../../../utils/functions";
import Dropdown from "../../Inputs/dropdown/Dropdown";

const Select = ({ input, update, onFocus }) => {

    return (
        <div className={classnames('formModel__item', {'formModel__item--error': input.error})}> 
             {input.error ? `${input.title} *`: input.title}
            <Dropdown 
                input={input} 
                update={(value) => update(input, value)}
                renderSpan={() => (
                    input.value  ? input.value  : input.title
                )}
                values={input.values}
                shouldSort={input.shouldSort}
                onFocus={onFocus}
            />
        </div>

    )
}

export default Select;