import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Icon from "../Icon";

const DatePickerFilterWithHours = ({ setFilters, filtersStartDate }) => {

    const [startDate, setStartDate] = useState();

    useEffect(() => {
        setStartDate(new Date(+filtersStartDate) )
    }, [])
    
    
    const CustomTime = ({ date, value, onChange }) => {

        const handleIncreaseHours = () => {
            const newValue = +value.split(':')[0] - 1
            onChange(`${newValue}:00`);
        }

        const handleDecreaseHours = () => {
            const newValue = +value.split(':')[0] + 1
            onChange(`${newValue}:00`);
        }

        return (
            <div className="flexGap10" >
                <button  onClick={handleIncreaseHours}>
                    <Icon type="arrowLeft" size={20} />
                </button> 
                <div
                    onChange={(e) => onChange(e.target.value)}
                >
                    {value}
                </div>
                <button onClick={handleDecreaseHours}>
                    <Icon type="arrowRight" size={20} />
                </button> 
            </div>
        )
    };

    useEffect(() => {
        if (startDate) {
          setFilters(prevState => {
            const filteredFilters = prevState.filter(filter => filter.id !== 'timestamp_event');
            return [...filteredFilters, { id: 'timestamp_event', value: startDate.getTime()}];
          });
        }
    
      }, [startDate,  setFilters]);

    return (
        <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeInput
            customTimeInput={<CustomTime />}
            inline
        />
    )
}

export default DatePickerFilterWithHours;