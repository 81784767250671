import { Link, useLocation } from 'react-router-dom';
import CommingSoon from './CommingSoon';
import Icon from './Icon';

const MenuItem = ({ link }) => {
    const { pathname } = useLocation();

    const basePath = `/${pathname.split('/')[1]}`;
  
    return (
        <>
            {link.disabled ? (
                <CommingSoon top={-2} right={-5}>
                    <div
                        className={
                            `menuItem txt18x27 
                             ${basePath === link.path ? 'menuItem--active' : ''} 
                            ${link.disabled ? 'menuItem--disabled' : ''}`
                         }
                        key={link.title}
                     >
                        <Link
                        to={link.disabled ? "#" : link.path}
                        onClick={link.disabled ? (e) => e.preventDefault() : null}
                        >
                            {link.title}
                        </Link>
                    </div>
                </CommingSoon>
            ) : (
                <div
                    className={
                        `menuItem txt18x27 
                        ${basePath === link.path ? 'menuItem--active' : ''} 
                        ${link.disabled ? 'menuItem--disabled' : ''}`
                    }
                    key={link.title}
                >
                   
                    <Link
                        to={link.disabled ? "#" : link.path}
                        onClick={link.disabled ? (e) => e.preventDefault() : null}
                    >
                        <Icon type={link.icon} size={25} />
                        {link.title}
                    </Link>
                </div>
            )}
        </>
       
    )
}

export default MenuItem;