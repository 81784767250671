import { useState, useEffect } from 'react';

const useScrollDirection = () => {
    const [scrollDir, setScrollDir] = useState('up');

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            
            const atTop = scrollY === 0;
            const atBottom = window.innerHeight + scrollY >= document.body.offsetHeight;

            const direction = scrollY > lastScrollY ? 'down' : 'up';
           
            if ((direction !== scrollDir) && (!atTop && !atBottom) && (Math.abs(scrollY - lastScrollY) > 10)) {
                setScrollDir(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };

        window.addEventListener('scroll', updateScrollDirection);

        return () => {
            window.removeEventListener('scroll', updateScrollDirection);
        };
    }, [scrollDir]);

    return scrollDir;
};

export default useScrollDirection;
