import React, { useState, useRef, useEffect, memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Icon from './Icon';
import { createPortal } from 'react-dom';


const Tooltip = memo (
    ({ text, down = false, maxWidth, style = {} }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });
    const tooltipRef = useRef(null);
    const buttonRef = useRef(null)

    const lineHeight = 26;
    const numLines = Math.floor(tooltipSize.height / lineHeight);

    const numLinesRight = tooltipSize.height / lineHeight;


    const dynamicTop = down ? -50 : numLinesRight * 7;

    const handleMouseEnter = () => {
        setTimeout(() => {
            setIsVisible(true);
        }, 100);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            setIsVisible(false);
        }, 100);
    };

    const tooltipVariants = {
        hidden: { opacity: 0, y: down ? 5 : -5 },
        visible: { opacity: 1, y: down ? -15 : -25 }
    };
    useEffect(() => {
        if (isVisible && tooltipRef.current && buttonRef.current) {
            const parentRect = buttonRef.current.getBoundingClientRect();
            setTooltipSize({
                width: tooltipRef.current.offsetWidth,
                height: tooltipRef.current.offsetHeight
            });

            tooltipRef.current.style.left = `${parentRect.left + (parentRect.width / 2) - (tooltipSize.width / 2)}px`;
            tooltipRef.current.style.top = `${parentRect.top - tooltipSize.height + dynamicTop}px`;
        }
    }, [isVisible, buttonRef, tooltipSize.width, tooltipSize.height ]);

    useEffect(() => {
        const handleTouchStart = (event) => {
            if (!tooltipRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        if (isVisible) {
            document.addEventListener('touchstart', handleTouchStart);
        }

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
        };
    }, [isVisible]);


    return (
        <div className="tooltip" style={style}>
            <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="tooltip__button"
                ref={buttonRef}
            >
                <Icon type="info" size={18} />
            </button>
            {isVisible && (
                createPortal(
                    <AnimatePresence>
                        <motion.div
                            ref={tooltipRef}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={tooltipVariants}
                            className={`tooltip__body txt14x21 ${down ? 'tooltip__body--down' : ''}`}
                            style={{ maxWidth: maxWidth }}
                        >
                            {text}
                        </motion.div>
                    </AnimatePresence>, document.body
                )

            )}
        </div>
    );
}
)  

export default Tooltip;


