import { memo, useEffect, useMemo} from "react"
import FormModel from "../../components/FormModels/FormModel"
import { getFilledModel } from "../../utils/functions";
import { mainSettingsChange__model } from "../../components/FormModels/formConfig";

const MainSettingsChange = memo(({ listOfParquetsData, assetsFromFilesData, model, setModel, timeFrame, mainAsset }) => {

    const dataSelectionValue = useMemo(() => model.find(el => el.key === 'parquet_filename')?.value, [model]);
    const pairsListValue = useMemo(() => model.find(el => el.key === 'pairs_list')?.value, [model]);
    const selectionOfPair = useMemo(() => model.find(el => el.key === 'selection_of_pairs')?.value, [model]);

    const updateParquetFilename = (element, formattedParquets) => ({
        ...element,
        value: element.value && formattedParquets.includes(element.value) ? element.value : formattedParquets[0],
        values: formattedParquets || []
    });

    const filterData = (data, timeFrame, mainAsset) => {
        const assetLower = mainAsset?.toLowerCase(); 
        return data.filter(el => {
          const elLower = el.toLowerCase(); 
          return timeFrame === '1s' 
            ? (el.includes('_1s') && elLower.includes(assetLower))
            : (!el.includes('_1s') && elLower.includes(assetLower));
        });
      }

      const formatedListOfParquets = useMemo(() => {
        return filterData(
          listOfParquetsData.map(el => {
            const arr = el.split('/');
            return arr[arr.length - 1].split('.')[0];
          }).filter(el => !el.startsWith('-')), 
          timeFrame, 
          mainAsset
        );
      }, [listOfParquetsData, timeFrame, mainAsset]);

   
      
      const formatedDataForPairsList = useMemo(() => {
        return filterData(Object.keys(assetsFromFilesData), timeFrame, mainAsset);
      }, [assetsFromFilesData, timeFrame, mainAsset]);

    useEffect(() => {
        if (!listOfParquetsData || !assetsFromFilesData) return;
        
            const pairsListByDataSelection = assetsFromFilesData[dataSelectionValue];
            const dataForBlacklistOfPairs = assetsFromFilesData[formatedDataForPairsList?.[0]];

            let newModel = [...model];

            switch(selectionOfPair) {
                case 'One Pair': 
            
                    newModel = getFilledModel(newModel, mainSettingsChange__model['One Pair']).map(el => {
                        switch(el.key) {
                            case 'parquet_filename':
                                return updateParquetFilename(el, formatedListOfParquets);
                            case  'select_symbol': 
                                return {...el, values: pairsListByDataSelection || [], value: pairsListByDataSelection?.[0]} || null;

                            default: 
                                return el

                    }})

                    break;

                case 'Whitelist': 
                    newModel = getFilledModel(newModel,  mainSettingsChange__model['Whitelist']).map(el => {

                        switch(el.key) {
                            case 'parquet_filename':
                                return updateParquetFilename(el, formatedListOfParquets);
                            case  'list_of_backtests': 
                                return {...el, values: pairsListByDataSelection};

                            default: 
                                return el

                    }})
                    
                    break;

                default:
                    newModel = getFilledModel(newModel,  mainSettingsChange__model['List']).map(el => {
                        switch(el.key) {
                            case 'parquet_filename':
                                return updateParquetFilename(el, formatedListOfParquets);

                            case 'pairs_list':
                                return {
                                    ...el,
                                    value: formatedDataForPairsList.find(el => el === 'BTC_normal_to_trade'),
                                    values: formatedDataForPairsList,
                                };
                            
                            case 'blacklist_of_pairs': 
                                return {
                                    ...el,
                                    values: dataForBlacklistOfPairs,
                                };

                            default: 
                                return el

                        }
                    });
            
                    break;
            
            }

            setModel(newModel)
            
    }, [listOfParquetsData, assetsFromFilesData, selectionOfPair, timeFrame, mainAsset]);

    useEffect(() => {
        const pairsArray  = dataSelectionValue && assetsFromFilesData[dataSelectionValue];

        setModel(prev => [...prev.map(el => el.key === 'select_symbol' || el.key === 'list_of_backtests'
            ? {...el, values: pairsArray, value:  pairsArray?.includes(el.value) ? el.value : null }
            : el)])
       
    }, [dataSelectionValue])

    useEffect(() => {
     
        if(pairsListValue) {
            const pairsArray  = pairsListValue && assetsFromFilesData[pairsListValue];
            setModel(prev => [...prev.map(el => el.key === 'blacklist_of_pairs' 
                ? {...el, values: pairsArray, value: el.value?.filter(e => pairsArray.includes(e)) || null}
                : el)])
        }
       
    }, [pairsListValue])

    return (
        <div className="backgroundContainer">
            <FormModel inputModels={model} setInputModels={setModel} />
        </div>
    ) 
})

export default MainSettingsChange;