import { useEffect } from "react";
import { mlModelVersion__model } from "../FormModels/formConfig";
import FormModel from "../FormModels/FormModel";

const NewMLModelVersionForm = ({ model, setModel, mlModelName }) => {

    useEffect(() => {
        setModel(mlModelVersion__model.map(el => {
            switch(el.key) {
                case 'name':
                    return {...el, value: mlModelName};

                default:  
                    return el

            }
        }))
    }, []);

    return (
        <div className="formContainer">
            <FormModel
                inputModels={model} 
                setInputModels={setModel}
            />

        </div>
    )
}

export default NewMLModelVersionForm;