import { useState } from "react";
import BackButton from "../../components/Buttons/BackButton";
import Button from "../../components/Buttons/Button";
import NewMLModelForm from "../../components/Forms/NewMLModelForm";
import { useMlModelAddMutation } from "../../redux/statisticsServices/mlModelsApi";
import { convertModelToObject, formValidation } from "../../utils/functions";
import IsSpinnerBlur from "../../components/IsSpinnerBlur";
import { STATUSES } from "../../utils/enams";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export function AddNewMLModelPage() {
    const [model, setModel] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [mlModelAdd] = useMlModelAddMutation();

    const handleSubmit = async () => {

        const mlModelData = convertModelToObject(model);


        const { status } = mlModelData;

        const isValidateError = formValidation(model, setModel)


        if(isValidateError) {
            toast.error("Not all fields are filled in");
            return;
        }

        const json = {
            ...mlModelData,
            status: +STATUSES[status.toUpperCase().split(' ').join('_')],
        }


        try {
            setIsLoading(true)

            await mlModelAdd(json).unwrap();

            toast.success("MLModel added successfully");
            setTimeout(() => {
                navigate('/ml_stat')
            }, 1000)
            

        } catch (error) {
            console.error("MLModel add error", error);
            toast.error("MLModel add error")
            //alert("Strategy add error", error);
        }

        setIsLoading(false)
    }



    return (
        <div className="itemPage" >
            <div className="flexSpaceBetween" >
                <BackButton path={'/ml_stat'} />
                <Button
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </div>
            <h2>Add MLModel</h2>
            <NewMLModelForm  model={model} setModel={setModel}/>

            {isLoading && <IsSpinnerBlur />}

        </div>
    )
}