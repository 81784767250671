import DropdownWithSearch from "./FilterInputs/DropdownWithSearch";
import { formatEnamKey } from '../utils/functions'
import DatePickerFilter from "./FilterInputs/DatePickerFilter";
import DatePickerFilterWithHours from "./FilterInputs/DatePickerFilterWithHours";
import { useContext, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { scale } from "../utils/animations";
import { format } from "date-fns";
import Icon from "./Icon";
import useOnClickOutside from "../hooks/useOnClickOutside";
import DropdownWithSearchOneValue from "./FilterInputs/DropdownWithSearchOneValue";
import { SidebarContext } from "../contexts/SidebarContext";


const PageFilters = ({ filters, setFilters, filterOptions, notAll = false }) => {

    const datePickerRef = useRef(null);
    const context = useContext(SidebarContext);
    const setIsOpen = context ? context.setIsOpen : null;

    const [isDatePicker, setIsDatePicker] = useState(false);
    useOnClickOutside(datePickerRef, () => setIsDatePicker(false))

    const handleIsDatePicker = () => {
        setIsDatePicker(!isDatePicker)
    }

    const handleSetFilter = (key, value) => {
      
        setFilters(prevState => {
            const filteredFilters = prevState.filter(filter => filter.id !== key);
            return [...filteredFilters, { id: key, value}]
        })
    }

    const handleSetFilterEnam = (key, value) => {
        const enamValue = filterOptions[key].values[value]
      
        setFilters(prevState => {
            const filteredFilters = prevState.filter(filter => filter.id !== key);
            return [...filteredFilters, { id: key, value: enamValue}]
        })

        if(setIsOpen) {
            setIsOpen(true)
        }
    }

    const handleSetFilterSelect = (key, value) => {
        setFilters(prevState => {
          const existingFilter = prevState.find(filter => filter.id === key);
          let updatedFilters;
      
          if (existingFilter) {
         
            updatedFilters = prevState.map(filter =>
              filter.id === key ? { ...filter, value: [...new Set([...filter.value, value])] } : filter
            );
          } else {
       
            updatedFilters = [...prevState, { id: key, value: [value] }];
          }
      
          return updatedFilters;
        });
      };

    const resetFilter = (key) => {
        setFilters(prevState => {
            const filteredFilters = prevState.filter(filter => filter.id !== key);
            return [...filteredFilters]
        })


        if(setIsOpen) {
            setIsOpen(true)
        }
    
    }

   const getFilteredFilterOptions = (type) => {
        const filteredFilterOptions = Object.entries(filterOptions).filter(el => el[1].show !== false).reduce((acc, [key, value]) => {
            if (value.type === type) {
                acc[key] = value;
            }
            return acc;
        }, {})
        return filteredFilterOptions
   }

   const buttons = getFilteredFilterOptions(0);
   const selects = getFilteredFilterOptions(1);
   const selectsOneValue = getFilteredFilterOptions(2);

   const startDate = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
   const endDate = filters.find(filter => filter.id === 'position_end_timestamp_lte')?.value;

   const timestampEvent = filters.find(filter => filter.id === 'timestamp_event')?.value;

    return (
        <div className="pageFilters">
            {Object.entries(buttons).length > 0 && (
                   <div className="pageFilters__buttons">
                {Object.entries(buttons).map(([key, values]) => {
                    const currentFilter = filters.find(filter => filter.id === key);

                    return (
                        <div key={key}>
                            {!notAll && (
                                
                                <button
                                    className={`pageFilters__button txt12x18 ${!currentFilter ? 'pageFilters__button--active' : ''}`}
                                    onClick={() => resetFilter(key)}
                                >
                                All
                            </button>
                            )}
                            
                            {Object.keys(values.values).map(value => {
                                const enamValue = filterOptions[key].values[value]

                                const isActive = currentFilter?.value === enamValue;

                                return (
                                    <button
                                        key={value}
                                        onClick={() => handleSetFilterEnam(key, value)}
                                        className={`pageFilters__button txt12x18 ${isActive ? 'pageFilters__button--active' : ''}`}
                                    >
                                        {formatEnamKey(value)}
                                    </button>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            )}

            {(Object.entries(selects).length > 0 || Object.entries(selectsOneValue).length > 0 || startDate || timestampEvent) && (
                <div className="pageFilters__selects">

                     
                    {(filterOptions.position_end_timestamp_gte || filterOptions.position_end_timestamp_lte) && (
                        <div className="pageFilters__datePicker">
                       {startDate ? (
                                <div className={`pageFilters__datePicker__values ${isDatePicker ? 'dropdown__toggle--open' : ''}`}  onClick={handleIsDatePicker}>
                                <span className="txt12x18">
                                        {`${format(new Date(+startDate), 'HH:mm dd-MM-yyyy')} - ${format(new Date(+endDate), 'HH:mm dd-MM-yyyy') }`}
                                    </span> 
                                <div className="dropdown__toggle__icon"> 
                                    <Icon type="dropdownDown"  size={20} />
                                </div>
                                
                                
                                </div>
                            ) : (
                            <button onClick={handleIsDatePicker} className="pageFilters__datePicker__button">
                                <span>DatePicker</span> 
                            </button>
                            )
                        }
                 

                        {isDatePicker && (
                            <AnimatePresence>
                                <motion.div 
                                    className="pageFilters__datePicker__container"
                                    variants={scale}
                                    transition={{ 
                                        duration: 0.2,
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20 
                                    }}
                                    initial="hidden"
                                    animate="visible"
                                    ref={datePickerRef}
                                >
                                    <DatePickerFilter 
                                        setFilters={setFilters}
                                        filtersStartDate={startDate}  
                                        filtersEndDate={endDate}
                                        handleIsDatePicker={handleIsDatePicker}
                                    />
                                </motion.div>
                            </AnimatePresence>
                            
                        )}
                    </div>
                
                    )}
                    {Object.entries(selectsOneValue).map(([key, values]) => {
                        const activeItem = filters.find(filter => filter.id === key);
                        return (
                            <DropdownWithSearchOneValue
                                key={key} 
                                title={key}
                                values={values.values} 
                                handleSetFilter={handleSetFilter} 
                                activeItem={activeItem}
                                resetFilter={resetFilter}
                            />
                            )
                    })}

                    {Object.entries(selects).map(([key, values]) => {
                        const activeItem = filters.find(filter => filter.id === key);
                        return (
                            <DropdownWithSearch 
                                key={key} 
                                title={key}
                                values={values.values} 
                                handleSetFilter={handleSetFilterSelect} 
                                activeItem={activeItem}
                                resetFilter={resetFilter}
                            />
                        )
                    })}


                    { timestampEvent && (
                        <div className="pageFilters__datePicker">
                            {timestampEvent ? (
                                    <div className={`pageFilters__datePicker__values ${isDatePicker ? 'dropdown__toggle--open' : ''}`}  onClick={handleIsDatePicker}>
                                        <span className="txt12x18">
                                                {format(new Date(+timestampEvent), 'HH:mm dd-MM-yyyy')}
                                            </span> 
                                        <div className="dropdown__toggle__icon"> 
                                            <Icon type="dropdownDown"  size={20} />
                                        </div>
                                        
                                        
                                        </div>
                                    ) : (
                                    <button onClick={handleIsDatePicker} className="pageFilters__datePicker__button">
                                        <span>DatePicker</span> 
                                    </button>
                                    )
                                }
                        

                                {isDatePicker && (
                                    <AnimatePresence>
                                        <motion.div 
                                            className="pageFilters__datePicker__container"
                                            variants={scale}
                                            transition={{ 
                                                duration: 0.2,
                                                type: "spring",
                                                stiffness: 260,
                                                damping: 20 
                                            }}
                                            initial="hidden"
                                            animate="visible"
                                            ref={datePickerRef}
                                        >
                                            <DatePickerFilterWithHours 
                                                setFilters={setFilters} 
                                                filtersStartDate={timestampEvent}  
                                            />
                                        </motion.div>
                                    </AnimatePresence>
                                    
                            )}
                        </div>
                
                    )}
                </div>
            )}    
        </div>
    )
}

export default PageFilters;