
import { StatisticsPositionsPage } from './pages/Statistics/StatisticsPositionsPage.js';
import { StatisticsGeneralPage } from './pages/Statistics/StatisticsGeneralPage.js';
import { StatisticsStrategiesPage } from './pages/Statistics/StatisticsStrategiesPage.js';
import { StatisticsPairsPage } from './pages/Statistics/StatisticsPairsPage.js';
import { Navigate } from 'react-router-dom';
import { StatisticsPositionPage } from './pages/Statistics/StatisticsPositionPage.js';
import { BacktestResult } from './pages/BacktestResult.js';
import { CryptoBubbles } from './pages/CryptoBubbles.js';
import React from "react";
import {EDACorrelation} from "./pages/EDACorrelation";
import { BlackList } from './pages/BlackList.js';
import { BacktestGeneralPage } from './pages/BacktestResults/BacktestGeneralPage.js';
import { BacktestPairsPage } from './pages/BacktestResults/BacktestPairsPage.js';
import { BacktestPositionsPage } from './pages/BacktestResults/BacktestPositionPage.js';
import { AddBacktestPage } from './pages/Backtests/AddBacktestPage.js';
import { Backtests } from './pages/Backtests.js';
import BacktestsInProgress from './pages/Backtests/BacktestsInProgress.js';
import BacktestsCalculated from './pages/Backtests/BacktestsCalculated.js';
import BacktestsError from './pages/Backtests/BacktestsError.js';
import BacktestsCanceled from './pages/Backtests/BacktestsCanceled.js';
import CompareBacktestsPage from './pages/Backtests/CompareBacktestsPage.js';
import { SettingsStrategyPage } from './pages/SettingsStrategyPage.js';
import { SeettingsAddNewStartegyPage } from './pages/SeettingsAddNewStartegyPage.js';
import { AccountsAddNewPage } from './pages/Accounts/AccountsAddNewPage.js';
import { AccountPage } from './pages/Accounts/AccountPage.js';
import { StatisticsGeneralPageSimple } from './pages/Statistics/StatisticsGeneralPageSimple.js';
import { MlStatistics } from './pages/MLStat/MlStatistics.js';
import { MlModelPage } from './pages/MLStat/MlModelPage.js';
import { AddNewMLModelPage } from './pages/MLStat/AddNewMLModelPage.js';
import { MlModelVersionPage } from './pages/MLStat/MlModelVersionPage.js';
import { AddNewMLModelVersionPage } from './pages/MLStat/AddNewMLModelVersionPage.js';

export const Links = {
    login: {
        path: "/login",
        title: ""
    },

    mlStat: {
        icon: "algorithm",
        path: "/ml_stat",
        title: "ML statistics",
        element: <MlStatistics />,
        children: [
            {
                path: 'ml_model/:mlModelName',
                element: <MlModelPage />,
                children: [
                    {
                        path: 'ml_model_version/:versionMlModel',
                        element: <MlModelVersionPage />,
                        children: [
                            {
                                path: 'backtest/:backtestId',
                                element: <BacktestResult />,
                            }
                        ]
                    },
                    {
                        path: 'addNew',
                        element: <AddNewMLModelVersionPage />
                    }
                ]
            },
            {
                path: 'addNew',
                element: <AddNewMLModelPage/>,
            },
            {
                path: 'eda_correlation',
                element: <EDACorrelation />,
            }
        ]
    },

    // mlSettings: {
    //     path: "/ml_settings",
    //     title: "ML Settings",
    //     disabled: true,
    //     // element: <MLSettings />,
    // },
    
    accounts: {
        icon: "accounts",
        path: "/accounts",
        title: "Accounts",
        children: [
            {
                path: 'addAccount',
                element: <AccountsAddNewPage />,
            },
            {
                path: 'account/:accountId',
                element: <AccountPage />,
            },
        ]
    },

    // backtests: {
    //     path: "/backtests",
    //     title: "Backtests",
    //     element: <Backtests />,
    // },

    backtests: {
        icon: "test",
        path: "/backtests",
        title: "Backtests",
        element: <Navigate to="/backtests/inProgress" replace />,
        children: [

            {
                path: 'compare',
                //title: "General",
                element: <CompareBacktestsPage />,
            },
            {
                path: 'new',
                //title: "General",
                element: <AddBacktestPage />,
            },
            {
                path: 'inProgress',
                title: "In Progress",
                element: <BacktestsInProgress />,
            },
            {
                path: 'calculated',
                title: "Calculated",
                element: <BacktestsCalculated />,
            },
            {
                path: 'error',
                title: "Error",
                element: <BacktestsError  />,
            },
            {
                path: 'canceled',
                title: "Canceled",
                element: <BacktestsCanceled  />,
            },
            {
                path: 'backtestId/:backtestId/general',
                title: "General",
                element: <BacktestGeneralPage />,
            },
            {
                path: 'backtestId/:backtestId/positions',
                title: "Positions",
                element: <BacktestPositionsPage />,
            },
            {
                path: 'backtestId/:backtestId/pairs',
                title: "Pairs",
                element: <BacktestPairsPage />,
            }
           
        ]
    },

    strategies: {
        icon: "repair",
        path: "/settings",
        title: "Trade Settings",
        children: [
            {
                path: 'strategy/:strategyName',
                element: <SettingsStrategyPage />,
            },
            {
                path: 'addStrategy',
                element: <SeettingsAddNewStartegyPage />,
            },
        ]
    },

    statistics: {
        icon: "chart",
        path: "/statistics",
        title: "Trade Statistics",
        element: <Navigate to="/statistics/general" replace />,
        children: [
            {
                path: 'general',
                title: "General",
                element: <StatisticsGeneralPage />,
                element_simple: <StatisticsGeneralPageSimple />,
            },
            {
                path: 'positions',
                title: "Positions",
                element: <StatisticsPositionsPage />,
                children: [ 
                    {
                        path: 'position/:positionId',
                        element: <StatisticsPositionPage />,
                    }
                    
                ]
            },
            {
                path: 'strategies',
                title: "Models",
                element: <StatisticsStrategiesPage />,
            },
            {
                path: 'pairs',
                title: "Pairs",
                element: <StatisticsPairsPage />,
            }
        ]
    },

    blackList: {
        icon: "forbid",
        path: "/blacklist",
        title: "Blacklist",
        element: <BlackList />,
    },

    // backtest2: {
    //     path: "/backtest2",
    //     title: "Backtest2",
    //     element: <Navigate to="/backtest2/general" replace />,
    //     children: [
    //         {
    //             path: 'general',
    //             title: "General",
    //             element: <BacktestGeneralPage />,
    //         },
    //         {
    //             path: 'positions',
    //             title: "Positions",
    //             element: <BacktestPositionsPage />,
    //             // children: [ 
    //             //     {
    //             //         path: 'position/:positionId',
    //             //         element: <StatisticsPositionPage />,
    //             //     }
                    
    //             // ]
    //         },
    //         {
    //             path: 'pairs',
    //             title: "Pairs",
    //             element: <BacktestPairsPage />,
    //         }
    //     ]
    // },

    // systemStatistics: {
    //     path: "/system_statistics",
    //     title: "System Statistics",
    //     disabled: true,
    // },

    // formPage: {
    //     path: "/formpage",
    //     title: "Form",
    //     element: <FormPage />,
    // },

    // logs: {
    //     path: "/logs",
    //     title: "Logs",
    //     disabled: true,
    // },

    cryptobubles: {
        icon: "bubbles",
        path: "/crypto_bubbles",
        title: "Crypto Bubbles",
        element: <CryptoBubbles/>,
    },

}