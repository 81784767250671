import { useEffect } from "react";
import { mlModel__model } from "../FormModels/formConfig";
import FormModel from "../FormModels/FormModel";

const NewMLModelForm = ({ model, setModel }) => {

    useEffect(() => {
        setModel(mlModel__model)
    }, []);

    return (
        <div className="formContainer">
            <FormModel
                inputModels={model} 
                setInputModels={setModel}
            />

        </div>
        
    )
}

export default NewMLModelForm