import { createRef, useRef, useState } from "react";
import { getProperty } from "../../pages/Settings";
import { formatDuration, formatEnamKey } from "../../utils/functions";
import { getRightOrderData } from "../MainLayouts/BacktestLayout";
import Table from "../Table/Table";
import TooltipWithoutButton from "../TooltipWithoutButton";
import useTabletView from "../../hooks/useTabletView";

const CompareBacktestsTable = ({ data = [] }) => {

    const [iTooltip, setITooltip] = useState(null);
    const isTablet = useTabletView();


    const pairsRefs = useRef([]);

    const sanitizeKey = (key) => {

        return key.replace(/\./g, '_pOINt_').replace(/@/g, '_dOg_');
    }

    const originalKey = (key) => {

        return key.replace(/_pOINt_/g, '.').replace(/_dOg_/g, '@');
    }

    const getPairRef = (index) => {
        if (!pairsRefs.current[index]) {
            pairsRefs.current[index] = createRef();
        }
        return pairsRefs.current[index];
    };


    const allKeys = [...new Set(data.map(backtest => ({
        pairs: backtest.pairs,
        ...getRightOrderData(backtest)
    })).flatMap(Object.keys))];

    const handleMouseAssetsSellEnter = (ind) => {
        setITooltip(ind);

    }

    const handleMouseAssetsSellLeave = () => {
        setITooltip(null)
    }


    const getCell = (key, value, i) => {

        //console.log(index)

        switch (key) {
            case 'period':
                return formatDuration(value);


            case 'pairs':
                return (
                    <>
                        <div
                            className="textOverflowEllipsis colorBlue"
                            style={{ maxWidth: 300, whiteSpace: 'nowrap' }}
                            onMouseEnter={() => handleMouseAssetsSellEnter(i)}
                            onMouseLeave={handleMouseAssetsSellLeave}
                            ref={getPairRef(i)}
                        >
                            {value.join(', ')}


                        </div>
                        <TooltipWithoutButton
                            text={value.join(', ')}
                            isVisible={iTooltip === i}
                            setIsVisible={setITooltip}
                            maxWidth={500}
                            parentWidth={400}
                            parentRef={pairsRefs.current[i]}
                            heightShift={150}
                        />
                    </>

                );

            default:
                return getProperty(key, value)
        }
    }

    const tableData = allKeys.map((key, i) => {

        const row = { keyName: key };
        data.forEach((backtest, i) => {
            const backtestId = sanitizeKey(`${backtest.backtest_id}___${i}`);
            const dataValue = backtest[key] ?? 'N/A';
            row[backtestId] = dataValue;
        });
        return row;
    });

    const regex = /___\d+/g;
    const tableColumns = tableData[0] && Object.keys(tableData[0]).map((key, i) => {

        return {
            accessorKey: key,
            header: key === 'keyName' ? '' : originalKey(key.replace(regex, '')),
            //filterFn: 'includesString',
            filterable: false,
            sortable: false,
            id: key,
            size: key === 'keyName' ? 200 : 300,

            cell: info => {
                const value = info.getValue();

                switch (key) {

                    case 'keyName':
                        return (
                            <div className="bold" style={{ width: 'max-content' }}>
                                {formatEnamKey(value)}
                            </div>
                        );

                    default:
                        return getCell(info.row.original.keyName, value, i);
                }
            }
        }

    })

    return (
        <Table
            tableData={tableData.length === 0 ? null : tableData}
            tableColumns={tableColumns}
            title="Compare Backtests Table"
            //csvData={tableData}
            enableDragAndDrop={true}
            // mainHeight={isTablet ? 500 : "max-content"}
            mainHeight={500}
        />

    )
}

export default CompareBacktestsTable;