import { configureStore } from '@reduxjs/toolkit';
import statisticsApi from '../statisticsServices/statisticsApi';
import tokenSlice from '../token';
import portfolioBacktestApi from '../portfolioBacktestServices/portfolioBacktestApi';
// import { authMiddleware } from '../services/authApi';


export default configureStore({
  reducer: {
    token: tokenSlice,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [portfolioBacktestApi.reducerPath]: portfolioBacktestApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(statisticsApi.middleware)
    .concat(portfolioBacktestApi.middleware),
});