import IsFetching from "../../components/IsFetching";
import { useLazyFetchPositionsClosedQuery, useLazyFetchPositionsOpenQuery, useCreateNewPositionMutation } from "../../redux/statisticsServices/positionsApi";
import { convertModelToObject } from "../../utils/functions";
import { useFetchStrategiesGeneralInfoQuery } from "../../redux/statisticsServices/strategiesApi";

import { useContext, useEffect, useMemo, useState, } from "react";
import useUrlFilters from "../../hooks/useUrlFilters";
import { useFetchPairsQuery } from "../../redux/statisticsServices/pairsApi";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { endTimestemp, startTimestemp } from "./StatisticsGeneralPage";
import TabsContainer from "../../components/TabsContainer";
import PositionsTab from "../../components/Statistics/PositionsTab";
import { useFetchUserAccountQuery } from "../../redux/statisticsServices/authApi";
import { FilterContext } from "../../contexts/FilterContext";
import { accountsBlackList } from "../../utils/variables";
import Modal from "../../components/Modal";
import NewPositionForm from "../../components/Forms/NewPositionForm";
import { newPosition__model } from "../../components/FormModels/formConfig";
import { STRATEGYSTATUSES } from "../../utils/enams";
import { toast } from "react-toastify";

export function StatisticsPositionsPage() {
    const { filters, setFilters } = useContext(FilterContext);

    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();

    const { data: strategiesDataFetch, isFetching: isFetchingStartegies } = useFetchStrategiesGeneralInfoQuery();

    const { data: pairsData, isFetching: isFetchinPairs } = useFetchPairsQuery();

    const [triggerFetchPositionsClosed, { data: positionsClosedData, isFetching: isFetchingPositionsClosed }] = useLazyFetchPositionsClosedQuery();
    const [triggerFetchPositionsOpen, { data: positionsOpenData, isFetching: isFetchingPositionsOpen }] = useLazyFetchPositionsOpenQuery();
    const [addNewPosition, { isSuccess: isSuccessAddNewPosition }] = useCreateNewPositionMutation();

    const [isModal, setIsModal] = useState(false);

    const [newPositionModel, setNewPositionModel] = useState([]);

    const [activeTabIndex, setActiveTabIndex] = useState(null);

    const handleOpenModal = () => {
        setIsModal(true);
    };

    const handleCloseModal = () => {
        setIsModal(false);
    };

    const accountId = useMemo(() => {
        const result = filters.find(filter => filter.id === 'account_id_in')?.value
        return Array.isArray(result) ? result[0] : result
    }, [filters.find(filter => filter.id === 'account_id_in')?.value]);

    const strategiesData = useMemo(() => {
        return strategiesDataFetch?.filter(el => el.account_id === accountId)
    }, [strategiesDataFetch, accountId]);

    useEffect(() => {
        if (!strategiesData || !pairsData) return

        const strategyMaped = strategiesData.filter(el => el.status === STRATEGYSTATUSES['ACTIVE']).map(e => e.strategy);
        const pairsMaped = pairsData.map(el => el.pair)

        const filledNewPositionModel = newPosition__model.map(el => {
            switch (el.key) {
                case 'strategy':
                    return {
                        ...el,
                        values: strategyMaped,
                        value: strategyMaped[0]
                    }

                case 'pair':
                    return {
                        ...el,
                        values: pairsMaped,
                        value: pairsMaped[0]
                    }

                default:
                    return el
            }
        })
        setNewPositionModel(filledNewPositionModel)
    }, [strategiesData, pairsData])

    

    const filterOptions = useMemo(() => {
        return {
            account_id_in: {
                type: 2,
                values: userAccountsData,
            },

            strategy_in: {
                type: 1,
                values: strategiesData?.map(el => el.strategy),
            },

            pair_in: {
                type: 1,
                values: pairsData?.map(el => el.pair),
            },
            position_end_timestamp_gte: {
                type: 3
            },
            position_end_timestamp_lte: {
                type: 3
            }

        }
    }, [strategiesData, userAccountsData, pairsData, accountId])

    const filtersInitialized = useUrlFilters(filters, setFilters, filterOptions);

    const applyFiltersAndFetchData = (filters, ifSetFilters = false) => {
        if (ifSetFilters) {
            setFilters(filters);
        }

        triggerFetchPositionsClosed(filters);
        triggerFetchPositionsOpen(filters)
    }


    useEffect(() => {

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        if (!userAccountsData ||  !filtersInitialized) return;

        if (!startFilterTimestamp) {
            const newFilters = [
                ...filters,
                { id: 'position_end_timestamp_gte', value: startTimestemp },
                { id: 'position_end_timestamp_lte', value: endTimestemp },
                { id: 'account_id_in', value: userAccountsData[0] },
            ];
            applyFiltersAndFetchData(newFilters, true);

        } else {
            applyFiltersAndFetchData(filters);
            
        }
       
    }, [userAccountsData,  filtersInitialized]);

    const handleApplyFilters = (filters) => {
        applyFiltersAndFetchData(filters)
    }

    const sortedOpenPositions = useMemo(() => {
        if (positionsOpenData) {
            return [...positionsOpenData].sort((a, b) => b.position_update_timestamp - a.position_update_timestamp)
        }

    }, [positionsOpenData])

    const sortedClosedPositions = useMemo(() => {
        if (positionsClosedData) {
            return [...positionsClosedData].sort((a, b) => b.position_end_timestamp - a.position_end_timestamp)
        }

    }, [positionsClosedData]);

    const positionsTableClick = (key, value) => {
        const newFilters = [...filters, { id: key, value }];
        setFilters(newFilters)
        triggerFetchPositionsClosed(newFilters);
        triggerFetchPositionsOpen(newFilters);
    }

    const tabsContent = [

        <PositionsTab
            key={0}
            data={sortedOpenPositions}
            isOpen={true}
            positionsTableClick={positionsTableClick}
            isFetching={isFetchingPositionsOpen}
        />,
        <PositionsTab
            key={1}
            data={sortedClosedPositions}
            positionsTableClick={positionsTableClick}
        />,
    ];

    const tabsLabels = ['Positions Open', 'Positions Closed'];

    const userName = localStorage.getItem('username');

    const handleSubmitAddNewPosition = async () => {
        const convertedNewPositionModel = convertModelToObject(newPositionModel);

        if (convertedNewPositionModel.qty_multiplicator < 0.5) {
            toast('Enter proper value for Quantity multiplicator(must be greater or equal then 0.5)');
            return;
        }

        try {
            await addNewPosition({
                strategy: convertedNewPositionModel.strategy,
                pair: convertedNewPositionModel.pair,
                qtyMultiplicator: convertedNewPositionModel.qty_multiplicator
            }).unwrap();

            toast.success('The position was added successfully')

            handleCloseModal(false);

        } catch (error) {
            console.error('Failed to add position:', error);
            toast.error('Failed to add position')
            //alert("Error, when closing position");
        }
    }

    useEffect(() => {
        if (isSuccessAddNewPosition) {
            triggerFetchPositionsOpen();
        }
    }, [isSuccessAddNewPosition])

    useEffect(() => {
      
        const tabIndex = localStorage.getItem('positionStatus'); 
    
        if (tabIndex !== null) {
           
            setActiveTabIndex(+tabIndex); 
            localStorage.removeItem('positionStatus')
        }
    }, []);

    useEffect(() => {
        if(filtersInitialized) {
            applyFiltersAndFetchData(filters)
        }
    }, [JSON.stringify(filters)])

    useEffect(() => {
        if(!strategiesDataFetch || !filtersInitialized) return;

        const filterStrategyValue  = filters.find(filter => filter.id === 'strategy_in')?.value[0]

        const strategy = strategiesDataFetch?.find(el => el.strategy === filterStrategyValue);

        if(strategy && strategy.account_id !== accountId) {
            const newFilters = filters.filter(filter => filter.id !== 'strategy_in');
            setFilters(newFilters)
        }
    
    }, [accountId, strategiesDataFetch, filtersInitialized])


    return (
        <>
            <div className="itemPage">
                <div>
                    <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
                    {isFetchingPositionsOpen ? (
                        <IsFetching />
                    ) : (
                        <div>
                            {!accountsBlackList.includes(userName) && (
                                <button
                                    className="button"
                                    onClick={handleOpenModal}
                                >
                                    + Add new
                                </button>
                            )}

                            <TabsContainer 
                                tabsLabels={tabsLabels}
                                activeIndex={activeTabIndex}
                            >
                                {tabsContent}
                            </TabsContainer>
                        </div>

                    )}
                </div>


            </div>


            <Modal
                isModal={isModal}
                handleModal={handleCloseModal}
                content={
                    <NewPositionForm
                        model={newPositionModel}
                        setModel={setNewPositionModel}
                        handleSubmit={handleSubmitAddNewPosition}
                    />
                }
            />
        </>

    )
}

