
import Icon from '../Icon';
import Dropdown from "../Inputs/dropdown/Dropdown";

const DropdownWithSearch = ({ title, values, handleSetFilter, activeItem, resetFilter }) => {
   
    const handleRemove = (value) => {
        const newValues = activeItem.value.filter(item => item !== value);
        resetFilter(title, value);
        newValues.forEach(val => handleSetFilter(title, val));
    }

    return (
        <Dropdown
            notClickClose={true}
            input={activeItem} 
            update={(value) => handleSetFilter(title, value)}
            values={values}
            customToggle={(handleToggle, isOpen) => (
                <div onClick={ activeItem ? null : handleToggle}  className={`dropdown__toggle txt12x18 ${isOpen ? 'dropdown__toggle--open' : ''} ${activeItem?.value.length > 0 ? 'dropdown__toggle--noHover dropdown__toggle--withValue' : ''}`} >
                    <div className="dropdown__selectedItems">
                        {activeItem ? (
                            activeItem.value.map((value, i) => (
                            <div key={value + i} className="dropdown__selectedItems__item">
                                {value}
                                <button onClick={() => handleRemove(value)} className="dropdown__removeButton">
                                    <Icon type="close" />
                                </button>
                            </div>
                            ))
                        ) : (
                            <div className="txt12x18 dropdown__toggle__title">{title === 'strategy' || title === 'strategy_in' ? 'model' :  title.split('_')[0]}</div>
                        )}

                    </div>
                

                    <button onClick={handleToggle} className="dropdown__toggle__button">  
                        <Icon type={'dropdownDown'} size={20}/>
                    </button>
                </div>

            )}
            shouldSort={title !== 'timeframe_in'}
        />
    )
}

export default DropdownWithSearch;


