import { useContext, useEffect, useMemo, useRef, useState } from "react";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { useFetchStrategiesGeneralInfoQuery, useLazyFetchStrategiesQuery } from "../../redux/statisticsServices/strategiesApi";
import { useFetchPairsQuery, useLazyFetchPairCandlesQuery } from "../../redux/statisticsServices/pairsApi";
import { useLazyFetchPositionsQuery } from "../../redux/statisticsServices/positionsApi";
import { useLocation } from "react-router-dom";
import { parseSearchParams } from "../../utils/functions";
import useUrlFilters from "../../hooks/useUrlFilters";
import IsFetching from "../../components/IsFetching";
import PnLByPositionChart from "../../components/Statistics/PnLByPositionChart";
import PairPnLByStrategiesChart from "../../components/Statistics/PairPnLByStrategiesChart";
import StrategiesPositionsCountForPairChart from "../../components/Statistics/StrategiesPositionsCountForPairChart";
import CandlestickChartWithVolume from "../../components/Charts/CandlestickChartWithVolume";
import { useLazyFetchTradesQuery } from "../../redux/statisticsServices/tradesApi";
import { endTimestemp, startTimestemp } from "./StatisticsGeneralPage";
import { useFetchUserAccountQuery } from "../../redux/statisticsServices/authApi";
import { FilterContext } from "../../contexts/FilterContext";

export function StatisticsPairsPage() {
    const { filters, setFilters } = useContext(FilterContext);
    const location = useLocation();

    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();

    const { data: strategiesDataFetch, isFetching: isFetchingStartegy } = useFetchStrategiesGeneralInfoQuery();

    const { data: pairsData, isFetching: isFetchinPairs } = useFetchPairsQuery();

    const [triggerFetchPositions, { data: positionsData, isFetching: isFetchingPositions, isSuccess: isSuccessPosition }] = useLazyFetchPositionsQuery();

    const [triggerFetchStrategies, { data: strategiesFilteredData, isFetching: isFetchingStartegiesFiltered, isError: isErrorStrategiesFiltered }] = useLazyFetchStrategiesQuery();

    const [triggerFetchPairCandles, { data: pairCandlesData, isFetching: isFetchingPairCandles }] = useLazyFetchPairCandlesQuery();

    const [triggerFetchPairTrades, { data: pairTradesData, isFetching: isFetchingPairTrades, isSuccess: isSuccessPairTrades }] = useLazyFetchTradesQuery();


    const accountId = useMemo(() => {
        const result = filters.find(filter => filter.id === 'account_id_in')?.value
        return Array.isArray(result) ? result[0] : result
    }, [filters.find(filter => filter.id === 'account_id_in')?.value]);

    const strategiesData = useMemo(() => {
        return strategiesDataFetch?.filter(el => el.account_id === accountId)
    }, [strategiesDataFetch, accountId]);

    const filterOptions = useMemo(() => {
        return {
            account_id_in: {
                type: 2,
                values: userAccountsData,

            },
            strategy_in: {
                type: 1,
                values: strategiesData?.map(el => el.strategy),
            },
            pair: {
                type: 2,
                values: pairsData?.map(el => el.pair),
            },
            position_end_timestamp_gte: {
                type: 3
            },
            position_end_timestamp_lte: {
                type: 3
            }
        }
    }, [strategiesData, userAccountsData, pairsData, accountId])

    const filtersInitialized = useUrlFilters(filters, setFilters, filterOptions);

    function createFilterForCandles(filters) {

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const endFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_lte')?.value;


        return [
            startFilterTimestamp && { id: "open_time_gte", value: startFilterTimestamp },
            endFilterTimestamp && { id: "open_time_lte", value: endFilterTimestamp }
        ].filter(filter => filter);
    }

    function createFilterForTrades(filters) {

        const pair = filters.find(filter => filter.id === 'pair')?.value;
        const strategies = filters.find(filter => filter.id === 'strategy_in')?.value;

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const endFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_lte')?.value;

        const accountId = filters.find(filter => filter.id === 'account_id_in')?.value;


        return [
            startFilterTimestamp && { id: "timestamp_gte", value: startFilterTimestamp },
            endFilterTimestamp && { id: "timestamp_lte", value: endFilterTimestamp },
            strategies && { id: "strategy_in", value: strategies },
            { id: "account_id_in", value: accountId },
            { id: 'pair', value: pair }
        ].filter(filter => filter);
    }

    const applyFiltersAndFetchData = (filters, ifSetFilters = false, pairValue) => {
        const filterForCandles = createFilterForCandles(filters);
                const filterForTrades = createFilterForTrades(filters)

                if(ifSetFilters) {
                      setFilters(filters);
                }
              
                triggerFetchPositions(filters);
                triggerFetchStrategies(filters);
                triggerFetchPairCandles({ pair: pairValue, filters: filterForCandles });
                triggerFetchPairTrades(filterForTrades)
    }


    useEffect(() => {
        if (!userAccountsData || !strategiesDataFetch || !filtersInitialized) return;

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const hasPairFilter = filters.some(filter => filter.id === 'pair');
        
        if (!startFilterTimestamp && !hasPairFilter) {
           
            const pairValue = 'ETH/BTC';
            const newFilters = [
                ...filters,
                { id: 'position_end_timestamp_gte', value: startTimestemp },
                { id: 'position_end_timestamp_lte', value: endTimestemp },
                { id: 'account_id_in', value: userAccountsData[0] },
                { id: 'pair', value: pairValue }
            ];

            applyFiltersAndFetchData(newFilters, true, pairValue);
        }  else if (startFilterTimestamp && !hasPairFilter){

            const pairValue = 'ETH/BTC';
            const newFilters = [
                ...filters,
                { id: 'pair', value: pairValue }
            ];

            applyFiltersAndFetchData(newFilters, true, pairValue);
        
        }  else if (hasPairFilter && pairsData) {
    
            const pairValue = filters.find(filter => filter.id === 'pair')?.value;

            applyFiltersAndFetchData(filters, false, pairValue);
        }

    }, [userAccountsData, pairsData, strategiesDataFetch, filtersInitialized])


    const handleApplyFilters = (filters) => {
        const pairValue = filters.find(filter => filter.id === 'pair')?.value;

        applyFiltersAndFetchData(filters, false, pairValue);
    }

    useEffect(() => {
        if(filtersInitialized) {
            const pairValue = filters.find(filter => filter.id === 'pair')?.value;
            applyFiltersAndFetchData(filters, false, pairValue);
        }
    }, [JSON.stringify(filters)]);

    useEffect(() => {
        if(!strategiesDataFetch || !filtersInitialized) return;

        const filterStrategyValue  = filters.find(filter => filter.id === 'strategy_in')?.value[0]

        const strategy = strategiesDataFetch?.find(el => el.strategy === filterStrategyValue);

        if(strategy && strategy.account_id !== accountId) {
            const newFilters = filters.filter(filter => filter.id !== 'strategy_in');
            setFilters(newFilters)
        }
    
    }, [accountId, strategiesDataFetch, filtersInitialized])

    return (
        <>
            <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
            <div className="itemPage">
                {isFetchingPositions || !positionsData ? (
                    <IsFetching height={200} />
                ) : (
                        <PnLByPositionChart
                            positionsData={positionsData.filter(data => data.is_closed)}
                            allowedClick={true}
                            startDate={pairCandlesData && [...pairCandlesData].sort((a, b) => a.open_time - b.open_time)[0].open_time}
                            endDate={pairCandlesData && [...pairCandlesData].sort((a, b) => a.open_time - b.open_time)[pairCandlesData.length - 1].open_time}
                        /> 
                
                )}

                {isFetchingPairCandles || isFetchingPairTrades ? (
                    <IsFetching height={200} />
                ) : (
                    <CandlestickChartWithVolume data={{
                            candles: pairCandlesData,
                            trades: (pairTradesData && isSuccessPairTrades) ? pairTradesData : [],
                        }}
                    />
                )}

                {isFetchingStartegiesFiltered ? (
                    <IsFetching height={200} />
                ): (
                    <PairPnLByStrategiesChart strategiesData={strategiesFilteredData} />
                )}

               
                {isFetchingUserAccounts ? (
                    <IsFetching height={200} />
                ): (
                    <StrategiesPositionsCountForPairChart strategiesData={strategiesFilteredData} />
                )}
            </div>
        </>
    )
}                                     