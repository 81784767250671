import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, TimeScale } from 'chart.js';
import { CandlestickController, OhlcElement, CandlestickElement, } from 'chartjs-chart-financial';
import { Chart as ReactChartJS } from 'react-chartjs-2';
import zoomPlugin, { resetZoom } from 'chartjs-plugin-zoom';
import * as styles from '../../styles/utils/_variables.scss';
import { format } from 'date-fns';
import { calculatePrecisionForUniqueTicks, formatFloat, formatTimestempUTC } from '../../utils/functions';
import useTabletView from '../../hooks/useTabletView';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';
import useMobileView from '../../hooks/useMobileView';
import { pick_colors } from '../../utils/variables';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  TimeScale,
  CandlestickController,
  CandlestickElement,
  OhlcElement,
  zoomPlugin
);

const tooltipLine = {
  id: 'tooltipLine',

  beforeDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      ctx.save();
      const activePoint = chart.tooltip._active[0];
      ctx.beginPath();
      ctx.setLineDash([5, 7]);
      ctx.moveTo(activePoint.element.x, chart.chartArea.top);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = styles.default.colorBlack;
      ctx.stroke();
      ctx.restore();
    }
  }
}

function firstAndLastElements(array) {

  return [array[0], array[array.length - 1]];
}

const drawRectanglesPlugin = {
  id: 'drawRectangles',
  afterDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.save();

    const positionsDataset = chart.data.datasets.find(dataset => dataset.label === 'Positions');
    const positionsMap = positionsDataset && positionsDataset.data.reduce((acc, position) => {
      acc[position.position_id] = position.buy_average_price;
      return acc;
    }, {});

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (dataset.label === 'Trades') {

        const meta = chart.getDatasetMeta(datasetIndex);
        if (!meta.hidden) {

          const groupedPoints = dataset.data.reduce((acc, el, idx) => {
            if (el.positionId) {
              acc[el.positionId] = (acc[el.positionId] || []).concat({ ...el, index: idx });
            }
            return acc;
          }, {});

          Object.values(groupedPoints).forEach(fullGroup => {
            const group = firstAndLastElements(fullGroup)

            if (group.length > 1) {

              for (let i = 0; i < group.length - 1; i++) {

                const firstPoint = meta.data[group[i].index];
                const secondPoint = group[i + 1].side === -1 && meta.data[group[i + 1].index];

                const firstPrice = group[i].price || group[i].average_price;
                const secondPrice = group[i + 1].price || group[i + 1].average_price;

                const yAxis = chart.scales[dataset.yAxisID];

                const startX = Math.max(firstPoint.x, chart.chartArea.left);
                const endX = Math.min(secondPoint.x, chart.chartArea.right);
                const positionAvgPrice = positionsMap[group[i].positionId];



                //const startY =  Math.max(Math.min(firstPoint.y, secondPoint.y), yAxis.top);
                const startY = fullGroup.length > 2 ? Math.max(yAxis.getPixelForValue(positionAvgPrice), yAxis.top) : Math.max(Math.min(firstPoint.y, secondPoint.y), yAxis.top);
                //const startY = yAxis.getPixelForValue(positionAvgPrice);

                const endY = Math.min(Math.max(firstPoint.y, secondPoint.y), yAxis.bottom);
                const width = endX - startX;
                //const height = Math.max(0, Math.min(endY, yAxis.bottom) - Math.max(startY, yAxis.top));

                let height;
                if (endY < yAxis.top || startY > yAxis.bottom) {
                  height = 0;
                } else {
                  height = Math.min(endY, yAxis.bottom) - Math.max(startY, yAxis.top);
                }

                //const height = Math.max(endY - startY, 10);

                if (width > 0) {
                  ctx.fillStyle = firstPrice > secondPrice ? `${styles.default.colorRed}50` : `${styles.default.colorGreen}50`;
                  ctx.fillRect(startX, startY, width, height);
                }
              }
            }
          });
        }
      }
    });

    ctx.restore();
  }
};


const CandlestickChartWithVolume = ({ data = {}, isBacktests = false, mainAsset = 'BTC', positions = null, useUTC = false }) => {

  const [isZoom, setIsZoom] = useState(false);

  const isTablet = useTabletView();
  const isMobile = useMobileView();

  const getValueDependingView = useValueDependingOnView()

  const groupedPoints = useMemo(() => {
    return data.trades.reduce((acc, el, idx) => {
      if (el.positionId) {
        acc[el.positionId] = (acc[el.positionId] || []).concat({ ...el, index: idx });
      }
      return acc;
    }, {});
  }, [data.trades]);

  const dataCandles = useMemo(() => {
    if (data.candles === undefined) return [];
    return [...data.candles].sort((a, b) => a.open_time - b.open_time);
  }, [data.candles]);

  const dataTrades = useMemo(() => {
    return [...data.trades].sort((a, b) => a.timestamp - b.timestamp)
  }, [data.trades])


  const dataPrice = useMemo(() => {
    return dataCandles.map(item => ({ x: item.open_time, y: item.close }));
  }, [dataCandles]);

  const chartRef = useRef(null);

  const handleDoubleClick = () => {
    if (chartRef.current) {
      if (isZoom) {
        resetZoom(chartRef.current);
        setIsZoom(false);
      } else {
        setIsZoom(true);

      }
    }
  };

  useEffect(() => {
    const chartInstance = chartRef.current;
    if (chartInstance) {
      const canvas = chartInstance.canvas;

      canvas.addEventListener('dblclick', handleDoubleClick);

      return () => {
        canvas.removeEventListener('dblclick', handleDoubleClick);
      };
    }
  }, [handleDoubleClick]);

  function getCandleForTrade(tradeTimestamp) {

    return dataCandles.find(c => c.open_time <= tradeTimestamp && c.close_time >= tradeTimestamp)
  };

  const res = dataTrades.map(item => {
    const candle = getCandleForTrade(item.timestamp);
    return {
      ...item,
      // candle_price: candle ? candle.close : item.average_price, // TODO: align trade price to close_price
      candle_price: isBacktests ? item.price : item.average_price, // prod
      candle_timestamp: candle ? candle.open_time : item.timestamp,
    };
  });

  const candlesArray = useMemo(() => {
    return dataCandles.map(item => ({
      x: item.open_time,
      o: item.open,
      h: item.high,
      l: item.low,
      c: item.close,
    }))
  }, [dataCandles]);

  const volumesArray = useMemo(() => {
    return dataCandles.map(item => ({
      x: item.open_time,
      y: item.volume,
      volume: Math.round(item.volume),
      o: item.open,
      c: item.close,
    }))

  }, [dataCandles])

  const chartData = useMemo(() => (
    {
      datasets: [
        {
          //xAxisID: 'x',
          yAxisID: 'y',
          label: 'Trades',
          data: res.map((item, i, arr) => ({
            x: item.candle_timestamp,
            y: item.candle_price,
            realTimestamp: item.timestamp,
            side: item.side,
            rotation: item.side === 1 ? 0 : 180,
            backgroundColor: item.side === 1 ? arr[i - 1]?.side === 1 && arr[i - 1]?.position_id === arr[i].position_id ? styles.default.colorDarkGray : styles.default.colorGreen : styles.default.colorRed,
            borderColor: '#fff',
            tradeId: item.trade_id,
            positionId: item.position_id,
            quantityBTC: item.quote_executed_qty,
            quantityBase: item.base_executed_qty,
            pair: item.pair,
            strategy: item.strategy,
            commision: item.commission_qty || formatFloat(item.commission),
            average_price: item.average_price,
            quantity: item.quantity,
            price: item.price,
            slippage: (item.slippage) * 100,
            pnl: item.pnl
          })),
          pointStyle: 'triangle',
          type: 'scatter',
          radius: 10,
          backgroundColor: (ctx) => ctx.raw?.backgroundColor,
          borderColor: (ctx) => ctx.raw?.borderColor,
          rotation: (ctx) => ctx.raw?.rotation,
          borderWidth: 0,
        },
        {
          yAxisID: 'y',
          transitions: {
            show: {
              animations: false
            },
            hide: {
              animations: false
            }
          },
          label: 'Candlestick',
          type: "candlestick",
          data: candlesArray,
          hidden: true,
          borderColors: {
            up: styles.default.colorCandleDown,
            down: styles.default.colorCandleUp,
            unchanged: styles.default.colorGreen
          },
          backgroundColors: {
            up: `${styles.default.colorCandleDown}`,
            down: `${styles.default.colorCandleUp}`,
            unchanged: styles.default.colorGreen
          },
        },

        {
          yAxisID: 'y',
          label: 'Price',
          data: dataPrice,
          pointStyle: 'circle',
          type: 'line',
          radius: 0,
          backgroundColor: `${pick_colors[0]}90`,
          borderColor: pick_colors[0],
          borderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
        },

        {
          label: 'Volume',
          data: volumesArray.map(el => ({
            ...el,
            backgroundColor: el.o > el.c ? `${styles.default.colorCandleUp}90` : `${styles.default.colorCandleDown}90`,

          })),
          type: 'bar',
          backgroundColor: (ctx) => ctx.raw?.backgroundColor,
          //borderColor: 'tra',
          yAxisID: 'volume',
          xAxisID: 'xV',


        },

        {
          label: 'Positions',
          data: positions?.map(pos => ({ ...pos, x: pos.timestamp, y: pos.buy_average_price })),
          hidden: true
        }

      ],

    }), [data, positions])


  const yRange = {
    min: Math.min(...dataCandles.map(c => c.low)),
    max: Math.max(...dataCandles.map(c => c.high))
  };

  const maxVolume = (Math.max(...dataCandles.map(c => c.volume)))

  const options = {
    animation: {
      duration: 0
    },
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: 10,
          callback: function (value) {
            if (useUTC) return formatTimestempUTC(value).split(" ")
            else {
              const date = new Date(value);
              const timeString = format(date, 'HH:mm');
              const dateString = format(date, 'dd.MM.yyyy');
              return [timeString, dateString];
            }
          },
        }
      },
      xV: {
        display: false,
        type: 'time',
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: 10,
          callback: function (value) {
            if (useUTC) return formatTimestempUTC(value).split(" ")
            else {
              const date = new Date(value);
              const timeString = format(date, 'HH:mm');
              const dateString = format(date, 'dd.MM.yyyy');
              return [timeString, dateString];
            }
          },
        }
      },
      volume: {
        max: maxVolume,
        beginAtZero: true,
        type: 'linear',
        stack: 'stock',
        stackWeight: getValueDependingView(1.5, 0.5, 0.2),
        grace: 1,

        ticks: {
          mirror: isTablet,
          precision: 10,
          font: {
            size: isTablet ? 8 : 12,
          },
          //maxTicksLimit: 5,
          callback: function (value) {
            return value.toFixed(0);
          }
        },

        title: {
          display: isMobile ? false : true,
          text: 'Volume',
          align: 'center'
        },


      },
      y: {
        offset: true,
        //beginAtZero: true,

        min: yRange.min,
        max: yRange.max,

        stack: 'stock',
        ticks: {
          mirror: isTablet,
          precision: 10,
          //maxTicksLimit: 5,
          font: {
            size: isTablet ? 8 : 12,
          },
          callback: function (value, index, ticks) {
            const precision = calculatePrecisionForUniqueTicks(ticks);
            return parseFloat(value).toFixed(precision);
          }
        },

        title: {
          display: isMobile ? false : true,
          text: mainAsset,
          align: 'center'
        },

        stackWeight: getValueDependingView(3, 2, 1),

      },

    },
    plugins: {
      legend: {
        align: 'end',
        labels: {
          usePointStyle: true,
          boxWidth: getValueDependingView(15, 10, 5),
          boxHeight: getValueDependingView(15, 10, 5),

          font: {
            size: getValueDependingView(12, 10, 8),
          },
          generateLabels: function (chart) {
            const datasets = chart.data.datasets;

            return datasets.reduce((acc, dataset, i) => {
              if (dataset.label !== 'Positions') {
                acc.push({
                  text: dataset.label,
                  fillStyle: styles.default.colorGray,
                  strokeStyle: styles.default.colorDarkGray,
                  pointStyle: 'circle',
                  hidden: !chart.isDatasetVisible(i),
                  datasetIndex: i
                });
              }
              return acc;
            }, []);
          }
        }
      },
      tooltip: {
        mode: 'x',
        intersect: false,

        backgroundColor: function (context) {
          
          const dataIndex = context.tooltip.dataPoints[0].dataIndex;
          const datasetIndex = context.tooltip.dataPoints[0].datasetIndex;
          const dataPoint = context.chart.data.datasets[datasetIndex].data[dataIndex];

          if (datasetIndex === 1) {
            return dataPoint.o > dataPoint.c ? styles.default.colorCandleUp : styles.default.colorCandleDown
          } else if ((datasetIndex === 3)) {
            return styles.default.colorBlack
          } else {
            return dataPoint.side === 1 ? styles.default.colorGreen : styles.default.colorRed
          }
        },
        displayColors: false,
        callbacks: {
          title: function (value) {
            try {
              // Code to format candlestick tooltip title (time and date)
              const dateString = value[0].label;
              const [time, date] = dateString.split(' ');
              const [hours, minutes] = time.split(':').map(Number);
              const [day, month, year] = date.split('.').map(Number);
              const dateObject = new Date(Date.UTC(year, month - 1, day, hours, minutes));

              const minutesOffset = dateObject.getTimezoneOffset();
              dateObject.setTime(dateObject.getTime() + minutesOffset * 60 * 1000)
              const formattedMS = dateObject.getTime();

              if (useUTC) return formatTimestempUTC(formattedMS)

            } catch (error) { }
          },
          label: function (context) {
            const datasetLabel = context.dataset.label;
            const { o, h, l, c, side, x, tradeId, positionId, quantityBTC, quantityBase, pair, y, strategy, commision, average_price, volume, quantity, price, slippage, pnl, realTimestamp } = context.raw;
            if (datasetLabel === 'Candlestick') {
              return [
                // `Candle openTime: ${format(new Date(x),'HH:mm dd.MM.yyyy')}`,
                `Open: ${o}`,
                `High: ${h}`,
                `Low: ${l}`,
                `Close: ${c}`
              ];
            } else if (datasetLabel === 'Trades') {
              return [
                side === 1 ? 'Buy signal' : 'Sell signal',
                tradeId && `Trade id:  ${tradeId}`,
                positionId && `Position id: ${positionId}`,
                `Datetime: ${useUTC ? formatTimestempUTC(realTimestamp) : format(new Date(realTimestamp), 'HH:mm dd.MM.yyyy')}`,
                quantityBTC && `Quantity BTC: ${quantityBTC}`,
                `Quantity ${pair.includes('/') ? pair.split('/')[0] : pair.split('_')[0]}: ${quantityBase || quantity.toFixed(9)}`,
                `Price: ${average_price || formatFloat(price)}`,
                strategy && `Strategy: ${strategy}`,
                slippage && `Slippage: ${formatFloat(slippage)} %`,
                commision && `Commision ${mainAsset}: ${commision}`,
                // pnl && `PnL: ${formatFloat(pnl * 100)}%`
              ].filter(el => el);
            } else if (datasetLabel === 'Volume') {
              return [
                `Close price: ${formatFloat(parseFloat(c))}`,
                `Volume: ${volume}`
              ];
            }
          },
        },

        filter: function (tooltipItem) {
          const datasetLabel = tooltipItem.dataset.label;
          if (datasetLabel === 'Trades') {
            return tooltipItem.raw.y !== undefined;
          }

          if (datasetLabel === 'Candlestick' || datasetLabel === 'Volume') {
            return tooltipItem.raw.y !== undefined || tooltipItem.raw.o !== undefined;
          }

          return false;
        }
      },
      zoom: isZoom && {
        pan: {
          enabled: true,
          mode: 'xy',
          speed: 100,
          modifierKey: 'ctrl',
          // onPan: ({ chart }) => {
          //   const xScale = chart.scales.x;

          //   const start = xScale.min;
          //   const end = xScale.max;

          //   let visibleData = [];
          //   for (let i = 0; i < chart.data.datasets[2].data.length; i++) {
          //     let el = chart.data.datasets[2].data[i];
          //     if (el.x >= parseInt(start) & el.x < parseInt(end)) {
          //       visibleData.push(el.y)
          //     }
          //   }

          //   const minY = Math.min(...visibleData);
          //   const maxY = Math.max(...visibleData);

          //   chart.options.scales.y.min = minY;
          //   chart.options.scales.y.max = maxY;

          //   chart.update();
          // },
          onPanComplete: ({ chart }) => {
            const xScale = chart.scales.x;

            const start = xScale.min;
            const end = xScale.max;

            let visibleData = [];
            for (let i = 0; i < chart.data.datasets[2].data.length; i++) {
              let el = chart.data.datasets[2].data[i];
              if (el.x >= parseInt(start) & el.x < parseInt(end)) {
                visibleData.push(el.y)
              }
            }

            const minY = Math.min(...visibleData);
            const maxY = Math.max(...visibleData);

            chart.options.scales.y.min = minY;
            chart.options.scales.y.max = maxY;

            chart.update();
          },
        },
        limits: {
          x: { min: 'original', max: 'original', minRange: 60 * 1000 },
          y: { min: 'original', max: 'original' },
        },
        zoom: {
          animations: false,
          wheel: {
            enabled: true,
            mode: 'x',
            speed: 0.4
          },
          pinch: {
            enabled: true,
            mode: 'xy'
          },
          mode: 'xy',
          drag: {
            enabled: true,
            backgroundColor: 'rgba(3, 3, 3, 0.3)',
            mode: 'xy',
          },
          // onZoom: ({ chart }) => {
          //   const xScale = chart.scales.x;

          //   const start = xScale.min;
          //   const end = xScale.max;

          //   let visibleData = [];
          //   for (let i = 0; i < chart.data.datasets[2].data.length; i++) {
          //     let el = chart.data.datasets[2].data[i];
          //     if (el.x >= parseInt(start) & el.x < parseInt(end)) {
          //       visibleData.push(el.y)
          //     }
          //   }

          //   const minY = Math.min(...visibleData);
          //   const maxY = Math.max(...visibleData);

          //   chart.options.scales.y.min = minY;
          //   chart.options.scales.y.max = maxY;

          //   chart.update();
          // },
          onZoomComplete: ({ chart }) => {
            const xScale = chart.scales.x;

            const start = xScale.min;
            const end = xScale.max;

            let visibleData = [];
            for (let i = 0; i < chart.data.datasets[2].data.length; i++) {
              let el = chart.data.datasets[2].data[i];
              if (el.x >= parseInt(start) & el.x < parseInt(end)) {
                visibleData.push(el.y)
              }
            }

            const minY = Math.min(...visibleData);
            const maxY = Math.max(...visibleData);

            chart.options.scales.y.min = minY;
            chart.options.scales.y.max = maxY;

            chart.update();
          },
        }
      }
    }
  };

  const mergedOptions = {
    ...options,
    maintainAspectRatio: false,
    plugins: {
      ...options.plugins,
      datalabels: false,
      title: {
        display: true,
        text: "OHLC",
        align: 'start',
        color: '#333333',
        font: {
          size: isTablet ? 16 : 20,
          lineHeigth: '27px',
          weigth: 500,
        }
      },
    }
  };

  if (dataCandles.length === 0) {
    return (<>
      {mergedOptions?.plugins?.title?.display && (
        <div style={{
          textAlign: 'left', padding: '10px', fontSize: '20px', lineHeight: '27px',
          fontWeight: '600',
          color: '#333',
        }}>
          {Array.isArray(mergedOptions?.plugins?.title?.text) ? mergedOptions?.plugins?.title?.text[0] : mergedOptions?.plugins?.title?.text}
        </div>
      )}
      <div className="noData">
        No candles data for this period
      </div>
    </>)
  }


  return (
    <div className="backgroundContainer" style={{ height: getValueDependingView('800px', '600px', '400px') }}>
      <ReactChartJS ref={chartRef} plugins={[tooltipLine, drawRectanglesPlugin]} type="candlestick" data={chartData} options={mergedOptions} />
    </div>
  )


};

export default CandlestickChartWithVolume;
