import { AnimatePresence, motion } from "framer-motion";
import Icon from "./Icon";
import { useRef } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useMobileView from "../hooks/useMobileView";

const Modal = ({ content, handleModal, isModal }) => {
    const windowRef = useRef(null);
    const isMobile = useMobileView();
    useOnClickOutside(windowRef, handleModal)
    return (
        <>
            <AnimatePresence>
                {isModal && (
                    <>
                        <motion.div
                            className="modal__shadow"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        />
                        <motion.div
                            className="modal"
                            initial={{ opacity: 0, scale: 0.2, x: '-50%', y: isMobile ? '-10%' : '-50%' }}
                            animate={{ opacity: 1, scale: 1, x: '-50%', y: isMobile ? '-10%' : '-50%'}}
                            exit={{ opacity: 0, scale: 0.2, x: '-50%', y: isMobile ? '-10%' : '-50%' }}
                            transition={{ duration: 0.3 }}
                            ref={windowRef}
                        >
                            <div className="modal__window">
                                <button className="modal__close" onClick={handleModal}>
                                    <Icon type="close" />
                                </button>
                                <div className="modal__body">
                                    {content}
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}

export default Modal;