import { classnames } from "../../../utils/functions"
import Icon from "../../Icon"

const DropdownToggle = ({ isOpen, handleToggle, height, renderSpan, input={} }) => {
    return (
        <div
            className={classnames(
                'dropdown__toggle textOverflowEllipsis input txt12x18 dropdown__selectedItems__item',
                {
                    'dropdown__toggle--open': isOpen,
                    'input--error': input?.error
                }
            )}
            onClick={ handleToggle }
            style={{ height,  width: 'initial'}}
        > 
            <span className={classnames('txt12x18 dropdown__toggle__title', 
                {'dropdown__toggle__title--error': input.error}
            )} >
                {renderSpan()}  
            </span>
            <div className={classnames('dropdown__toggle__icon', {'dropdown__toggle__icon--error': input.error})} >
                <Icon
                    type={'dropdownDown'} 
                    size={20}
                />
            </div>
        
        </div> 
    )
}

export default DropdownToggle