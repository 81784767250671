import React, { createContext, useState } from 'react';

export const TradeSettingsContext = createContext();

export const TradeSettingsProvider = ({ children  }) => {
    const [filters, setFilters] = useState([]);

    return (
        <TradeSettingsContext.Provider value={{ filters, setFilters}}>
            {children}
        </TradeSettingsContext.Provider>
    );
};