import useTabletView from "../../hooks/useTabletView";
import { formatFloat } from "../../utils/functions";
import { pick_colors } from "../../utils/variables";
import BarChartHorizontal, { getModifiedData } from "../Charts/BarChartHorizontal";
import Tooltip from "../Tooltip";

const StrategiesPositionsCountForPairChart = ({ strategiesData = [] }) => {
    const isTablet = useTabletView();
    const dataArray = getModifiedData(strategiesData, 'total_position_count');
    const totalCount = strategiesData.reduce((sum, strategy) => sum + strategy.total_position_count, 0);


    const data = {
        labels: dataArray.map(item => item.strategy),
        datasets: [
            {
                label: 'BTC',
                data: dataArray.map(item => formatFloat(item.total_value)),
                backgroundColor: pick_colors.map((color, index) => `${color}90`),
                borderColor: pick_colors,
                borderWidth: 1,
                maxBarThickness: 40
            }
        ],
    };

    const options = {
        scales: {
            x: {
                title: {
                    text: 'Positions count',
                }
            }
        },
        plugins: {
            title: {
                text: ['Pair positions count by Models', `Total count: ${totalCount}`],
            },
            tooltip: {
                backgroundColor: function (context) {
                    const tooltipIndex = context.tooltip.dataPoints[0].dataIndex;
                    return pick_colors[tooltipIndex];
                },

                callbacks: {
                    label: function (context) {
                        let label = (dataArray[context.dataIndex].original_value / totalCount * 100).toFixed(2) + `% (${dataArray[context.dataIndex].original_value}) `
                        return label;
                    }
                }
            },
            datalabels: {
                formatter: (value, context) => {
                    return dataArray[context.dataIndex].original_value ? (dataArray[context.dataIndex].original_value / totalCount * 100).toFixed(2) + `% (${dataArray[context.dataIndex].original_value})` : null;
                }
            }
        },
    };

    return (
        <div>
            <BarChartHorizontal data={isNaN(totalCount) ? null : data} options={options} enableDataLabels={true}
                tooltip={<Tooltip text='Розподілення закритих угод обраної пари по моделях' maxWidth={300} style={{ top: isTablet ? 28 : 31, left: isTablet ? 232 : 295 }} />} />
        </div>

    )
}


export default StrategiesPositionsCountForPairChart;