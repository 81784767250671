import LineChart from "../Charts/LineChart";
import * as styles from '../../styles/utils/_variables.scss';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ChartTabs from "../Charts/ChartTabs";

const ModelVersionsChart = ({ mlModelVersionsData }) => {
    const navigate = useNavigate();
    const modesArray = ['PnL', 'Average monthly trades'];

    const [mode, setMode] = useState(0)

    const chartOptions = {

      onHover: (event, chartElements) => {
        
        const canvas = event.native.target;

        if (chartElements.length > 0) {
            canvas.classList.add('cursorPointer');
        } else {
            canvas.classList.remove('cursorPointer');
        }
    },

        onClick: (event, elements) => {
          if (elements.length > 0) {
            const versionMlModel = elements[0].element.$context.raw.version
    
            navigate(`ml_model_version/${versionMlModel}`)
          }
        },
        scales: {
          x: {
            
            type: 'linear',
            title: {
              text: 'Version',
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return modesArray[mode] === 'PnL' ?  value + '%' : value
              }
            },
            title: {
              text: modesArray[mode] === 'PnL' ? 'PnL' : 'Average monthly trades',
            },
          },
        },
        plugins: {
          custom: {},
          tooltip: {
            displayColors: false,
            mode: 'nearest',
            callbacks: {
              title: function (context) {
                const name = context[0].raw.version
    
                return `Version: ${name}`
              }
            }
          },
          title: {
            text: 'Versions',
          },
          legend: {
            display: false,
          }
        }
      }

    const data = mlModelVersionsData && mlModelVersionsData.map((item, index) => ([{
        x: index + 1,
        y: item.final_pnl_percent,
        version: item.version
      }, {
        x: index + 1,
        y: item.avg_monthly_trades,
        version: item.version
      }]));

    const chartData = {
        datasets: [
          {
            data: modesArray[mode] === 'PnL' ?  data.map(el => el[0]) : data.map(el => el[1]),
            borderColor: modesArray[mode] === 'PnL' ? styles.default.colorBlue : styles.default.colorOrange,
            label: modesArray[mode] === 'PnL' ?  'Final PnL(%)' :  'Average monthly trades',
            fill: false,
            type: 'line',
            radius: 7,
            hoverRadius: 15,
          }, 
        ]
      };
    return (
      <div className="flexColumnGap5" style={{ gap: 0 }}>
        <ChartTabs
          mode={mode}
          setMode={setMode}
          modesArray={modesArray}
        />
        <LineChart
            chartData={chartData}
            options={chartOptions}
            showZeroLine={modesArray[mode] === 'PnL'}
            zoomEnabled={false}
            allowedClick={true}
        />
      </div>
      
    )
}

export default ModelVersionsChart