import { useCallback } from "react"

const Radio = ({ input, update}) => {

    const handleChange = useCallback((e) => {
        update(input, e.target.value)
    }, [input, update])  

    return (
        <div className="formModel__item">
            {input.title}
            <div className="flexGap20 radio">
                {input.values.map(value => {
                    return (
                        <label key={value}>
                            <input 
                                name={input.key}
                                type="radio"
                                value={value}
                                id={value}
                                checked={input.value === value}
                                onChange={handleChange}
                                hidden
                            />
                            <div className="radio__label"/>
                            <span>{value}</span>
                        </label>
                    )
                })}
            </div> 
        </div>
    )
}

export default Radio