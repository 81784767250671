import { format } from "date-fns";
import { pick_colors } from "../../utils/variables";
import { calculatePrecisionForUniqueTicks, formatTimestemp } from "../../utils/functions";
import LineChart from "../Charts/LineChart";
import { useState } from "react";
import * as styles from '../../styles/utils/_variables.scss';
import ChartTabs from "../Charts/ChartTabs";

const ModelsBalancesChart = ({ data = {} }) => {

  const modesArray = ['Cumulative PnL', 'Total Balance', 'Free Balance', 'Free Balance (%)', 'Avg weighted PnL']

  const [mode, setMode] = useState(0)

  const getY = (value, dataArray, i) => {

    switch (modesArray[mode]) {
      case 'Total Balance':
        return value.total_balance;

      case 'Free Balance':
        return value.free_balance;

      case 'Free Balance (%)':
        return (value.free_balance / value.total_balance) * 100;

      case 'Avg weighted PnL':
        return value.avg_weighted_pnl_percent;

      default:
        return dataArray[i]

    }
  }

  const tooltipModelsBalancesChart = {
    'Cumulative PnL': 'PnL моделей у відсотках (по закритим та відкритим угодам)',
    'Total Balance': 'Баланс моделей в BTC (по закритим та відкритим угодам)',
    'Free Balance': 'Вільний баланс моделей в BTC',
    'Free Balance (%)': 'Вільний баланс моделей у %',
    'Avg weighted PnL': 'Середньозважений PnL по відкритих угодах',
  };

  const getTableTitle = () => {
    switch (modesArray[mode]) {
      case 'Total Balance':
        return 'Models Total Balance';

      case 'Free Balance':
        return 'Models Free Balance';

      case 'Free Balance (%)':
        return 'Models Free Balance (%)';

      case 'Avg weighted PnL':
        return 'Average weighted PnL (open positions)';

      default:
        return 'Models Cumulative PnL'

    }
  }

  // const tooltipsAccountChart = {
  //   'Cumulative PnL': 'Зміна загального балансу у відсотках (по закритим та відкритим угодам)',
  //   'Total Balance': 'Зміна загального балансу в BTC (по закритим та відкритим угодам)'
  // }



  const getData = (data) => {
    const dataArray = Object.values(data).filter(el => el);
    let cumulativePnL = 0;
    let cumulativePnLData = [0];

    for (let i = 1; i < dataArray.length; i++) {
      let prevBalance = dataArray[i - 1]?.total_balance;

      let currentBalance = dataArray[i]?.total_balance - dataArray[i]?.force_difference;
      let currentPnL = currentBalance - prevBalance;
      let currentPnLPercent = (prevBalance === 0) ? currentPnL : currentPnL / (prevBalance);

      cumulativePnL += currentPnLPercent;
      cumulativePnLData.push(cumulativePnL);
    }

    return Object.entries(data).filter(([key, value]) => value !== null).map(([key, value], i) => {
      return {
        x: +key,
        y: getY(value, cumulativePnLData.map(el => el * 100), i),
        updateTime: value.update_time,
        freeBalance: value.free_balance,
        totalBalance: value.total_balance,
        forceDifference: value.force_difference,
        pnl: getY(value, cumulativePnLData.map(el => el * 100), i)?.toFixed(2),

        openPositionsCount: value.open_positions_count,
        avgWeightedPnlPercent: value.avg_weighted_pnl_percent,


      }
    })
  }

  const chartData = {
    datasets:
      Object.entries(data).map(([model, dataObject], index) => {

        return {
          data: getData(dataObject),
          borderColor: pick_colors[index],
          backgroundColor: `${pick_colors[index]}90`,
          label: model.split('_')[1],
          fill: false,
          type: 'line',
          radius: 0,
          //  hoverRadius: 15,
          borderWidth: 2.5,
          segment: modesArray[mode] === 'Total Balance' && {
            borderColor: (ctx) => {
              const { p0, p1 } = ctx;
              if (p1.raw.forceDifference !== 0) {
                return p1.parsed.y > p0.parsed.y ? styles.default.colorGreen : styles.default.colorRed;
              }
            }
          },

          fill: modesArray[mode] === 'Cumulative PnL' && Object.entries(data).length === 1 && {
            target: {
              value: 0,
            },
            below: `${styles.default.colorRed}90`,
            above: `${styles.default.colorGreen}90`,
          }

        }
      })
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          callback: function (value) {
            const date = new Date(value);
            const timeString = format(date, 'HH:mm');
            const dateString = format(date, 'dd.MM.yyyy');
            return [timeString, dateString];
          },
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value, index, ticks) {
            const precision = calculatePrecisionForUniqueTicks(ticks);
            return (
              (modesArray[mode] === 'Cumulative PnL' || modesArray[mode] === 'Free Balance (%)' || modesArray[mode] === 'Avg weighted PnL')
                ? value.toFixed(2) + '%'
                : parseFloat(value).toFixed(precision) + ' BTC'
            )



          }
        },

        title: {
          //display: true,
          text: modesArray[mode],

          align: 'center'
        }
      }
    },
    plugins: {
      tooltip: {
        mode: chartData.datasets.length > 1 ? "nearest" : 'index',
        //mode: 'index',
        backgroundColor: function (context) {
          const datasetIndex = context.tooltip.dataPoints[0].datasetIndex;
          return pick_colors[datasetIndex]
        },
        displayColors: false,
        callbacks: {
          label: function (context) {
            const {
              totalBalance,
              freeBalance,
              forceDifference,
              pnl,
              avgWeightedPnlPercent,
              openPositionsCount
            } = context.raw;

            return modesArray[mode] === 'Avg weighted PnL'
              ? [
                `Model: ${context.dataset.label}`,
                `Avg weighted PnL percent: ${avgWeightedPnlPercent}%`,
                `Open positions count: ${openPositionsCount}`
              ].filter(el => el)
              : [
                `Model: ${context.dataset.label}`,
                `Total balance: ${totalBalance} BTC`,
                `Free balance: ${freeBalance} BTC`,
                `Manual change: ${forceDifference} BTC`,
                modesArray[mode] === 'Cumulative PnL' && `PnL: ${pnl}%`

              ].filter(el => el);

          },
          title: function (context) {

            const { updateTime } = context[0].raw;

            return formatTimestemp(updateTime);
          },
        }
      },

      title: {
        display: true,
        align: 'start',
        text: getTableTitle(), // TODO

        color: '#333333',
      },
      legend: {
        display: true,

        align: 'end',
        labels: {
          usePointStyle: true,
        }
      },

    }
  };

  return (
    <div className="flexColumnGap5" style={{ gap: 0 }}>
      <ChartTabs
        mode={mode}
        setMode={setMode}
        modesArray={modesArray}
        tooltips={tooltipModelsBalancesChart}
      />

      <div>
        <LineChart
          chartData={chartData}
          options={chartOptions}
          showZeroLine={true}
          zeroLineColor={styles.default.colorDarkGray}
        />
      </div>
    </div>


  )
}

export default ModelsBalancesChart;