import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { convertModelToObject, getFilledModel } from "../../utils/functions";
import { useAccountAddMutation } from "../../redux/statisticsServices/accountsApi";
import { useNavigate } from "react-router-dom";
import { encryptKey } from "../../utils/crypto";
import AccountForm from "../../components/AccountForm";
import { account__model } from "../../components/FormModels/formConfig";
import IsSpinnerBlur from "../../components/IsSpinnerBlur";

export function AccountsAddNewPage() {
    const [model, setModel] = useState([]);
    const navigate = useNavigate();

    const [accountAdd, { data: accountAddData, isSuccess }] = useAccountAddMutation();
    const [isLoading, setIsLoading] = useState(false);

    const isSandbox = useMemo(() => model.find(el => el.key === 'is_sandbox')?.value, [model]);

    useEffect(() => {
            const newModel = isSandbox 
            ?  getFilledModel(model, account__model).map(el => {
                switch(el.key) {
                    case 'trading_bot_url': 
                        return {
                            ...el, 
                            show: false,
                            value: 'sandbox'
                        };

                    case 'api_key': 
                    case 'api_secret': 
                    case 'public_key': 
                        return {
                            ...el, 
                            show: false,
                            value: ''
                        };
                
                    default: 
                        return el;     
                }
            }) 
            : getFilledModel(model, account__model).map(el => {
                switch(el.key) {
                    case 'trading_bot_url': 
                        return {
                            ...el, 
                            value: ''
                        };
                
                    default: 
                        return el;     
                }
            }) 

            setModel(newModel)
       
        
    }, [isSandbox])

    const handleSubmit = async () => {

        const accountData = convertModelToObject(model);
        // const emptyFieldsAllowed = ['trading_bot_url', 'api_key', 'api_secret', 'public_key'];
        const emptyFieldsAllowed = isSandbox 
            ? [ 'trading_bot_url' , 'api_key', 'api_secret', 'public_key']
            : ['trading_bot_url'];
        const filteredEntries = Object.entries(accountData).filter(([key, value]) => {
            return !emptyFieldsAllowed.includes(key) && value.length < 1;
        });


        if (filteredEntries.length > 0) {
            const missingFields = filteredEntries.map(subArray => subArray[0]).join(', ');
            toast(`Required fields are not filled: ${missingFields}`);
            return;
        }

        const { is_sandbox, public_key, ...restOfData } = accountData;
        const json = {
                ...restOfData,
                api_key: await encryptKey(accountData.public_key, accountData.api_key),
                api_secret: await encryptKey(accountData.public_key, accountData.api_secret)
            }


        try {

            setIsLoading(true)

            await accountAdd(json).unwrap();
            toast.success("Account added successfully");

        } catch (error) {
            console.error("Account add error", error);
            toast.error("Account add error", error.message);
            //alert("Account add error", error);
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (isSuccess) {
            toast("Account added");
            navigate(`/accounts/account/${accountAddData.account_id}`)
        }
    }, [isSuccess, accountAddData]);


    return (
        <div className="itemPage" >
            <h2>Add Account</h2>
            <div className="formContainer">
                <AccountForm
                    model={model}
                    setModel={setModel}
                />

            </div>

            <div className="formContainer">
                <button
                    className="button widthFull"
                    onClick={handleSubmit}
                >
                    Create
                </button>
            </div>

            {isLoading && <IsSpinnerBlur />}

        </div>
    )
}