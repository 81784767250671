import React from "react";
import CommingSoon from "../CommingSoon";

const InputSave = ({ value, setValue, title, titleClass, commonClass, handleSave, type, options = [], isSoon=false, disabled = false}) => {

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const displayValue = value ? value : '';

    return (
        <label className={commonClass}>
            {isSoon ? (
                <CommingSoon maxWidth={true} top={-12} right={-15}>
                     <div className={titleClass}>{title}</div>
                </CommingSoon>
            ) : (
                 <span className={titleClass}>{title}</span>
            )}
           
            <div className="flexGap10">
                {type === 'select' ? (
                    <select
                        className="input"
                        value={displayValue}
                        onChange={(e) => handleChange(e)}
                        disabled={disabled}
                    >
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input
                        className="input"
                        value={displayValue}
                        onChange={(e) => handleChange(e)}
                        type={type}
                    />
                )}
                <button 
                    className="button buttonSave" 
                    onClick={handleSave} 
                    disabled={disabled} 
                >
                    Save
                </button>
            </div>
        </label>
    );
};

export default InputSave;
