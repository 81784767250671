import { deleteToken } from '../../utils/auth';
import { STATISTICS_API_BASE_URL, STATISTICS_API_URLS } from './statistics_api_urls';

class StatisticsAPI {
    static URLS = STATISTICS_API_URLS;
    static BASE_URL = STATISTICS_API_BASE_URL;

    static formatUrl(url) {
        return `${this.BASE_URL}${url}`;
    }

    static async login(username, password) {
        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const response = await fetch(StatisticsAPI.formatUrl(this.URLS.login), {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            const data = await response.json();

            return `${data.token_type} ${data.access_token}`;
        } catch (error) {
            const errorMessage = `Error during login: ${error.message}`;
            throw new Error(errorMessage);
        }
    }

    static async logout() {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': token
            };

            const response = await fetch(StatisticsAPI.formatUrl(this.URLS.logout), {
                method: 'POST',
                headers: headers
            });

            if (!response.ok) {
                throw new Error('Failed to logout');
            }

            const data = await response.json();
            console.log(data);

            deleteToken();
            return true;
        } catch (error) {
            console.error('Error during login:', error.message);
            return null;
        }
    }
}

export default StatisticsAPI;
