import FormModel from "../FormModels/FormModel"

const NewPositionForm = ({ model, setModel, handleSubmit}) => {
    return (
        <div className="saveWarning widthFull">
            <div className="saveWarning__title txt20x27">
                New Position
            </div>
            <FormModel inputModels={model} setInputModels={setModel} />
            <button 
                className="button widthFull"
                onClick={handleSubmit}
            >
                Open Position
            </button>
        </div>
    )
}

export default NewPositionForm