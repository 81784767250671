import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import merge from 'lodash.merge';
import useTabletView from '../../hooks/useTabletView';
import useMobileView from '../../hooks/useMobileView';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';

export const getModifiedData = (dataArray = [], value) => {
  return dataArray.map(data => ({
    strategy: data.strategy.split('_')[1],
    total_value: Math.abs(data[value]),
    original_value: data[value]
  }))
    .sort((a, b) => {

      if (a.original_value > 0 && b.original_value > 0) {
        return b.total_value - a.total_value;
      }

      if (a.original_value < 0 && b.original_value < 0) {
        return a.total_value - b.total_value;
      }

      return b.original_value - a.original_value;
    });
}

ChartJS.register(
  BarElement,
  ChartDataLabels,

);

const BarChartHorizontal = ({ data, options, tooltip = null }) => {

  const getValueDependingView = useValueDependingOnView()


  const isTablet = useTabletView();
  const isMobile = useMobileView();
  const chartRef = useRef(null);

  const defaultOptions = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: 'y', // Switch x and y axes for horizontal bar chart
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
      },
      title: {
        display: true,
        align: 'start',
        color: '#333',
        font: {
          size: isTablet ? 16 : 20,
          lineHeigth: '27px',
          weigth: 500,
        }
      },
      datalabels: {
        anchor: 'center',
        align: 'end',
        color: '#555',
        font: {
          weight: 'bold'
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: isMobile ? false : true,
          align: 'center',
          font: {
            size: 16,
            weight: '600',
          }
        },

        ticks: {
          maxTicksLimit: isMobile ? 5 : 10,
          font: {
            size: getValueDependingView(12, 10, 8),
          }

        }
      },
      y: {
        title: {
          display: true,
          font: {
            size: 16,
            weight: '600',
          }
        },

        ticks: {
          mirror: isTablet,
          font: {
            size: isTablet ? 8 : 12,
          }

        }
      }
    },
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = chartRef.current;
      const numberOfBars = data.datasets[0].data.length;
      const chartHeight = isMobile ? numberOfBars * 30 + 100 : numberOfBars * 40 + 200;


      chartInstance.canvas.parentNode.style.height = `${chartHeight}px`;

    }
  }, [data]);

  const mergedOptions = merge({}, defaultOptions, options);

  return (
    <div className="backgroundContainer" style={{ position: 'relative' }}>
      {tooltip && data && tooltip}
      {data ? (
        <Bar ref={chartRef} data={data} options={mergedOptions} />
      ) : (
        <>
          {mergedOptions?.plugins?.title?.display && (
            <div className='table__title'>
              {Array.isArray(mergedOptions?.plugins?.title?.text) ? mergedOptions?.plugins?.title?.text[0] : mergedOptions?.plugins?.title?.text}
            </div>
          )}
          <div style={{ textAlign: 'center', padding: '50px', fontSize: '20px' }}>
            No data
          </div>
        </>
      )}
    </div>
  );
};

export default BarChartHorizontal;

