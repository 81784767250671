import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import useUrlFilters from '../../hooks/useUrlFilters';
import PageFilters from '../PageFilters';
import { getFilteredArray } from '../../utils/functions';

const PageFiltersLayout = ({ filterOptions, setVisibleItems, defaultFiler, data, children }) => {

    const location = useLocation();

    const [filters, setFilters] = useState([]);
    useUrlFilters(filters, setFilters, filterOptions);

    useEffect(() => {
        if (location.search.length === 0) {
            setFilters([{ id: defaultFiler.key, value: defaultFiler.value }])
        }
    }, [])

    useEffect(() => {
        if (data) {
            setVisibleItems(getFilteredArray(data, filters))
        }

    }, [filters, data])

    return (
        <>
          
                <PageFilters filters={filters} setFilters={setFilters} filterOptions={filterOptions} notAll={true} />
           

            {children}
        </>
    )
}

export default PageFiltersLayout;
