const IsFetching = ({ height = null}) => {
    return (
         <div className="isFetching" style={{ height: height && height}}>
            <div className="isFetching__item"></div>
            <div className="isFetching__item"></div>
            <div className="isFetching__item"></div>
            <div className="isFetching__item"></div>
            <div className="isFetching__item"></div>
        </div>
    )
    
}

export default IsFetching;
