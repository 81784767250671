import { classnames } from "../../../utils/functions";
import Icon from "../../Icon";
import Dropdown from "../../Inputs/dropdown/Dropdown";

const SelectBox = ({ input, update, onFocus }) => {

    const handleDelete = (e, item) => {
        e.stopPropagation()
        update(input, [...input.value.filter(el => el !== item)]);
    }

    return (
        <div className={classnames('formModel__item', {'formModel__item--error': input.error})}> 
              {input.error ? `${input.title} *`: input.title}
            <Dropdown
                input={input} 
                update={(value) => update(input, input.value ? [...input.value, value] : [value])}
                renderSpan={() => (
                    input.value && input.value.length > 0  
                        ? <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap'}}>
                            {input.value.map(item => {
                                return (
                                    <button 
                                        className="dropdown__toggle__title__item" 
                                        onClick={(e) => handleDelete(e, item)}
                                        key={item}
                                    >
                                        <span>{item}</span>
                                        <Icon type="close"/>
                                    </button>
                                    )
                                })}
                            </div> 
                     
                        : input.title
                       
                )}
                values={input.values}
                notClickClose={true}   
                onFocus={onFocus}
            />
        </div>
    )
}

export default SelectBox;