import { useEffect, useMemo } from "react";

import { useLocation } from "react-router-dom";
import BacktestListTable from "../../components/Backtests/BacktestListTable";
import IsFetching from "../../components/IsFetching";
import { statusEnum } from "../../components/MainLayouts/BacktestLayout";

import { useFetchBacktestsQuery } from "../../redux/statisticsServices/backtestsApi";

const BacktestsCalculated = () => {

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const { data: backtestsData, isFetching: isFetchingBacktests, refetch } = useFetchBacktestsQuery();

    useEffect(() => {
        if(searchParams.get('runningBT')) {
        refetch();
        }

  }, [])
     
    const calculatedBacktestData = useMemo(() => {
        
        if (!backtestsData) return [];
     
        return backtestsData.filter(backtest => statusEnum[backtest.status] === 'Finished').sort((a, b) => b.finish_timestamp - a.finish_timestamp);
      }, [backtestsData]);

      if (isFetchingBacktests ) {
        return <IsFetching />
    }
    return (

        <BacktestListTable
          data={calculatedBacktestData} 
          title={'Calculated Backtests'} 
          calculatedBacktests={true}
          highlightRow={searchParams.get('runningBT') ? {
            key: 'backtest_id',
            value: searchParams.get('runningBT')
          } : null}
        />
    )
}

export default BacktestsCalculated;