import { AnimatePresence, motion } from "framer-motion";
import { tooltipVariants } from "../utils/animations";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const TooltipWithoutButton = ({ text, isVisible, setIsVisible, maxWidth, parentRef, parentWidth = 60, heightShift = 50 }) => {
    const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });
    const tooltipWithoutButtonRef = useRef(null);
    
    useEffect(() => {
        if (isVisible && tooltipWithoutButtonRef.current && parentRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const tooltipRect = tooltipWithoutButtonRef.current.getBoundingClientRect();
            
            const calculatedLeft = parentRect.left + (parentRect.width / 2) - (tooltipRect.width / 2);
            const calculatedTop = parentRect.top + window.scrollY - tooltipRect.height + heightShift;
            
            const viewportWidth = window.innerWidth;
            const overflowRight = Math.max(0, (calculatedLeft + tooltipRect.width) - viewportWidth);
            const overflowLeft = Math.max(0, -calculatedLeft); 
            
            tooltipWithoutButtonRef.current.style.left = `${calculatedLeft + overflowLeft - overflowRight}px`;
            tooltipWithoutButtonRef.current.style.top = `${calculatedTop}px`;
        }
    }, [isVisible, parentRef, heightShift, window.innerWidth]);
    
    

    useEffect(() => {
        const handleTouchStart = (event) => {
            if (!tooltipWithoutButtonRef.current.contains(event.target)) {
                setIsVisible(null);
            }
        };

        if (isVisible) {
            document.addEventListener('touchstart', handleTouchStart);
        }

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
        };
    }, [isVisible]);

    return (
        <>
            {isVisible && (
                createPortal(
                    <AnimatePresence>
                        <motion.div
                            ref={tooltipWithoutButtonRef}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={tooltipVariants}
                            className="tooltip__body tooltip__body--withoutCaret txt14x21"
                            style={{ maxWidth: maxWidth }}
                        >
                            {text}
                        </motion.div>
                    </AnimatePresence>, document.body
                )
            )}
        </>
    )
}

export default TooltipWithoutButton;
