import React, { useEffect, useMemo, useState } from 'react';

import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import IsFetching from '../IsFetching';
import MultiRangeSlider from '../Inputs/MultiRangeSlider';
import useMobileView from '../../hooks/useMobileView';
import BubbleChartD3 from './BubbleChartD3';
import Tooltip from '../Tooltip';
import useTabletView from '../../hooks/useTabletView';
import ChartTabs from './ChartTabs';

const BubbleChart = ({ data, allowedModes  }) => {
    const modesArray = ['PnL', 'Position count'];

    const [mode, setMode] = useState(0)

    const isTablet = useTabletView();

    const isMobile = useMobileView();

    const [pnlRange, setPnlRange] = useState({ min: 0, max: 0 });
    const [filteredData, setFilteredData] = useState([]);

    const maxPnl = useMemo(() => modesArray[mode] === 'PnL' ? data && Math.max(...data.map(d => d.avg_pnl_percent || d.percent_pnl)) : data && Math.max(...data.map(d => d.total_position_count)), [mode, data])  ;
    const minPnl = useMemo(() => modesArray[mode] === 'PnL' ? data && Math.min(...data.map(d => d.avg_pnl_percent || d.percent_pnl)) : data && Math.min(...data.map(d => d.total_position_count)), [mode, data]) ;

    useEffect(() => {
        if (data) {

            setPnlRange({
                min: minPnl,
                max: maxPnl,
            })
        }

    }, [data, mode]);

    useEffect(() => {
        const filteredDataArray = modesArray[mode] === 'PnL' 
        ? data?.filter(el => (el.avg_pnl_percent ||  el.percent_pnl) >= pnlRange.min && (el.avg_pnl_percent ||  el.percent_pnl) <= (pnlRange.max + 0.01))
        : data?.filter(el => (el.total_position_count) >= pnlRange.min && (el.total_position_count) <= (pnlRange.max))

        setFilteredData(filteredDataArray)
    }, [data, pnlRange, mode])


    if (data === undefined) {
        return <IsFetching />
    }

    const chartD3Data = filteredData.map(el => ({
        label: el.pair.split('/')[0],
        value: modesArray[mode] === 'PnL' ? el.avg_pnl_percent || el.percent_pnl : el.total_position_count

    }))

    return (
        <div className="flexColumnGap5" style={{ gap: 0 }}>
            {allowedModes && (
                <ChartTabs
                    mode={mode}
                    setMode={setMode}
                    modesArray={modesArray}
                />
            )}
          
            <div className="backgroundContainer">
                {data.length !== 0 ? (
                    <>
                        <div className='table__title'>

                            <span style={{ position: 'relative' }}>Pairs PnL</span>

                                {!isMobile &&  <Tooltip text='PnL всіх закритих угод по парах' maxWidth={300} style={{ left: isTablet ? -20 : -50 }} />}
                                {data.length > 1 ? (
                                    <MultiRangeSlider 
                                        step={modesArray[mode] === 'PnL' ? 0.01 : 1} 
                                        min={minPnl} 
                                        max={+maxPnl} 
                                        value={pnlRange} 
                                        onChange={setPnlRange} 
                                        modesArray={modesArray}
                                        mode={mode}
                                    />
                                ) : (
                                    <div style={{ width: '80%'}}/>
                                ) }
                        </div>

                        {/* <Bubble ref={chartRef} data={chartData} options={mergedOptions} /> */}
                        <BubbleChartD3
                            data={chartD3Data}
                            modesArray={modesArray}
                            mode={mode}
                        />
                    </>

                ) : (
                    <>
                    
                            <div className='table__title'>
                                <span style={{ position: 'relative' }}>Pairs PnL</span>
                            </div>
                    
                        <div style={{ textAlign: 'center', padding: '50px', fontSize: '20px' }}>
                            No data
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BubbleChart;
