import React, { useEffect, useState } from 'react';

const ToggleSwitch = ({ value, setValue }) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(value);
    }, [value]);

    const toggleSwitch = () => {
        setIsChecked(!isChecked);
        setValue(!isChecked)
    };

    return (
        <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleSwitch;
