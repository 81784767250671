import DoughnutChart from "../Charts/DoughnutChart";

import { pick_colors_doughnut } from "../../utils/variables";
import { hexToRgb } from "../../utils/functions";

const brightenedColors = pick_colors_doughnut.map(hex => {
  let color = hexToRgb(hex);
  return `rgba(${color.r + 30}, ${color.g + 30}, ${color.b + 30}, 1)`; 
});

const UnusedBalanceDoughnutChart = ({ data }) => {
    const chartData = {
        labels: data.map(d => d.label),
        datasets: [
          {
            data: data.map(d => d.amount), 
            backgroundColor: pick_colors_doughnut,
            hoverBackgroundColor: brightenedColors,
          }
        ]
      };
    const options = {
        plugins: {
            title: {
                text: 'Unused Balance', 
              },

              tooltip: {
                callbacks: {
                    label: function (context) {
        
                    return `${context.raw} %`
                  },
                }

                
              }
        }
    }
    return (
        <DoughnutChart options={options} data={chartData}/>
    )
}

export default UnusedBalanceDoughnutChart;