import { useContext, useMemo } from "react";

import IsFetching from "../../components/IsFetching";

import BacktestListTable from "../../components/Backtests/BacktestListTable";
import { statusEnum } from "../../components/MainLayouts/BacktestLayout";
import { BacktestsContext } from "../../contexts/BacktestsContext";

const BacktestsError = () => {
    const { 
        backtestsData,
        isFetchingBacktests,
      } = useContext(BacktestsContext)
    
    const errorBacktestData = useMemo(() => {
     
        if (!backtestsData) return [];
    
        return backtestsData.filter(backtest => statusEnum[backtest.status] === 'Error').sort((a, b) => b.launch_timestamp - a.launch_timestamp);
     }, [backtestsData]);
   
     if (isFetchingBacktests ) {
         return <IsFetching />
     }
   
    
    return (
       
            <BacktestListTable
              data={errorBacktestData} 
              title={'Error Backtests'} 
              errorBacktest={true}
           />
    
    )
}

export default BacktestsError;