
import { useLocation } from "react-router-dom";
import SubHeaderTwoMenuItem from "./SubHeaderTwoMenuItem";
import { useEffect, useMemo } from "react";
import useMobileView from "../hooks/useMobileView";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';

import { Pagination } from 'swiper/modules';
import 'swiper/scss/pagination';
import useScrollDirection from "../hooks/ useScrollDirection";
import { motion, useAnimation } from "framer-motion";
import * as styles from '../styles/utils/_variables.scss';


const SubHeaderTwo = ({ 
    menuItem, 
    linkList, 
    withSearchParams = false, 
    withSlug = false, 
    currentPathIndex = 2,
    extraPart = null,
}) => {

    const location = useLocation(); 
    const isMobile = useMobileView();

    const currentSearch = location.search;
    const slug = useMemo(() => {
        const locationPathnameArray = location.pathname.split('/');
        return withSlug ? `${locationPathnameArray[3]}` : '';
    }, [location.pathname, withSlug]);

    const scrollDirection = useScrollDirection(); 

    const controls = useAnimation(); 

    useEffect(() => {
        if (isMobile) {
        
            controls.start({
                top: scrollDirection === 'up' ? styles.default.headerHeight : '0',
            });
        } else {
        
            controls.start({
                top: styles.default.headerHeight
            });
        }
    }, [scrollDirection, controls, isMobile]);

    return (
        <motion.div 
            className="subHeaderTwo"
            initial={{top:  styles.default.headerHeight }} 
            animate={controls} 
            transition={{ type: "tween", duration: 0.1, ease: "easeInOut" }}
        >
            <div className="section">
                <div className={'flexSpaceBetween'} style={{ width: '100%'}}>
                    <div 
                        className="subHeaderTwo__main"
                       
                    >
                        {isMobile ? (
                            <Swiper
                                modules={[Pagination]}
                                slidesPerView={2}
                                spaceBetween={40}
                                pagination={{ clickable: true }}
                                style={{height: '100%', width: 'calc(100vw - 40px)'}}
                                
                            >
                                {linkList.map(link => {
                                    const fullPath = `${menuItem.path}/${withSlug ? link.path.replace(':backtestId', slug) : link.path}${withSearchParams ? currentSearch : ''}`;
                                    return (
                                        <SwiperSlide key={link.path} style={{display: 'flex', justifyContent: 'center'}}>
                                            <SubHeaderTwoMenuItem link={link} path={fullPath} currentPathIndex={currentPathIndex} />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        ) : (
                            linkList.map(link => {
                                const fullPath = `${menuItem.path}/${withSlug ? link.path.replace(':backtestId', slug) : link.path}${withSearchParams ? currentSearch : ''}`;
                                return (
                                    <SubHeaderTwoMenuItem key={link.path} link={link} path={fullPath} currentPathIndex={currentPathIndex} />
                                );
                            })
                        )}
                    </div>
                        {extraPart && extraPart()}
                    
                  
                </div>
            </div>
        </motion.div>
    );
}

export default SubHeaderTwo;