import { useLocation, useParams } from "react-router-dom"
import IsFetching from "../../components/IsFetching";
import { useFetchPositionCandlesQuery, useFetchPositionQuery, useFetchPositionTradesQuery, useClosePositionMutation, useLazyFetchPositionQuery, useLazyFetchPositionTradesQuery, useLazyFetchPositionCandlesQuery, useSavePositionBuyMoreMutation } from "../../redux/statisticsServices/positionsApi";
import Properties from "../../components/Properties";
import { formatAssetOrPercent, getProperty } from "../Settings";
import { useEffect, useState } from "react";
import SaveWarning from "../../components/SaveWarning";
import { convertModelToObject, formatFloat } from "../../utils/functions";
import CandlestickChartWithVolume from "../../components/Charts/CandlestickChartWithVolume";
import { getCustomLink } from "./StatisticsGeneralPage";
import { toast } from "react-toastify";
import { multiBuy__model } from "../../components/FormModels/formConfig";
import FormModel from "../../components/FormModels/FormModel";
import { accountsBlackList } from "../../utils/variables";
import Modal from "../../components/Modal";
import useMobileView from "../../hooks/useMobileView";
import BackButton from "../../components/Buttons/BackButton";

export function StatisticsPositionPage() {
    const location = useLocation();
    const { positionId } = useParams();
    const [isModal, setIsModal] = useState(false);
    const [currentSaveFunction, setCurrentSaveFunction] = useState(null);

    const { data: positionData, isFetching: isFetchingPosition, isError: isErrorPosition } = useFetchPositionQuery(positionId);
    const [triggerFetchPosition] = useLazyFetchPositionQuery();

    const { data: positionTradesData, isFetching: isFetchingPositionTrades, isError: isErrorPositionTrades } = useFetchPositionTradesQuery(positionId);
    const [triggerFetchPositionTrades] = useLazyFetchPositionTradesQuery();

    const { data: positionCandlesData, isFetching: isFetchingPositionCandles, isError: isErrorPositionCandles } = useFetchPositionCandlesQuery(positionId);
    const [triggerFetchPositionCandles] = useLazyFetchPositionCandlesQuery();

    const [closePosition] = useClosePositionMutation();

    const [buyMore] = useSavePositionBuyMoreMutation();
    const [multiBuyModel, setMultiBuyModel] = useState([]);

    

    const isMobile = useMobileView();

    useEffect(() => {
        setMultiBuyModel(multiBuy__model)
    }, [])

    const searchParams = new URLSearchParams(location.search);


    if (isFetchingPosition || isFetchingPositionTrades || isFetchingPositionCandles) {
        return (
            <IsFetching />
        )
    }


    if (isErrorPosition || isErrorPositionTrades || isErrorPositionCandles) {
        return (
            <div>NO DATA</div>
        )
    }

    localStorage.setItem('positionStatus', positionData.is_closed ? 1 : 0);
    
    const handleClosePosition = async () => {
        try {
            await closePosition({ positionId }).unwrap();
            triggerFetchPosition(positionId);
            triggerFetchPositionTrades(positionId);
            toast.success('The position was closed successfully')

            setTimeout(() => {
                triggerFetchPositionCandles(positionId)
            }, 60000)

        } catch (error) {
            console.error('Failed to close position:', error);
            toast.error('Failed to close position')
            //alert("Error, when closing position");
        } finally {
            handleCloseModal(false);
        }
    };

    const handleBuyMore = async () => {
        const convertedMultiBuyModeld = convertModelToObject(multiBuyModel);
        if (convertedMultiBuyModeld.qty_multiplicator < 0.5) {
            toast('Enter proper value for Quantity multiplicator(must be greater or equal then 0.5)');
            return;
        }

        try {
            await buyMore({ positionId, qtyMultiplicator: convertedMultiBuyModeld.qty_multiplicator }).unwrap();
            toast.success('Additional buy completed successfully');

            setTimeout(() => {
                triggerFetchPosition(positionId)
            }, 60000)
        } catch (error) {
            console.error('Failed to Additional buy position:', error);
            toast.error('Failed to Additional buy position')
        }
    }

    const handleOpenModal = (saveFunction) => {
        setCurrentSaveFunction(() => saveFunction);
        setIsModal(true);
    };

    const handleCloseModal = () => {
        setCurrentSaveFunction(() => null);
        setIsModal(false);
    };

    let formatedData = {}
    const hiddenRows = ['commission_loss']
    for (const [key, value] of Object.entries(positionData)) {
        if (hiddenRows.includes(key)) continue;

        if (key.includes("base")) formatedData[key] = value === positionData["base_asset"] ? value : `${formatFloat(value)} ${positionData["base_asset"]}`;
        else if (key.includes("quote")) formatedData[key] = value === positionData["quote_asset"] ? value : `${formatFloat(value)} ${positionData["quote_asset"]}`;
        else if (key.includes("commission_r") || key.includes("spend")) {
            formatedData[key] = formatAssetOrPercent(key, value, 'BTC');
        }

        else if (key === 'pair') {
            formatedData[key] = getCustomLink(searchParams, key, value, 'pairs');
        }

        else if (key === 'strategy') {
            formatedData[key] = getCustomLink(searchParams, key, value, 'strategies');
        }

        else formatedData[key] = getProperty(key, value);
    }

    const trade_settings = {
        "position_id": "Ідентифікатор позиції",
        "pair": "Торгова пара",
        "model": "По якій моделі відкрито позицію",
        "base_asset": "Актив, який торгується",
        "quote_asset": "Актив, до якого торгується",
        "position_start_timestamp": "Час відкриття позиції",
        "position_end_timestamp": "Час закриття позиції",
        "position_duration": "Тривалість позиції",
        "buy_base_qty": "Скільки куплено активу, який торгується",
        "buy_quote_qty": "Скільки було витрачено, щоб купити актив, який торгується",
        "sell_quote_qty": "За скільки було продано актив, який торгується",
        "sell_base_qty": "Скільки продано активу, який торгується",
        "buy_average_price": "Середня ціна покупки активу, який торгується",
        "buy_min_price": "Найменша ціна покупки активу, який торгується",
        "buy_max_price": "Найвища ціна покупки активу, який торгується",
        "sell_average_price": "Середня ціна продажу активу, який торгується",
        "sell_min_price": "Найменша ціна продажу активу, який торгується",
        "sell_max_price": "Найвища ціна продажу активу, який торгується",
        "trades_count": "Кількість трейдів (BUY+SELL)",
        "buy_trades_count": "Кількість трейдів на покупку",
        "sell_trades_count": "Кількість трейдів на продаж",
        "commission_real": "Скільки витрачено на комісію",
        "pnl": "PnL в абсолютному значенні",
        "percent_pnl": "PnL у відсотках",
        "avg_commission_percent": "Cерeдній відсоток, витрачений на комісію",
        "lowest_price": "Найнижча можлива ціна в цій позиції",
        "highest_price": "Найвища можлива ціна в цій позиції",
        "is_closed": "Чи закрита позиція",
        "position_spend": "Витрати на дану позицію (сума, на яку купили актив + комісія на покупку)"
    }

    const  userName = localStorage.getItem('username');

    return (
        <>
            <div className="titleMargin" >
                <BackButton path={`/statistics/positions?${searchParams.toString()}`} />
               
            </div>
            <div className="itemPage">
                <Properties data={positionData} formatedData={formatedData} trade_settings={trade_settings} />

                <CandlestickChartWithVolume data={{
                    candles: positionCandlesData,
                    trades: positionTradesData
                }}
                />

                {!positionData.is_closed && (
                    <div className="gridTwoColumn">

                        
                            <div className="widthFull">
                                {!accountsBlackList.includes(userName )  && (
                                    <FormModel 
                                        inputModels={multiBuyModel} 
                                        setInputModels={setMultiBuyModel}
                                        saveFunctions={{
                                            qty_multiplicator: () => handleBuyMore(),

                                        }}
                                    />
                                )}    
                            </div>
                            <button
                                className="button  button--disable widthFull"
                                onClick={() => handleOpenModal(() => handleClosePosition())}
                                style={{ height: 35 }}
                            >
                                Close Position
                            </button>
                    </div>

                )}
            </div>

            <Modal
                isModal={isModal}
                handleModal={handleCloseModal}
                content={
                    <SaveWarning noClick={handleCloseModal} yesClick={currentSaveFunction} title={`Close position: ${positionId}?`} />
                }
            />
        </>
    )
}
