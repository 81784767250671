
// const STATISTICS_API_BASE_URL = process.env.REACT_APP_STATISTICS_API_BASE_URL  // || "https://stattest.obscurial.org";
//const STATISTICS_API_BASE_URL = "http://0.0.0.0:8085";
const STATISTICS_API_BASE_URL = "https://stattest.obscurial.org";

const STATISTICS_API_URLS = {
    login: "/login",
    logout: "/logout",
    user_accounts: "/user/accounts",

    ml_models: "/ml-models",
    ml_model_stats: "/ml-models/{model_name}/stats",
    ml_models_ml_model_versions: "/ml-models/{ml_name}/versions",
    ml_model_version: "/ml-models/{model_name}/version/{model_version}",
    ml_model_version_backtests: "/ml-models/{model_name}/version/{model_version}/backtests",
    ml_models_ml_model_version_backtest_list: "/ml-models/{ml_name}/version/{ml_version}/backtest_list",

    strategies: "/strategies",
    strategies_stats: "/strategies/stats",
    strategy_pairs_stats: "/strategies/{strategy_name}/pairs/stats",
    strategies_balances: "/strategies/balances",

    set_strategy_position_quantity: "/strategies/{strategy_name}/position_quantity",

    set_strategy_status: "/strategies/{strategy_name}/proc_status",
    set_strategy_balance: "/strategies/{strategy_name}/balance",

    set_strategy_pairs_position_quantity: "/strategies/{strategy_name}/pairs/{pair}/position_quantity",
    set_strategy_pairs_canTrade: "/strategies/{strategy_name}/pairs/{pair}/can_trade",

    positions: "/positions",
    positions_closed: "/positions/closed",
    positions_open: "/positions/open/lite",
    positions_position: "/positions/{position_id}",
    positions_position_trades: "/positions/{position_id}/trades",
    positions_position_candles: "/positions/{position_id}/candles",
    close_position: "/positions/{position_id}/close",
    close_all_strategy_positions: "/positions/exit_all",
    positions_position_buy_more: "/positions/{position_id}/buy_more",


    pairs: "/pairs",
    pairs_stats: "/pairs/stats",
    pairs_pair_candles: "/pairs/{pair}/candles",
    pairs_blacklist: "/pairs/blacklist",

    trades: "/trades",

    accounts: "/accounts",
    account: "/accounts/{account_id}",
    accounts_account_free_balance: "/accounts/{account_id}/free_balance",
    accounts_account_tb_url: "/accounts/{account_id}/tb_url",

    accounts_account_statistic_general: "/accounts/{account_id}/statistic/general",
    accounts_account_total_balance: "/accounts/{account_id}/total_balance",
    accounts_account_commission_trade: "/accounts/{account_id}/commission/trade",
    accounts_account_id_commission_balances: "/accounts/{account_id}/commission_balances",

    backtest: "/backtests/{backtest_id}",
    backtest_stats: "/backtests/{backtest_id}/stats",
    backtest_pairs_stats: "/backtests/{backtest_id}/pairs/stats",
    backtest_positions: "/backtests/{backtest_id}/positions",
    backtest_trades: "/backtests/{backtest_id}/trades",
    backtest_run: "/backtests",
    backtests: "/backtests",
    backtest_cancel_task: "/backtests/{backtest_id}/cancel_task",
    backtest_set_high_priority: "/backtests/{backtest_id}/set_high_priority",
    backtests_stats: "/backtests/stats",
    backtests_backtest_trades_distribution: "/backtests/{backtest_id}/trades/distribution",
    backtests_backtest_status: "/backtests/{backtest_id}/status"


};

export { STATISTICS_API_BASE_URL, STATISTICS_API_URLS };