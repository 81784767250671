import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { STATUSES } from '../../utils/enams';

import { useFetchMlModelsQuery } from '../../redux/statisticsServices/mlModelsApi';
import IsFetching from '../../components/IsFetching';
import PageFiltersLayout from '../../components/PageLoyouts/PageFiltersLayout';
import * as styles from '../../styles/utils/_variables.scss';
import { accountsWhiteList } from '../../utils/variables';
import Button from '../../components/Buttons/Button';

export function MlStatistics() {
    const navigate = useNavigate();

    const { data, isFetching } = useFetchMlModelsQuery();

    const [visibleModels, setVisibleModels] = useState([]);

    const filterOptions = {
        status: {
            type: 0,
            values: STATUSES,
        },
    }

    if (isFetching) {
        return (
            <IsFetching />
        )
    }

    const getTrainingStatus = (status) => {
        switch (status) {
            case 0:
                return "Training on";

            default:
                return "Training off"
        }
    }

    function transformString(str) {
        return str.replace(/([a-z])([A-Z])/g, (match, p1, p2, offset, string) => {
            const before = string.slice(0, offset + 1); // part before the split point
            const after = string.slice(offset + 1); // part after the split point

            if (before.length > 5 && after.length > 5) {
                return `${p1}<br />${p2}`;
            }
            return match;
        });
    }

    const  userName = localStorage.getItem('username');

    const handleAddNewMLModel = () => {
        navigate('addNew')
    }

    return (
        <div className='itemPage'>
                <h1 className="titleMargin flexSpaceBetween">
                    Models

                    {accountsWhiteList.includes(userName) && (
                        <Button
                            onClick={handleAddNewMLModel}
                        >
                            + Add new
                        </Button>
                    )}
                    
                </h1>
                <PageFiltersLayout
                    filterOptions={filterOptions}
                    setVisibleItems={setVisibleModels}
                    defaultFiler={{ key: 'status', value: STATUSES.ACTIVE }}
                    data={data}
                >

                    <div className="mlStatistics__container">

                        {visibleModels.map(({ name, status }, i) => {
                            const trainStatusLabel = getTrainingStatus(status);
                            return (

                                <Link key={`${name}${i}`} to={`ml_model/${name}`}>
                                    <button className="button mlStatistics__container__button">
                                        <div className='labeltag txt14x21' style={{ background: trainStatusLabel === "Training on" ? styles.default.colorGreen : styles.default.colorRed }}>
                                            {trainStatusLabel}
                                        </div>
                                        <div className="textOverflowEllipsis ">
                                            <span dangerouslySetInnerHTML={{ __html: transformString(name) }} />
                                        </div>

                                        {name === 'Market Regimes' && (
                                            <div className='txt14x21 labeltag--additional'>
                                                support model
                                            </div>
                                        )}
                                    </button>
                                </Link>

                            )
                        })}

                    </div>

                </PageFiltersLayout>
                {/*<div className="mlStatistics__container">*/}

                {/*    <Link to={`eda_correlation`} >*/}
                {/*        <h1>More info:</h1>*/}
                {/*        <button className="button pageButton">Research EDA correlation </button>*/}
                {/*    </Link>*/}
                {/*</div>*/}


        </div>
    )
}