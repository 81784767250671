import { LuLogOut } from "react-icons/lu";
import { BiSolidUpArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiArrowDownSLine } from "react-icons/ri";
import { RiArrowUpSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa6";
import { FaStop } from "react-icons/fa6";
import { TbProgressDown } from "react-icons/tb";
import { TbCircleCheck } from "react-icons/tb";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { TbFileTypeCsv } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { BiArrowFromBottom } from "react-icons/bi";
import { FaScaleBalanced } from "react-icons/fa6";
import { LuDownloadCloud } from "react-icons/lu";
import { PiGraphBold } from "react-icons/pi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { LiaFlaskSolid } from "react-icons/lia";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { MdOutlineCandlestickChart } from "react-icons/md";
import { TbForbid2 } from "react-icons/tb";
import { GiBubbles } from "react-icons/gi";
import { SiBinance } from "react-icons/si";
import { FaExclamation } from "react-icons/fa";
import { LiaTrashAlt } from "react-icons/lia";
import { LiaClone } from "react-icons/lia";
import { TbRefresh } from "react-icons/tb";

import * as styles from '../styles/utils/_variables.scss';

const Icon = ({ type, size, className = null }) => {
  console.log(className)
  const icon = {
    logout: (<LuLogOut size={size} />),
    up: (<BiSolidUpArrow size={size}/>),
    down: (<BiSolidDownArrow size={size}/>),
    burger: (<RxHamburgerMenu size={size}/>),
    dropdownUp: (<RiArrowUpSLine />),
    dropdownDown: (<RiArrowDownSLine size={size}/>),
    arrowLeft: (<RiArrowLeftSLine size={size}/>),
    arrowRight: (<RiArrowRightSLine size={size}/>),
    close: (<IoCloseSharp size={size} className={className}/>),
    start: (<FaPlay color="#fff" />),
    pause: (<FaPause color="#fff" />),
    disable: (<FaStop color="#fff" />),
    inProcess: (<TbProgressDown size={size}/>), 
    done: (<TbCircleCheck size={size} />),
    expand: (<FaExpandArrowsAlt size={size} />),
    info: (<BsInfoCircle size={size} color={styles.default.colorBlue} />),
    downloadCSV: (<TbFileTypeCsv size={size}/>),
    settings: (<IoSettingsOutline size={size} />),
    high_priority: (<BiArrowFromBottom size={size} className={className} />),
    scale: (<FaScaleBalanced size={size} color="#fff" />),
    refresh: (<LuDownloadCloud size={size} className={className} color="#fff" />),
    algorithm: (<PiGraphBold  size={size} />),
    accounts: (<MdOutlineManageAccounts size={size}/>),
    test: (<LiaFlaskSolid size={size}/>),
    repair: (<MdOutlineSettingsSuggest size={size}/>),
    chart: (<MdOutlineCandlestickChart size={size}/>),
    forbid: (<TbForbid2 size={size}/>),
    bubbles: (<GiBubbles size={size}/>),
    binance: (<SiBinance size={size} />),
    exclamation: (<FaExclamation size={size} />),
    trash: (<LiaTrashAlt size={size} color="#fff"/>),
    copy: (<LiaClone size={size} color="#fff"/>),
    refreshTwoArrow: (<TbRefresh size={size} color="#fff" className={className} />),
    
  }

  return icon[type]
}

export default Icon;