export const pick_colors = [   
    "#3d5a80",
    "#ee6c4d",
    "#98c1d9",
    "#293241",
    "#845EC2",
    "#D65DB1",
    "#FF6F91",
    "#FF9671",
    "#FFC75F",
    "#F9F871",
    "#845E05",
    "#556519",
    "#286338",
    "#005D52",
    "#09535D",
    "#2F4858",
    "#84AA0E",
    "#369E47",
    "#008B68",
    "#007677",
    "#005F70",
    "#2F4858",
    "#A2AA0E",
    "#57A041",
    "#008E63",
    "#007874",
    "#006070",
    "#2F4858",
];

export const pick_colors_doughnut = [   
    "#1a6ee9",
    "#e896ad",
    "#98c1d9",
    "#293241",
    "#845EC2",
    "#D65DB1",
    "#FF6F91",
    "#FF9671",
    "#FFC75F",
    "#F9F871",
    "#845E05",
    "#556519",
    "#286338",
    "#005D52",
    "#09535D",
    "#2F4858",
    "#84AA0E",
    "#369E47",
    "#008B68",
    "#007677",
    "#005F70",
    "#2F4858",
    "#A2AA0E",
    "#57A041",
    "#008E63",
    "#007874",
    "#006070",
    "#2F4858",
];

export const blacklist_criterions = {
    "criterion_1": {
        "name": "price_btc_to_usdt",
        "threshold": 0.17,
        "symbol": ">="
    },
    "criterion_2": {
        "name": "cnt_transaction_24h",
        "threshold": 1000,
        "symbol": "<"
    },
    "criterion_4": {
        "name": "zero_candles_volume_ratio",
        "threshold": 78,
        "symbol": ">="
    },
    "criterion_5": {
        "name": "change_btc_vol_hour",
        "threshold": 8,
        "symbol": ">="
    },
    "criterion_6": {
        "name": "volume_24h",
        "threshold": 4,
        "symbol": "<"
    },
    "criterion_7": {
        "name": "diff_usdt_btc_vol",
        "threshold": 17,
        "symbol": ">="
    },
}

export const timeFrames = ['1s', '1m', '5m', '15m', '1h', '4h', '12h', '1d'];

export const accountsBlackList = ['Main'];
export const accountsWhiteList = ['SuperTrader', 'ServiceTrader'];

export const startLinkIndex = 4;

