import { useContext, useEffect, useMemo, useState } from "react";
import MainSettingsChange from "../../components/PortfolioBacktestForm/MainSettingsChange";
import { useFetchAssetsFromFilesQuery, useFetchListOfModelsQuery, useFetchListOfParquetsQuery, useLazyFetchStrategyControlsByNameQuery } from "../../redux/portfolioBacktestServices/defaultApi";
import IsFetching from '../../components/IsFetching';
import MainSettings from "../../components/PortfolioBacktestForm/MainSettings";
import BallanceControl from "../../components/PortfolioBacktestForm/BallanceControl";
import ModelsControls from "../../components/PortfolioBacktestForm/ModelsControls";
import { convertModelToObject, filledInputs, formValidation } from "../../utils/functions";
import { format, parse } from "date-fns";
import BlacklistSettings from "../../components/PortfolioBacktestForm/BlacklistSettings";
import { useBacktestRunMutation, useFetchBacktestByIdQuery } from "../../redux/statisticsServices/backtestsApi";

import { useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/Buttons/BackButton";
import { isBacktestOnlyMode } from "../../App";
import { toast } from "react-toastify";
import useTabletView from "../../hooks/useTabletView";
import Button from "../../components/Buttons/Button";
import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";
import { ballanceControl__model, blacklistSettings__model, mainSettings__model, modelsControls__model} from "../../components/FormModels/formConfig";

export function AddBacktestPage() {
    const [mainSettingsModel, setMainSettingsModel] = useState([]);
    const [mainSettingsChangeModel, setMainSettingsChangeModel] = useState([]);

    const [ballanceControlModel, setBallanceControlModel] = useState([]);
    const [modelsControlsModel, setModelsControlsModel] = useState([]);
    const [blacklistSettingsModel, setBlacklistSettingsModel] = useState([]);
    const [searchParams] = useSearchParams();
    const currentBacktest = searchParams.get('backtest');

    const {
        backtestByIdData,
    } = useContext(BacktestResultsContext);

    const { data: backtestByIdDataFetch, isFetching: isFetchingBacktestById } = useFetchBacktestByIdQuery(currentBacktest, {
        skip: !currentBacktest || (currentBacktest && backtestByIdData),
    });

    const currentBacktestData = currentBacktest ? backtestByIdData || backtestByIdDataFetch : null;

    const { data: listOfParquetsData, isFetching: isFetchingListOfParquets } = useFetchListOfParquetsQuery();
    const { data: assetsFromFilesData, isFetching: isFetchingAssetsFromFiles } = useFetchAssetsFromFilesQuery();
    const { data: listOfModelsData, isFetching: isFetchingListOfModels } = useFetchListOfModelsQuery();
    console.log(listOfModelsData)
    const [backtestRun, { data: backtestRunData, loading }] = useBacktestRunMutation();

    const [triggerFetchModelControls, { data: modelControlsData, isFetching: isFetchingModelControls, isError: isErrorModelControls }] = useLazyFetchStrategyControlsByNameQuery();

    const navigate = useNavigate();

    const modelName = useMemo(() => modelsControlsModel.find(el => el.key === 'strategy')?.value, [modelsControlsModel]);

    const timeFrame = useMemo(() => mainSettingsModel.find(el => el.key === 'time_frame')?.value, [mainSettingsModel]);

    const mainAsset = useMemo(() => mainSettingsChangeModel.find(el => el.key === 'main_asset')?.value, [mainSettingsChangeModel]);

    const isTablet = useTabletView()

    useEffect(() => {
        if (!listOfModelsData) return;
        if(currentBacktestData) {
            const { configuration, backtest_id, assets, ...rest} = currentBacktestData;
           
            const configObject =  Object.assign({...rest, },  ...Object.values(configuration)); 
            const { period } = configObject;

            const testing_start_date = parse(period?.[0], 'yyyyMMdd', new Date());
            const testing_end_date = parse(period?.[1], 'yyyyMMdd', new Date());

            const mainSettingsModelFilled = filledInputs(mainSettings__model, {...configObject, testing_start_date, testing_end_date} );
            const ballanceControlModelFilled = filledInputs(ballanceControl__model, configObject );
            const modelsControlsModelFilled = filledInputs( modelsControls__model, configObject );
            const blacklistSettingsModelFilled = filledInputs( blacklistSettings__model, configObject );
           
           
            setMainSettingsModel(mainSettingsModelFilled)
            setBallanceControlModel(ballanceControlModelFilled)
            setModelsControlsModel(modelsControlsModelFilled.map(el => el.key === 'strategy' 
                ? {...el, values: Object.values(listOfModelsData)}
                : {el}
            ))
            setBlacklistSettingsModel(blacklistSettingsModelFilled)
        }

    }, [currentBacktestData, listOfModelsData])

    

    useEffect(() => {
        if (modelName) {

            const modelKeyValue =  listOfModelsData[modelName]
            triggerFetchModelControls(modelKeyValue);

        }
    }, [modelName])

    useEffect(() => {
        setMainSettingsModel(prev => {
            const newSettings = prev.map(el => el.key === 'backtest_id'
                ? { ...el, placeholder: `${modelName}(${format(new Date(), 'yyy-MM-dd_HH:mm:ss')})[${timeFrame}][${mainAsset}]` }
                : el);
            return newSettings;
        });
    }, [modelName, timeFrame, mainAsset])


    useEffect(() => {
        if (backtestRunData) {
            if (!isBacktestOnlyMode) {

                navigate(`/backtests/inProgress?runningBT=${backtestRunData.backtest_id}`)
                //window.open(`/backtests/backtestId/${backtestRunData.backtest_id}/general`, '_blank  ')
            }
        }

    }, [backtestRunData])

    if (isFetchingListOfParquets || isFetchingAssetsFromFiles || isFetchingListOfModels) {
        return (
            <IsFetching />
        )
    }

    const handleSubmit = async () => {

        const isValidateError = formValidation(modelsControlsModel, setModelsControlsModel);

        if(isValidateError) {
            toast.error("Not all fields are filled in");
            return;
        }

        const settings = {
            ...convertModelToObject(mainSettingsChangeModel),
            ...convertModelToObject(mainSettingsModel),
        }

        const {
            backtest_id,
            calculate_estimate,
            parquet_filename,
            time_frame,
            testing_start_date,
            testing_end_date,
            pairs_list,
            blacklist_of_pairs,
            select_symbol,
            selection_of_pairs,
            list_of_backtests,
            common_cash,
            high_priority,
            main_asset
        } = settings;

        const { strategy, ...rest } = convertModelToObject(modelsControlsModel)
        const { slippage } = convertModelToObject(ballanceControlModel)

        const getAssets = () => {
            switch (selection_of_pairs) {
                case 'One Pair':
                    return [select_symbol];

                case 'Whitelist':
                    return (list_of_backtests && list_of_backtests.length !== 0) ? list_of_backtests : assetsFromFilesData[parquet_filename];

                default:
                    return assetsFromFilesData[pairs_list].filter(el => blacklist_of_pairs ? !blacklist_of_pairs.includes(el) : el)
            }
        }

        const assets = getAssets()

        if (!assets || assets.some(el => !el)) {
            alert(`Empty pairs list for selected data. Create pairs list for [${parquet_filename}]`);
            return;
        }

        const json = {
            backtest_id: backtest_id.length > 0
                ? `${backtest_id}_${modelName}(${format(new Date(), 'yyy-MM-dd_HH:mm:ss')})[${timeFrame}][${mainAsset}]`
                : `${modelName}(${format(new Date(), 'yyy-MM-dd_HH:mm:ss')})[${timeFrame}][${mainAsset}]`,
            high_priority,
            calculate_estimate,
            model: strategy,
            main_asset,
            configuration: {
                api_params: {
                    parquet_filename,
                    strategy,
                    time_frame,
                    period: [
                        format(new Date(testing_start_date), 'yyyyMMdd'),
                        format(new Date(testing_end_date), 'yyyyMMdd'),
                    ],
                    assets,
                    main_asset,
                },

                portfolio_params: {
                    ...convertModelToObject(ballanceControlModel),
                    common_cash: common_cash || false,

                },
                strategy_params: {
                    ...rest
                },
                blacklist: {
                    ...convertModelToObject(blacklistSettingsModel)
                }
            }

        }

        //console.log(json)

        try {
            if (isBacktestOnlyMode) {
                window.open(`/backtests/backtestId/${json.backtest_id}/general`, '_blank')
            }

            await backtestRun(json).unwrap();
            toast.success("Backtest run!")

        } catch (error) {
            console.error("Backtest run error", error);
            toast.error("Backtest run error", error.message)
            //alert("Backtest run error", error);
        }
    }

    return (
        <div className="itemPage" style={{ marginTop: isTablet ? -10 : -20 }}>
            <div className="flexSpaceBetween" >
                <BackButton path={'/backtests'} />
                <Button
                    disabled={isErrorModelControls || isFetchingModelControls || (loading && !backtestRunData)}
                    onClick={handleSubmit}
                >
                    Backtest
                </Button>
            </div>

            <div
                className="formPage"
            >
                <ModelsControls
                    model={modelsControlsModel}
                    setModel={setModelsControlsModel}
                    listOfModels={listOfModelsData}
                    modelControlsData={modelControlsData}
                    isFetchingModelControls={isFetchingModelControls}
                    isErrorModelControls={isErrorModelControls}
                />

                <MainSettings
                    model={mainSettingsModel}
                    setModel={setMainSettingsModel}
                />

                <BallanceControl
                    model={ballanceControlModel}
                    setModel={setBallanceControlModel}
                    mainAsset={mainAsset}

                />

                <MainSettingsChange
                    listOfParquetsData={listOfParquetsData}
                    assetsFromFilesData={assetsFromFilesData}
                    model={mainSettingsChangeModel}
                    setModel={setMainSettingsChangeModel}
                    timeFrame={timeFrame}
                    mainAsset={mainAsset}
                />



                <BlacklistSettings
                    model={blacklistSettingsModel}
                    setModel={setBlacklistSettingsModel}
                />


            </div>

        </div>

    )
}