import { formatTimestemp, formatTimestempUTC } from "../../utils/functions"
import * as styles from '../../styles/utils/_variables.scss';
import { format } from "date-fns";
import LineChart from "../Charts/LineChart";

const FreeBalanceChart = ({ data, startBalance, mainAsset }) => {
    const chartData = {
        datasets: [
            {
                data: data.free_balance.map(el => {

                    return {
                        x: el.timestamp,
                        y: el.free_balance,
                    }

                }),

                label: 'Free Balance',
                fill: false,
                type: 'line',
                radius: 0,
                borderColor: styles.default.colorDarkGray,
                backgroundColor: 'transparent',
                hoverRadius: 5,
                borderWidth: 2,
            },
        ]
    }

    const chartOptions = {
        scales: {
            x: {
                type: 'time',
                title: {
                    text: 'Datetime',
                },
                time: {
                    tooltipFormat: 'HH:mm dd.MM.yyyy',
                    displayFormats: {
                        hour: 'HH:mm dd.MM.yyyy'
                    }
                },
                adapters: {
                    date: {
                        zone: 'utc',
                    }
                },
                ticks: {
                    maxTicksLimit: 10,
                    callback: function (value) {
                        return formatTimestempUTC(value).split(" ")
                    },
                }
            },
            y: {
                //max: startBalance,
                title: {
                    text: 'Free Balance',
                },
                ticks: {
                    callback: function (value) {
                        return `${(value * 100).toFixed(2)} %`
                    }
                },
                min: 0
            }
        },
        plugins: {
            tooltip: {
                intersect: false,
                displayColors: false,
                callbacks: {
                    title: function (context) {
                        const x = context[0].raw.x

                        return `Time: ${formatTimestempUTC(x)}`
                    },

                    label: function (context) {
                        const { y } = context.raw;

                        return `${context.dataset.label} ${(y * 100).toFixed(2)} %`
                    }
                }
            },
            title: {
                text: `Free balance Chart`,
            },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'line'
                }
            }
        }
    };


    return (
        <LineChart
            chartData={data.length === 0 ? null : chartData}
            options={chartOptions}
            showZeroLine={true}
            zeroLineValue={startBalance}
            zeroLineColor={styles.default.colorDarkGray}
        />
    )
}

export default FreeBalanceChart;