import { Link } from "react-router-dom";
import { getProperty } from "../../pages/Settings";
import { formatEnamKey, formatTimestempUTCTable } from "../../utils/functions";
import Table from "../Table/Table";
import { useContext } from "react";
import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";

const PositionsTableBacktest = ({ data = [], generalLink = null }) => {

    const {
        mainAsset,
    } = useContext(BacktestResultsContext)

    const tableData = data.map(el => {
        const {
            pair,
            buy_base_qty,
            position_start_timestamp,
            buy_average_price,
            buy_quote_qty,
            position_end_timestamp,
            sell_base_qty,
            sell_quote_qty,
            sell_average_price,
            commission,
            percent_pnl,
            pnl,
            is_closed,
            position_duration

        } = el;

        return {
            pair,
            position_start_timestamp,
            buy_base_qty,
            buy_quote_qty,
            buy_average_price,
            position_end_timestamp,
            sell_base_qty,
            sell_quote_qty,
            sell_average_price,
            commission,
            percent_pnl,
            pnl,
            is_closed,
            position_duration
        }
    });

    const tableColumns = tableData[0] && Object.keys(tableData[0]).map(key => ({
        accessorKey: key,
        header: key === 'strategy' ? 'Model' : formatEnamKey(key.replace('timestamp', '')),
        filterFn:  'myCustomFilter',
        filterable: !(key === 'is_closed'),
        sortable: true,

        cell: info => {
            const value = info.getValue();

            if (key === 'pair') {
                return generalLink ? (
                    <div className="link">
                        <Link to={`${generalLink}/pairs?pair=${value}`} >
                            {value}
                        </Link>
                    </div>

                ) : (
                    <div>{value}</div>
                )
            }
            else if (key.endsWith('timestamp')) {
                return formatTimestempUTCTable(value);
            }

            return getProperty(key, value, mainAsset)
        }
    }))

    return (
        <Table
            tableData={tableData.length === 0 ? null : tableData}
            tableColumns={tableColumns}
            headerItemWidth={140}
            title={`Positions (limit 1000)`} // TODO
            csvData={tableData}
        />
    )
}

export default PositionsTableBacktest;