const InfoItem = ({ inputKey, value}) => {
    
    return (
        <>
            <div className="txt16x24upper">
                {inputKey}
            </div>
            <div className="txt24x27 bold">
                {Array.isArray(value) ? (
                    <>
                         {value.map((el, index) => (
                            <div key={index}>{el}</div> 
                        ))}
                    </>
                ) : (
                    value
                )}
               
            </div>
        </>
    )
}

export default InfoItem;