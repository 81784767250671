import { useEffect } from "react";
import FormModel from "./FormModels/FormModel";
import { account__model } from "./FormModels/formConfig";

const AccountForm = ({ model, setModel, saveFunctions}) => {

    useEffect(() => {
        setModel(account__model)
    }, []);

    return (
        <FormModel
            inputModels={model} 
            setInputModels={setModel}
            saveFunctions={saveFunctions}
        />
      
    )
}

export default AccountForm;