import React, { useContext, useEffect, useRef} from 'react';
import Header from '../Header';
import { Outlet } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion'
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ToggleSidebarButton from '../Buttons/ToggleSidebarButton';
import useMobileView from '../../hooks/useMobileView';
import { SidebarContext} from '../../contexts/SidebarContext';
import useScrollDirection from '../../hooks/ useScrollDirection';
import * as styles from '../../styles/utils/_variables.scss';

const TwoColumnLayout = ({ leftContent, filter = null }) => {

    const isMobile = useMobileView();

    const { isOpen, setIsOpen } = useContext(SidebarContext);

    const scrollDirection = useScrollDirection(); 

    const controls = useAnimation(); 

    useEffect(() => {
        controls.start({
            top: scrollDirection === 'up' ? styles.default.headerHeight : '0',
            x: isOpen ? '0%' : '-100%' 
        });
    }, [scrollDirection, isOpen, controls]);

   

    const rightPanelRef = useRef(null);

    const handleOpen = () => {
        if(isMobile) {
            setIsOpen(!isOpen)
        }
    }

    useOnClickOutside(rightPanelRef, () => setIsOpen(false))

    return (
       <>
            <Header />
                <div className="twoColumnLayout">
                
                {isMobile? (
                        <>
                         <motion.div 
                            ref={rightPanelRef}
                            className="scrolling"
                            initial={{ x: '-100%', top:  styles.default.headerHeight }} 
                            animate={controls} 
                            transition={{ type: "tween", duration: 0.1, ease: "easeInOut" }}
                        >
                            
                            {leftContent}
                         
                        </motion.div>
  {/* <ToggleSidebarButton handleOpen={handleOpen} isOpen={isOpen} /> */}
                        </>
                       

                    
                ) : (
                    <div className="scrolling"  >
                   
                        {leftContent}
                    </div>
                )}
                {!isOpen && isMobile && (
                    <ToggleSidebarButton handleOpen={handleOpen} isOpen={isOpen} scrollDirection={scrollDirection}/>
                )}
            
                     {filter}
                
                    
                    <div className='page'>
                           
                       
                      
                            <div className="section section--mini">
                                <Outlet />
                            </div>
                    
                    </div>
                
            </div>
       </>
         
           
 
            
        
    );
};

export default TwoColumnLayout;
