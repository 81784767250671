import portfolioBacktestApi from './portfolioBacktestApi';
import { PORTFOLIO_BACKTEST_URLS } from '../../api/portfolio_backtest_api/portfolio_backtest_api_urls';
import { validateResponse } from '../statisticsServices/statisticsApi';
import { buildQueryString } from '../../utils/functions';


export const defaultApi =  portfolioBacktestApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchListOfParquets: builder.query({
      query: () => PORTFOLIO_BACKTEST_URLS.list_of_parquets,
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'Parquets', id: 'Parquets_LIST'},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "Parquets")
      },
    }),

    fetchAssetsFromFiles: builder.query({
        query: () => PORTFOLIO_BACKTEST_URLS.assets_from_files,
        providesTags:
        (result = []) => [
          //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
          { type:  'AssetsFromFiles', id: 'AssetsFromFiles_LIST'},
        ],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          validateResponse(dispatch, queryFulfilled, "AssetsFromFiles")
        },
    }),

    fetchListOfModels: builder.query({
        query: () => PORTFOLIO_BACKTEST_URLS.list_of_models,
        providesTags:
        (result = []) => [
          //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
          { type:  'ListOfModels', id: 'ListOfModels_LIST'},
        ],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          validateResponse(dispatch, queryFulfilled, "ListOfModels")
        },
    }),

    fetchStrategyControlsByName: builder.query({
      query: (strategyName) => PORTFOLIO_BACKTEST_URLS.get_strategy_controls_by_name.replace('{model_id}', strategyName),
      providesTags:
      (result = []) => [
        //...result.map(({ name, version }) => ({ type: 'MLModels', id: `${name}_${version}`})),
        { type:  'StrategyControls', id: 'StrategyControls_LIST'},
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, "StrategyControls")
      },
    }),

    fetchParquetPairCandles: builder.query({
      query: ({ pair, filters }) => `${PORTFOLIO_BACKTEST_URLS.candles_pair.replace("{pair}", pair && pair.replace('/', '_'))}${buildQueryString(filters)}` ,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'ParquetPairCandles', id: `ParquetPairCandles_Pair${result?.[0]?.pair}_LIST` },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'ParquetPairCandle')
      },
    }),

    fetchDataFromGoogleStorage: builder.query({
      query: () => PORTFOLIO_BACKTEST_URLS.download_data_from_google_storage,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'DataFromGoogleStorage', id: 'DataFromGoogleStorage' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'DataFromGoogleStorage')
      },
    }),

  }),

  overrideExisting: false,
});

export const {
    useFetchListOfParquetsQuery,
    useFetchAssetsFromFilesQuery,
    useFetchListOfModelsQuery,
    useLazyFetchStrategyControlsByNameQuery,
    useLazyFetchParquetPairCandlesQuery,
    useLazyFetchDataFromGoogleStorageQuery,
} = defaultApi;