import { useCallback } from "react";

const Checkbox = ({ input, update }) => {

    const handleChange = useCallback((e) => {
        const newValue = e.target.value;
        const newValues = input.value.includes(newValue)
            ? input.value.filter(el => el !== newValue)
            : [...input.value, newValue];
        update(input, newValues);
    }, [input, update]);


    return (
        <div className="formModel__item">
            {input.title}
            <div className="flexGap20 checkbox">
                {input.values.map(value => {
                    return (
                        <label key={value}>
                            <input 
                                name={input.key}
                                type="checkbox"
                                value={value}
                                id={value}
                                checked={input.value.includes(value)}
                                onChange={handleChange}
                                hidden
                            />
                            <div className="checkbox__label"/>
                            <span>{value}</span>
                        </label>
                    )
                })}
            </div> 
        </div>
    )
}

export default Checkbox;