import statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'
import { buildQueryString } from '../../utils/functions';


export const accountsApi = statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccounts: builder.query({
      query: () => STATISTICS_API_URLS.accounts,
     
      providesTags: (result = []) => [
        // result.map(account => ({ type: 'Accounts', id: account.account_id })),
        { type: 'Accounts', id: 'Accounts_LIST' },
      ],

      
      async onQueryStarted(_, { dispatch, queryFulfilled }) {

        validateResponse(dispatch, queryFulfilled, 'Accounts')
      },
    }),

    fetchAccount: builder.query({
      query: (accountId) => STATISTICS_API_URLS.account.replace("{account_id}", accountId),
      providesTags: (result = [], error, { accountId }) => [
        { type: 'Account', id: accountId },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'Accounts')
      },
    }),

    fetchAccountsStatisticGeneral: builder.query({
      query: ({ filters, accountId }) => `${STATISTICS_API_URLS.accounts_account_statistic_general.replace("{account_id}", accountId)}${buildQueryString(filters)}`,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'AccountsStatisticGeneral', id: 'AccountsStatisticGeneral_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'AccountsStatisticGeneral')
      },
    }),

    fetchAccountsTotalBalance: builder.query({
      query: ({ filters, accountId }) => `${STATISTICS_API_URLS.accounts_account_total_balance.replace("{account_id}", accountId)}${buildQueryString(filters)}`,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'AccountsTotalBalance', id: 'AccountsTotalBalance_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'AccountsTotalBalance')
      },
    }),

    accountAdd: builder.mutation({
      query: (body) => ({
        url: STATISTICS_API_URLS.accounts,
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'UserAccounts');
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchUserAccount', undefined, (draft) => {

            if (!draft || !draft.accounts) {

              draft = [arg.account_id];
            } else {
              draft.push(arg.account_id);
            }

          })
        );
        try {
          await queryFulfilled;
        } catch {
          console.error(`Error while account ${arg.account_id} add`);
          patchResult.undo()
        }
      },
    }),

    updateAccountFreeBalance: builder.mutation({
      query: ({ accountId, freeBalance }) => ({
        url: STATISTICS_API_URLS.accounts_account_free_balance.replace("{account_id}", accountId) + `?free_balance=${freeBalance}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ accountId, freeBalance }, { dispatch, queryFulfilled }) {

        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchAccount', accountId, (draft) => {

            draft.free_balance = freeBalance;

          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Account')
        } catch {
          patchResult.undo()
        }
      },
    }),

    updateAccountTradingBotUrl: builder.mutation({
      query: ({ accountId, tradingBotUrl }) => ({
        url: STATISTICS_API_URLS.accounts_account_tb_url.replace("{account_id}", accountId) + `?trading_bot_url=${tradingBotUrl}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ accountId, tradingBotUrl }, { dispatch, queryFulfilled }) {

        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchAccount', accountId, (draft) => {

            draft.trading_bot_url = tradingBotUrl;

          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Account')
        } catch {
          patchResult.undo()
        }
      },
    }),

    setAccountCommissionTrade: builder.mutation({
      query: ({ accountId, commissionBalance }) => ({
        url: STATISTICS_API_URLS.accounts_account_commission_trade.replace("{account_id}", accountId) + `?change_commission_balance_qty=${commissionBalance}`,
        method: 'POST',
      }),
      async onQueryStarted({ accountId, commissionBalance }, { dispatch, queryFulfilled }) {

        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchAccount', accountId, (draft) => {

            if (draft.commission_balance) {
              draft.commission_balance += commissionBalance;
            } else {
              draft.commission_balance = commissionBalance; 
            }
          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Account')
        } catch {
          patchResult.undo()
        }
      },
    }),

    fetchAccountCommissionBalance: builder.query({
      query: ({ filters, accountId }) => `${STATISTICS_API_URLS.accounts_account_id_commission_balances.replace("{account_id}", accountId)}${buildQueryString(filters)}`,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'AccountCommissionBalance', id: 'AccountCommissionBalance_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'AccountCommissionBalance')
      },
    }),

  }),

  overrideExisting: false,
});

export const {
  useFetchAccountsQuery,
  useFetchAccountQuery,
  useLazyFetchAccountsStatisticGeneralQuery,
  useLazyFetchAccountsTotalBalanceQuery,
  useAccountAddMutation,
  useUpdateAccountFreeBalanceMutation,
  useUpdateAccountTradingBotUrlMutation,
  useSetAccountCommissionTradeMutation,
  useLazyFetchAccountCommissionBalanceQuery,

} = accountsApi;