import IsFetching from "../IsFetching";
import PairPnlChart from "./ PairPnlChart";
import GeneralPositionsStatsTable from "./GeneralPositionsStatsTable";
import PositionsTable from "./PositionsTable";

const PositionsTab = ({ data, isOpen = false, positionsTableClick, IsFetching = false }) => {
    const aggregatedData = data?.reduce((acc, { pair, percent_pnl, buy_quote_qty }) => {
        if (acc[pair]) {
          acc[pair].totalWeightedPnl += percent_pnl * buy_quote_qty;
          acc[pair].totalQty += buy_quote_qty;
        } else {
          acc[pair] = {
            pair,
            totalWeightedPnl: percent_pnl * buy_quote_qty,
            totalQty: buy_quote_qty,
          };
        }
        return acc;
      }, {});

      const result = aggregatedData && Object.values(aggregatedData).map(({ pair, totalWeightedPnl, totalQty }) => ({
        pair,
        avg_pnl_percent: totalWeightedPnl / totalQty
      }));


    return (
        <div  className="itemPage">
            <GeneralPositionsStatsTable 
                data={data} 
                positionsTableClick={positionsTableClick}
                isOpen={isOpen}
            />

            <PositionsTable
                data={data} 
                title={isOpen ? 'Position Open (if we close them now)' : 'Position Closed'} 
                positionsTableClick={positionsTableClick}
                isOpen={isOpen}
            />

                 {IsFetching ? (
                    <IsFetching height={200} />
                ) : (
                    <>
                        <PairPnlChart pairsStatsData={result} allowedModes={false}/>
                    </>

                )}
               

        </div>
        
    )
}

export default PositionsTab;