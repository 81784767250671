import React, { useMemo, useState, useRef } from 'react';
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
    getFacetedUniqueValues,
    getFacetedRowModel
} from '@tanstack/react-table';
import Icon from '../Icon'

import TablePagination from './TablePagination';
import { formatDuration, formatEnamKey, formatTimestemp } from '../../utils/functions';
import HeaderRow from './HeaderRow';
import TableBody from './TableBody';

import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
    arrayMove,
    SortableContext,
    horizontalListSortingStrategy,
} from '@dnd-kit/sortable'

import DraggableTableHeader from './DraggableTableHeader'
import DragAlongCell from './DragAlongCell'
import { useVirtualizer } from '@tanstack/react-virtual';
import { backgroundItem } from '../../utils/variables';
import { getPropertyString } from '../../pages/Settings';



const Table = ({
    tableData,
    title,
    tableColumns,
    headerItemWidth,
    extraRow = null,
    trade_settings = {},
    csvData = null,
    tooltipKey = null,
    keyBold = [],
    highlightRow = null,
    textAlignCenterKeys = [],
    enableDragAndDrop = false,
    mainHeight = 660,
    isPinned = true
}) => {

    const tableRef = useRef(null);
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);

    const data = useMemo(() => tableData, [tableData]);

    const columns = useMemo(() => tableColumns, [tableColumns]);

    const [columnOrder, setColumnOrder] = useState(() =>
        columns?.map(c => c.id || 'defaultId')
    );

    const table = useReactTable({
        data,
        columns,
        filterFns:  {
            myCustomFilter: (rows, columnIds, filterValue) => {
                const cellValue = getPropertyString(columnIds, rows.getValue(columnIds)) ;
                return cellValue?.toString().toLowerCase().includes(filterValue.toLowerCase());
        }},
        state: {
            sorting,
            columnFilters,
            columnOrder,
        },
        // initialState: {
        //     pagination: {
        //       pageSize,
        //     },
        //   },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        //getPaginationRowModel: getPaginationRowModel(),

        onColumnOrderChange: setColumnOrder,
        columnResizeMode: 'onChange',
        columnResizeDirection: 'ltr',
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
    });

    function handleDragEnd(event) {
        if (!enableDragAndDrop) return;
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldIndex = columnOrder.indexOf(active.id);
            const newIndex = columnOrder.indexOf(over.id);

            if (newIndex === 0) {
                return;
            }

            setColumnOrder(columnOrder => arrayMove(columnOrder, oldIndex, newIndex));
        }
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor)
    );


    const downloadCSV = () => {

        const headers = Object.keys(csvData[0]);

        const getValue = (key, value) => {
            switch (true) {
                case key.includes('timestamp'):
                    return formatTimestemp(value);

                case key.includes('price'):
                    return `${value} BTC`;

                case key.includes('duration'):
                    return formatDuration(value);

                default:
                    return value
            }
        }

        const rows = csvData.map(row =>
            headers.map(header => {
                const value = row[header];

                const formatedValue = getValue(header, value);
                return value ? `"${formatedValue.toString().replace(/"/g, '""')}"` : "";
            }).join(',')
        );

        const csvContent = [headers.map(header => formatEnamKey(header)).join(','), ...rows].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleSortingChange = updater => {
        setSorting(updater)
        if (!!table.getRowModel().rows.length) {
            rowVirtualizer.scrollToIndex?.(0)
        }
    }

    table.setOptions(prev => ({
        ...prev,
        onSortingChange: handleSortingChange,
    }))


    const rowVirtualizer = useVirtualizer({
        count: tableData && table.getRowModel().rows?.length,
        getScrollElement: () => tableRef.current,
        estimateSize: () => 40,
        overscan: 5,
    });


    return (
        <div className="table backgroundContainer">

            {title && (
                <div className="table__title">
                    {title}
                    {tableData !== null && csvData && (
                        <button onClick={downloadCSV} className="dropdown__toggle">
                            <Icon type="downloadCSV" size={25} />
                        </button>
                    )}
                </div>
            )}
            {tableData === null ? (
                <div style={{ textAlign: 'center', padding: '50px', fontSize: '20px' }}>
                    No data
                </div>
            ) : (
                <>
                    <div
                        className="table__scrollContainer scrolling"
                        ref={tableRef}
                        style={{ height: table.getRowModel().rows.length <= 15 ? 'max-content' : mainHeight, border: '1px solid rgb(207, 214, 228)', borderRadius: 5 }}
                    >

                        {enableDragAndDrop ? (
                            <DndContext
                                collisionDetection={closestCenter}
                                modifiers={[restrictToHorizontalAxis]}
                                onDragEnd={handleDragEnd}
                                sensors={sensors}
                            >

                                <table className="table__main" {...{
                                    style: {
                                        width: table.getCenterTotalSize(),
                                        //borderSpacing: 2
                                        borderLeft: 'none'
                                    },
                                }}>
                                    <thead
                                        className="table__head"
                                        style={{ display: "table-header-group" }}
                                    >
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id}>
                                                <SortableContext
                                                    items={columnOrder}
                                                    strategy={horizontalListSortingStrategy}
                                                >
                                                    {headerGroup.headers.map((header, i) => (
                                                        <DraggableTableHeader key={header.id} header={header} isPinned={i === 0} />
                                                    ))}
                                                </SortableContext>
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody className="table__body txt14x21">
                                        {table.getRowModel().rows.map((row, rowI) => {
                                            const isLast = rowI === table.getRowModel().rows.length - 1
                                            return (
                                                <tr key={row.id} className="table__row" style={{ display: 'table-row' }}>
                                                    {row.getVisibleCells().map((cell, i) => (
                                                        <SortableContext
                                                            key={cell.id}
                                                            items={columnOrder}
                                                            strategy={horizontalListSortingStrategy}
                                                        >
                                                            <DragAlongCell
                                                                key={cell.id}
                                                                cell={cell}
                                                                isPinned={i === 0}
                                                                isLast={isLast}
                                                            />
                                                        </SortableContext>
                                                    ))}
                                                </tr>
                                            )

                                        })}
                                    </tbody>

                                </table>
                            </DndContext>
                        ) : (


                            <table
                                className="table__main "
                                style={{ borderLeft: isPinned && 'none' }}
                            //ref={tableRef}

                            >
                                <HeaderRow
                                    headers={table.getHeaderGroups()}
                                    tableRef={tableRef}
                                    headerItemWidth={headerItemWidth}
                                    trade_settings={trade_settings}
                                    columnFilters={columnFilters}
                                    isPinned={isPinned}
                                />
                                <TableBody
                                    rows={table.getRowModel().rows}
                                    highlightRow={highlightRow}
                                    textAlignCenterKeys={textAlignCenterKeys}
                                    tooltipKey={tooltipKey}
                                    extraRow={extraRow}
                                    keyBold={keyBold}
                                    tableColumnsLength={tableColumns.length}
                                    rowVirtualizer={rowVirtualizer}
                                    isPinned={isPinned}
                                />
                            </table>
                        )}
                    </div>

                    {/* {table.getPageCount() > 1 && (
                        <TablePagination table={table} />
                    )} */}
                </>
            )
            }
        </div>
    );
};

export default Table;
