import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import zoomPlugin, { resetZoom } from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import * as styles from '../../styles/utils/_variables.scss';
import merge from 'lodash.merge';
import useTabletView from '../../hooks/useTabletView';
import useMobileView from '../../hooks/useMobileView';
import useValueDependingOnView from '../../hooks/useValueDependingOnView';

import CustomeTooltip from "../Tooltip";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin, 
);

const tooltipLine = {
  id: 'tooltipLine',

  beforeDraw: (chart) => {
    if (chart?.tooltip?._active && chart?.tooltip?._active.length) {
      const ctx = chart.ctx;
      ctx.save();
      const activePoint = chart.tooltip._active[0];
      ctx.beginPath();
      ctx.setLineDash([5, 7]);
      ctx.moveTo(activePoint.element.x, chart.chartArea.top);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = styles.default.colorBlack;
      ctx.stroke();
      ctx.restore();
    }
  }
}

const LineChart = ({ 
  chartData, 
  options, 
  showZeroLine = false, 
  zeroLineValue = 0, 
  zeroLineColor = 'red',
  zoomEnabled = true,
  allowedClick = false,
  tooltip=null, 
  tooltipLeft=null,
}) => {

  const [isZoom, setIsZoom] = useState(false);

  const isTablet = useTabletView();
  const isMobile = useMobileView();

  const getValueDependingView = useValueDependingOnView()

  const chartRef = useRef(null);

  const handleDoubleClick = () => {
    if (chartRef.current) {
      if (isZoom) {
        resetZoom(chartRef.current);
        setIsZoom(false);
      } else {
        setIsZoom(true);

      }
    }
  };


  const zeroLinePlugin = {
    id: 'zeroLinePlugin',
    beforeDraw: (chart, options) => {
      const { showZeroLine } = chart.config.options.plugins.zeroLinePlugin;
      if (!showZeroLine) return;

      const yScale = chart.scales.y;
      const ctx = chart.ctx;
      let xAxis = yScale.getPixelForValue(zeroLineValue);
         
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.moveTo(chart.chartArea.left, xAxis);
        ctx.lineTo(chart.chartArea.right, xAxis);
        ctx.lineWidth = 2;
        ctx.strokeStyle = zeroLineColor;
        ctx.stroke();
        ctx.restore();
        
      
    }
  };

  useEffect(() => {
    const chartInstance = chartRef.current;
    if (chartInstance) {
      const canvas = chartInstance.canvas;

      canvas.addEventListener('dblclick', handleDoubleClick);

      return () => {
        canvas.removeEventListener('dblclick', handleDoubleClick);
      };
    }
  }, [handleDoubleClick]);




  const defaultOptions = {
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
        borderJoinStyle: 'miter'
      }
    },
   
    scales: {
      x: {
        title: {
          display: isMobile ? false : true,
          align: 'center',
          font: {
            size: 16,
            weight: '600',
          }
        },
        ticks: {
          maxTicksLimit: isMobile ? 5 : 10,
          font: {
            size: getValueDependingView(12, 10, 8),
          }
         
        }
      },
      y: {
        title: {
          display: isMobile ? false : true,
          font: {
            size: isTablet ? 12 : 16,
            weight: '600',
          }
        },

        ticks: {
          mirror: isTablet, 
          font: {
            size: isTablet ? 8 : 12,
          }
         
        }
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      datalabels: false,
      zeroLinePlugin: { showZeroLine },
      zoom: (zoomEnabled && isZoom) && {
        pan: {
          enabled: true,
          mode: 'xy',
          speed: 100,
          modifierKey: 'ctrl',
        },
        limits: {
          x: { min: 'original', max: 'original', minRange: 60 * 1000 },
          y: { min: 'original', max: 'original' },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true, 
            mode: 'xy'
          },
          mode: 'xy',
          drag: {
            enabled: true,
            backgroundColor: 'rgba(3, 3, 3, 0.3)',
            mode: 'xy',
          },
        },
      },
      title: {
        display: true,
        align: 'start',
        color: '#333333',
        font: {
          size: isTablet ? 16 : 20,
          lineHeigth: '27px',
          weigth: 500,
        }
      },
      legend: {
        align: 'end',
        labels: {
          usePointStyle: true,
          boxWidth: getValueDependingView(15, 10, 5),
          boxHeight: getValueDependingView(15, 10, 5),

          font: {
            size: getValueDependingView(12, 10, 8),
          }
        }
      },

    },
    //onHover: allowedClick ? handleHover : null,
  };

  const mergedOptions = merge({}, defaultOptions, options);

  return (
    <>
      {chartData ? (
      
        <div className="backgroundContainer" style={{ height: getValueDependingView('600px', '400px', '300px'), position: 'relative'}}>
          {tooltip && (
            <CustomeTooltip text={tooltip} maxWidth={300}  style={{
              position: 'absolute',
              top: isMobile ? 20 : 30, 
              left: tooltipLeft,
          }} />
          )}
            <Line ref={chartRef} data={chartData} options={mergedOptions} plugins={[tooltipLine, zeroLinePlugin]} />
        </div>
    
     
       
      ) : (
        <>
          {mergedOptions?.plugins?.title?.display && (
            <div style={{
              textAlign: 'left', padding: '10px', fontSize: '20px', lineHeight: '27px',
              fontWeight: '600',
              color: '#333',
            }}>
              {Array.isArray(mergedOptions?.plugins?.title?.text) ? mergedOptions?.plugins?.title?.text[0] : mergedOptions?.plugins?.title?.text}
            </div>
          )}
          <div style={{ textAlign: 'center', padding: '50px', fontSize: '20px' }}>
            No data
          </div>
        </>
      )}
    </>
  )
};

export default LineChart;
