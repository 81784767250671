
// const PORTFOLIO_BACKTEST_BASE_URL = process.env.REACT_APP_PORTFOLIO_BACKTEST_BASE_URL // || "https://backtest-v2-api.obscurial.org";
const PORTFOLIO_BACKTEST_BASE_URL = "https://backtest-v2-api.obscurial.org";


const PORTFOLIO_BACKTEST_URLS = {
    list_of_parquets: "/parquets",
    assets_from_files: "/assets",
    list_of_models: "/models",
    get_strategy_controls_by_name: "/models/{model_id}/configuration",
    candles_pair: "/candles/{pair}",
    download_data_from_google_storage: "/download_data_from_google_storage"
};

export { PORTFOLIO_BACKTEST_BASE_URL, PORTFOLIO_BACKTEST_URLS };