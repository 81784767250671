import statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'
import { buildQueryString } from '../../utils/functions';


export const pairsApi = statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPairs: builder.query({
      query: () => STATISTICS_API_URLS.pairs,
      providesTags: (result = []) => [
        //...result.map(({ pair }) => ({ type: 'Pairs', id: pair })),
        { type: 'Pairs', id: 'Pairs_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'Pairs')
      },
    }),

    fetchPairsStats: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.pairs_stats}${buildQueryString(filters)}` ,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'PairsStats', id: 'PairsStats_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PairsStats')
      },
    }),

    fetchPairCandles: builder.query({
      query: ({ pair, filters }) => `${STATISTICS_API_URLS.pairs_pair_candles.replace("{pair}", pair && pair.replace('/', '_'))}${buildQueryString(filters)}` ,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'PairCandles', id: `PairCandles_Pair${result?.[0]?.pair}_LIST` },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PairCandles')
      },
    }),

    fetchPairsBlacklist: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.pairs_blacklist}${buildQueryString(filters)}` ,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'PairsBlacklist', id: 'PairsBlacklist_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'PairsBlacklist')
      },
    }),

  }),

  overrideExisting: false,
});

export const {
  useFetchPairsQuery,
  useLazyFetchPairsStatsQuery,
  useLazyFetchPairCandlesQuery,
  useLazyFetchPairsBlacklistQuery
} = pairsApi;