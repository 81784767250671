import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useUrlFilters = (filters, setFilters, filterOptions) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [filtersInitialized, setFiltersInitialized] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const newFilters = [];
        
        params.forEach((value, key) => {
            if (Object.keys(filterOptions).includes(key)) {
                newFilters.push(filterOptions[key].type === 1 ? { id: key, value: value.split('|') } : { id: key, value });
            }
        });

        if (isFirstRender) {
            setFilters(newFilters);
            setIsFirstRender(false);
            setFiltersInitialized(true);
        } else {
            Object.keys(filterOptions).forEach(key => {
                params.delete(key);
            });

            filters.forEach(filter => {
                if (Object.keys(filterOptions).includes(filter.id)) {
                    const value = Array.isArray(filter.value) ? filter.value.join('|') : filter.value;
                    params.set(filter.id, value);
                }
            });

            navigate(`?${params.toString()}`, { replace: true });
        }
    }, [filters, navigate]);

    return filtersInitialized; 
};

export default useUrlFilters;



