const SaveWarning = ({ noClick, yesClick, title = 'Save changes?' }) => {
    return (
        <div className="saveWarning">
            <div className="saveWarning__title txt20x27">{title}</div>
            <div className="flexGap20">
                <button
                    className="button button--disable"
                    onClick={noClick}
                >
                    No
                </button>
                <button
                    className="button button--active"
                    onClick={yesClick}
                >
                    Yes
                </button>
            </div>
        </div>
    )

}

export default SaveWarning