import React, { useRef, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { dropdown, scale } from '../../utils/animations';
import { formatEnamKey } from '../../utils/functions';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { getPropertyString } from '../../pages/Settings';
import Icon from '../Icon';

const FilterInputs = ({ col, cellRef, setShowFilterInput }) => {
    const filterInputRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandend = () => {
        setIsExpanded(!isExpanded)
    }

    useEffect(() => {
        const handleScroll = () => {
            setShowFilterInput(null); 
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (filterInputRef.current && cellRef.current) {
            const cellRect = cellRef.current.getBoundingClientRect();
            const inputHeight = filterInputRef.current.offsetHeight;

            filterInputRef.current.style.left = `${cellRect.left}px`;
            filterInputRef.current.style.top = `${cellRect.top + window.scrollY - inputHeight}px`; 
        }
    }, [cellRef]);

    const columnFilterValue = col.getFilterValue();

    const sortedUniqueValues = useMemo(
        () => {
            const array =   Array.from(col.getFacetedUniqueValues().keys())
                .sort()
                .slice(0, 5000).map(el => getPropertyString(col.id, el));
                return array.filter(el => columnFilterValue ? el.toLowerCase().includes(columnFilterValue?.toLowerCase()) : el)
                //return array
        },
        [col.getFacetedUniqueValues(), columnFilterValue]
    );

    useOnClickOutside(filterInputRef, () => setShowFilterInput(null));

    return createPortal(
        <AnimatePresence>
            <motion.div
                ref={filterInputRef}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={scale}
                className="filterInputs"
                transition={{
                    duration: 0.2,
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
            >
               
                <div className="filterInputs__input flexColumnGap5 dropdown__list" style={{ position: 'relative'}}>
                    <input
                        className="input"
                        type="text"
                        placeholder={`Filter ${formatEnamKey(col.columnDef.header)}...`}
                        value={columnFilterValue || ''}
                        onChange={e => col.setFilterValue(e.target.value)}
                        
                    />
                    <button 
                        onClick={handleExpandend}
                        className={`filterInputs__input__toggle ${isExpanded ? 'filterInputs__input__toggle--open' : ''}`} 
                    >
                       
                        <Icon type={"dropdownDown"} size={20}/>
                       
                        
                    </button>

                        {columnFilterValue && (
                            <button className="filterInputs__input__close" onClick={() => col.setFilterValue('')}>
                                <Icon type="close" size={15} />
                            </button>
                        )}

                   
                </div>
                <AnimatePresence>
                    {isExpanded && (
                        <motion.div 
                            
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={dropdown}
                            transition={{ duration: 0.1, ease: "easeOut" }}
                            className="dropdown__list__main scrolling"
                        >
                            {sortedUniqueValues.map(value => (
             
                                <div 
                                    className="dropdown__item txt14x21"
                                    value={value} 
                                    key={value}
                                    onClick={() => col.setFilterValue(value)}
                                >
                                    {value}
                                </div>
                            ))}
                        </motion.div>
                            
                    
                    )}
                </AnimatePresence>
                 
               
               
                
            </motion.div>
        </AnimatePresence>,
        document.body
    );
}

export default FilterInputs;

