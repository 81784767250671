import Icon from "../Icon"
import Button from "./Button"

const DeleteButton = ({ deletingFunc }) => {
    return (
        <Button
            onClick={deletingFunc}
            className="button--widthMaxContent"
        >
            <Icon type="trash" size={20} />
        </Button>
    )
}

export default DeleteButton