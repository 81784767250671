import { memo, useEffect } from "react"
import FormModel from "../../components/FormModels/FormModel"
import { mainSettings__model } from "../FormModels/formConfig";

const MainSettings = memo(({ model, setModel}) => {

    useEffect(() => {
        setModel(mainSettings__model) 
    }, []);


    return (
        <div className="backgroundContainer">
            <FormModel inputModels={model} setInputModels={setModel} />
        </div>
    )
     
}) 

export default MainSettings;