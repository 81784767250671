import { createContext } from "react";

export const BacktestResultsContext = createContext(null);

export const BacktestResultsProvider = ({ children, value}) => {
    return (
        <BacktestResultsContext.Provider value={value} >
            {children}
        </BacktestResultsContext.Provider>

    )
}