import { motion, useAnimation } from "framer-motion";
import Icon from "../Icon"
import { useEffect } from "react";
import * as styles from '../../styles/utils/_variables.scss';

const ToggleSidebarButton = ({ handleOpen , isOpen, scrollDirection}) => {

    const controls = useAnimation(); 

    useEffect(() => {
        controls.start({
            top: scrollDirection === 'up' ? styles.default.headerHeight : '0',
        });
    }, [scrollDirection, controls]);

    return (
        <motion.button 
            className={`toggleSidebarButton ${isOpen ? 'toggleSidebarButton--open' : ''}`} 
            onClick={handleOpen}
            initial={{top:  styles.default.headerHeight }} 
            animate={controls} 
            transition={{ type: "tween", duration: 0.1, ease: "easeInOut" }}
        >
            <Icon type={isOpen ? 'arrowLeft' : 'arrowRight'} size={25} />
            
         </motion.button>
    )
}

export default ToggleSidebarButton