import { useCallback, useEffect, useState } from "react";
import useMobileView from "../../hooks/useMobileView";

const MultiRangeSlider = ({ min, max, value, step, onChange, modesArray, mode }) => {
  const [minValue, setMinValue] = useState(value ? value.min : min);
  const [maxValue, setMaxValue] = useState(value ? value.max : max);
  const [isDragging, setIsDragging] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const inMobile = useMobileView()

  useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const updateRange = useCallback(() => {
      if (!isDragging) {
          onChange({ min: minValue, max: maxValue });
      }
  }, [minValue, maxValue, isDragging]);

  useEffect(() => {
      if (!isDragging) {
          updateRange();
      }
  }, [isDragging, updateRange]);

  const onMinChange = (e) => {
    e.preventDefault();
    const newMinVal = +e.target.value; 
    setMinValue(newMinVal <= maxValue ? newMinVal : maxValue); 
    setActiveThumb('min')
};
 
const onMaxChange = (e) => {
    e.preventDefault();
    const newMaxVal = +e.target.value; 
    setMaxValue(newMaxVal >= minValue ? newMaxVal : minValue); 
    setActiveThumb('max');
};

  const startDrag = () => {
      setIsDragging(true);
  };

  const endDrag = () => {
      setIsDragging(false);
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

    return (
        <div className="multiRangeSlider">
            <div className="multiRangeSlider__inputWrapper">
                <input
                    type="range"
                    value={minValue}
                    min={min}
                    max={max}
                    step={step}
                    onMouseDown={startDrag}
                    onMouseUp={endDrag}
                    onTouchStart={startDrag}
                    onTouchEnd={endDrag}
                    onChange={onMinChange}
                    style={{ zIndex: activeThumb === 'min' ? 3 : 1 }}
                />
                <input
                    type="range"
                    value={maxValue}
                    min={min}
                    max={max}
                    step={step}
                    onMouseDown={startDrag}
                    onMouseUp={endDrag}
                    onTouchStart={startDrag}
                    onTouchEnd={endDrag}
                    onChange={onMaxChange}
                    style={{ zIndex: activeThumb === 'max' ? 3 : 2 }}
                />
            </div>
            <div className="multiRangeSlider__controlWrapper">
                
                <div className="multiRangeSlider__controlWrapper__rail">
                    <div
                        className="multiRangeSlider__controlWrapper__innerRail" 
                        style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
                    />
                </div>
                <div className="multiRangeSlider__controlWrapper__control" style={{ left: `${minPos}%` }}>
                    <div className="txt8x12">{inMobile ? modesArray[mode] === 'PnL' ? `${minValue.toFixed(2)}%` : `${minValue}` : modesArray[mode] === 'PnL' ? `PnL ${minValue.toFixed(2)}%` : `Count ${minValue}`}</div>
                </div>
                <div className="multiRangeSlider__controlWrapper__control multiRangeSlider__controlWrapper__control--top" style={{ left: `${maxPos}%` }}>
                    <div className="txt8x12">{inMobile ? modesArray[mode] === 'PnL' ? `${maxValue.toFixed(2)}%` : `${maxValue}` : modesArray[mode] === 'PnL' ? `PnL ${maxValue.toFixed(2)}%` : `Count ${maxValue}`}</div>
                </div>
            </div>
        </div>
    );
};

export default MultiRangeSlider;
