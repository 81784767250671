import React from 'react';
import Icon from '../Icon';
import IsSpinner from '../IsSpinner';

const StatusDisplay = ({ status }) => {

  const commonStyle = "textAlCenter bold txt16x24upper";

  if (status !== 'Finished' && status !== 'Error' && status !== 'Canceled') {
    return (
      <>
        <div className={`${commonStyle}`}>
          {`${status}`}
        </div>
        <IsSpinner />
      </>
    );
  }

  if (status === 'Error' || status === 'Canceled') {
    return (
      <>
        <div className={`${commonStyle} ${status === 'Error' ? 'colorRed' : ''} flexColumnGap5`}>
          {`${status}`}
          <div className="isFetching">
            <Icon type="close" size={100} />
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default StatusDisplay;
