import { useEffect, useState } from "react";
import { useUpdateStrategyPositionQuantityMutation, useUpdateStrategyStatusMutation, useUpdateStrategyBalanceMutation, useFetchStrategiesGeneralInfoQuery } from "../redux/statisticsServices/strategiesApi";
import { useFetchAccountQuery } from "../redux/statisticsServices/accountsApi";

import { getProperty } from "./Settings";
import IsFetching from "../components/IsFetching";
import Properties from "../components/Properties";
import { STRATEGYSTATUSES } from "../utils/enams";
import { useFetchStrategiesQuery } from '../redux/statisticsServices/strategiesApi';
import ActionButton from "../components/Buttons/ActionButton";
import InputSave from "../components/Inputs/InputSave";

import SaveWarning from "../components/SaveWarning";
import { SettingsStrategyPairs } from "../components/SettingsStrategyPairs";
import { formatFloat } from "../utils/functions";
import CommingSoon from "../components/CommingSoon";
import { useCloseAllStrategyPositionsMutation } from "../redux/statisticsServices/positionsApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Label from "../components/Label";
import useMobileView from "../hooks/useMobileView";
import useTabletView from "../hooks/useTabletView";
import Modal from "../components/Modal";

export function SettingsStrategyPage() {
    const { data, isFetching } = useFetchStrategiesGeneralInfoQuery();

    const { strategyName } = useParams();

    const [strategyItemInput, setStrategyItemInput] = useState({});

    const isMobile = useMobileView();
    const isTablet = useTabletView();
  

    useEffect(() => {
     
        if (data) {
            const selectedStrategy = data.find(strategy => strategy.strategy === strategyName);

            setStrategyItemInput(selectedStrategy);
        }

    }, [data, strategyName])

    const [isModal, setIsModal] = useState(false);
    const [currentSaveFunction, setCurrentSaveFunction] = useState(null);
    const accountId = strategyItemInput.account_id;

    const filter = [{
        'id': 'strategy_in',
        'value': strategyItemInput?.strategy
    }]
    const { data: strategyList, isFetching: isFetchingStrategyStats } = useFetchStrategiesQuery(filter, {
        skip: !strategyItemInput,
    });
    const strategyItem = strategyList && strategyList.length > 0 ? strategyList[0] : null;

    const { data: accountsData, isFetching: isFetchingAccounts, isError: isLoadingAccountsError } = useFetchAccountQuery(accountId, {
        skip: !accountId,
    });

    useEffect(() => {
        if (strategyItem) {
            setPositionQuantity(+strategyItem.position_quantity)
            setStrategyBalance(+strategyItem.total_balance);
        }
    }, [strategyItem])


    const [positionQuantity, setPositionQuantity] = useState();
    const [updateStrategyPositionQuantity] = useUpdateStrategyPositionQuantityMutation();

    const [strategyBalance, setStrategyBalance] = useState();
    const [updateStrategyStatus] = useUpdateStrategyStatusMutation();

    const [updateStrategyBalance] = useUpdateStrategyBalanceMutation();

    const [closeAllStrategyPositions] = useCloseAllStrategyPositionsMutation();

    if (isFetchingStrategyStats || isFetching || isFetchingAccounts || !strategyItemInput || !accountId) {
        return <IsFetching />
    }

    const hanldeStrategyQuantitySave = async () => {
        try {
            const strategyName = strategyItem.strategy;
            await updateStrategyPositionQuantity({ strategyName, positionQuantity }).unwrap();
            toast.success('Success in updating strategy position quantity')
        } catch (error) {
            console.error('Error, when updating strategy position quantity:', error);
            toast.error('Error, when updating strategy position quantity')
            //alert("Error, when updating strategy position quantity");
        } finally {
            handleCloseModal(false);
        }
    };

    const hanldeStrategyBalanceSave = async () => {
        try {
            const strategyName = strategyItem.strategy;
            await updateStrategyBalance({ strategyName, strategyBalance, accountId }).unwrap();
            toast.success('Success in updating strategy balance')
        } catch (error) {
            console.error('Error, when updating strategy balance:', error);
            toast.error('Error, when updating strategy balance')
            //alert("Error, when updating strategy balance");
        } finally {
            handleCloseModal(false);
        }
    };

    const hanldeStrategyStatusSave = async (status) => {
        try {
            const strategyName = strategyItem.strategy;
            await updateStrategyStatus({ strategyName, status }).unwrap();
            toast.success('Success in updating strategy status')
        } catch (error) {
            console.error('Error, when updating strategy status:', error);
            toast.error('Error, when updating strategy status')
            //alert("Error, when updating strategy status");
        } finally {
            handleCloseModal(false);
        }
    };

    const hanldeCloseAllStrategyPositions = async () => {
        try {
            const strategyName = strategyItem.strategy;
            await closeAllStrategyPositions(strategyName).unwrap();
            toast.success('Success in closing all strategy positions')
        } catch (error) {
            console.error('Error, when closing all strategy positions:', error);
            toast.error('Error, when closing all strategy positions')
            //alert("Error, when closing all strategy positions");
        } finally {
            handleCloseModal(false);
        }
    };


    const { account_id, exchange, strategy, status, position_quantity, can_buy, force_difference, update_time, balance, ...restData } = strategyItem;
    const selectedProperties = ['total_balance', 'free_balance']; // Replace with actual property keys

    // Check the condition and filter properties
    const dataToDisplay = restData.total_position_count === null
        ? Object.fromEntries(Object.entries(restData).filter(([key]) => selectedProperties.includes(key)))
        : restData;

    const handleOpenModal = (saveFunction) => {
        setCurrentSaveFunction(() => saveFunction);
        setIsModal(true);
    };

    const handleCloseModal = () => {
        setCurrentSaveFunction(() => null);
        setIsModal(false);
    };


    const trade_settings_properties = {
        "total_balance": "Загальний баланс",
        "free_balance": "Баланс в ВТС, який не задіяний на даний момент часу",
        "total_pnl": "Загальний PnL всіх позіцій",
        "avg_pnl": "Середній PnL кожної позиції",
        "max_pnl": "Найкращий PnL за позіцию",
        "min_pnl": "Найгірший PnL за позицію",
        "avg_pnl_percent": "Середній PnL у відсотках",
        "total_position_count": "Загальна кількість позицій",
        "win_position_count": "Кількість плюсових позицій",
        "loss_position_count": "Кількість збиткових позицій",
        "total_trades_count": "Загальна кільксть трейдів (купівля+продаж)",
        "buy_trades_count": "Кількість трейдів на покупку",
        "sell_trades_count": "Кількість трейдів на продаж",
        "avg_win_pnl": "Середній PnL плюсової позиції",
        "avg_loss_pnl": "Середній PnL збиткової позицій",
        "total_avg_duration": "Середня тривалість позицій",
        "avg_win_duration": "Середня тривалість плюсової позицій",
        "avg_loss_duration": "Середня тривалість збиткової позицій",
        "best_pair": "Найприбутковіша пара",
        "best_pair_pnl": "PnL найкращої пари"
    }

    const trade_settings_table = {
        "pair": "Назва пари",
        "total_pnl": "Загальний PnL",
        "avg_pnl": "Середній PnL",
        "max_pnl": "Найкращий PnL за угоду",
        "min_pnl": "Найгірший PnL за угоду",
        "avg_pnl_percent": "Відсоток PnL в середньому",
        "total_poition_count": "Загальна кількість угод",
        "win_position_count": "Кількість плюсових угод",
        "loss_position_count": "Кількість збиткових угод",
        "total_trades_count": "Загальна кількість трейдів (BUY+SELL)",
        "buy_trades_count": "Кількість трейдів на покупку",
        "sell_trades_count": "Кількість трейдів на продаж",
        "avg_win_pnl": "Середній PnL плюсових угод",
        "avg_loss_pnl": "Середній PnL збиткових угод",
        "total_avg_duration": "Середня тривалість всіх угод",
        "avg_win_duration": "Середня тривалість плюсової угоди",
        "avg_loss_duration": "Середня тривалість збиткової угоди"
    }

    return (
        <>
            
            <div className="itemPage" >
            <h2>
                {strategyItem.strategy.split('_')[1]}
            </h2>
                <Label label={'Account ID'} content={<span>{accountId}</span>} />

                <InputSave
                    value={exchange}
                    setValue={() => { }}
                    title={'Exchange '}
                    titleClass={"label"}
                    commonClass={"flexColumnGap5"}
                    handleSave={() => { }}
                    type="select"
                    options={[exchange]} // currently only one option
                    isSoon={true}
                    disabled={true}
                />

                <InputSave
                    value={positionQuantity}
                    setValue={setPositionQuantity}
                    title={'Position quantity'}
                    titleClass={"label"}
                    commonClass={"flexColumnGap5"}
                    handleSave={() => handleOpenModal(hanldeStrategyQuantitySave)}
                    type="number"
                />

                <div className={`strategy-config ${isTablet ? 'flexColumnGap20' : 'flexSpaceBetween'}`}>
                    <InputSave
                        value={strategyBalance}
                        setValue={setStrategyBalance}
                        title={'Strategy current balance'}
                        titleClass={"label"}
                        commonClass={"flexColumnGap5"}
                        handleSave={() => handleOpenModal(hanldeStrategyBalanceSave)}
                        type="number"
                    />

                    <Label 
                        label={'Account free balance'} 
                        content={isLoadingAccountsError ? "Error fetching account data" : <span>{`${formatFloat(accountsData?.free_balance)} `}<strong>BTC</strong></span>} 
                    />

                </div >

                <div className={`${isMobile ? 'flexColumnGap10 ' : 'flexGap20'}`}>
                    {strategyItem.status === +STRATEGYSTATUSES['ACTIVE'] && (
                        <>
                            <ActionButton action={'pause'} onClick={() => handleOpenModal(() => hanldeStrategyStatusSave(1))} />
                            <ActionButton action={'disable'} className={'button--disable'} onClick={() => handleOpenModal(() => hanldeStrategyStatusSave(+STRATEGYSTATUSES['DISABLED']))} />
                        </>

                    )}

                    {strategyItem.status === +STRATEGYSTATUSES['PAUSE'] && (
                        <>
                            <ActionButton action={'start'} className={'button--active'} onClick={() => handleOpenModal(() => hanldeStrategyStatusSave(0))} />
                            <ActionButton action={'disable'} className={'button--disable'} onClick={() => handleOpenModal(() => hanldeStrategyStatusSave(+STRATEGYSTATUSES['DISABLED']))} />
                        </>

                    )}

                    {strategyItem.status === +STRATEGYSTATUSES['DISABLING'] && (
                        <>
                            Strategy is decreasing balance. Target balance: <b>{strategyItem.balance}</b>
                        </>

                    )}

                    {(strategyItem.status === +STRATEGYSTATUSES['DISABLED']) && (
                        <CommingSoon top={-10} right={-5}>
                            <ActionButton
                                action={'start'}
                                className={'button--active'}
                                onClick={() => handleOpenModal(() => hanldeStrategyStatusSave(0))}
                                disabled={true}
                            />
                        </CommingSoon>
                    )}

                </div>

                {strategyItem.status !== +STRATEGYSTATUSES['DISABLED'] && (
                    <button 
                        className="button" 
                        style={{width: 200, alignSelf: 'center' }}
                        onClick={() => handleOpenModal(hanldeCloseAllStrategyPositions)}
                    >
                        Close all positions
                    </button>
                )}

                <Properties data={dataToDisplay} renderFunction={getProperty} trade_settings={trade_settings_properties} />

                <SettingsStrategyPairs strategy={strategy} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal} trade_settings={trade_settings_table} />

            </div >


            <Modal
                isModal={isModal}
                handleModal={handleCloseModal}
                content={
                    <SaveWarning noClick={handleCloseModal} yesClick={currentSaveFunction} />
                }
            />

        </>

    )
}