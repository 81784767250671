import { useMemo } from "react";
import { useFetchBacktestByIdPairsStatsQuery } from "../../redux/statisticsServices/backtestsApi";
import PositionsStatsTableBacktest from "./PositionsStatsTableBacktest"
import IsFetching from "../IsFetching";



const PositionsStatsTab = ({ backtestId, isFilters }) => {

    const queryParams = {
        backtestId, 
        ...(isFilters && { filters: [{ id: 'is_closed', value: true }] }) 
    };

    const { data: backtestByIdPairsStatsData, isFetching:  isFetchingBacktestByIdPairsStats} = useFetchBacktestByIdPairsStatsQuery(queryParams);

    const sortedBacktestByIdPairsStatsData = useMemo(() => {
        if(backtestByIdPairsStatsData) {
            return [...backtestByIdPairsStatsData].sort((a, b) => a.pair.localeCompare(b.pair))
        }
        
    }, [backtestByIdPairsStatsData]) 

    if (isFetchingBacktestByIdPairsStats) {
        return (
            <IsFetching />
        )
    }

    return (
        <PositionsStatsTableBacktest data={sortedBacktestByIdPairsStatsData} generalLink={`/backtests/backtestId/${backtestId}`}/> 

    )
}

export default PositionsStatsTab