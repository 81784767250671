import React from 'react';



export function EDACorrelation() {
    const edaCorrelationApiUrl = "http://34.118.90.52:8000/";

    return (
        <div className="iframe-container">
          <iframe
            className="iframe-fullscreen"
            src={edaCorrelationApiUrl}
            title="Introduction To WiseGPT"
            allowFullScreen
          ></iframe>
        </div>
    )
}
