import { useEffect, useState } from "react";
import IsFetching from "../../components/IsFetching";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { useFetchBacktestsQuery, useLazyFetchBacktestsStatsQuery } from "../../redux/statisticsServices/backtestsApi";
import { convertEnam, parseSearchParams } from "../../utils/functions";
import { BACKTESTSTATUSES } from "../../utils/enams";
import useUrlFilters from "../../hooks/useUrlFilters";
import { timeFrames } from "../../utils/variables";
import CompareBacktestsTable from "../../components/BacktestResult/CompareBacktestsTable";
import { useLocation } from "react-router-dom";
import { useFetchListOfModelsQuery } from "../../redux/portfolioBacktestServices/defaultApi";
import { useIsAppleDevice } from "../../hooks/useIsAppleDevice";

const CompareBacktestsPage = () => {
    const [filters, setFilters] = useState([]);
    const location = useLocation()

    const { data: backtestsData, isFetching: isFetchingBacktests } = useFetchBacktestsQuery();

    const { data: listOfModelsData, isFetching:  isFetchingListOfModels } =  useFetchListOfModelsQuery();

    const [trigerFetchBacktestsStats, { data: backtestsStatsData, isFetching: isFetchingbacktestsStatsData }] = useLazyFetchBacktestsStatsQuery()

    const [filtersInitialized, setFiltersInitialized] = useState(false);

    const statusEnum = convertEnam(BACKTESTSTATUSES);

    const isApple = useIsAppleDevice();


    const filterOptions = {

        backtest_id_in: {
            type: 1,
            values: backtestsData ? [...backtestsData].filter(backtest => statusEnum[backtest.status] === 'Finished').map(el => el.backtest_id).sort((a, b) => a.localeCompare(b)) : [],
        },


        model_in: {
            type: 1,
            values: listOfModelsData ? [...Object.keys(listOfModelsData)] : [],
        },

        timeframe_in: {
            type: 1,
            values: timeFrames,
        },

    }

    useUrlFilters(filters, setFilters, filterOptions);

    const searchParams = new URLSearchParams(location.search);
    const initialFiltersFull = parseSearchParams(searchParams);

    const initialFilters = [];

    initialFiltersFull.forEach(value => {

        if (Object.keys(filterOptions).includes(value.id)) {
            initialFilters.push({ id: value.id, value: value.value });
        }
    });

    useEffect(() => {
        if (initialFilters.length > 0) {
            trigerFetchBacktestsStats(initialFilters)
        } 

        setFiltersInitialized(true); 
    }, []);

    const handleApplyFilters = (filters) => {
        trigerFetchBacktestsStats(filters)
    }

    useEffect(() => {
        if(filtersInitialized) {
            handleApplyFilters(filters)
        }
    }, [JSON.stringify(filters)])


    if (isFetchingBacktests || isFetchingListOfModels || isFetchingbacktestsStatsData) {
        return (
            <>
                <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
                <IsFetching />
            </>
        )
    }

    return (
        <>
            <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
            <div className="itemPage" style={{paddingBottom: isApple ? 180 : 120 }}>

                <CompareBacktestsTable data={backtestsStatsData} />

            </div>
        </>
        
    )
}

export default CompareBacktestsPage