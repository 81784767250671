import { startOfDay, subWeeks, endOfDay } from "date-fns";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLazyFetchStrategiesBalancesQuery, useLazyFetchStrategiesQuery } from "../../redux/statisticsServices/strategiesApi";
import useUrlFilters from "../../hooks/useUrlFilters";
import { formatEnamKey, formatFloat, parseSearchParams, reorderKeys } from "../../utils/functions";
import IsFetching from "../../components/IsFetching";
import FilterSectionWithAplly from "../../components/PageLoyouts/FilterSectionWithAplly";
import { getProperty } from "../Settings";
import { useLazyFetchPairsStatsQuery } from "../../redux/statisticsServices/pairsApi";
import PairPnlChart from "../../components/Statistics/ PairPnlChart";
import PairPnLByStrategiesChart from "../../components/Statistics/PairPnLByStrategiesChart";
import AccountsTotalBalanceChart from "../../components/Statistics/AccountsTotalBalanceChart";
import { useLazyFetchAccountCommissionBalanceQuery, useLazyFetchAccountsStatisticGeneralQuery, useLazyFetchAccountsTotalBalanceQuery } from "../../redux/statisticsServices/accountsApi";
import ModelsBalancesChart from "../../components/Statistics/ModelsBalancesChart";
import { useFetchUserAccountQuery } from "../../redux/statisticsServices/authApi";
import { FilterContext } from "../../contexts/FilterContext";
import AccountCommissionBalanceChart from "../../components/Statistics/AccountCommissionBalanceChart";
import useMobileView from "../../hooks/useMobileView";
import StrategiesTable from "../../components/Statistics/StrategiesTable";

export const startTimestemp = startOfDay(subWeeks(new Date(), 1)).getTime();
export const endTimestemp = endOfDay(new Date()).getTime();

export const getCustomLink = (searchParams, key, value, subPath) => {
    searchParams.set(key, value);

    const newSearchString = searchParams.toString();

    return (
        <div className="link">
            <Link to={`/statistics/${subPath}?${newSearchString}`}>
               {getProperty(key, value)}
            </Link>
        </div>
    );

}

export function StatisticsGeneralPage() {
    const { filters, setFilters } = useContext(FilterContext);
    const location = useLocation();
    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();

    const [triggerFetchGeneralData, { data: generalData, isFetching: isFetchingGeneralData, isError: isErrorGeneralData }] = useLazyFetchAccountsStatisticGeneralQuery();
    const [triggerFetchStrategies, { data: strategiesFilteredData, isFetching: isFetchingStartegiesFiltered }] = useLazyFetchStrategiesQuery();
    const [triggerFetchPairsStats, { data: pairsStatsData, isFetching: isFetchingPairsStats, isError: isErrorPairsStats }] = useLazyFetchPairsStatsQuery();
    const [triggerFetchAccountaTotalBalance, { data: accountsTotalBalanceData, isFetching: isFetchingAccountsTotalBalance, isError: isErrorAccountsTotalBalance }] = useLazyFetchAccountsTotalBalanceQuery();
    const [triggerFetchStrategiesBalances, { data: strategiesBalancesData, isFetching: isFetchingStrategiesBalances }] = useLazyFetchStrategiesBalancesQuery();
    const [triggerFetchAccountCommissionBalance, { data: AccountCommissionBalanceData, isFetching: isFetchingAccountCommissionBalance }] = useLazyFetchAccountCommissionBalanceQuery();

    const firstRender = useRef(true);

    const isMobile = useMobileView();

    const filterOptions = {
        account_id_in: {
            type: 2,
            values: userAccountsData,
        },

        position_end_timestamp_gte: {
            type: 3
        },
        position_end_timestamp_lte: {
            type: 3
        }

    }

    useUrlFilters(filters, setFilters, filterOptions);

    function createFilterForAccountStatisticGeneral(filters) {
        return filters.filter(filter => filter.id !== 'account_id_in');
    }

    function createFilterForAccountsTotalBalance(filters) {

        const startFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        const endFilterTimestamp = filters.find(filter => filter.id === 'position_end_timestamp_lte')?.value;


        return [
            startFilterTimestamp && { id: "update_time_gte", value: startFilterTimestamp },
            endFilterTimestamp && { id: "update_time_lte", value: endFilterTimestamp },
        ];
    }

    function createFilterForStrategiesBalances(filterForAccountsTotalBalance, accountId) {

        return [
            { id: "timeframe", value: '15m' },
            { id: "account_id_in", value: accountId },
            ...filterForAccountsTotalBalance

        ].filter(filter => filter);
    }

    function createFilterForAccountCommissionBalance(filterForAccountsTotalBalance) {

        return [
            { id: "timeframe", value: '15m' },
            ...filterForAccountsTotalBalance

        ].filter(filter => filter);
    }

    const searchParams = new URLSearchParams(location.search);
    const initialFiltersFull = parseSearchParams(searchParams);


    const initialFilters = [];

    initialFiltersFull.forEach(value => {

        if (Object.keys(filterOptions).includes(value.id)) {
            initialFilters.push({ id: value.id, value: value.value });
        }
    });

    const applyFiltersAndFetchData = (filters, ifSetFilters = false) => {
        const accountId = filters.find(filter => filter.id === 'account_id_in')?.value;
        const filterForAccountsTotalBalance = createFilterForAccountsTotalBalance(filters);
        const filterForStrategiesBalances = createFilterForStrategiesBalances(filterForAccountsTotalBalance, accountId);
        const filterForAccountCommissionBalance = createFilterForAccountCommissionBalance(filterForAccountsTotalBalance);
        if(ifSetFilters) {
            setFilters(filters);
        }
        
        if (accountId) {
            triggerFetchGeneralData({ filters: createFilterForAccountStatisticGeneral(filters), accountId });
            triggerFetchAccountaTotalBalance({ filters: filterForAccountsTotalBalance, accountId });
            triggerFetchAccountCommissionBalance({ filters: filterForAccountCommissionBalance, accountId });
        }
    
        triggerFetchStrategies(filters);
        triggerFetchPairsStats(filters);
        triggerFetchStrategiesBalances(filterForStrategiesBalances);
    };

    useEffect(() => {
        if (firstRender.current) {

            firstRender.current = false;
        }

        const startFilterTimestamp = initialFilters.find(filter => filter.id === 'position_end_timestamp_gte')?.value;
        if (!userAccountsData) return

        if (!startFilterTimestamp) {
            const newFilters = [
                ...initialFilters,
                { id: 'position_end_timestamp_gte', value: startTimestemp },
                { id: 'position_end_timestamp_lte', value: endTimestemp },
                { id: 'account_id_in', value: userAccountsData[0] },

            ];
            applyFiltersAndFetchData(newFilters, true);


        } else {
            applyFiltersAndFetchData(initialFilters);
        }

    }, [userAccountsData]);

    const [visibleGeneralData, setVisibleGeneralData] = useState({});


    useEffect(() => {
        if (generalData) {
            // end balance from Binance
            // const freeBalancePercent = generalData.end_balance !== 0 ? `(${(generalData.account_free_balance / generalData.end_balance * 100).toFixed(2)}%)` : ``;
            // end balance from DB
            const freeBalancePercent = generalData.strategies_total_balance !== 0 ? `(${(generalData.account_free_balance_percent).toFixed(2)}%)` : ``;
            const strategiesUnusedBalancePercent = generalData.strategies_total_balance !== 0 ? `(${(generalData.strategies_unused_balance_percent).toFixed(2)}%)` : ``;

            const startBalance = generalData.start_balance !== 0 ? `${generalData.start_balance.toFixed(9)} BTC` : 'No data';
            const endBalance = generalData.end_balance !== 0 ? `${generalData.end_balance.toFixed(9)} BTC` : 'No data';
            const balanceChange = generalData.start_balance === 0 || generalData.end_balance === 0 ? 'No data' : `${generalData.balance_diff.toFixed(9)} (${(generalData.balance_diff_percent * 100).toFixed(2) }%)`

            const mergedData = !isErrorGeneralData ? {
                'Total position count': generalData.total_position_count,
                'Start | end balance (BTC)': `${startBalance} | ${endBalance}`,
                'Balance change': balanceChange,
                'Best pair': `${generalData.best_pair} (${formatFloat(generalData.best_pair_pnl)} BTC)`,
                'Best model': `${generalData.best_strategy} (${formatFloat(generalData.best_strategy_pnl)} BTC)`,
                'Total commission (BTC)': `${generalData.total_commission.toFixed(9)} (${(generalData.avg_commission_percent).toFixed(3)}%)`,
                'Account Total balance': `${generalData.account_total_balance.toFixed(9)} BTC`,
                'Account Free balance': `${generalData.account_free_balance.toFixed(9)} BTC ${freeBalancePercent}`,
                'Strategies Total balance': `${generalData.strategies_total_balance.toFixed(9)} BTC`,
                'Strategies Unused balance': `${generalData.strategies_unused_balance.toFixed(9)} BTC ${strategiesUnusedBalancePercent}`,
            } : {};

            setVisibleGeneralData(mergedData);
        }

    }, [generalData, isErrorGeneralData]);


    const sortedAccounts = useMemo(() => {
        if (accountsTotalBalanceData) {
            return !isErrorAccountsTotalBalance ? [...accountsTotalBalanceData].sort((a, b) => a.update_time - b.update_time) : []
        }
    }, [accountsTotalBalanceData, isErrorAccountsTotalBalance])

    const handleApplyFilters = (filters) => {
        applyFiltersAndFetchData(filters);
    }

    // if (isFetchingUserAccounts || firstRender.current) {
    //     return (
    //         <>
    //             <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
    //             <IsFetching />
    //         </>
    //     )
    // }

    if (strategiesFilteredData?.length === 0) {
        return (
            <>
                <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
                <div>NO DATA</div>
            </>

        )
    }

    const desiredKeyOrder = [
        'strategy',
        'balance',
        'total_balance',
        'free_balance',
        'total_pnl',
        'total_position_count',
        'total_trades_count',
        'avg_pnl',
        'avg_pnl_percent',
        'avg_win_pnl',
        'avg_loss_pnl',
        'total_avg_duration',
        'avg_win_duration',
        'avg_loss_duration',
        'max_pnl',
        'min_pnl',
        'turnover_pnl_percent',
        'win_position_count',
        'loss_position_count',
        'buy_trades_count',
        'sell_trades_count',
        'best_pair',
        'best_pair_pnl',
        'account_id',
        'exchange',
    ];


    const tableData = reorderKeys(strategiesFilteredData, desiredKeyOrder);

    const tableColumns = tableData?.[0] ? Object.keys(tableData[0])?.map((key, index) => ({
        accessorKey: key,
        header: key === 'strategy' ? 'Model' : formatEnamKey(key),
        filterFn:  'myCustomFilter',
        filterable: true,
        sortable: true,
        size: isMobile  ?  index === 0 ? 130 : 130 : 165,

        cell: info => {
            const value = info.getValue();
            if (key === 'strategy') {
                return getCustomLink(searchParams, key, value, 'strategies')
            }

            return getProperty(key, value)
        }

    })) : []

    

    // const sortedAccounts = accountsTotalBalanceData && [...accountsTotalBalanceData].sort((a, b) => a.update_time - b.update_time);

    const getGeneralInfo = (key, value) => {
        switch (key) {
            case 'Best model':
                const modelName = value.split(' ')[0];
                const otherData = value.split(' ')[1] + ' ' + value.split(' ')[2]

                return (
                    <>
                        <div className="txt16x24upper">
                            {key}
                        </div>
                        <div className="txt20x27">
                            {getCustomLink(searchParams, 'strategy', modelName, 'strategies')}
                            {otherData}
                        </div>
                    </>
                )

            case 'Best pair':
                const pairName = value.split(' ')[0];
                const otherDataPair = value.split(' ')[1] + ' ' + value.split(' ')[2]

                return (
                    <>
                        <div className="txt16x24upper">
                            {key}
                        </div>
                        <div className="txt20x27">
                            {getCustomLink(searchParams, 'pair', pairName, 'pairs')}
                            {otherDataPair}
                        </div>
                    </>
                )

            default:
                return (
                    <>
                        <div className="txt16x24upper">
                            {key}
                        </div>
                        <div className="txt20x27">
                            {value}
                        </div>
                    </>
                )

        }
    }

    return (
        <>
            <FilterSectionWithAplly filters={filters} setFilters={setFilters} filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} />
            <div className="itemPage statisticsGeneralPage">
                {isFetchingGeneralData ? (
                        <IsFetching height={200}/>
                    ) : (
                        <div className="backgroundContainer">
                            {Object.values(visibleGeneralData).length  === 0 ? (
                                <div className="noData">No Data</div>
                            ) : (
                                <div className="statisticsGeneralPage__mainInfo">
                        
                                <>
                                {Object.entries(visibleGeneralData).map(([key, value]) => {
                                        return (
                                            <div className="infoItem" key={key}>
                                                {getGeneralInfo(key, value)}
                                            </div>

                                        )
                                    })}
                                </>
                                
                            </div>
                            )}
                    </div>
                )}

                {isFetchingStartegiesFiltered || !tableData ? (
                    <IsFetching height={200} />
                ) : (
                  <StrategiesTable tableData={tableData} tableColumns={tableColumns}/>
                )}
                {isFetchingAccountsTotalBalance ? (
                    <IsFetching height={200} />
                ): (
                    <AccountsTotalBalanceChart data={sortedAccounts} />
                )}

                {isFetchingStrategiesBalances ? (
                    <IsFetching height={200} />
                ) : (
                     <ModelsBalancesChart data={strategiesBalancesData} />
                )}

                {isFetchingAccountCommissionBalance ? (
                    <IsFetching />
                ) : (
                    <AccountCommissionBalanceChart data={AccountCommissionBalanceData} accountId={filters.find(filter => filter.id === 'account_id_in')?.value} />
                )}
              
               
             

                {isFetchingPairsStats ? (
                    <IsFetching height={200}/>
                ) : (
                     <PairPnlChart pairsStatsData={pairsStatsData} />
                )}


                {isFetchingStartegiesFiltered  ? (
                    <IsFetching height={200} />
                ) : (
                      <PairPnLByStrategiesChart strategiesData={strategiesFilteredData} />
                )}
            </div>
        </>

    )
}
