import Dropdown from "../Inputs/dropdown/Dropdown";


const DropdownWithSearchOneValue = ({ title, values, handleSetFilter, activeItem }) => {
   
    return (
        <Dropdown 
            input={activeItem} 
            update={(value) => handleSetFilter(title, value)}
            renderSpan={() => (
               activeItem ? activeItem.value : title
            )}
            values={values}
            height={33}
        />
    )
}

export default DropdownWithSearchOneValue;
