import statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'
import { buildQueryString } from '../../utils/functions';

export const strategiesApi = statisticsApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchStrategiesGeneralInfo: builder.query({
      query: () => STATISTICS_API_URLS.strategies,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'StrategiesGeneralInfo', id: 'StrategiesGeneralInfo_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'StrategiesGeneralInfo')
      },
    }),

    fetchStrategiesBalances: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.strategies_balances}${buildQueryString(filters)}`,
      providesTags: (result = []) => [
        //...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy })),
        { type: 'StrategiesBalances', id: 'StrategiesBalances_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'StrategiesBalances')
      },
    }),

    fetchStrategies: builder.query({
      query: (filters) => `${STATISTICS_API_URLS.strategies_stats}${buildQueryString(filters)}`,
      providesTags: (result = []) => [
        ...result.map(({ strategy }) => ({ type: 'Strategies', id: strategy.strategy })),
        { type: 'Strategies', id: 'Strategies_LIST' },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'Strategies')
      },
    }),

    updateStrategyPositionQuantity: builder.mutation({
      query: ({ strategyName, positionQuantity }) => ({
        url: STATISTICS_API_URLS.set_strategy_position_quantity.replace("{strategy_name}", strategyName) + `?position_quantity=${positionQuantity}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ strategyName, positionQuantity }, { dispatch, queryFulfilled }) {
        // validateResponse(dispatch, queryFulfilled, 'Strategies')
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchStrategies', undefined, (draft) => {
            const strategy = draft.find(s => s.strategy === strategyName);
            if (strategy) {
              strategy.position_quantity = positionQuantity;
            }
          })
        )
        try {
          await validateResponse(dispatch, queryFulfilled, 'Strategies')
        } catch {
          patchResult.undo()
        }
      },
    }),

    updateStrategyStatus: builder.mutation({
      query: ({ strategyName, status }) => ({
        url: STATISTICS_API_URLS.set_strategy_status.replace("{strategy_name}", strategyName) + `?status=${status}`,
        method: 'PATCH',
        body: []
      }),
      async onQueryStarted({ strategyName, status }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await validateResponse(dispatch, queryFulfilled, 'Strategies');
          dispatch(
            statisticsApi.util.updateQueryData('fetchStrategies', [{
                  'id': 'strategy',
                  'value': strategyName
              }], (draft) => {
              const strategy = draft.find(s => s.strategy === strategyName);
              if (strategy) {
                if (data["status"] !== null) {
                  strategy.status = data["status"];
                }
                if (data["balance"] !== null) {
                  strategy.balance = data["balance"];
                }
              }
            })
          );
          dispatch(
            statisticsApi.util.updateQueryData('fetchStrategiesGeneralInfo', undefined, (draft) => {
              const strategy = draft.find(s => s.strategy === strategyName);
              if (strategy) {
                if (data["status"] !== null) {
                  strategy.status = data["status"];
                }
              }
            })
          );
        } catch {
          console.error(`Error while updating Strategy Status for ${strategyName}`);
        }
      },
    }),

    updateStrategyBalance: builder.mutation({
      query: ({ strategyName, strategyBalance, _ }) => ({
        url: STATISTICS_API_URLS.set_strategy_balance.replace("{strategy_name}", strategyName) + `?balance=${strategyBalance}`,
        method: 'PATCH',
        body: []
      }),
      async onQueryStarted({ strategyName, strategyBalance, accountId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await validateResponse(dispatch, queryFulfilled, 'Strategies');
          dispatch(
            statisticsApi.util.updateQueryData('fetchStrategies', [{
                'id': 'strategy',
                'value': strategyName
              }], (draft) => {
              const strategy = draft.find(s => s.strategy === strategyName);
              if (strategy) {
                if (data["status"] !== null) {
                  strategy.status = data["status"];
                }
                strategy.balance = +strategyBalance;
                strategy.total_balance += data["balance_diff"];
                strategy.free_balance += data["balance_diff"];
              }
            })
          );
          dispatch(
            statisticsApi.util.updateQueryData('fetchAccount', accountId, (draft) => {
              draft.free_balance -= data["balance_diff"];
            })
          );
          dispatch(
            statisticsApi.util.updateQueryData('fetchStrategiesGeneralInfo', undefined, (draft) => {
              const strategy = draft.find(s => s.strategy === strategyName);
              if (strategy) {
                if (data["status"] !== null) {
                  strategy.status = data["status"];
                }
              }
            })
          );
        } catch {
          console.error(`Error while updating Strategy Balance for ${strategyName}`);
        }
      },
    }),


    fetchStrategyPairs: builder.query({
      query: (strategyName) => STATISTICS_API_URLS.strategy_pairs_stats.replace("{strategy_name}", strategyName),
      providesTags: (result = [], error, { strategyName }) => [
        { type: 'StrategyPairs', id: strategyName },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'StrategyPairs')
      },
    }),

    updateStrategyPairPositionQuantity: builder.mutation({
      query: ({ strategyName, pair, positionQuantity }) => ({
        url: STATISTICS_API_URLS.set_strategy_pairs_position_quantity.replace("{strategy_name}", strategyName).replace("{pair}", pair.replace('/', '_')) + `?position_quantity=${positionQuantity}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ strategyName, pair, positionQuantity }, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'StrategyPairs');
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchStrategyPairs', strategyName, (draft) => {
            const strategyPair = draft.find(s => s.pair === pair);
            if (strategyPair) {
              strategyPair.position_quantity = positionQuantity;
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),

    updateStrategyPairCanTrade: builder.mutation({
      query: ({ strategyName, pair, canTrade }) => ({
        url: STATISTICS_API_URLS.set_strategy_pairs_canTrade.replace("{strategy_name}", strategyName).replace("{pair}", pair.replace('/', '_')) + `?can_trade=${canTrade}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ strategyName, pair, canTrade }, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'StrategyPairs');
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchStrategyPairs', strategyName, (draft) => {
            const strategyPair = draft.find(s => s.pair === pair);
            if (strategyPair) {
              strategyPair.can_trade = canTrade;
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),

    strategyAdd: builder.mutation({
      query: (body) => ({
        url: STATISTICS_API_URLS.strategies,
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        validateResponse(dispatch, queryFulfilled, 'StrategiesGeneralInfo');
        const patchResult = dispatch(
          statisticsApi.util.updateQueryData('fetchStrategiesGeneralInfo', undefined, (draft) => {
            draft.push(arg); 
          })
        );
        try {
          await queryFulfilled;
        } catch {
          console.error(`Error while strategy ${arg.strategy} add`);
          patchResult.undo()
        }
      },
    }),


  }),


  overrideExisting: false,
});

export const {
  useFetchStrategiesGeneralInfoQuery,
  useLazyFetchStrategiesBalancesQuery,
  useFetchStrategiesQuery,
  useLazyFetchStrategiesQuery,
  useFetchStrategyPairsQuery,
  useUpdateStrategyPositionQuantityMutation,
  useUpdateStrategyStatusMutation,
  useUpdateStrategyBalanceMutation,
  useUpdateStrategyPairPositionQuantityMutation,
  useUpdateStrategyPairCanTradeMutation,
  useStrategyAddMutation
} = strategiesApi;