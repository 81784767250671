import React, { useState } from 'react';
import { Navigate, redirect, useLocation, useNavigate } from 'react-router-dom';
import { storeToken, validateToken } from '../utils/auth';
import { Links } from '../links.js';
import StatisticsAPI from '../api/statistics_api/statistics_api_connector.js';
//import { useLoginMutation } from '../redux/services/authApi.js';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/token.js';
import { routes } from '../App.js';
import { toast } from 'react-toastify';
import { startLinkIndex } from '../utils/variables.js';



const LoginForm = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    // const [loginMutation] = useLoginMutation();

    const dispatch = useDispatch();

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const redirect = urlSearchParams.get('redirect');


    const isTokenValid = validateToken();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };


    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setUsername('');
        setPassword('');

        try {
            const token = await StatisticsAPI.login(username, password);
            const tokenExpiration = Math.floor(Date.now() / 1000) + 3000000;
            storeToken(token, tokenExpiration, username);
            dispatch(setToken({ token, username }))
        
            // loginMutation(username, password);

            redirect ? navigate(redirect) : navigate(Links.home);
        } catch (error) {
            toast.error(error.message)
            console.error(error);
            //alert(error);
            
        }
    };

    
    return (
        isTokenValid ? (
            <>
                <Navigate to={routes[startLinkIndex].path} />
            </>
        ) : (
            <div className="loginForm">
                <h2 className="loginForm__title">Login</h2>
                <form
                    onSubmit={handleSubmit}
                    className="loginForm__form"
                >
                    <div className="loginForm__form__item">
                        <label
                            htmlFor="username"
                            className="txt12x18"
                        >
                            Username
                            :</label>
                        <input
                            className="input txt14x21"
                            type="username"
                            id="username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                            placeholder="Enter your username"
                        />
                    </div>
                    <div className="loginForm__form__item">
                        <label
                            htmlFor="password"
                            className="txt12x18"
                        >
                            Password:
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            className="input txt14x21"
                            placeholder="Enter your password"
                        />
                    </div>
                    <button
                        type="submit"
                        className="button txt14x21"
                    >
                        Login
                    </button>
                </form>
            </div>
        )
    );
};

export default LoginForm;
