import  statisticsApi, { validateResponse } from './statisticsApi';
import { STATISTICS_API_URLS } from '../../api/statistics_api/statistics_api_urls'
import { deleteToken, storeToken } from '../../utils/auth';

export const authApi = statisticsApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: STATISTICS_API_URLS.login,
                method: 'POST',
                body: credentials,
            }),
        }),

        logout: builder.mutation({
            query: () => ({
                url: STATISTICS_API_URLS.logout,
                method: 'POST',
            }),
        }),

        fetchUserAccount: builder.query({
            query: () => STATISTICS_API_URLS.user_accounts,
            transformResponse: (response) => {
                
                return response.sort((a, b) => a.length - b.length); 
            },
            providesTags: (result = []) => [
              //...result.map(({ pair }) => ({ type: 'Pairs', id: pair })),
              { type: 'UserAccounts', id: 'UserAccounts_LIST' },
            ],

            
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
              validateResponse(dispatch, queryFulfilled, 'UserAccounts')
            },
          }),
    }),
    overrideExisting: false,
});

export const authMiddleware = async (ctx, next) => {
    if (ctx.endpointName === 'login' && ctx.response) {
        const tokenExpiration = Math.floor(Date.now() / 1000) + 3000000;
        storeToken(ctx.response.data.token, tokenExpiration);
    } else if (ctx.endpointName === 'logout') {
        deleteToken();
    }
    return next();
}

export const { 
    useLoginMutation, 
    useLogoutMutation,
    useFetchUserAccountQuery
} = authApi;