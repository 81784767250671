import React from 'react';


export function CryptoBubbles() {
    return (
        <div className="iframe-container">
          <iframe
            className="iframe-fullscreen"
            src="https://cryptobubbles.net"
            title="Introduction To WiseGPT"
            allowFullScreen
          ></iframe>
        </div>
    )
}
