import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { routes } from '../App.js';
import { Logout } from './Logout';
import MenuItem from './MenuItem.js';
import Icon from './Icon.js';
import { sidebarVariants } from '../utils/animations.js';
import useOnClickOutside from '../hooks/useOnClickOutside.js';
import useTabletView from '../hooks/useTabletView.js';
import useScrollDirection from '../hooks/ useScrollDirection.js';
import useMobileView from '../hooks/useMobileView.js';


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    const isTablet = useTabletView();
    const isMobile = useMobileView();

    const navRef = useRef(null);

    useOnClickOutside(navRef, () => setIsOpen(false))


    useEffect(() => {
        const html = document.documentElement;
        html.style.overflowY = isOpen ? 'hidden' : '';
    }, [isOpen]);

    const scrollDir = useScrollDirection();
    const controls = useAnimation();

    useEffect(() => {
     
        if (isMobile) {
            if (scrollDir === 'down') {
                controls.start({ y: '-100%' });
            } else {
                controls.start({ y: '0%' });
            }
        } else {
            controls.start({ y: '0%' });
        }
    }, [scrollDir, controls, isMobile]); 

    return (
        
            <motion.header 
                className="header"
                initial={{ y: '0%' }}
                animate={controls}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
                <div className="section">
                    <div className="header__main">
                        {isTablet ? (
                            <>
                                <button className="header__toggle" onClick={toggleMenu}>
                                    <Icon type="burger" size={25} />
                                </button>
                                <motion.nav
                                    ref={navRef}
                                    className="header__nav"
                                    variants={ sidebarVariants}
                                    initial="closed"
                                    animate={isOpen  ? "open" : "closed"}
                                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                                
                                >
                            
                                    <button className="header__toggle" onClick={toggleMenu} style={{ alignSelf: 'end'}}>
                                        <Icon type="close" size={25} />
                                    </button>
                                    <div className= "flexColumnGap30" style={{ overflow: 'auto'}}>
                                        {routes.map(link => (
                                            <MenuItem link={link} key={link.title}/>
                                        ))}
                                    </div>
                                    
                                   
                                </motion.nav>
                            </>
                           
                        ) : (
                            <nav  className="header__nav">
                                {routes.map(link => (
                                    <MenuItem link={link} key={link.title}/>
                                ))}
                            </nav>
                        )}

                       
        
                        <div className="header__logout">
                            <Logout />
                        </div>
                    </div>
                </div>    
            </motion.header>
    );
}

export default Header;