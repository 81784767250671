import { useContext, useMemo, useState } from "react";
import AddStrategyForm from "../components/AddStrategyForm";
import IsSpinnerBlur from "../components/IsSpinnerBlur";
import { toast } from "react-toastify";
import { useStrategyAddMutation, useUpdateStrategyBalanceMutation } from "../redux/statisticsServices/strategiesApi";
import { convertModelToObject } from "../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { STRATEGYSTATUSES } from "../utils/enams";
import { TradeSettingsContext } from "../contexts/TradeSettingsContext";
import { useFetchUserAccountQuery } from "../redux/statisticsServices/authApi";
import IsFetching from "../components/IsFetching";

export function SeettingsAddNewStartegyPage() {
    const [model, setModel] = useState([]);
    const status = useMemo(() => model.find(el => el.key === 'status')?.value, [model]);
    const { data: userAccountsData, isFetching: isFetchingUserAccounts } = useFetchUserAccountQuery();
    const [strategyAdd, { data: strategyAddData, loading: strategyAddLoading }] = useStrategyAddMutation();
    const [updateStrategyBalance, { data: updateStrategyBalanceData }] = useUpdateStrategyBalanceMutation();
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();

    const { setFilters } = useContext(TradeSettingsContext);

    const navigate = useNavigate();

    if (isFetchingUserAccounts) {
        return (
            <>
                <IsFetching />
            </>

        )
    }

    const handleSubmit = async () => {

        const strategyData = convertModelToObject(model);

        const { strategy, balance, account_id, status } = strategyData;

        const formatedStatus = STRATEGYSTATUSES[status.toUpperCase()]

        const json = {
            ...strategyData,
            balance: 0,
            status: formatedStatus,
            can_buy: status === 'Active',
        }
        json.position_quantity = json.position_quantity === "" ? null : json.position_quantity;

        const searchParams = new URLSearchParams();


        try {
            setIsLoading(true)

            await strategyAdd(json).unwrap();

            if (balance !== 0) {
                toast.success("Strategy added, balance determined");
                await updateStrategyBalance({ strategyName: strategy, strategyBalance: balance, accountId: account_id }).unwrap();
            }
            toast.success("Strategy added is success");
            searchParams.set('status', formatedStatus);
            setFilters(prev => prev.map(el => el.id === 'status' ? { ...el, value: formatedStatus } : el))
            //window.location.replace(`/settings/strategy/${strategy}?status=${formatedStatus}`);
            navigate(`/settings/strategy/${strategy}?${searchParams.toString()}`)



        } catch (error) {
            console.error("Strategy add error", error);
            toast.error()
            //alert("Strategy add error", error);
        }

        setIsLoading(false)
    }


    return (
        <div className="itemPage" >
            <h2 >Add Strategy</h2>
            <div className="formContainer">
                <AddStrategyForm
                    model={model}
                    setModel={setModel}
                    userAccountsData={userAccountsData}
                />
            </div>

            <div className="formContainer">
                <button
                    className="button widthFull"
                    onClick={handleSubmit}
                >
                    Create
                </button>
            </div>


            {isLoading && <IsSpinnerBlur />}
        </div>

    )
} 