import { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import Icon from "../Icon";
import { motion } from "framer-motion";
import { dropdown } from "../../utils/animations";
import { formatEnamKey } from "../../utils/functions";
import { format, parse } from "date-fns";
import { getProperty } from "../../pages/Settings";
import TooltipWithoutButton from "../TooltipWithoutButton";
import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";

const Configuration = forwardRef(({ data, isConfigurationOpen, setIsConfigurationOpen }, ref) => {

    const { 
        mainAsset, 
    } = useContext(BacktestResultsContext)

    const [isTooltip, setIsTooltip] = useState(false);

    const assetsRef = useRef(null)

    useEffect(() => {
        return () => setIsConfigurationOpen(false) 
    }, [])

    const handleMouseAssetsSellEnter = useCallback((key) => {
        if (key === 'assets') {
            setIsTooltip(true);
        }
    }, []) 
    
    const handleMouseAssetsSellLeave = useCallback(() => {
        setIsTooltip(false)
    }, []) 

    const { api_params, portfolio_params, strategy_params, blacklist } = data;

    const titleArray = ['Api parameters', 'Portfolio parameters', 'Model parameters', 'Blacklist parameters']

    const convertDates = (dates) => {
        return dates.map(date => {
         
          const parsedDate = parse(date, 'yyyyMMdd', new Date());
          
          return format(parsedDate, 'dd.MM.yyyy');
        });
      }

    const dataArray = useMemo(() => {
        return [
            { 
                model: api_params.strategy,
                time_frame: api_params.time_frame,
                period: convertDates(api_params.period).join(' - '),
                assets: api_params.assets.join(', '),
            },

            {
                start_balance: portfolio_params.initial_equity,
                position_size: portfolio_params.position_size,
                size_type: portfolio_params.size_type,
                commission: portfolio_params.fees,
                stop_loss: portfolio_params.stop_loss,
                take_profit: portfolio_params.take_profit,
                slippage: portfolio_params.slippage_toggle ? 'dynamic' : portfolio_params.slippage,
                common_cash: portfolio_params.common_cash ? 'true' : 'false'
            },

            {  
                ...strategy_params
            }, 

            {
                
                ...(Object.values(blacklist).some(el => el.length > 0) ? blacklist : null)
            }, 

            
        ].filter(el => Object.values(el).length > 0 && el)
    }, [data]);

    const handleOpenConfiguration = () => {
        setIsConfigurationOpen(!isConfigurationOpen)
    }

    return (
        <motion.div 
            ref={ref}
            initial="hidden"
            animate={isConfigurationOpen ? 'visible' : 'hidden'}
            className="configuration"
        >
            <button 
                className={`dropdown__toggle txt18x27 bold ${isConfigurationOpen ? 'dropdown__toggle--open' : ''}`}
                onClick={handleOpenConfiguration}
            >
                <span>Configuration</span>
                <div className="dropdown__toggle__icon">
                     <Icon
                        type={'dropdownDown'} 
                        size={20}
                    />
                </div>
                   
              
    
            </button>

            {isConfigurationOpen && (
                <motion.div 
                    style={{ gridTemplateColumns: `repeat(${dataArray.length > 3 ? 3 : dataArray.length}, 1fr)`}}
                    variants={dropdown}
                    className="configuration__body scrollingHidden"
                >
                    {dataArray.map((el, index)=> {
                        return (
                            <div className="configuration__body__item flexColumnGap10" key={index}>
                                <p className="txt20x27 bold textAlCenter">{titleArray[index]}</p>
                                <div className="scrollingHidden">
                                     {Object.entries(el).map(([key, value], i) => {
                                    return (
                                        <div className="flexSpaceBetween" style={{ gap: 20, position: 'relative'}} key={i}>
                                            <span className="bold txt16x24">{`${formatEnamKey(key)}:`}</span>
                                            <div 
                                                className={`textOverflowEllipsis txt16x24 ${key === 'assets' ? 'colorBlue' : '' }`} 
                                                style={{ width: 'initial', maxWidth: 500 - dataArray.length * 75}}
                                                onMouseEnter={() => handleMouseAssetsSellEnter(key)}
                                                onMouseLeave={handleMouseAssetsSellLeave}
                                                ref={key === 'assets' ?  assetsRef : null}
                                            >
                                                {getProperty(key, value, mainAsset)}

                                               
                                               
                                            </div>

                                             {key === 'assets' && (
                                                    <TooltipWithoutButton 
                                                        text={value}
                                                        isVisible={isTooltip}
                                                        setIsVisible={setIsTooltip}
                                                        maxWidth={500}
                                                        parentWidth={1200 / dataArray.length}
                                                        parentRef={assetsRef}
                                                        //heightShift={200}
                                                    />
                                                )}
                                        </div>
                                        
                                    )
                                })}
                                </div>
                               
                            </div>
                        )
                    })}
                </motion.div>
            )}

           
            
        </motion.div>
    )
})

export default Configuration