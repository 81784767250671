const Textarea = ({ input, update, save}) => {
    const handleChange = (e) => {
        const value = e.target.value;
        update(input, value )
    }

    return (
        <label className="formModel__item ">
            {input.title}
            <div className="flexGap10" >

                <textarea
                    className="input"
                    value={input.value}
                    placeholder={input.placeholder || input.title.replace(/\s*\(.*?\)\s*/g, '')}
                    onChange={handleChange}
                    disabled={input.disabled}
                    rows={3} 
                    style={{ height: 96}}
            />
            {input.withSave && (
                <button 
                    className="button buttonSave" 
                    onClick={save}
                    type="button"
                >
                    Save
                </button>
            )}
            </div>
            
        </label>
    )
}

export default Textarea;