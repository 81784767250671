const EntityButton = ({ onClick, isActive, title, property, disabled=false, style={} }) => {
    return (
        <button
            className={`button entityButton ${isActive ? 'entityButton--active' : ''}`} 
            onClick={onClick}
            disabled={disabled}
            style={style}
        >
            <div className="textOverflowEllipsis  entityButton__title">{title}</div>
            <div className="entityButton__property txt12x18">{property}</div>
        </button>
    )
}

export default EntityButton;