import { format } from "date-fns";
import { pick_colors } from "../../utils/variables";
import { formatTimestemp } from "../../utils/functions";
import LineChart from "../Charts/LineChart";
import * as styles from '../../styles/utils/_variables.scss';
import Tooltip from "../Tooltip";
import useTabletView from "../../hooks/useTabletView";
import useValueDependingOnView from "../../hooks/useValueDependingOnView";

const AccountCommissionBalanceChart = ({ data = {}, accountId }) => {
  const isTablet = useTabletView();
  const getValueDependingView = useValueDependingOnView()

  const getData = (data) => {
    return Object.entries(data).filter(([key, value]) => value !== null).map(([key, value]) => {
      return {
        x: +key,
        y: value.commission_balance,
        updateTime: value.update_time,
        totalBalance: value.total_balance,
        forceDifference: value.force_difference
      }
    })
  }


  const chartData = {
    datasets: [
      {
        data: getData(data),
        borderColor: pick_colors[1],
        backgroundColor: `${pick_colors[1]}90`,
        label: accountId,
        fill: false,
        type: 'line',
        radius: 0,
        //hoverRadius: 15,
        borderWidth: 2.5,
        segment: {
          borderColor: (ctx) => {
            const { p0, p1 } = ctx;
            if (p1.raw.forceDifference !== 0) {
              return p1.parsed.y > p0.parsed.y ? styles.default.colorGreen : styles.default.colorRed;
            }
          }
        },

      }
    ]
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'HH:mm dd.MM.yyyy',
          displayFormats: {
            hour: 'HH:mm dd.MM.yyyy'
          }
        },
        adapters: {
          date: {
            zone: 'utc',
          }
        },
        ticks: {
          callback: function (value) {
            const date = new Date(value);
            const timeString = format(date, 'HH:mm');
            const dateString = format(date, 'dd.MM.yyyy');
            return [timeString, dateString];
          },
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + ' BNB'
          }
        },

        title: {
          text: 'Commission balance',
          align: 'center'
        }
      }
    },
    plugins: {
      tooltip: {
        intersect: false,

        backgroundColor: function (context) {
          return pick_colors[1]
        },
        displayColors: false,
        callbacks: {
          label: function (context) {
            const {
              y,
              totalBalance,
              forceDifference,
            } = context.raw;

            return [
              `Account: ${context.dataset.label}`,
              `Commision balance: ${y} BNB`,
              `Manual change: ${forceDifference} BNB`
            ].filter(el => el);

          },
          title: function (context) {

            const { updateTime } = context[0].raw;

            return formatTimestemp(updateTime);
          },
        }
      },

      title: {
        display: true,
        align: 'start',
        text: 'Account commission balance', // TODO
        color: '#333333',
      },
      legend: {
        display: true,

        align: 'end',
        labels: {
          usePointStyle: true,
        }
      },

    }
  };

  return (
    <>
      <LineChart
        chartData={chartData}
        options={chartOptions}
        tooltip="Баланс BNB для комісій"
        tooltipLeft={getValueDependingView(310, 250, 235)}
      />
    </>
  )
}

export default AccountCommissionBalanceChart;