import { createContext } from "react";

export const BacktestsContext = createContext(null);

export const BacktestsProvider = ({ children, value}) => {
    return (
        <BacktestsContext.Provider value={value} >
            {children}
        </ BacktestsContext.Provider>

    )
}