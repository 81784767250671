import { useCallback } from "react";
import Number from "./Inputs/Number";
import Select from "./Inputs/Select";
import Radio from "./Inputs/Radio";
import Slider from "./Inputs/Slider";
import Checkbox from "./Inputs/Checkbox";
import SelectBox from "./Inputs/SelectBox";
import Toggle from "./Inputs/Toggle";
import DateInput from "./Inputs/DateInput";
import String from "./Inputs/String";
import Textarea from "./Inputs/Textarea";
import useMobileView from "../../hooks/useMobileView";

const FormModel = ({ inputModels, setInputModels, saveFunctions={} }) => {
    const isMobile = useMobileView()

    const update = useCallback((input, value) => {
        const newModels = inputModels.map(el => {
            if (el.key=== input.key) {
                return { ...el, value: value }; 
            }
            return el;
        });

        setInputModels(newModels);
    }, [inputModels, setInputModels]); 

    const onFocus = (input) => {
        setInputModels(prev => prev.map(el => el.key === input.key ? {...el, error: false} : el))
    }

    const getForm = (item) => {
        if(item.show === false) return null
    
        const { type } = item;
        let commonProps = { input: item, update: update, onFocus};

        if (item.withSave) {
            commonProps.save = saveFunctions[item.key];  
        }

        switch (type) {
            case 'string': return <String {...commonProps} />;
            case 'number': return <Number {...commonProps} />;
            case 'select': return <Select {...commonProps} />;
            case 'radio': return <Radio {...commonProps} />;
            case 'slider': return <Slider {...commonProps} />;
            case 'checkbox': return <Checkbox {...commonProps} />;
            case 'selectbox': return <SelectBox {...commonProps} />;
            case 'toggle': return <Toggle {...commonProps} />;
            case 'date': return <DateInput {...commonProps} />;
            case 'textarea': return <Textarea {...commonProps} />;
            case 'header': return <p className="txt16x24upper" style={{ marginBottom: -15 }}>{item.title}</p>;
            default: return <div>{item.name}</div>;
        }
    };

   return (
    <form className="formModel">
        {inputModels.map((el, index) => {
            if (el.groupWithNext && !isMobile) {
                return (
                    <div key={el.key} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20}}>
                        {getForm(el)}
                        {getForm(inputModels[index + 1])}
                    </div>
                );
            } else if (!inputModels[index - 1]?.groupWithNext) {
                return (
                    <div key={el.key} style={{ display: el.show === false ? 'none' : 'initial'}}>
                        {getForm(el)}
                    </div>
                );
            }
            return null;
        })}
    </form>
   )
} 

export default FormModel