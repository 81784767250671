import { useNavigate } from "react-router-dom";
import Icon from "../Icon"
import useTabletView from "../../hooks/useTabletView";

const BackButton = ({ path }) => {
    const navigate = useNavigate();
    const isTablet = useTabletView();

    const handleNavigate = () => {
        navigate(path)
    }
    return (
        <button onClick={handleNavigate}>
            <Icon type="arrowLeft" size={isTablet ? 35 : 40} />
        </button>
    )
}

export default BackButton