

import { getProperty } from "../../pages/Settings";
import { formatEnamKey } from "../../utils/functions";
import Table from "../Table/Table";


const GeneralPositionsStatsTable = ({ data = [], positionsTableClick, isOpen}) => {


    const groupedData = data.reduce((acc, { strategy, percent_pnl, buy_quote_qty}) => {
        if(acc[strategy]) {
            acc[strategy].totalWeightedPnl += percent_pnl * buy_quote_qty;
            acc[strategy].totalQty += buy_quote_qty;
            acc[strategy].count += 1;
        } else {
            acc[strategy] = {
                strategy,
                totalWeightedPnl: percent_pnl * buy_quote_qty,
                totalQty: buy_quote_qty,
                count: 1
            }
        };
        return acc
    }, {});

    const tableData = Object.values(groupedData).map(el => {
        return {
            strategy: el.strategy,
            avg_pnl_percent: el.totalWeightedPnl / el.totalQty,
            total_qty: el.totalQty,
            positions_count: el.count
        }
    })

    const tableColumns = tableData[0] && Object.keys(tableData[0]).map((key, i) => ({
        accessorKey: key,
        header: key === 'strategy' ? 'Model' : formatEnamKey(key),
        filterFn:  'myCustomFilter',
        filterable: true,
        sortable:  true,
        minSize: 150,

        size: '25%',
      


        cell: info => {

            const value = info.getValue();           

            switch (key) {

                case 'strategy':
                    return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                            <button className="link" onClick={() => positionsTableClick('strategy_in', [value])} >
                                {getProperty(key, value)}
                            </button>                 
                    );

              
                default:
                    return getProperty(key, value);
            }
        },
    }))

    const trade_settings = isOpen 
    ? {
        "strategy": "Модель",
        "avg_pnl_percent": "Середній PnL по відкритим угодам",
        "total_qty": "Баланс BTC задіяний у відкритих угодах",
        "positions_count": "Кількість відкритих угод зараз"
        }
    :  {
        "strategy": "Модель",
        "avg_pnl_percent": "Середній PnL по закритим угодам",
        "total_qty": "Баланс BTC який був задіяний в угодах",
        "positions_count": "Кількість закритих угод за обраний період"
    }

    return (
        <Table
            tableData={tableData.length === 0 ? null : tableData}
            tableColumns={tableColumns}
            title={`Positions ${isOpen ? 'Open' : 'Closed'} General Statistic`} 
            //csvData={data}
            trade_settings={trade_settings}
         />
    )
}

export default GeneralPositionsStatsTable;