import { classnames } from "../../../utils/functions";

const Number = ({ input, update, save, onFocus = () => {} }) => {


    const handleChange = (e) => {
        const value = e.target.value;
        update(input, value === "" ? '' : +value)
    }

    const handleBlur = (e) => {
        const value = e.target.value;
        let validatedValue = value;

        if (value === "") {
            update(input, '');
            return;
        }

        const numValue = parseFloat(value);

        if (input.min_max) {
            if (numValue < input.min_max[0]) {
                validatedValue = input.min_max[0];
            } else if (numValue > input.min_max[1]) {
                validatedValue = input.min_max[1];
            }
        }

        if (input.step) {
            const step = parseFloat(input.step);
            const min = input.min_max ? parseFloat(input.min_max[0]) : 0;
            const delta = (numValue - min) % step;
            if (delta !== 0) {
                validatedValue = numValue - delta + (delta > step / 2 ? step : 0);
            }
        }

        if (validatedValue !== value) {
            update(input, validatedValue);
        }
    };

    const handleOnFocus = () => {
        onFocus(input)
    }

    return (
        <label className={classnames('formModel__item', {'formModel__item--error': input.error})}>
            {input.error ? `${input.title} *`: input.title}
            <div className="flexGap10" >
                <input
                    className={classnames('input', {'input--error': input.error})} 
                    value={input.value}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    step={input.step || 'any'}
                    min={input.min_max ? input.min_max[0] : undefined}
                    max={input.min_max ? input.min_max[1] : undefined}
                    disabled={input.disabled}
                    onFocus={handleOnFocus}
                />
                {input.withSave && (
                    <button
                        className="button buttonSave"
                        onClick={save}
                        type="button"
                        style={{ minWidth: 70, whiteSpace: 'nowrap'}}
                    >
                        {input.actionTitle ? input.actionTitle : 'Save'}
                    </button>
                )}
            </div>

        </label>
    )
}

export default Number;