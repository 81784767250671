import { useEffect, useState } from "react";
import ToggleSwitch from "../../Inputs/ToggleSwitch";

const Toggle = ({ input, update}) =>{

    const [value, setValue] = useState(false)

    useEffect(() => {
        setValue(input.value)
    }, [])

    useEffect(() => {
        update(input, value)
    }, [value])

    return (
        <div  className="formModel__item">
            {input.title}
            <ToggleSwitch value={value} setValue={setValue}/>
        </div>
    )
}

export default Toggle;