import { Link } from "react-router-dom";
import { getProperty } from "../../pages/Settings";
import { formatEnamKey } from "../../utils/functions";
import Table from "../Table/Table";
import { useContext } from "react";
import { BacktestResultsContext } from "../../contexts/BacktestResultsContext";

const PositionsStatsTableBacktest = ({ data = [], generalLink }) => {

    const { 
        mainAsset, 
    } = useContext(BacktestResultsContext)

    const tableData = data;   

    const tableColumns = tableData[0] && Object.keys(tableData[0]).map(key => ({
        accessorKey: key,
        header: key === 'strategy' ? 'Model' : formatEnamKey(key.replace('timestamp', '')),
        filterFn:  'myCustomFilter',
        filterable: true,
        sortable: true,

        cell: info => {
            const value = info.getValue();
            if(key === 'pair') {
                return (
                    <div className="link">
                        <Link to={`${generalLink}/pairs?pair=${value}`}>
                            {value}
                        </Link>
                    </div>
                )
            }

            return getProperty(key, value, mainAsset)
        } 
    }))

    return (
        <Table
            tableData={tableData.length === 0 ? null : tableData}
            tableColumns={tableColumns}
            headerItemWidth={150}
            title="Positions Statistics"
            csvData={tableData}
        />
    )
}

export default PositionsStatsTableBacktest;