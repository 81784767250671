import { useState } from "react";
import BackButton from "../../components/Buttons/BackButton";
import Button from "../../components/Buttons/Button";
import IsSpinnerBlur from "../../components/IsSpinnerBlur";
import { useNavigate, useParams } from "react-router-dom";
import NewMLModelVersionForm from "../../components/Forms/NewMLModelVersionForm";
import { convertModelToObject, formValidation } from "../../utils/functions";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useMlModelVersionAddMutation } from "../../redux/statisticsServices/mlModelsApi";

export function AddNewMLModelVersionPage() {
    const [model, setModel] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [mlModelVersionAdd] = useMlModelVersionAddMutation();

    const { mlModelName } = useParams();

    const handleSubmit = async () => {

        const mlModelVersionData = convertModelToObject(model);

        const { payload, training_start_date, training_end_date, testing_start_date, testing_end_date } = mlModelVersionData

        const isValidateError = formValidation(model, setModel)


        if(isValidateError) {
            toast.error("Not all fields are filled in");
            return;
        }

        const json = {
            ...mlModelVersionData,
            payload: payload.length > 0 ? {Custom: payload} : {},
            training_start_date: format(new Date(training_start_date), 'yyy-MM-dd'),
            training_start_month: format(new Date(training_start_date), 'yyy-MM-dd'),
            training_end_date: format(new Date(training_end_date), 'yyy-MM-dd'),
            training_end_month: format(new Date(training_end_date), 'yyy-MM-dd'),
            testing_start_date: format(new Date(testing_start_date), 'yyy-MM-dd'),
            testing_end_date: format(new Date(testing_end_date), 'yyy-MM-dd'),
        }

        try {
            setIsLoading(true)

            await mlModelVersionAdd({body: json, modelName: mlModelName}).unwrap();

            toast.success("MLModelVersion added successfully")
            setTimeout(() => {
                navigate(`/ml_stat/ml_model/${mlModelName}`)
            }, 1000)
            

        } catch (error) {
            console.error("MLModelVersion add error", error);
            toast.error("MLModelVersion add error")
        }

        setIsLoading(false)

        
    }

    return (
        <div className="itemPage" >
            <div className="flexSpaceBetween" >
                <BackButton path={`/ml_stat/ml_model/${mlModelName}`} />
                <Button
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </div>
            <h2>Add MLModelVersion</h2>
            <NewMLModelVersionForm  model={model} setModel={setModel} mlModelName={mlModelName}/>

            {isLoading && <IsSpinnerBlur />}

        </div>
    )
}