import { hexToRgb } from "../../utils/functions";
import { pick_colors_doughnut } from "../../utils/variables";
import PolarAreaChart from "../Charts/PolarAreaChart";

const brightenedColors = pick_colors_doughnut.map(hex => {
  let color = hexToRgb(hex);
  return `rgba(${color.r + 30}, ${color.g + 30}, ${color.b + 30}, 1)`; 
});

const TotalPositionsPolarAreaChart = ({data}) => {
    const chartData = {
        labels: data.map(d => d.label), 
        datasets: [
          {
            label: 'Count',
            data: data.map(d => d.value), 
            backgroundColor: pick_colors_doughnut.map(el => `${el}CC`),
            hoverBackgroundColor: brightenedColors,
            borderWidth: 1
          }
        ]
      };

    const options = {
        plugins: {
            title: {
                text: 'Total Positions', 
              }
        }
    }
    return (
        <PolarAreaChart  options={options} data={chartData} />
    )
}

export default TotalPositionsPolarAreaChart