export const STATUSES = Object.freeze({
    ACTIVE: '0',
    IN_PROGRESS: '1',
    INACTIVE: '2'
})

export const STRATEGYSTATUSES = Object.freeze({
    ACTIVE: 0,
    PAUSE: 1,
    DISABLING: 2,
    DISABLED: 3,
})

export const BACKTESTSTATUSES = Object.freeze({
    Error: -1,
    Added: 0,
    CalculatingEstimates: 1,
    CalculatingBacktest: 2,
    SavingResults: 3,
    Finished: 4,
    Canceled: 5,
})
