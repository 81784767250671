import Table from "../Table/Table"

const StrategiesTable = ({ tableData, tableColumns, trade_settings }) => {
    return (
        <Table
            title={'Models'}
            tableData={Object.keys(tableData[0]).length < 13 ? null : tableData}
            tableColumns={tableColumns}
            headerItemWidth={150}
            trade_settings={trade_settings}
        />
    )
}

export default StrategiesTable