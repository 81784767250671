
import useMobileView from './useMobileView';
import useTabletView from './useTabletView';

const useValueDependingOnView = () => {
  const isMobile = useMobileView();
  const isTablet = useTabletView();

  const getValueDependingView = (full, tablet, mobile) => {
    if (isMobile) return mobile;
    if (isTablet) return tablet;
    return full;
  };

  return getValueDependingView;
};

export default useValueDependingOnView;
