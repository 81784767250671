import { createRef, useRef, useState } from "react";
import { flexRender } from '@tanstack/react-table';
import Icon from "../Icon";

import FilterInputs from './FilterInputs';
import Tooltip from "../Tooltip";
import useMobileView from "../../hooks/useMobileView";

const HeaderRow = ({ headers, tableRef, trade_settings, columnFilters, isPinned }) => {

    const [showFilterButton, setShowFilterButton] = useState(null);
    const [showFilterInput, setShowFilterInput] = useState(null);
    const headerRefs = useRef(headers[0].headers.map(() => createRef()));

    const isMobile = useMobileView()

    const handleHeaderMouseEnter = (id) => {

        setShowFilterButton(id);
    };

    const handleHeaderMouseLeave = () => {
        setShowFilterButton(null);
    };

    const handleShowFilterInput = (id) => {
        if (showFilterInput === id) {
            setShowFilterInput(null)
        } else {
            setShowFilterInput(id)
        }
    }

    return (
        <thead
            className="table__head"

        >
            {headers.map(headerGroup => (
                <tr
                    key={headerGroup.id}
                    style={{ display: 'flex', width: '100%', height: '100%' }}
                >
                    {headerGroup.headers.map((header, index) => (
                        <th
                            key={header.id}

                            className={`table__head__item ${isPinned ? 'table--stickyCell' : ''}`}
                            onMouseEnter={() => handleHeaderMouseEnter(header.id, index, 200)}
                            onMouseLeave={handleHeaderMouseLeave}
                            style={{
                                width: header.getSize(),
                                zIndex: isPinned && index === 0 && 11,
                            }}
                            ref={headerRefs.current[index]}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    onClick={header.column.columnDef.sortable ? header.column.getToggleSortingHandler() : null}
                                    className='flexGap5'
                                    style={{ marginRight: 5, cursor: header.column.columnDef.sortable ? 'pointer' : 'initial' }}
                                >
                                    {flexRender(header.column.columnDef.header, header.getContext())}

                                    {header.column.columnDef.sortable && (
                                        <div style={{ width: 10 }}>
                                            {header.column.getIsSorted()
                                                ? (header.column.getIsSorted() === 'desc'
                                                    ? <Icon type="down" size={10} />
                                                    : <Icon type="up" size={10} />
                                                )
                                                : ''
                                            }
                                        </div>
                                    )}


                                </div>

                                {trade_settings[header.id] && (
                                    <Tooltip text={trade_settings[header.id]} maxWidth={isMobile ? 80 : 200} />
                                )}

                                <div style={{ width: 40, cursor: header.column.columnDef.filterable ? 'pointer' : 'initial' }}>
                                    {(header.column.columnDef.filterable && (showFilterButton === header.id || showFilterInput === header.id || columnFilters.map(filter => filter.id).includes(header.id))) && (
                                        <button onClick={() => handleShowFilterInput(header.id)} >
                                            <Icon type="burger" />
                                        </button>
                                    )}
                                </div>

                                {/* {trade_settings[header.id] && (
                                <div
                                style={{showFilterInput
                                    left: '75%',
                                    top: '38%' }}>
                                    <Tooltip text={trade_settings[header.id]} down={true} />
                                </div>
                            )}

                            
                            {(header.column.columnDef.filterable && (showFilterButton === header.id || showFilterInput === header.id || columnFilters.map(filter => filter.id).includes(header.id))) && (
                                <div style={{
                                    position: 'absolute',
                                    width: '40px',
                                    left: '75%',
                                    top: '38%' }}>
                                
                                    <button onClick={() => handleShowFilterInput(header.id)} >
                                        <Icon type="burger" />
                                    </button>
                                </div>
                            )} */}
                            </div>

                            {showFilterInput === header.id && (
                                <FilterInputs
                                    col={header.column}
                                    cellRef={headerRefs.current[index]}
                                    setShowFilterInput={setShowFilterInput}
                                />
                            )}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default HeaderRow