import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STATISTICS_API_BASE_URL } from '../../api/statistics_api/statistics_api_urls'
import { deleteToken } from '../../utils/auth';
import { resetToken } from '../token';

const baseQuery = fetchBaseQuery({
  baseUrl: STATISTICS_API_BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');

    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  }
});

export const validateResponse = async (dispatch, queryFulfilled, tag) => {
  try {
    return await queryFulfilled;
    // const result = await queryFulfilled;
    // console.log(result)
    // if(!result.data) {
    //   const error = new Error("No Content");
    //   error.error = { status: 204, message: "No data found for the given filter." };
    //   throw error;
    // }
  } catch (error) {
    console.log(error);
    if (error?.error && error?.error?.status === 401) {
      deleteToken();
      dispatch(resetToken());

      if (tag) dispatch(statisticsApi.util.invalidateTags([tag]))
    }
    if (error?.error && error?.error?.status === 400) {
      console.log("ERROR 404");
    }
    // if (error?.error && error?.error?.status === 204) {
    //   console.log("ERROR 204")
    // }
  }
}

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: baseQuery,
  tagTypes: ['MLModels', 'ModelVersions'],
  endpoints: () => ({}),
});

export default statisticsApi;