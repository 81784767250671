import { useRef, useState } from 'react';
import useMobileView from '../../hooks/useMobileView';
import PageFilters from '../PageFilters';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import { motion } from 'framer-motion'
import { dropdown } from '../../utils/animations';

const FilterSectionWithAplly = ({ filters, setFilters, filterOptions, handleApplyFilters, title, isApply = false }) => {
    const isMobile = useMobileView();

    const sectionRef = useRef(null)

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        if(isMobile) {
            setIsOpen(!isOpen)
        }
    }

    const handleApplyFiltersAndClose = () => {
        handleApplyFilters(filters);
        setIsOpen(false)
    }

    useOnClickOutside(sectionRef, () => setIsOpen(false))

    return (
        <>
        {isMobile 
            ? (
                <motion.div
                    initial="hidden" 
                    animate={isOpen ? 'visible' : 'hidden'}
                    style={{ marginBottom: 20}}
                >
                    {isOpen ? (
                        <motion.div 
                            variants={dropdown}
                            ref={sectionRef} 
                            className={`filterSectionWithAplly ${isMobile ? 'flexColumnGap10' : 'flexSpaceBetween'}`}
                            style={{ gap: 15}}
                        >
                            <PageFilters filters={filters} setFilters={setFilters} filterOptions={filterOptions} notAll={true} /> 
                                {handleApplyFilters && isApply && (
                                    <button 
                                        className="button"
                                        onClick={handleApplyFiltersAndClose}
                                    >
                                        Apply filters 
                                    </button>
                                )}
                            
                        </motion.div>
                    ) : (
                        <button className='button widthFull' style={{marginTop: 10}} onClick={handleOpen}>
                            Filters
                        </button>
                    )}
                   
                   
                </motion.div>
               
            ) : (
                <div className={`filterSectionWithAplly ${isMobile ? 'flexColumnGap10' : 'flexSpaceBetween'}`}>
                    <PageFilters filters={filters} setFilters={setFilters} filterOptions={filterOptions} notAll={true} /> 
                        {handleApplyFilters && isApply && (
                            <button 
                                className="button"
                                onClick={() => handleApplyFilters(filters)}
                            >
                                Apply filters 
                            </button>
                        )}
                
                </div>
        )}

        {title && <h1>{title}</h1>}
           
            
        </>
    );
}

export default FilterSectionWithAplly;