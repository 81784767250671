import { createContext, useContext, useState } from 'react';

const AppModeContext = createContext();

export const useAppMode = () => useContext(AppModeContext);

export const AppModeProvider = ({ children }) => {
  const [mode, setMode] = useState('simple'); 

  const toggleMode = () => {
    setMode(prevMode => prevMode === 'simple' ? 'engineering' : 'simple');
  };

  return (
    <AppModeContext.Provider value={{ mode, toggleMode }}>
      {children}
    </AppModeContext.Provider>
  );
};
