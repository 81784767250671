import { PORTFOLIO_BACKTEST_BASE_URL } from "../../api/portfolio_backtest_api/portfolio_backtest_api_urls";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: PORTFOLIO_BACKTEST_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
  
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    }
  });

  export const portfolioBacktestApi = createApi({
    reducerPath: 'portfolioBacktestApi',
    baseQuery: baseQuery,
    //tagTypes: ['MLModels', 'ModelVersions'],
    endpoints: () => ({}),
  });
  
  export default portfolioBacktestApi;