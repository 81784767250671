import { memo, useEffect } from "react"
import FormModel from "../../components/FormModels/FormModel"
import { getFilledModel } from "../../utils/functions";
import { modelsControls__model } from "../FormModels/formConfig";
import { useSearchParams } from "react-router-dom";

const ModelsControls = memo(({ model, setModel, listOfModels, modelControlsData, isFetchingModelControls, isErrorModelControls}) => {
    const [searchParams] = useSearchParams();
    const strategy = searchParams.get('strategy');
   

    useEffect(() => {
        if (! listOfModels) return;
        
            const dataForModelsList = Object.keys(listOfModels);

            let newModel = modelsControls__model.map(el => el.key === 'strategy' 
            ? {
                ...el,
                value: strategy ?  dataForModelsList.find(el => el.toLowerCase() === strategy?.toLowerCase())  : null,
                values: dataForModelsList,
                } 
            : el );

            setModel(newModel)
            
    }, [listOfModels]);

    useEffect(() => {

        if(modelControlsData) {
            const newConfig = Object.entries(modelControlsData).map(el => ({
                key: el[0],
                ...{
                    ...el[1],
                    type: el[1].input
                } 
            }))

            setModel(model => [...model.filter(el => el.key === 'strategy'), ...newConfig]);

        }

    }, [modelControlsData])

    useEffect(() => {
        if(isErrorModelControls) {
            setModel(getFilledModel(model, modelsControls__model));
        }
        
    }, [isErrorModelControls])

    return (
        <div className="backgroundContainer" style={{ opacity: isFetchingModelControls ? 0.5 : 1}}>
           <FormModel inputModels={model} setInputModels={setModel} />
           {isErrorModelControls && <div style={{ marginTop: 20}}>IS ERROR FETCHING DATA</div>}
        </div>
        
    ) 
}) 

export default ModelsControls;