import React, { useContext, useEffect, useMemo, useState } from 'react';
import { STRATEGYSTATUSES } from '../utils/enams';
import { useFetchStrategiesGeneralInfoQuery } from '../redux/statisticsServices/strategiesApi';
import { formatFloat, formatDuration, getFilteredArray, convertEnam } from '../utils/functions';
import Icon from '../components/Icon'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import IsFetching from '../components/IsFetching';
import * as styles from '../styles/utils/_variables.scss';
import useUrlFilters from '../hooks/useUrlFilters';
import PageFilters from '../components/PageFilters';
import EntityButton from '../components/Buttons/EntityButton';
import TwoColumnLayout from '../components/PageLoyouts/TwoColumnLayout';

import { format } from 'date-fns';
import { TradeSettingsContext } from '../contexts/TradeSettingsContext';
import { SidebarContext } from '../contexts/SidebarContext';
import useScrollDirection from '../hooks/ useScrollDirection';
import { motion, useAnimation } from 'framer-motion';
import useMobileView from '../hooks/useMobileView';


export const formatAssetOrPercent = (key, value, asset) => {
    return (
        key.includes('percent') ? (
            <div
                style={{ color: value > 0 ? styles.default.colorGreen : styles.default.colorRed }}
                className='flexGap5'
            >
                <Icon type={value > 0 ? 'up' : 'down'} size={10} />
                {`${formatFloat(value)}%`}
            </div>
        ) : (
            <div
                style={{ color: value > 0 ? styles.default.colorGreen : styles.default.colorRed }}
                className='flexGap5'
            >
                <Icon type={value > 0 ? 'up' : 'down'} size={10} />
                {`${formatFloat(value)} ${asset}`}
            </div>
        )
    )
}

export const getProperty = (key, value, asset = 'BTC', isMobile = false) => {
    switch (true) {
        case key === 'strategy':
            return (
                <div>
                    {value.split('_')[1]}
                </div>
            )

        case key.includes('pnl'):
            // Check if key contains "percent"
            return formatAssetOrPercent(key, value, asset);

        case key.includes('price'):
            return (
                <div
                    className='flexGap5'
                >
                    {value ? `${formatFloat(value)} ${asset}` : 'N/A'}
                </div>
            );

        case (key === 'is_closed'):
            return (
                <div style={{ color: value ? styles.default.colorGreen : styles.default.colorRed }}>
                    <Icon type={value ? "done" : "inProcess"} size={25} />
                </div>
            )

        case (key === 'is_active'):
            return (
                <div style={{ color: value ? styles.default.colorGreen : styles.default.colorRed }}>
                    <Icon type={value ? "done" : "close"} size={isMobile ? 15 : 25} />
                </div>
            )

        case (key.includes('timestamp') || key.includes('time')) && !key.includes('time_frame'):
            return  !isNaN(new Date(value).getTime()) ? value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : '' : value;

        case key.includes('duration'):
            return formatDuration(value);

        case key.includes('percent'): // Handle percentage values
            return `${formatFloat(value)}%`;

        case key.includes('balance'): // Handle percentage values
            return `${formatFloat(value)}  ${asset}`;

        case Array.isArray(value):
            return `${value.map(el => el)}`

        default:
            return typeof value === 'number' ? formatFloat(value) : value;
    }
}

export const getPropertyString = (key, value, asset = 'BTC') => {
    switch (true) {
        case key === 'strategy':
            return value.split('_')[1]
           
        case key.includes('price'):
            return (
               
                    value ? `${formatFloat(value)} ${asset}` : 'N/A'
              
            );


        case  (key.includes('timestamp') || key.includes('time')) && !key.includes('time_frame'):
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');

        case key.includes('duration'):
            return formatDuration(value);

        case key.includes('percent'): // Handle percentage values
            return `${formatFloat(value)}%`;

        case key.includes('balance'): // Handle percentage values
            return `${formatFloat(value)}  ${asset}`;

        case Array.isArray(value):
            return `${value.map(el => el)}`

        default:
            return typeof value === 'number' ? formatFloat(value) : value;
    }
}

export function Settings() {

    useEffect(() => {
        document.body.classList.add('body--withSubheader');
        
        return () => {
          document.body.classList.remove('body--withSubheader');
        };
      }, []); 

    const isMobile = useMobileView();

    const scrollDirection = useScrollDirection(); 

    const controls = useAnimation(); 

    useEffect(() => {
        if (isMobile) {
        
            controls.start({
                top: scrollDirection === 'up' ? styles.default.headerHeight : '0',
            });
        } else {
        
            controls.start({
                top: styles.default.headerHeight
            });
        }
    }, [scrollDirection, controls, isMobile]);

    const location = useLocation();
    const navigate = useNavigate();
    const { strategyName } = useParams();

 

    const { data, isFetching } = useFetchStrategiesGeneralInfoQuery();

    const { filters, setFilters } = useContext(TradeSettingsContext);

    const { setIsOpen } = useContext(SidebarContext);

    const filterOptions = {
        status: {
            type: 0,
            values: STRATEGYSTATUSES,
        },
    }

    useUrlFilters(filters, setFilters, filterOptions);

    const [visibleStrategies, setVisibleStrategies] = useState([]);

    useEffect(() => {
        if (location.search.length === 0) {

            setFilters([{ id: 'status', value: STRATEGYSTATUSES.ACTIVE }])
        }
    }, [])

    useEffect(() => {
        if (data) {
            setVisibleStrategies(getFilteredArray(data, filters))
        }

    }, [filters, data])

    const handleSelectCurrentStrategy = (strategy) => {
        navigate(`strategy/${strategy.strategy}`);
        setIsOpen(false)
    };

    const handleAddNewStrategy = () => {
        navigate(`addStrategy`);
        setIsOpen(false)
    };
    const params = new URLSearchParams(location.search);

    const statusOfCurrentStrategy = useMemo(() => (data?.find(strategy => strategy.strategy === strategyName)?.status), [strategyName]);

    useEffect(() => {

        if (+filters[0]?.value !== statusOfCurrentStrategy && filters.length > 0 && statusOfCurrentStrategy !== undefined) {
            console.log(+filters[0]?.value !== statusOfCurrentStrategy && filters.length > 0 && statusOfCurrentStrategy !== undefined)
            filters.forEach(filter => params.set(filter.id, filter.value))
            navigate(`${'/settings'}?${params.toString()}`, { replace: true })
        }
    }, [params.get('status'), statusOfCurrentStrategy])

    if (isFetching) {

        return (
            <TwoColumnLayout rightContent={<IsFetching />} />
        )
    }

    const getBackground = (status) => {
        switch (status) {
            case 0:
                return styles.default.colorGreen;

            case 1:
                return styles.default.colorOrange;

            case 2:
                return styles.default.colorRedLite;

            default:
                return styles.default.colorRed;
        }
    }

    return (

        <TwoColumnLayout leftContent={
            <div>
                <EntityButton
                    title="+ Add"
                    style={{ height: 32, flexDirection: 'row' }}
                    onClick={handleAddNewStrategy}
                    isActive={location.pathname.includes('addStrategy')}
                />

                <div className="twoColumnLayout__leftContainer scrolling">
                    {visibleStrategies.map(strategy => {
                        const backgroundColor = getBackground(strategy.status)

                        return (
                            <div className="entityButtonContainer" key={strategy.strategy}>
                                <EntityButton
                                    isActive={strategyName && strategyName === strategy.strategy}
                                    onClick={() => handleSelectCurrentStrategy(strategy)}
                                    title={strategy.strategy.split('_')[1]}
                                    property={convertEnam(STRATEGYSTATUSES)[strategy.status]}
                                    style={{ background: backgroundColor }}
                                />
                            </div>
                        )

                    })}
                </div>

            </div>
        }

            filter={
                <motion.div 
                    className={`filterContainer`}  
                    initial={{top:  styles.default.headerHeight }} 
                    animate={controls} 
                    transition={{ type: "tween", duration: 0.1, ease: "easeInOut" }}
                >
                    <PageFilters filters={filters} setFilters={setFilters} filterOptions={filterOptions} />
                </motion.div>
            }>
        </TwoColumnLayout>
    )
}

