export const encryptKey = async (publicKey, data) => {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);

    const importedPublicKey = await window.crypto.subtle.importKey(
        "spki",
        Uint8Array.from(atob(publicKey), c => c.charCodeAt(0)),
        {
            name: "RSA-OAEP",
            hash: "SHA-256",
        },
        true,
        ["encrypt"]
    );

    const encryptedData = await crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        importedPublicKey,
        encodedData
    );

    return btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
};