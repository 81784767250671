const Label = ({ label, content }) => {
    return (
        <div className="flexColumnGap5">
            <label className="label">
                {label}
            </label>
            <div className="">
                {content}
            </div>
        </div>
    )
}

export default Label