import { useParams } from "react-router-dom";
import { useFetchAccountQuery, useSetAccountCommissionTradeMutation, useUpdateAccountFreeBalanceMutation, useUpdateAccountTradingBotUrlMutation } from "../../redux/statisticsServices/accountsApi";
import IsFetching from "../../components/IsFetching";
import { useEffect, useState } from "react";
import { account__model, buyBNB__model } from "../../components/FormModels/formConfig";
import { toast } from "react-toastify";
import { convertModelToObject, filledInputs } from "../../utils/functions";
import Separator from "../../components/Separator";
import FormModel from "../../components/FormModels/FormModel";
import AccountForm from "../../components/AccountForm";
import Label from "../../components/Label";
import useMobileView from "../../hooks/useMobileView";

export function AccountPage() {
    const { accountId } = useParams();

    const [model, setModel] = useState([]);

    const [buyBNBModel, setBuyBNBModel] = useState([]);

    const isMobile = useMobileView();


    const { data: accountData, isFetching: isFetchingAccount } = useFetchAccountQuery(accountId, {
        skip: !accountId,
    });

    const [updateAccountFreeBalance] = useUpdateAccountFreeBalanceMutation();
    const [updateAccountTradingBotUrl] = useUpdateAccountTradingBotUrlMutation();
    const [setAccountCommissionTrade] = useSetAccountCommissionTradeMutation();

    const handleUpdateAccountFreeBalance = async () => {
        try {
            const convertModel = convertModelToObject(model);
            await updateAccountFreeBalance({ accountId: accountId, freeBalance: convertModel.free_balance });
            toast.success('Free Balance was updated!')
        } catch (e) {
            console.log(`Was Free Balance updated error, ${e}`)
            toast.error(`Was Free Balance updated error`)
        }

    }

    const handleUpdateTradingBotUrl = async () => {
        try {
            const convertModel = convertModelToObject(model);
            await updateAccountTradingBotUrl({ accountId: accountId, tradingBotUrl: convertModel.trading_bot_url });
            toast.success('TradingBotUrl was updated!')
        } catch (e) {
            console.log(`Was TradingBotUrl updated error, ${e}`)
            toast.error(`Was TradingBotUrl updated error`)
        }

    }

    const handleSetAccountCommissionTrade = async () => {
        try {
            const convertModel = convertModelToObject(buyBNBModel);
            await setAccountCommissionTrade({ accountId: accountId, commissionBalance: convertModel.change_commission_balance_qty });
            toast.success('Commission balance set successfully!');
            setBuyBNBModel(buyBNB__model)
        } catch (e) {
            console.log(`There was an error when setting the commission balance, ${e}`)
            toast.error(`There was an error when setting the commission balance`)
        }

    }


    useEffect(() => {
        const accountPageConfig = account__model.map(input => {
            switch (input.key) {
                case 'free_balance':
                case 'trading_bot_url':
                    return {
                        ...input,
                        withSave: true,
                        disabled: false,
                    };
                case 'public_key':
                case 'api_key':
                case 'api_secret':
                case 'is_sandbox':
                    return {
                        ...input,
                        show: false,
                    };
                default:
                    return {
                        ...input,
                        disabled: true,
                    };
            }
        })
        if (!accountData) return;
        const inputsWithValue =  filledInputs(accountPageConfig, accountData) 

        setModel(inputsWithValue);
        setBuyBNBModel(buyBNB__model)

    }, [accountData])

    if (isFetchingAccount) {
        return <IsFetching />
    }

    return (
        <div className="itemPage" >
            <h2 >General</h2>
            <div className="formContainer">
                <AccountForm
                    model={model}
                    setModel={setModel}
                    saveFunctions={{
                        trading_bot_url: () => handleUpdateTradingBotUrl(),
                        free_balance: () => handleUpdateAccountFreeBalance(),
                    }}
                />
            </div>

            <Separator />

                <div className={ 'flexColumnGap30'} >
                    <h2>Commission</h2>
                    <div className={ isMobile ? 'flexColumnGap30' : 'flexSpaceBetween'}>
                        <Label label={'Account free balance'} content={<span>{`${accountData.free_balance} ${accountData.main_asset}`}</span>} />
                        <Label label={'Account commission balance'} content={<span>{`${accountData.commission_balance} ${accountData.main_asset}`}</span>} />
                    </div>
                    
                <div className="formContainer"> 
                    <FormModel 
                        inputModels={buyBNBModel} 
                        setInputModels={setBuyBNBModel}
                        saveFunctions={{
                            change_commission_balance_qty: () => handleSetAccountCommissionTrade()
                        }}
                    />

                </div>
                
               
            </div>

            

        </div>
    )
}