import { useEffect, useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';

export function useIsAppleDevice() {
    const [isAppleDevice, setIsAppleDevice] = useState(false);

    useEffect(() => {
        if (isIOS || isMacOs) {
            setIsAppleDevice(true);
        }
    }, []);

    return isAppleDevice;
}
